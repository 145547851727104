import React from "react";
import {
  Grid,
  Typography,
  Container,
  Box,
  Button,
  ButtonGroup,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
// import SearchBarRole from "./Components/SearchBarRole";
import CssBaseline from "@mui/material/CssBaseline";
import TableTeam from "./Components/TableTeam";
import Header from "../../component/header/Header";
import LeftDrawer from "../../component/leftDrawer/LeftDrawer";
// import AddRoleCard from "./Components/AddRoleCard";
import { useStyles } from "../question/Question.style";
import { Notification } from "../../Utils/SnackbarNotification/Notification";
import AddTeam from "./Components/AddTeam/AddTeam";
import UpdateTeam from "./Components/UpdateTeam/UpdateTeam";
import { styled } from "@mui/material/styles";
import Permission from "../../component/Permission/Permission";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const TeamManagement = () => {
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(0);
  const [opendrawer, setOpenDrawer] = React.useState(true);
  const [toggleTeam, setToggleTeam] = React.useState(true);
  const [toggleEditTeam, setToggleEditTeam] = React.useState(false);
  const [editUserData, setEditUserData] = React.useState("");
  const [overalldata, setOveralldata] = React.useState("");
  const [active, setActive] = React.useState("");
  const [inactive, setInactive] = React.useState("");
  const [teamCount, setTeamCount] = React.useState("All");

  const classes = useStyles();

  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("");

  const handleDialog = () => {
    setOpen(false);
    // setOpen(!open);
  };
  const ref = React.useRef(open);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAlert = () => {
    setAlert(false);
  };

  const handleClick = () => {
    <AddTeam />;
  };

  // console.log("active", active);
  // console.log("inactive", inactive);

  return (
    <Permission menu="Team">
      <>
        {toggleTeam ? (
          toggleEditTeam ? (
            <UpdateTeam
              setToggleEditTeam={setToggleEditTeam}
              editUserData={editUserData}
            />
          ) : (
            <>
              <Header />
              <div style={{ display: "flex" }}>
                <LeftDrawer open={opendrawer} />
                <Box
                  component="main"
                  sx={{ flexGrow: 1, p: 3 }}
                  style={{ background: "#EEEEEE" }}
                >
                  {/*<DrawerHeader/>*/}
                  <>
                    <Typography
                      sx={{
                        marginTop: "15px",
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "20px",
                        lineHeight: "25px",
                        color: "#0B0B0C",
                      }}
                    >
                      Team Management
                    </Typography>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Box>
                          <ButtonGroup
                            variant="text"
                            size="large"
                            color="inherit"
                            sx={{ marginTop: "12px" }}
                          >
                            <Button
                              sx={{
                                backgroundColor:
                                  teamCount === "All" ? "rgb(193,193,193)" : "",
                                textTransform: "none",
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "14px",
                                ":hover": {
                                  fontWeight: 700,
                                },
                              }}
                              onClick={() => {
                                setTeamCount("All");
                              }}
                            >
                              {`All (${overalldata})`}
                            </Button>
                            <Button
                              sx={{
                                backgroundColor:
                                  teamCount === "Inactive"
                                    ? "rgb(193,193,193)"
                                    : "",
                                textTransform: "none",
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "14px",
                                ":hover": {
                                  fontWeight: 700,
                                },
                              }}
                              // setTeamCount
                              onClick={() => {
                                setTeamCount("Inactive");
                              }}
                            >
                              {`Disabled (${inactive})`}
                            </Button>
                            <Button
                              sx={{
                                backgroundColor:
                                  teamCount === "Active"
                                    ? "rgb(193,193,193)"
                                    : "",
                                textTransform: "none",
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: "14px",
                                ":hover": {
                                  fontWeight: 700,
                                },
                              }}
                              onClick={() => {
                                setTeamCount("Active");
                              }}
                            >
                              {`Active (${active})`}
                            </Button>
                          </ButtonGroup>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => setToggleTeam(false)}
                          // onClick={<AddTeam />}
                          sx={{
                            bgcolor: "#2D404E",
                            padding: "10px",
                            borderRadius: "20px",
                            width: "200px",
                            height: "36px",
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                          }}
                        >
                          <AddIcon sx={{ mr: 1 }} /> Add Team
                        </Button>
                      </Grid>
                    </Grid>
                    <Box sx={{ mt: 3.5 }}>
                      <TableTeam
                        setActive={setActive}
                        setInactive={setInactive}
                        setOveralldata={setOveralldata}
                        setEditUserData={setEditUserData}
                        setToggleEditTeam={setToggleEditTeam}
                        search={search}
                        update={update}
                        setUpdate={setUpdate}
                        setAlert={setAlert}
                        setAlertType={setAlertType}
                        setAlertContent={setAlertContent}
                        teamCount={teamCount}
                      />
                    </Box>
                    <Notification
                      message={
                        alertContent
                          ? alertContent
                          : `Success : Data Fetched Successfully.`
                      }
                      open={alert}
                      severity={alertType}
                      onClose={handleAlert}
                    />
                  </>
                </Box>
              </div>
            </>
          )
        ) : (
          <AddTeam setToggleTeam={setToggleTeam} />
        )}
      </>
    </Permission>
  );
};

export default TeamManagement;
