import React, { useState } from "react";
import ScheduleSurvey from "./ScheduleSurvey";
import TeamCards from "./TeamCards";
const TabTeam = (props) => {
  const [navigate, setNavigate] = useState(true);
  return (
    <>
      {navigate ? (
        <TeamCards
          // setNavigate={setNavigate}
          setTabTeamActive={props.setTabTeamActive}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default TabTeam;
