import React from "react";
import { Grid, Typography, Button, Box, TextField } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import API from "../../../E2E/Axios.Utils";
import axios from "axios";

const ChangePassword = ({
  handleDialog,
  update,
  setUpdate,
  setAlert,
  setAlertContent,
  setAlertType,
}) => {
  const initialValues = {
    old_password: "",
    new_password: "",

    remember: false,
  };

  const passwordRegExp =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  const validationSchema = Yup.object().shape({
    old_password: Yup.string()
      .min(8, "Minimum characters should be 8")
      .matches(
        passwordRegExp,
        "Password must have one upper, lower case, number, special symbol ."
      )
      .required("This field is required."),
    new_password: Yup.string()
      .min(8, "Minimum characters should be 8")
      .matches(
        passwordRegExp,
        "Password must have one upper, lower case, number, special symbol ."
      )
      .required("This field is required."),
  });

  const token = localStorage.getItem("token").toString();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const onSubmit = (values, props) => {
    // console.log(values);
    API.post("/app/auth/change_password", values, config)
      .then((res) => {
        // console.log(res);
        // console.log(res.data.message);
        handleDialog();
        setUpdate(update + 1);
        setAlertContent(res.data.message);
        setAlertType("success");
        setAlert(true);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
        } else {
          setAlertContent(err.response.data.message);
          setAlertType("error");
          setAlert(true);
        }
      });
    props.resetForm();
  };

  return (
    <Box>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ mb: 2 }}
      >
        Change Password
      </Typography>
      <Box>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form noValidate>
              <Field
                as={TextField}
                label="Current Password"
                name="old_password"
                fullWidth
                error={props.errors.old_password && props.touched.old_password}
                size="small"
                helperText={<ErrorMessage name="old_password" />}
                required
                sx={{ marginBottom: "3%" }}
              />

              <Field
                as={TextField}
                label="New Password"
                name="new_password"
                fullWidth
                error={props.errors.new_password && props.touched.new_password}
                size="small"
                helperText={<ErrorMessage name="new_password" />}
                required
                sx={{ marginBottom: "1.5%" }}
              />

              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "1rem",
                  mt: 2,
                }}
              >
                <Grid item>
                  <button
                    onClick={handleDialog}
                    style={{
                      padding: "10px",
                      borderRadius: "20px",
                      width: "101px",
                      height: "36px",
                      border: "solid 1px #787878",
                      backgroundColor: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    Cancel
                  </button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      padding: "10px",
                      borderRadius: "20px",
                      width: "101px",
                      height: "36px",
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default ChangePassword;
