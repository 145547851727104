import { FormControl, InputLabel, TextField } from "@mui/material";
import React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "::placeholder": {
    color: "#2D404E",
  },

  "& .MuiInputBase-input": {
    borderRadius: 15,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    // width: "auto",
    width: "45vh",
    padding: "7px 12px",
    borderColor: "#000",
    marginBottom: "20px",
    fontWeight: "500",

    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      // borderColor: theme.palette.primary.main,
    },
  },
}));

const TextFieldcomp = (props) => {
  return (
    <div className={`InputBox__wrapper ${props.className}`} style={props.style}>
      {/* {props.label &&<label>{props.label}</label> }
      <input
        type={props.type}
        name={props.name}
        className={`InputBox ${props.className}`} style={props.style}
      /> */}

      <FormControl variant="standard">
        {props.label && (
          <InputLabel shrink htmlFor={props.label}>
            {props.label}
          </InputLabel>
        )}
        <BootstrapInput
          required
          className="BootstrapInput"
          defaultValue=""
          placeholder={props.placeholder}
          id={props.label}
          value={props.value}
          onChange={props.onChange}
          type={props.type}
          // inputComponent={props.inputComponent}
          endAdornment={props.endAdornment}
        />
      </FormControl>
    </div>
  );
};

export default TextFieldcomp;
