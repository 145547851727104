import React from "react";
import { Grid, Typography, Container } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SearchBarRole from "./Components/SearchBarRole";
import TablesRole from "./Components/TablesRole";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import Header from "../../component/header/Header";
import LeftDrawer from "../../component/leftDrawer/LeftDrawer";
import AddRoleCard from "./Components/AddRoleCard";
import CustomModel from "../../Utils/CustomModel/CustomModel";
import { useStyles } from "../question/Question.style";
import { Notification } from "../../Utils/SnackbarNotification/Notification";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Permission from "../../component/Permission/Permission";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const RoleManagement = () => {
  const [search, setSearch] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(0);
  const [opendrawer, setOpenDrawer] = React.useState(true);

  const classes = useStyles();

  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("");

  const handleDialog = () => {
    setOpen(false);
    // setOpen(!open);
  };
  const ref = React.useRef(open);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAlert = () => {
    setAlert(false);
  };

  return (
    // Role
    <Permission menu="Role">
      <Header/>
      <div style={{display: 'flex'}}>
        <LeftDrawer open={opendrawer}/>
          <Box component="main" sx={{flexGrow: 1, p: 3}} style={{background: '#EEEEEE'}}>
              <>
                  <Grid
                      container
                      sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                      }}
                  >
                      <Grid item>
                <Typography
                    variant="h5"
                    sx={{
                        marginTop: "15px",
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        fontWeight: "700",
                        fontSize: "20px",
                        lineHeight: "25px",
                        color: "#0B0B0C",
                    }}
                >
                  Role Management
                </Typography>
                </Grid>
                <Grid item>
                  <>
                    <CustomModel handleDialog={handleDialog} open={open}>
                      <Grid container spacing={0}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <Typography variant="h5" sx={{ mb: 2 }}>
                            {/* Add Role */}
                          </Typography>

                          <AddRoleCard
                            handleDialog={handleDialog}
                            setUpdate={setUpdate}
                            update={update}
                            setAlert={setAlert}
                            setAlertType={setAlertType}
                            setAlertContent={setAlertContent}
                          />
                        </Grid>
                      </Grid>
                    </CustomModel>
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      ref={ref}
                      onClick={() => setOpen(true)}
                      sx={{
                        display: "none",
                      }}
                      // sx={{
                      //   bgcolor: "#2D404E",
                      //   padding: "10px",
                      //   borderRadius: "20px",
                      //   width: "200px",
                      //   height: "36px",
                      //   display: "none",
                      // }}
                    >
                      <AddIcon sx={{ mr: 1 }} /> Add Role
                    </LoadingButton>
                  </>
                </Grid>
              </Grid>
              <Box>
                {/* <SearchBarRole setSearch={setSearch} search={search} /> */}
              </Box>
              <Box sx={{ mt: 6 }}>
                <TablesRole
                  search={search}
                  update={update}
                  setUpdate={setUpdate}
                  setAlert={setAlert}
                  setAlertType={setAlertType}
                  setAlertContent={setAlertContent}
                />
              </Box>
              <Notification
                message={
                  alertContent
                      ? alertContent
                      : `Success : Data Fetched Successfully.`
                }
                open={alert}
                severity={alertType}
                onClose={handleAlert}
              />
          </>
        </Box>
      </div>

    </Permission>
  );
};

export default RoleManagement;
