import React from "react";
import { Box, CssBaseline, Typography } from "@mui/material";
import Header from "../../../component/header/Header";
import LeftDrawer from "../../../component/leftDrawer/LeftDrawer";
import { useStyles } from "./ClientTabScreen.style";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import TabSurvey from "./tabSurvey";
import TabTeam from "./tabTeam";
import ProfileImgTitle from "../../../component/ProfileImgTitle";
import { downArrow, tataLogo, userProfile } from "../../../component/Images";
// import { Box, CssBaseline, Typography } from '@material-ui/core';
import { useNavigate, useParams } from "react-router-dom";
import ScheduleSurvey from "./tabTeam/ScheduleSurvey";
import TabOverview from "./tabOverview";
import Activity from "./tabActivity/Activity";
import CreateQuestion from "./tabSurvey/CreateQuestion";
import SurveyCards from "./tabSurvey/SurveyCards";
import { organizationOverview, getEmployeeList } from "../../../thunk/Action";
import { useDispatch } from "react-redux";
import TeamCards from "./tabTeam/TeamCards";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const ClientTabScreen = () => {
  // const params = useParams();
  // console.log("params=====",params);
  const [open, setOpen] = React.useState(true);

  const [value, setValue] = React.useState(0);
  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [tabTeamActive, setTabTeamActive] = React.useState(true);
  const [tabBatchCard, setTabBatchCard] = React.useState(false);
  const [batchCardData, setbatchCardData] = React.useState([]);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  const [overviewData, setOverviewData] = React.useState([]);

  let { id } = useParams();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token").toString();

  const organizationOverViewApiCall = () => {
    const request = {
      token: token,
      id: id,
      onSuccess: (res) => {
        if (res?.status === 200) {
          setOverviewData(res?.data?.organization_overview);
        }
      },
      onFail: (error) => {
        // setIsLoading(false);
        if (error?.response?.status === 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          navigate("/login");
        } else {
          setAlertContent(error?.response?.data?.message);
          setAlertType("error");
          setAlert(true);
        }
      },
    };
    dispatch(organizationOverview(request));
  };

  React.useEffect(() => {
    organizationOverViewApiCall();
  }, []);

  return (
    <div>
      <Header />
      <div style={{ display: "flex" }}>
        <LeftDrawer open={open} />

        {tabTeamActive ? (
          tabBatchCard ? (
            <CreateQuestion
              batchCardData={batchCardData}
              setTabBatchCard={setTabBatchCard}
            />
          ) : (
            <Box
              className={classes.body}
              sx={{ flexGrow: 1, p: 3 }}
              style={{ background: "#EEEEEE" }}
            >
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "900",
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#0B0B0C",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/client")}
              >
                Back
              </Typography>

              <div className={classes.profiles}>
                <ProfileImgTitle
                  image={overviewData.Logo_link}
                  title={overviewData.client_entity_name}
                  subTitle={overviewData.corporate_address}
                  className={classes.profile_logoProfile}
                />
                <div>
                  <p className="contactPersion">Contact Person</p>
                  <div className={classes.userProfileDropdown}>
                    <ProfileImgTitle
                      // image={userProfile}
                      title={overviewData.chro_name}
                      subTitle={overviewData.chro_email}
                      className={classes.profile_userProfile}
                    />
                    {/* <img src={downArrow} /> */}
                  </div>
                </div>
              </div>

              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="Overview" {...a11yProps(0)} />
                  <Tab label="Survey" {...a11yProps(1)} />
                  <Tab label="Activity" {...a11yProps(2)} />
                  <Tab label="Batch" {...a11yProps(3)} />
                  {/* <Tab label="Settings" {...a11yProps(4)} /> */}
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <TabOverview />
              </TabPanel>
              <TabPanel value={value} index={1}>
                {/* <TabSurvey /> */}
                <TabTeam setTabTeamActive={setTabTeamActive} />
                {/* <TeamCards/> */}
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Activity />
              </TabPanel>
              <TabPanel value={value} index={3}>
                {/* Teams */}
                <SurveyCards
                  setbatchCardData={setbatchCardData}
                  setTabBatchCard={setTabBatchCard}
                />
              </TabPanel>
              {/* <TabPanel value={value} index={4}>
                Settings
              </TabPanel> */}
            </Box>
          )
        ) : (
          <ScheduleSurvey setTabTeamActive={setTabTeamActive} />
        )}
      </div>
    </div>
  );
};

export default ClientTabScreen;
