import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .css-19z1ozs-MuiPaper-root-MuiAppBar-root": {
      height: "80px",
      display: "flex",
      justifyContent: "center",
      background: "linear-gradient(91.25deg, #394857 -28.42%, #00212A 117.39%)",
    },
    "& .avatar": {
      display: "flex",
      alignItems: "center",
      "& img": {
        cursor: "pointer",
      },
    },
  },
  logo: {},
  searchBox: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#fcfcfb",
    borderRadius: "4px",
    paddingLeft: "15px",
    "& input": {
      width: "625px",
      // height: "54px",
      background: "#FFF",
      borderRadius: "4px",
    },
  },
  searchButton: {
    marginLeft: "auto",
    width: "50px",
    height: "50px",
    backgroundColor: "#fcfcfb",
    borderRadius: "4px",
  },
  notificationBox: {
    display: "flex",
    alignItems: "center",
    // backgroundColor:'red',
    borderRadius: "4px",
  },
  dropdownBox: {
    borderRadius: "4px",
    backgroundColor: "#FAFEFF",
    padding: "5px 10px",
  },
  titleText: {
    color: "black",
    marginLeft: "1px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: "700",
  },
  contentText: {
    color: "black",
    marginLeft: "1px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: "400",
  },
  roots: {
    "& .css-1191obr-MuiPaper-root-MuiAppBar-root": {
      // width: 'calc(100% - 240px)',
      width: "100%",
    },
    "& .MuiDrawer-paperAnchorLeft": {
      marginTop: "80px",
    },
    backgroundColor: "#EEEEEE",
  },
  drawerOnOff: {
    position: "absolute",
    width: "46px",
    height: "46px",
    borderRadius: "50%",
    "& .MuiButtonBase-root": {
      backgroundColor: "#FFC20E",
    },
  },
  questionText: {
    // "& .MuiTypography-root": {
    marginTop: "30px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "25px",
    color: "#0B0B0C",
    // },
  },
  pagenumber: {
    listStyle: "none",
    display: "flex",
  },
  pagenumberLi: {
    padding: "10px",
    border: "1px solid white",
    cursor: "pointer",
    justifyContent: "space-between",
  },
  Pagination: {
    width: 25,
    height: 25,
    borderRadius: "2px",
    margin: "5px",
    fontSize: 12,
    paddingTop: "4px",
    backgroundColor: "#2D404E",
    color: "#ffff",
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    border: "none",
  },

  modal_AddQuestionStyle: {
    backgroundColor:"rgb(0,0,0,0.4)",
    "& .MuiPaper-root": {
      width: "74%",
      maxWidth: "100% !important",
      marginLeft:'15%',
      
    },
  },

  modal_content_AddQuestionStyle: {
    "& header": {
      backgroundColor: "#128CB0",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      color: "#fff",
      padding: "15px 20px",

      "& .titleModal": {
        fontFamily: "'Montserrat'",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "18px",
        lineHeight: "22px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        marginBottom: "0",
      },
    },
  },

  modalContent_AddQue: {
    padding: "25px 20px 25px 0",

    "& .MuiGrid-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    "& .que": {
      fontFamily: "'Montserrat'",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "16px",
      lineHeight: "20px",
      textTransform: "capitalize",
      color: "#374047",
      marginBottom: "0",
    },
  },
  TextField_Style:{
    width:'100%'
  },
  pagenumber:{
    listStyle:"none",
    display:"flex",
    
    
  },
  pagenumberLi :{
    padding:"10px",
    border:"1px solid white",
    cursor:"pointer",
    justifyContent:"space-between"
  },
  Pagination:{
    width: 25,
    height: 25,
    borderRadius: "2px",
    margin: "5px",
    fontSize: 12,
    paddingTop: "4px",
    backgroundColor: "#2D404E",
    color: "#ffff",
    display:"flex",
    alignItem:"center",
    justifyContent:"center",
    border:"none"
  },
  PaginationDisable:{
    width: 25,
    height: 25,
    borderRadius: "2px",
    margin: "5px",
    fontSize: 12,
    paddingTop: "4px",
    backgroundColor: "#ffffff",
    color: "#000",
    display:"flex",
    alignItem:"center",
    justifyContent:"center",
    border:"none"
  },
  btnStyle:{
    backgroundColor:'#128CB0',
    color:'#fff',
    
  },
});

export { useStyles };
