import React, { useEffect, useRef, useContext } from "react";

import Emoji from "../Emoji/Emoji";

import style from './MultipleChoiceQuestion.module.scss'

import {saveAnswer} from "../../context/chatActions";

import { ChatContext } from "../../context/chatContext";

const MultipleChoiceQuestion = () => {
    const { chatDispatch, chatConversationState } = useContext(ChatContext);

    return (<div className={style.questionContainer}>
        {chatConversationState.buttons.length > 0 ?
            chatConversationState.buttons.map((button, index) => {
                return (
                    <div key={index} className={`${style.answerContainer} ${chatConversationState.answer == button.payload ? style.selected : ''}`} onClick={() => saveAnswer(button.payload, chatDispatch)}>
                        <p className={style.answerText}>{button.title}</p>
                    </div>
                )
            })
        : undefined}
    </div>)
}

export default MultipleChoiceQuestion;
