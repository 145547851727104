import Dropdown from "./Dropdown";
import { Grid } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import { DateRange } from "react-date-range";
import moment from "moment";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { useStyles } from "../Dashboard.style";
import { useEffect, useState, useRef } from "react";
import API from "../../../E2E/Axios.Utils";
import { useDispatch } from "react-redux";
import {
  fetchDashboardDetails,
  fetchMoodDetails,
  fetchEngagementDrivers,
  fetchManagerDashboard,
  fetchRoleDashboard,
  fetchTeamDashboard,
  fetchCommunicationDashboard,
  fetchAppreciationDashboard,
  fetchLearnDevDashboard,
  fetchDiversityDashboard,
  fetchWorkLifeDashboard,
} from "../../../thunk/Action";

const getCompanies = (token, setFunction) => {
  API.get("/app/secure/list_customer?page=1&max_rows=1000", {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      const companies = res.data.customer_list.map((company) => ({
        value: company.org_id,
        label: company.client_entity_name,
      }));
      setFunction(companies);
    })
    .catch((err) => {});
};

const getTeams = (token, setFunction, selectedCompanies) => {
  API.get(
    `/app/secure/teams?page=1&max_rows=1000&sort_by=&org_id=${selectedCompanies}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  )
    .then((res) => {
      const teams = res.data.teams_list.map((team) => ({
        value: team.team_id,
        label: team.team_name,
      }));
      setFunction(teams);
    })
    .catch((err) => {});
};

const getLocations = (token, setFunction) => {
  API.get("/app/secure/emp_loc_dashboard", {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      const teams = res.data.location.map((location) => ({
        value: location,
        label: location,
      }));
      setFunction(teams);
    })
    .catch((err) => {});
};

const DashboardFilters = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [gender, setGender] = useState("");
  const [selectedGender, setSelectedGender] = useState([""]);

  const [ageGroup, setAgeGroup] = useState("");
  const [selectedAgeGroup, setSelectedAgeGroup] = useState([""]);

  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([""]);

  const [teams, setTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([""]);

  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([""]);

  const [role, setRole] = useState("");

  const token = localStorage.getItem("token").toString();

  const [range, setRange] = useState([
    {
      startDate: moment(new Date()).subtract("30", "days").toDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // open close
  const [open, setOpen] = useState(false);

  const refOne = useRef(null);

  useEffect(() => {
    getCompanies(token, setCompanies);
    getTeams(token, setTeams, selectedCompanies);
    getLocations(token, setLocations);
  }, []);

  useEffect(() => {
    const queryParams = {
      gender: selectedGender.filter(Boolean).join(","),
      ageGroup: selectedAgeGroup.filter(Boolean).join(";"),
      startDate: moment(range[0].startDate).format("YYYY-MM-DD"),
      endDate: moment(range[0].endDate).format("YYYY-MM-DD"),
      locationValue: selectedLocations.filter(Boolean).join(","),
      organisationIds: selectedCompanies.filter(Boolean).join(","),
      teamIds: selectedTeams.filter(Boolean).join(","),
    };
    dispatch(
      fetchDashboardDetails({
        token,
        ...queryParams,
      })
    );
    dispatch(fetchMoodDetails({ token, ...queryParams }));
    dispatch(fetchEngagementDrivers({ token, ...queryParams }));
    dispatch(fetchManagerDashboard({ token, ...queryParams }));
    dispatch(fetchRoleDashboard({ token, ...queryParams }));
    dispatch(fetchTeamDashboard({ token, ...queryParams }));
    dispatch(fetchCommunicationDashboard({ token, ...queryParams }));
    dispatch(fetchAppreciationDashboard({ token, ...queryParams }));
    dispatch(fetchLearnDevDashboard({ token, ...queryParams }));
    dispatch(fetchDiversityDashboard({ token, ...queryParams }));
    dispatch(fetchWorkLifeDashboard({ token, ...queryParams }));
  }, [
    selectedCompanies,
    selectedTeams,
    selectedGender,
    selectedAgeGroup,
    selectedLocations,
    range,
  ]);

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  // Hide on outside click
  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    const role = localStorage.getItem("role");
    setRole(role);
  }, []);

  return (
    <Grid container xs={12}>
      <p style={{ fontFamily: "Montserrat" }}>Filters</p>
      <Grid container xs={12} style={{ background: "rgba(28, 31, 36, 0.67)" }}>
        {role === "Super Admin" ? (
          <Grid
            item
            xs={12}
            md={6}
            lg={2}
            className={classes.filterOptionsContainer}
          >
            <p className={classes.filterName}>Company Name</p>
            <Dropdown
              defaultValue={""}
              options={[
                {
                  value: "",
                  label: "All",
                },
                ...companies,
              ]}
              onChange={(value) => {
                const finalValue =
                  value.length == 2 && value.indexOf("") !== -1
                    ? value.shift()
                    : value;
                const val = value.length >= companies.length ? [""] : value;
                setSelectedCompanies(val);
              }}
              value={selectedCompanies}
            />
          </Grid>
        ) : null}
        <Grid
          item
          xs={12}
          md={6}
          lg={2}
          className={classes.filterOptionsContainer}
        >
          <p className={classes.filterName}>Team</p>
          <Dropdown
            defaultValue={"All"}
            options={[
              {
                value: "",
                label: "All",
              },
              ...teams,
            ]}
            onChange={(value) => {
              const finalValue =
                value.length == 2 && value.indexOf("") !== -1
                  ? value.shift()
                  : value;
              setSelectedTeams(value);
            }}
            value={selectedTeams}
          />{" "}
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg={2}
          item
          className={classes.filterOptionsContainer}
        >
          <p className={classes.filterName}>Gender</p>
          <Dropdown
            defaultValue={""}
            options={[
              {
                value: "",
                label: "All",
              },
              { value: "male", label: "Male" },
              { value: "female", label: "Female" },
              { value: "transgender", label: "Transgender" },
              {
                value: "prefer not to respond",
                label: "Prefer not to respond",
              },
            ]}
            onChange={(value) => {
              const finalValue =
                value.length == 2 && value.indexOf("") !== -1
                  ? value.shift()
                  : value;
              const val = value.length >= 4 ? [""] : value;
              setSelectedGender(val);
            }}
            value={selectedGender}
          />
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg={1}
          item
          className={classes.filterOptionsContainer}
        >
          <p className={classes.filterName}>Age</p>
          <Dropdown
            defaultValue={""}
            options={[
              {
                value: "",
                label: "All",
              },
              { value: "20,30", label: "20 to 30" },
              { value: "30,40", label: "30 to 40" },
              { value: "40,50", label: "40 to 50" },
              { value: "50,60", label: "50 to 60" },
              { value: "60,70", label: "60 to 70" },
              { value: "70,80", label: "70 to 80" },
            ]}
            onChange={(value) => {
              const finalValue =
                value.length == 2 && value.indexOf("") !== -1
                  ? value.shift()
                  : value;
              const val = value.length >= 6 ? [""] : value;
              setSelectedAgeGroup(val);
            }}
            value={selectedAgeGroup}
          />
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg={3}
          item
          className={classes.filterOptionsContainer}
        >
          <p className={classes.filterName}>Date Range</p>
          <div className="calendarWrap">
            <TextField
              value={`${moment(range[0].startDate).format(
                "DD-MM-YYYY"
              )} to ${moment(range[0].endDate).format("DD-MM-YYYY")}`}
              readOnly
              className={classes.calenderInput}
              onClick={() => setOpen((open) => !open)}
            />

            <div ref={refOne}>
              {open && (
                <DateRange
                  onChange={(item) => setRange([item.selection])}
                  editableDateInputs={true}
                  moveRangeOnFirstSelection={false}
                  ranges={range}
                  months={1}
                  direction="horizontal"
                  className="calendarElement"
                />
              )}
            </div>
          </div>
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg={1}
          item
          className={classes.filterOptionsContainer}
        >
          <p className={classes.filterName}>Location</p>
          <Dropdown
            defaultValue={"All"}
            options={[
              {
                value: "",
                label: "All",
              },
              ...locations,
            ]}
            onChange={(value) => {
              const finalValue =
                value.length == 2 && value.indexOf("") !== -1
                  ? value.shift()
                  : value;
              const val = value.length >= locations.length ? [""] : value;
              setSelectedLocations(val);
            }}
            value={selectedLocations}
          />{" "}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardFilters;
