import React from "react";
import ScheduleSurvey from "../client/tabsContent/tabTeam/ScheduleSurvey";
import { Container, Box } from "@mui/material";
import LeftDrawer from "../../component/leftDrawer/LeftDrawer";
import { useStyles } from "../question/Question.style";
import Header from "../../component/header/Header";

const ScheduleSurveyStyle = ({ setToggleTeam }) => {
  const [opendrawer, setOpenDrawer] = React.useState(true);
  const classes = useStyles();
  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <LeftDrawer open={opendrawer} />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          style={{ background: "#EEEEEE" }}
        >
          <ScheduleSurvey setToggleTeam={setToggleTeam} />
        </Box>
      </div>
    </>
  );
};

export default ScheduleSurveyStyle;
