// import { Box, Typography } from "@material-ui/core";
import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Container,
  Tab,
  Tabs,
  IconButton,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Card from "../../../../component/UIElements/Card";
import { useStyles } from "./CreateQuestion.style";
// import Grid from "@material-ui/core/Grid";
import { backArrow, Dots } from "../../../../component/Images";
import BatchCardResponse from "./InsideBatchTab/BatchCardResponse";
import BatchCardOverview from "./InsideBatchTab/BatchCardOverview";

const CreateQuestion = ({ setTabBatchCard, batchCardData }) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  // const CreateQuestion = (props) => {
  const classes = useStyles();

  const renderTab = () => {
    switch (selectedTab) {
      case 0:
        return <BatchCardOverview data={batchCardData} />;

      default:
        return <BatchCardResponse data={batchCardData} />;
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div style={{ background: "#EEEEEE", padding: "20px", width: "100vw" }}>
      <Box sx={{ mt: 1.5, ml: 1 }}>
        <Typography
          className={classes.backBtn}
          onClick={() => setTabBatchCard(false)}
        >
          <img src={backArrow} /> Back
        </Typography>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Tabs
          value={selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          // aria-label="subscription tabs"
          aria-label="scrollable auto tabs example"
          sx={{ fontSize: "11px" }}
        >
          <Tab label="Overview" />
          <Tab label="Anonymous Response" />
        </Tabs>
        <div className="page-container">{renderTab()}</div>
      </Box>

      {/* <Box className={classes.root}>
        <header className={classes.headerCard}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              Net Promoter® Score (NPS)
            </Grid>
            <Grid item xs={12} sm={1}>
              10
            </Grid>
            <Grid item xs={12} sm={2}>
              20.05.2022
            </Grid>
            <Grid item xs={12} sm={3}>
              35%
            </Grid>
            <Grid item xs={12} sm={2}>
              20.05.2022
            </Grid>
            <Grid item xs={12} sm={1}>
              <img src={Dots} />
            </Grid>
          </Grid>
        </header>
        <Card className={classes.cardStyle}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              Question Bank - Preview Day 7 - Survey Template
            </Grid>
            <Grid item xs={12} sm={8}>
              Q 1 Your Sample Question will appear here just tap and write your
              question.
            </Grid>
          </Grid>
        </Card>
      </Box> */}
    </div>
  );
};

export default CreateQuestion;
