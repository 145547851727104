import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Container,
} from "@mui/material";
import TableAnonymusResponse from "./Response/TableAnonymusResponse";

const BatchCardResponse = ({ data }) => {
  return (
    <Grid Container sx={{ mt: 4 }}>
      <Box>
        <Typography variant="h6">Anonymous Response</Typography>
      </Box>
      <Box sx={{ mt: 4 }}>
        <TableAnonymusResponse surveyScheduleID={data.survey_schedule_id} />
      </Box>
    </Grid>
  );
};

export default BatchCardResponse;
