import React from "react";
import { useField, useValidation } from "usetheform";
import { DropzoneArea } from "material-ui-dropzone";
import { AttachFile } from "@material-ui/icons";

const required = (value) => (value ? undefined : "Attachments Required");
export const MaterialuiDropzone = (props) => {
  const [status, validation] = useValidation([required]);
  const { value, setValue } = useField({
    type: "custom",
    touched: true,
    name: "materialuiDropzone",
    ...validation
  });

  const handleChange = (files) => {
    /* it avoids to call setValue when DropzoneArea 
       is initialized with empty values */
    if (!(!value && files.length === 0)) {
      setValue(files);
    }
  };

  return (
    <div>
      <DropzoneArea 
      showFileNames
      getPreviewIcon={(file) => {
        if (file.file.type.split('/')[0] === 'image')
          return (
            <></>
            // <img role="presentation" src={file.data} />
          );
      }}
      onChange={props.handleChange}
      Icon={AttachFile}
      filesLimit={1}
      //  showPreviewsInDropzone={false}
       showFileNamesInPreview={false}
       useChipsForPreview={false}
       dropzoneText={"Drag and drop csv here or browse from computer"}
       acceptedFiles={[".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values"]}
/>
      {status.error && <span className="Error">{status.error}</span>}
    </div>
  );
};
