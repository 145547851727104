import React, { useRef, useContext, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";

import { ChatContext } from "../context/chatContext";
import getChat, { surveyEndApi, surveyStart } from "../context/chatApi";

import PolarQuestion from "./PolarQuestion/PolarQuestion";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion/MultipleChoiceQuestion";
import SliderQuestion from "./SliderQuestion/SliderQuestion";
import {
  setAnswerAnonymously,
  setStartChatFalse,
  resetChatData,
  setPreviousAnswerFeedbackFalse,
  setEndSurveyTrue,
  setChatLoading,
} from "../context/chatActions";

import styles from "./Messages.module.scss";

import { closeSurvey, startSurvey } from "../../thunk/Action";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const Messages = (props) => {
  const { messages } = props;
  const el = useRef(null);
  const { chatDispatch, chatConversationState } = useContext(ChatContext);

  console.log(chatConversationState);

  const renderAnswerOptions = (param) => {
    switch (param) {
      case "yes_no":
        return <PolarQuestion />;
      case "slider":
        return <SliderQuestion />;
      case "mcq":
        return <MultipleChoiceQuestion />;
      case "text":
      default:
        return <></>;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (chatConversationState.loadFeedback) {
        resetChatData(chatDispatch);
        props.closeSurvey();
      }
    }, 2000);
  }, [chatConversationState.loadFeedback]);

  useEffect(() => {
    if (
      chatConversationState.question &&
      chatConversationState.question.trim().toLowerCase() == "skip"
    ) {
      getChat(
        "",
        chatConversationState.question,
        props.employee_id,
        props.survey_schedule_id,
        props.survey_id,
        props.surveySenderId,
        chatConversationState.answerAnonymously
      )(chatDispatch);
    }
  }, [chatConversationState.question]);

  const token = localStorage.getItem("token").toString();

  return (
    <div className={styles.messagesContainer}>
      {chatConversationState.loadFeedback ? (
        <h3 className={styles.questionText}>
          Hooray! You have successfully completed all the surveys assigned to
          you. You may check how many rewards you have accumulated so far. Your
          HR team will reach out to you for next steps and way forward.
        </h3>
      ) : chatConversationState.endSurvey ? (
        <>
          <h5 className={styles.questionText}>
            You have successfully completed the {props.survey_name} survey.
            Please click the NEXT button.
          </h5>
          <hr style={{ width: "100%" }} />
          <h5 className={styles.questionText} style={{fontSize: '18px'}}>
            if you’d like to change your answers, please click this button to
            restart
          </h5>
        </>
      ) : chatConversationState.previousAnswerFeedback && (chatConversationState.previousAnswerFeedbackType == 'text' || chatConversationState.previousAnswerFeedbackType == 'new_page') ? (
        <h3 className={styles.questionText} style={{ flex: 1 }}>
          {chatConversationState.previousAnswerFeedback}
        </h3>
      ) : (
        <>
          <h3 className={styles.questionText}>
            {chatConversationState.question &&
            chatConversationState.question.trim().toLowerCase() == "skip"
              ? ""
              : chatConversationState.question}
          </h3>
          {chatConversationState.previousAnswerFeedback && !chatConversationState.previousAnswerFeedbackType ? (
              <h3 className={styles.feedbackInfo}>
                {chatConversationState.previousAnswerFeedback}
              </h3>
          ) : <></>}
          <div className={styles.answerContainer}>
            {renderAnswerOptions(chatConversationState.answerType)}
          </div>
          {chatConversationState.start == false ? (
            <div className={styles.anonymousContainer}>
              <Checkbox
                id="answerAnonymous"
                checked={chatConversationState.answerAnonymously}
                onChange={(status) => {
                  setAnswerAnonymously(status.target.checked, chatDispatch);
                }}
              />
              <label
                className={styles.answerAnonymouslyText}
                htmlFor="answerAnonymous"
              >
                Answer Anonymously
              </label>
            </div>
          ) : undefined}
        </>
      )}
      {chatConversationState.endSurvey ? <p
          className={`${styles.btnText} ${styles.btnContainer}`}
          style={{
            background:
                "linear-gradient(91.25deg, #394857 -28.42%, #00212A 117.39%)",
            pointerEvents: "all",
            color: "#fff",
            cursor: "pointer",
          }}
          onClick={() => {
            resetChatData(chatDispatch);
            setChatLoading(chatDispatch);
            setTimeout(() => {
              props.closeSurvey();
              surveyStart(
                  props.employee_id,
                  props.survey_schedule_id,
                  props.startSurvey,
                  true,
                  chatDispatch
              );
            }, 200);
          }}
      >
        Restart
      </p> : <></>}
      {!chatConversationState.loadFeedback &&
      chatConversationState.previousAnswerFeedbackType !== "text" &&
      chatConversationState.answerType !== "text" ? (
        <div
          className={`${
            !chatConversationState.endSurvey ? styles.btnContainer : ""
          } ${
            chatConversationState.answer ||
            chatConversationState.previousAnswerFeedback ||
            chatConversationState.endSurvey
              ? styles.active
              : ""
          }`}
          onClick={() => {
            if (chatConversationState.previousAnswerFeedback) {
              setPreviousAnswerFeedbackFalse(
                chatConversationState.nextQuestionPayload,
                chatDispatch
              );
              if (chatConversationState.nextEndSurvey == true)
                setEndSurveyTrue(chatDispatch);
            } else {
              if (chatConversationState.endSurvey) {
                surveyEndApi(
                  props.employee_id,
                  props.survey_schedule_id,
                  props.startSurvey
                )(chatDispatch);
              } else {
                console.log("sss");
                getChat(
                  chatConversationState.answer,
                  chatConversationState.question,
                  props.employee_id,
                  props.survey_schedule_id,
                  props.survey_id,
                  props.surveySenderId,
                  chatConversationState.answerAnonymously
                )(chatDispatch);
              }
            }

            if (chatConversationState.start == true) {
              setStartChatFalse(chatDispatch);
            }
          }}
        >
          {chatConversationState.start == true ? (
            <p className={styles.btnText}>Start</p>
          ) : chatConversationState.endSurvey ? (
                <p
                  className={`${styles.btnText} ${styles.btnContainer}`}
                  style={{
                    background:
                      "linear-gradient(91.25deg, #394857 -28.42%, #00212A 117.39%)",
                    pointerEvents: "all",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  Next
                </p>
          ) : (
            <p className={styles.btnText}>Next Question</p>
          )}
        </div>
      ) : undefined}
    </div>
  );
};

const mapStateToProps = (state) => {
  return state.reducer.survey;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeSurvey,
      startSurvey,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
