import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .css-1191obr-MuiPaper-root-MuiAppBar-root": {
      // width: 'calc(100% - 240px)',
      width: "100%",
    },
    "& .MuiDrawer-paperAnchorLeft": {
      marginTop: "80px",
    },
    backgroundColor:'#EEEEEE'
  },
  drawerOnOff: {
    position: "absolute",
      width: "46px",
      height: "46px",
      borderRadius: "50%",
    "& .MuiButtonBase-root": {
      backgroundColor: "#FFC20E",
      
      // display: "flex",
      // justifyContent: "center",
      // alignItems: "center",
    },
  },
  // root: {
  //   "& .css-19z1ozs-MuiPaper-root-MuiAppBar-root": {
  //     height: "80px",
  //     display: "flex",
  //     justifyContent: "center",
  //     background: "linear-gradient(91.25deg, #394857 -28.42%, #00212A 117.39%)",
  //   },
  //   "& .avatar": {
  //     display: "flex",
  //     alignItems: "center",
  //     "& img": {
  //       cursor: "pointer",
  //     },
  //   },
  // },
  // logo: {},
  // searchBox: {
  //   display: "flex",
  //   alignItems: "center",
  //   width: "1025px",
  //   backgroundColor: "#fcfcfb",
  //   borderRadius: "4px",
  //   paddingLeft:'15px',
  //   "& input": {
  //     width: "625px",
  //     // height: "54px",
  //     background: "#FFF",
  //     borderRadius: "4px",
  //   },
  // },
  // searchButton: {
  //   marginLeft: "auto",
  //   width: "50px",
  //   height: "50px",
  //   backgroundColor: "#fcfcfb",
  //   borderRadius: "4px",
  // },
  // notificationBox: {
  //   display: "flex",
  //   alignItems: "center",
  //   // backgroundColor:'red',
  //   borderRadius: "4px",
  // },
  // dropdownBox: {
  //   borderRadius: "4px",
  //   backgroundColor: "#FAFEFF",
  //   padding: "5px 10px",
  // },
  // titleText: {
  //   color: "black",
  //   marginLeft: "1px",
  //   fontSize: "14px",
  //   fontFamily: "Montserrat",
  //   fontWeight: "700",
  // },
  // contentText: {
  //   color: "black",
  //   marginLeft: "1px",
  //   fontSize: "14px",
  //   fontFamily: "Montserrat",
  //   fontWeight: "400",
  // },
});

export { useStyles };
