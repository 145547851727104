import axiosInstance from "../axios/axiosInstance";
import {
    CHAT_LOADING,
    CHAT_LOAD_SUCCESS,
    CHAT_LOAD_ERROR,
    SAVE_ANSWER,
    SET_LOAD_FEEDBACK,
    RESET_CHAT_DATA,
    SET_END_SURVEY, SET_CHAT_LOADING_FALSE,
} from "./chatReducer";
import API from "../../E2E/Axios.Utils";

import { startSurvey } from "../../thunk/Action";
import {setStartChatFalse} from "./chatActions";

const token = localStorage.getItem("token")
  ? localStorage.getItem("token").toString()
  : null;

const config = {
  headers: { Authorization: `Bearer ${token}` },
};

export default (
    message,
    question,
    employeeId,
    surveyScheduleId,
    surveyId,
    surveySenderId,
    answerAnonymously = false
  ) =>
  (dispatch) => {
    dispatch({
      type: CHAT_LOADING,
    });
    axiosInstance()
      .post("webhook", {
        sender: surveySenderId,
        message: message,
      })
      .then((res) => {
        sendPrevAnswerFeedback(
          message,
          question,
          employeeId,
          surveyScheduleId,
          surveyId,
          surveySenderId,
          answerAnonymously,
          res.data
        )(dispatch);
      })
      .catch((err) => {
        dispatch({
          type: CHAT_LOAD_ERROR,
          payload: err.response ? err.response.data : "CONNECTION_ERROR",
        });
      });
  };
export const surveyStart = (employeeId, surveyScheduleId, startSurvey, restartSurvey = false, dispatch = () => {}) => {
  const token = localStorage.getItem("token")?.toString();

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  console.log(restartSurvey);

  API.post(
    "/app/secure/employee_response",
    {
      employee_id: employeeId,
      survey_schedule_id: surveyScheduleId,
      action: "start",
    },
    config
  )
    .then((res) => {
      const surveyData = res.data.survey_details[0];
      if(restartSurvey)
          dispatch({
              type: SET_CHAT_LOADING_FALSE,
          });
      startSurvey({
        company_name: surveyData.org_id.client_entity_name,
        start_command: surveyData.survey_id.start_command,
        survey_schedule_id: surveyData.survey_schedule_id,
        employee_id: employeeId,
        survey_name: surveyData.survey_id.name,
        survey_id: surveyData.survey_id.survey_id,
        surveySenderId: Math.floor(Math.random() * 100 + 1) + Date.now(),
        welcomeMessage:
          surveyData.survey_id.name.toLowerCase().trim() == "day 1"
            ? "Hey welcome to AceNgage. My name is Ash and I will stay in touch with you for the next 120 days. You can reach out to me at any point by writing to ash@acengage.com. Please feel absolutely free to reach out. I look forward to chatting with you 🙂"
            : surveyData.survey_id.name.toLowerCase().trim() == "day 7"
            ? `Congrats at completing your first week at ${surveyData.org_id.client_entity_name}`
            : ``,
      });
    })
    .catch((err) => {
      if (err?.response?.status == 406) {
          if(restartSurvey)
              dispatch({
                  type: SET_CHAT_LOADING_FALSE,
              });
          const surveyData = err.response.data.survey_detail[0];
        startSurvey({
          company_name: surveyData.org_id.client_entity_name,
          start_command: surveyData.survey_id.start_command,
          survey_schedule_id: surveyData.survey_schedule_id,
          employee_id: employeeId,
          survey_name: surveyData.survey_id.name,
          survey_id: surveyData.survey_id.survey_id,
          surveySenderId: Math.floor(Math.random() * 100 + 1) + Date.now(),
          welcomeMessage:
            surveyData.survey_id.name.toLowerCase().trim() == "day 7"
              ? `Congrats at completing your first week at ${surveyData.org_id.client_entity_name}`
              : `We have noticed that ${surveyData.survey_id.name} survey is pending, please click on Start to continue ${surveyData.survey_id.name} Survey`,
        });
      }
    });
};

export const surveyEndApi =
  (employeeId, surveyScheduleId, startSurvey) => (dispatch) => {
    dispatch({
      type: RESET_CHAT_DATA,
    });
    dispatch({
      type: CHAT_LOADING,
    });

    const token = localStorage.getItem("token")?.toString();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    API.post(
      "/app/secure/employee_response",
      {
        employee_id: employeeId,
        survey_schedule_id: surveyScheduleId,
        action: "finish",
      },
      config
    )
      .then((response) => {
        dispatch({
          type: SET_LOAD_FEEDBACK,
        });
      })
      .catch((err) => {
        if (err?.response?.status == 406) {
          const surveyData = err.response.data.survey_detail[0];
          API.post(
            "/app/secure/employee_response",
            {
              employee_id: employeeId,
              survey_schedule_id: surveyData.survey_id.survey_schedule_id,
              action: "start",
            },
            config
          )
            .then((res) => {
              startSurvey({
                company_name: surveyData.org_id.client_entity_name,
                start_command: surveyData.survey_id.start_command,
                survey_schedule_id: surveyData.survey_schedule_id,
                employee_id: employeeId,
                survey_name: surveyData.survey_id.name,
                survey_id: surveyData.survey_id.survey_id,
                surveySenderId: Math.floor(Math.random() * 10000 + 1),
                welcomeMessage:
                  surveyData.survey_id.name.toLowerCase().trim() == "day 7"
                    ? `Congrats at completing your first week at ${surveyData.org_id.client_entity_name}`
                    : ``,
              });
            })
            .catch((err) => {
              if (err?.response?.status == 406) {
                const surveyData = err.response.data.survey_detail[0];
                startSurvey({
                  start_command: surveyData.survey_id.start_command,
                  survey_schedule_id: surveyData.survey_schedule_id,
                  employee_id: employeeId,
                  survey_name: surveyData.survey_id.name,
                  survey_id: surveyData.survey_id.survey_id,
                  surveySenderId: Math.floor(Math.random() * 10000 + 1),
                  welcomeMessage:
                    surveyData.survey_id.name.toLowerCase().trim() == "day 7"
                      ? `Congrats at completing your first week at ${surveyData.org_id.client_entity_name}`
                      : `We have noticed that ${surveyData.survey_id.name} survey is pending, please click on Start to continue ${surveyData.survey_id.name} Survey`,
                });
              }
            });
        }
      });
  };

export const sendPrevAnswerFeedback =
  (
    message,
    question,
    employeeId,
    surveyScheduleId,
    surveyId,
    surveySenderId,
    answerAnonymously = false,
    nextPayload,
    endSurvey = false
  ) =>
  (dispatch) => {
    dispatch({
      type: CHAT_LOADING,
    });
    const token = localStorage.getItem("token")?.toString();

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if(question) {
        API.post(
            `/app/secure/response_of_survey`,
            {
                answer: message,
                survey_id: surveyId,
                survey_schedule_id: surveyScheduleId,
                question: question,
                employee_id: employeeId,
                is_anonymous: answerAnonymously,
            },
            config
        )
            .then((response) => {
                // dispatch({
                //   type: SAVE_ANSWER,
                //   payload: "",
                // });
                dispatch({
                    type: CHAT_LOAD_SUCCESS,
                    payload: nextPayload,
                });

                if (endSurvey) {
                    dispatch({
                        type: SET_END_SURVEY,
                    });
                }
            })
            .catch((err) => {
                dispatch({
                    type: CHAT_LOAD_ERROR,
                    payload: err.response ? err.response.data : "CONNECTION_ERROR",
                });
            });
    } else {
        dispatch({
            type: CHAT_LOAD_SUCCESS,
            payload: nextPayload,
        });
    }
  };
