import React from "react";
import { Box, Button, Typography, Link, Card, Grid } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../login/Login.style";
import { Rectangle } from "../../component/Images";
import TextFieldcomp from "../../component/UIElements/TextFieldComp";
import { useLocation } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Notification } from "../../Utils/SnackbarNotification/Notification";
import API from "../../E2E/Axios.Utils";

const ResetPasswordF = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  // const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [values, setValues] = React.useState({
    showPassword: false,
    showConfirmPassword: false,
  });
  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("");

  const handleAlert = () => {
    setAlert(false);
  };

  const location = useLocation().pathname.toString();
  const token = location.split("/");

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const config = {
    headers: { Authorization: `Bearer ${token[2]}` },
  };
  const handleClick = (newState) => () => {
    const data = {
      password: confirmpassword,
    };
    API.post("/app/auth/reseting_password_done", data, config)
      .then((res) => {
        setAlertContent(res?.data?.message);
        setAlertType("success");
        setAlert(true);
        setTimeout(() => {
          navigate("/login");
        }, 500);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
        } else {
          setAlertContent(err.data.message);
          setAlertType("error");
          setAlert(true);
        }
      });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{
        backgroundImage: `url(${Rectangle})`,
        backgroundRepeat: "no-repeat",
        height: "100vh",
        backgroundSize: "cover",
      }}
    >
      <Card className={classes.cardStyle}>
        <div style={{ padding: "25px" }}>
          {/* <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          sx={{ padding: "10px" }}
        > */}
          <Typography
            sx={{
              fontWeight: "800",
              fontSize: "20px",
              margin: "10px",
              textAlign: "center",
            }}
          >
            Reset Password
          </Typography>

          <TextFieldcomp
            label=" New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            type={values.showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="start">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="start"
                >
                  {values.showPassword ? (
                    <Visibility
                      sx={{
                        position: "absolute",
                        right: "12px",
                        bottom: "7px",
                      }}
                    />
                  ) : (
                    <VisibilityOff
                      sx={{
                        position: "absolute",
                        right: "12px",
                        bottom: "7px",
                      }}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <TextFieldcomp
            label=" Confirm Password"
            value={confirmpassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type={values.showConfirmPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="start">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownNewPassword}
                  edge="start"
                >
                  {values.showConfirmPassword ? (
                    <Visibility
                      sx={{
                        position: "absolute",
                        right: "12px",
                        bottom: "7px",
                      }}
                    />
                  ) : (
                    <VisibilityOff
                      sx={{
                        position: "absolute",
                        right: "12px",
                        bottom: "7px",
                      }}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          {/* <Box display="flex" flexDirection="row" sx={{ width: "80%" }}></Box> */}
          <Button
            variant="contained"
            color="secondary"
            sx={{
              padding: "8px",
              borderRadius: "10px",
              width: "100%",
              marginTop: "10px",
              textTransform: "none",
              background:
                "linear-gradient(93.39deg, #582FF9 1.3%, #AC2FF9 97.46%), #1B1C1D",
            }}
            onClick={handleClick({
              vertical: "bottom",
              horizontal: "center",
            })}
          >
            Submit
          </Button>
          <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Link href="/login" variant="body2">
              Sign In
            </Link>
          </Grid>
          {/* </Box> */}
          <Notification
            message={
              alertContent
                ? alertContent
                : `Success : Data Fetched Successfully.`
            }
            open={alert}
            severity={alertType}
            onClose={handleAlert}
          />
        </div>
      </Card>
    </Box>
  );
};
export default ResetPasswordF;
