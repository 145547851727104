import * as React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Pagination,
  Grid,
  FormControl,
  Select,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchNotFound from "../../../../../../Utils/SearchNotFound/SearchNotFount";
import moment from "moment";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router";
import API from "../../../../../../E2E/Axios.Utils";
import InputLabel from "@material-ui/core/InputLabel";

const headCells = [
  {
    id: "Question",
    numeric: false,
    disablePadding: true,
    label: "QUESTION",
  },
  {
    id: "Answer",
    numeric: true,
    disablePadding: false,
    label: "ANSWER",
  },
  {
    id: "Comments",
    numeric: true,
    disablePadding: false,
    label: "COMMENTS",
  },
  {
    id: "Created Date",
    numeric: true,
    disablePadding: false,
    label: "CREATED DATE",
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sx={{ fontWeight: "bold", bgcolor: "#128CB0", color: "white" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  employeeId,
  surveyScheduleID,
  handleDialog,
  setAlert,
  setAlertType,
  setAlertContent,
}) {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState([]);
  const [pages, setPages] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [usersData, setUsersData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(null);
  const [maxrow, setMaxRow] = React.useState(10);
  const [selecteds, setSelecteds] = React.useState("");
  const [totalRecord, setTotalRecord] = React.useState("");

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    console.log("Pages:::", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPages(1);
    console.log("rowPerPage:::", rowsPerPage);
  };

  const token = localStorage.getItem("token").toString();

  const getUsersData = () => {
    setLoading(true);
    API.get(
      `/app/secure/list_survey_responce?page=${pages}&max_rows=${maxrow}&employee_id=${employeeId}&survey_schedule_id=${surveyScheduleID}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        // console.log("Dta for dta", res);
        setUsersData(res?.data?.survey_schedule_list);
        setPageCount(res?.data?.total_page_count);
        setTotalRecord(res?.data?.total_record_count);
        setLoading(false);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          setLoading(false);
          navigate("/login");
        } else {
          setAlertContent(err.data.message);
          setAlertType("error");
          setAlert(true);
          setLoading(false);
        }
      });
  };

  React.useEffect(() => {
    getUsersData();
  }, [pages, maxrow]);

  const selectionChangeHandler = (event) => {
    setSelecteds(event.target.value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ mb: 2 }}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="Companies Table"
            sx={{ minWidth: 750 }}
          >
            <EnhancedTableHead />

            {
              <TableBody>
                {usersData.map((user) => {
                  const {
                    survey_question_id,
                    survey_response_id,
                    sub_answer,
                    answer,
                    created_date,
                  } = user;

                  return (
                    <TableRow hover tabIndex={-1} key={survey_response_id}>
                      <TableCell align="center">
                        {survey_question_id.title}
                      </TableCell>

                      <TableCell align="center">{answer}</TableCell>
                      <TableCell align="center">{sub_answer}</TableCell>
                      <TableCell align="center">
                        {moment(created_date).format("DD-MM-YYYY")}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            }
          </Table>
        </TableContainer>

        <Grid container spacing={2} sx={{ padding: "10px" }}>
          <Grid item xs={7}>
            <Box display="flex" alignItems={"center"} flexDirection="row">
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                Row per Page
              </Typography>
              <FormControl variant="standard" sx={{ m: 0, minWidth: 50 }}>
                <InputLabel
                  style={{
                    marginLeft: "20px",
                    position: "absolute",
                    marginTop: "20px",
                  }}
                >
                  {selecteds === "" ? 10 : selected}
                </InputLabel>
                <Select
                  size="small"
                  value={selecteds}
                  onChange={selectionChangeHandler}
                  disableUnderline
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    paddingLeft: 10,
                    paddingTop: 8,
                    ":before": {
                      borderColor: "#fafafc",
                    },
                    ":after": {
                      borderColor: "#fafafc",
                    },
                    height: 30,
                    justifyContent: "center",
                  }}
                >
                  <MenuItem
                    value={1}
                    onClick={() => {
                      setMaxRow(10);
                    }}
                  >
                    10
                  </MenuItem>
                  <MenuItem
                    value={2}
                    onClick={() => {
                      setMaxRow(20);
                    }}
                  >
                    20
                  </MenuItem>
                  <MenuItem
                    value={3}
                    onClick={() => {
                      setMaxRow(50);
                    }}
                  >
                    50
                  </MenuItem>
                  <MenuItem
                    value={4}
                    onClick={() => {
                      setMaxRow(100);
                    }}
                  >
                    100
                  </MenuItem>
                </Select>
              </FormControl>
              {/* <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "20px",
                }}
              >
                of {totalRecord}
              </Typography> */}
            </Box>
          </Grid>
          <Grid item xs={4} sx={{ marginLeft: "40px" }}>
            <Pagination
              style={{ marginTop: "2%", marginLeft: "5%" }}
              onChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={pages}
              rowsPerPage={rowsPerPage}
              count={pageCount || 0}
            />
          </Grid>
        </Grid>
      </Paper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={handleDialog}>CLose</Button>
      </Box>
    </Box>
  );
}
