import { rgbToHex } from "@material-ui/core";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  datepicker: {
    "& .react-datepicker__input-container> input": {
      width: "500px",
      height: "50px",
      marginTop: "10px",
      // marginLeft:"-96%"
      //position:"absolute"
    },
  },
  dialog: {
    "& .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop": {
      backgroundColor: '#00000028',
    }
  },
  modal_content_AddQuestionStyle: {
    "& header": {
      backgroundColor: "#128CB0",
      display: "flex",
      justifyContent: "space-between",
      //   alignItems: "center",
      color: "#fff",
      padding: "15px 20px",
      width: "100%",

      "& .titleModal": {
        fontFamily: "'Montserrat'",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "18px",
        lineHeight: "22px",
        textTransform: "capitalize",
        color: "#FFFFFF",
        marginBottom: "0",
      },
    },
  },
});

export { useStyles };
