import React from "react";
import {
  Grid,
  TextField,
  FormControl,
  MenuItem,
  FormHelperText,
  InputLabel,
  Select,
  Autocomplete,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@mui/material";
import API from "../../../../../E2E/Axios.Utils";
import { fieldToTextField } from "formik-material-ui";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@mui/styles";
import { useParams } from "react-router-dom";

const useStyles = makeStyles({
  InputBox__wrapper: {
    width: "100%",
  },

  Select_formControl: {
    width: "100%",
  },

  datepicker: {
    "& .react-datepicker__input-container> input": {
      font: "inherit",
      letterSpacing: "inherit",
      color: "currentColor",
      padding: "16.5px 14px",
      border: "0",
      boxSizing: "content-box",
      background: "none",
      height: "1.4375em",
      margin: "0",
      WebkitTapHighlightColor: "transparent",
      display: "block",
      minWidth: "0",
      width: "80.5%",
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderStyle: "solid",
      borderWidth: "1px",
      borderRadius: "4px",
    },
    "& .react-datepicker-popper": {
      zIndex: "100",
    },
  },
});

const AddTeamForm = ({
                       // handleDialog,
                       // update,
                       // setUpdate,
                       setAlert,
                       setAlertType,
                       setAlertContent,
                       employeeIdData,
                       handleform,
                       submitRef,
                       orgIdData,
                       setOrgIdData,
                       setTabTeamActive,
                       setToggleTeam,
                     }) => {
  const [oragnisationData, setoragnisationData] = React.useState([]);
  const [teamData, setTeamData] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [role, setRole] = React.useState("");
  let {id} = useParams();
  const classes = useStyles();

  const initialValues = {
    name: "",
    survey_id: "",
    org_id: id,
    start_date: "",
    end_date: "",
    team_ids: "",
    employee_ids: "",
    remember: false,
  };

  const surveyId = localStorage.getItem("surveyId");
  const token = localStorage.getItem("token").toString();
  const roleId = localStorage.getItem("role").toString();
  const orgId = orgIdData;

  const setDateFormat = (date) => {
    const formatStartDate = `${date.getFullYear()}-${
        date.getMonth() + 1
    }-${date.getDate()}`;
    return formatStartDate;
  };

  const onSubmit = (values, props) => {

    let arr1 = [];
    if (values?.team && values?.team.length > 0) {
      values.team.map((user) => {
        arr1.push(user.team_id);
      });
    }

    const startDate = setDateFormat(values.start_date);
    const endDate = setDateFormat(values.end_date);
    const oganId = role === "Client" ? orgId : values.org_id;

    const data = {
      name: values.name,
      survey_id: surveyId,
      org_id: oganId,
      start_date: startDate,
      end_date: endDate,
      team_ids: arr1,
      employee_ids: employeeIdData,
      remember: false,
    };

    API.post("/app/secure/survey_schedule", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        // handleDialog();
        // setUpdate(update + 1);
        // console.log("res.data.msg", res);
        setAlertContent(res.data.message);
        setAlertType("success");
        setAlert(true);
        setTimeout(() => {
          if (role == "Client") {
            setToggleTeam(true);
          } else {
            setTabTeamActive(true);
          }
        }, 500);
      })
      .catch((err) => {
        // console.log(err.response.data.message);
        if (err?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
        } else {
          // setAlertContent(err.data.message);
          setAlertContent(err.response.data.message);
          setAlertType("error");
          setAlert(true);
        }
      });
    props.resetForm();
  };

  // console.log("handleform", handleform);

  React.useEffect(() => {
    if (handleform) onSubmit();
  }, [handleform]);

  const AlphaNumeric = /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/;

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(AlphaNumeric, "Please enter valid tab name.")
      .max(40, "Tab name must be at most 40 characters.")
      .required("This field is required."),
    // org_id: Yup.string().required("This field is required."),
    // team: Yup.array().required("At least one Team is required"),
    start_date: Yup.string().required("This field is required."),
    end_date: Yup.string().required("This field is required."),
  });
  const getOrganisationData = () => {
    API.get("/app/secure/list_customer?page=1&max_rows=1000", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setoragnisationData(res.data.customer_list);
      })
      .catch((err) => {});
  };
  React.useEffect(() => {
    getOrganisationData();
    getTeamData();
  }, []);

  const getTeamData = () => {
    API.get(`/app/secure/teams?page=1&max_rows=10&org_id=${orgId}`, {
      headers: {Authorization: `Bearer ${token}`},
    })
        .then((res) => {
          // console.log("responseTeamdata", res);
          setTeamData(res.data.teams_list);
        })
        .catch((err) => {
        });
  };

  React.useEffect(() => {
    // console.log('org')
    getTeamData();
  }, [orgId]);

  React.useEffect(() => {
    let arr = [];
    teamData.map((user) => {
      arr.push(user);
      return arr;
    });
    // console.log("arr", arr);
    setSelected(arr);
  }, [teamData]);

  React.useEffect(() => {
    var role = localStorage.getItem("role");
    setRole(role);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(props) => (
        <Form noValidate>
          <Grid
            container
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2.5,
            }}
          >
            <Grid item xs={5.65}>
              <Field
                as={TextField}
                label="Batch name"
                name="name"
                fullWidth
                error={props.errors.name && props.touched.name}
                helperText={<ErrorMessage name="name" />}
                required
                sx={{ mt: 1.2 }}
              />
            </Grid>
            <Grid item xs={5.65}>
              <FormControl fullWidth variant="outlined">
                <Field
                  component={FormikAutocomplete}
                  label="Team"
                  name="team"
                  options={selected}
                  getOptionLabel={(option) => option.team_name}
                  textFieldProps={{
                    fullWidth: true,
                    margin: "normal",
                    variant: "outlined",
                  }}
                  multiple
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} className={classes.datepicker}>
              <Field
                component={DatePickerField}
                label="Start Date "
                placeholderText={"Start Date *"}
                name="start_date"
                required
              />
              <Field
                as={TextField}
                name="start_date"
                fullWidth
                error={props.errors.start_date && props.touched.start_date}
                helperText={<ErrorMessage name="start_date" />}
                sx={{
                  color: "red",
                  "& .MuiInputBase-formControl": {
                    display: "none",
                  },
                }}
              />
            </Grid>
            <Grid item xs={4} className={classes.datepicker}>
              <Field
                component={DatePickerField}
                label="End Date "
                placeholderText={"End Date *"}
                name="end_date"
                required
              />
              <Field
                as={TextField}
                name="end_date"
                fullWidth
                error={props.errors.end_date && props.touched.end_date}
                helperText={<ErrorMessage name="end_date" />}
                sx={{
                  color: "red",
                  "& .MuiInputBase-formControl": {
                    display: "none",
                  },
                }}
              />
            </Grid>
            {role === "Super Admin" ? (
              <Grid item xs={4}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="select" required>
                    Select Organisation
                  </InputLabel>
                  <Field
                    as={Select}
                    name="org_id"
                    id="select"
                    label="select Organisation"
                    variant="outlined"
                    required
                  >
                    {oragnisationData.map((name) => {
                      return (
                        <MenuItem
                          key={name.org_id}
                          value={name.org_id}
                          onClick={() => setOrgIdData(name.org_id)}
                        >
                          {name.client_entity_name}
                        </MenuItem>
                      );
                    })}
                  </Field>
                </FormControl>
              </Grid>
            ) : null}
          </Grid>

          <Button
            type="submit"
            ref={submitRef}
            variant="contained"
            sx={{ display: "none" }}
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AddTeamForm;

const FormikAutocomplete = ({ textFieldProps, ...props }) => {
  const {
    form: { setTouched, setFieldValue },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;

  return (
    <FormControl>
      <Autocomplete
        {...props}
        {...field}
        onChange={(_, value) => setFieldValue(name, value)}
        onBlur={() => setTouched({ [name]: true })}
        getOptionSelected={(item, current) => item.value === current.value}
        limitTags={2}
        renderInput={(props) => (
          <TextField
            {...props}
            {...textFieldProps}
            label="Team"
            // helperText={helperText}
            // error={error}
            // required
          />
        )}
      />
    </FormControl>
  );
};

export const DatePickerField = ({ textFieldProps, ...props }) => {
  const {
    form: { setTouched, setFieldValue },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name, value } = field;
  return (
    <DatePicker
      {...field}
      {...props}
      onChange={(val) => {
        setFieldValue(name, val);
      }}
      onBlur={() => setTouched({ [name]: true })}
      selected={(value && new Date(value)) || null}
      dateFormat="yyyy-MM-dd"
      autoComplete="off"
      showYearDropdown
      showMonthDropdown
      dropdownMode="select"
    />
  );
};
