import React, { useEffect, useRef, useContext } from "react";

import Emoji from "../Emoji/Emoji";

import style from './PolarQuestion.module.scss'

import {saveAnswer} from "../../context/chatActions";

import { ChatContext } from "../../context/chatContext";

const PolarQuestion = () => {
    const { chatDispatch, chatConversationState } = useContext(ChatContext);

    return <div className={style.questionContainer}>
        <div className={`${style.smiley} ${chatConversationState.answer == 'yes' ? style.selected : ''}`} onClick={() => saveAnswer('yes', chatDispatch)}>
            <Emoji className={style.emoji} symbol={'0x1F60A'} />
            <p className={style.response}>Yes</p>
        </div>
        <div className={`${style.smiley} ${chatConversationState.answer == 'no' ? style.selected : ''}`} onClick={() => saveAnswer('no', chatDispatch)}>
            <Emoji className={style.emoji} symbol={'0x1F61E'} />
            <p className={style.response}>No</p>
        </div>
    </div>
}

export default PolarQuestion;
