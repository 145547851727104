import React from "react";
import RewardManagement from "../RewardManagement/RewardManagement";
import AllDashboard from "./AllDashboard";

const Dashboard = () => {
  const [role, setRole] = React.useState("");

  React.useEffect(() => {
    var role = localStorage.getItem("role");
    setRole(role);
  }, []);

  return (
    <div>{role === "Employee" ? <RewardManagement /> : <AllDashboard />}</div>
  );
};

export default Dashboard;
// Employee
