import React, { useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import addPlusIcon from "../../assets/images/svgs/plus_add_club.svg";
import deleteIcon from "../../assets/images/svgs/close.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useStyles } from "./dialog.style";
//import { KeyboardDatePicker } from "@material-ui/pickers";

const Dialogcomp = (props) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const classes = useStyles();

  const [inputs, setInputs] = useState([]);
  const [subInputs, setSubInputs] = useState({});
  const [spocInputs, setSpocInputs] = useState({});

  const [formFields, setFormFields] = useState([{ name: "", description: "" }]);
  const addFields = () => {
    setFormFields([...formFields, { name: "", description: "" }]);
  };
  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  const onChangeIndustryName = (text, idx) => {
    const localObj = formFields.map((item, index) =>
      index === idx ? { ...item, name: text } : item
    );
    setFormFields(localObj);
  };

  const onChangeIndustryDesc = (text, idx) => {
    const localObj = formFields.map((item, index) =>
      index === idx ? { ...item, description: text } : item
    );
    setFormFields(localObj);
  };

  const [subformFields, setSubFormFields] = useState([
    { name: "", description: "" },
  ]);
  const addsubFields = () => {
    setSubFormFields([...subformFields, { name: "", description: "" }]);
  };
  const removeSubFields = (index) => {
    let data = [...subformFields];
    data.splice(index, 1);
    setSubFormFields(data);
  };

  const onChangeSubIndustryName = (text, idx) => {
    const localObj = subformFields.map((item, index) =>
      index === idx ? { ...item, name: text } : item
    );
    setSubFormFields(localObj);
  };

  const onChangeSubIndustryDesc = (text, idx) => {
    const localObj = subformFields.map((item, index) =>
      index === idx ? { ...item, description: text } : item
    );
    setSubFormFields(localObj);
  };

  const [sopformFields, setSopFormFields] = useState([
    {
      spoc_name: "",
      spoc_designation: "",
      spoc_email: "",
      spoc_mobile_number: "",
    },
  ]);
  const addSopFields = () => {
    setSopFormFields([
      ...sopformFields,
      {
        spoc_name: "",
        spoc_designation: "",
        spoc_email: "",
        spoc_mobile_number: "",
      },
    ]);
  };
  const removeSopFields = (index) => {
    let data = [...sopformFields];
    data.splice(index, 1);
    setSopFormFields(data);
  };

  const onChangeSpocName = (text, idx) => {
    const localObj = sopformFields.map((item, index) =>
      index === idx ? { ...item, spoc_name: text } : item
    );
    setSopFormFields(localObj);
  };

  const onChangeSpocDesig = (text, idx) => {
    const localObj = sopformFields.map((item, index) =>
      index === idx ? { ...item, spoc_designation: text } : item
    );
    setSopFormFields(localObj);
  };

  const onChangeSpocEmail = (text, idx) => {
    const localObj = sopformFields.map((item, index) =>
      index === idx ? { ...item, spoc_email: text } : item
    );
    setSopFormFields(localObj);
  };

  const onChangeSpocMobileNo = (text, idx) => {
    const localObj = sopformFields.map((item, index) =>
      index === idx ? { ...item, spoc_mobile_number: text } : item
    );
    setSopFormFields(localObj);
  };

  const onCreateClick = () => {
    props.onClick(formFields, subformFields, sopformFields);
  };
  const [errorf, setErrorf] = useState(false);
  const [validNameerror, setValidNameError] = useState(false);
  const [validDescerror, setValidDescError] = useState(false);

  const Validation = () => {
    if (!props.value1 || !props.value2) {
      setErrorf(true);
    } else if (
      props.value1.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) === null||
      props.value1.match(/^[0-9]+$/) !== null
    ) {
      setValidNameError(true);
    } else if (
      props.value2.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) === null ||
      props.value2.match(/^[0-9]+$/) !== null ||
      props.value2.length > 500
    ) {
      setValidDescError(true);
    } else {
      props.onClick();
    }
  };
  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        className={classes.dialog}
      >
        <DialogTitle className={classes.modal_content_AddQuestionStyle}>
          <header>{props.title}</header>
        </DialogTitle>
        <DialogContent>
          <Box flexDirection={"column"} display="flex" alignItems="center">
            {props.value1 !== undefined ? (
              <TextField
                required
                error={
                  errorf && !props.value1
                    ? true
                    : validNameerror &&
                      (props.value1.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) === null ||
                      props.value1.match(/^[0-9]+$/) !== null)
                    ? true
                    : false
                }
                helperText={
                  errorf && !props.value1
                    ? "Required Name."
                    : validNameerror &&
                      (props.value1.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) === null ||
                        props.value1.match(/^[0-9]+$/) !== null)
                    ? "Enter valid name."
                    : ""
                }
                id="outlined-basic"
                label={props.label1}
                variant="outlined"
                sx={{
                  width: "500px",
                  marginTop: "10px",
                }}
                value={props.value1}
                onChange={props.onChange1}
              />
            ) : null}
            {props.value2 !== undefined ? (
              <TextField
                required
                error={
                  errorf && !props.value2
                    ? true
                    : validDescerror &&
                      (props.value2.match(/^[A-Za-z0-9]*$/) === null ||
                        props.value2.match(/^[0-9]+$/) !== null ||
                        props.value2.length > 500)
                    ? true
                    : false
                }
                helperText={
                  errorf && !props.value2
                    ? "Required Description."
                    : validDescerror &&
                      (props.value2.match(/^[A-Za-z0-9]*$/) === null ||
                        props.value2.match(/^[0-9]+$/) !== null ||
                        props.value2.length > 500)
                    ? "Enter valid Description."
                    : ""
                }
                id="outlined-basic"
                label={props.label2}
                variant="outlined"
                sx={{
                  width: "500px",
                  marginTop: "10px",
                }}
                value={props.value2}
                onChange={props.onChange2}
              />
            ) : null}
            {/* {props.value3 !== undefined ? (
              <TextField
                id="outlined-basic"
                label={props.label3}
                variant="outlined"
                sx={{
                  width: "500px",
                  marginTop: "10px",
                }}
                value={props.value3}
                onChange={props.onChange3}
              />
            ) : null} */}
            {/* {props.value4 !== undefined ? (
              <TextField
                id="outlined-basic"
                label={props.label4}
                variant="outlined"
                sx={{
                  width: "500px",
                  marginTop: "10px",
                }}
                value={props.value4}
                onChange={props.onChange4}
              />
            ) : null} */}
            {/* {props.selected !== undefined ? (
              <div className={classes.datepicker}>
                <div>
                  <DatePicker
                    placeholderText="Date Of Birth"
                    // selected={selectedDate}
                    // onChange={date =>setSelectedDate(date)}
                    selected={props.selected}
                    onChange={props.onChange}
                    dateFormat="yyyy-MM-dd"
                  />
                </div>
              </div> */}
            {/* ) :    <Fragment>
                     <KeyboardDatePicker
               placeholder="2018/10/10"
            //  // value={selectedDate}
            //  // onChange={date => handleDateChange(date)}
            //   format="yyyy/MM/dd"
            // />
            //     </Fragment>

            //   <TextField
            //   id="outlined-basic"
            //   label={props.label4}
            //    type="date"
            //   defaultValue="2017-05-24"
            //   variant="outlined"
            //   sx={{
            //         width: "500px",
            //         marginTop: "10px",
            //      }}
            //   onChange={props.onChange4}
            //   value={props.value4}
            //   InputLabelProps={{
            //     shrink: true,
            //   }}

            // />
            // <TextField
            //   id="outlined-basic"
            //   label={props.label4}
            //   variant="outlined"
            //   sx={{
            //     width: "500px",
            //     marginTop: "10px",
            //   }}
            //   value={props.value4}
            //   onChange={props.onChange4}
            // />
            null}

            {props.value5 !== undefined ? (
              <TextField
                id="outlined-basic"
                label={props.label5}
                variant="outlined"
                sx={{
                  width: "500px",
                  marginTop: "10px",
                }}
                value={props.value5}
                onChange={props.onChange5}
              />
            ) : null}
            {props.value6 !== undefined ? (
              <TextField
                id="outlined-basic"
                label={props.label6}
                variant="outlined"
                sx={{
                  width: "500px",
                  marginTop: "10px",
                }}
                value={props.value6}
                onChange={props.onChange6}
              />
            ) : null}
            {props.value7 !== undefined ? (
              <TextField
                id="outlined-basic"
                label={props.label7}
                variant="outlined"
                sx={{
                  width: "500px",
                  marginTop: "10px",
                }}
                value={props.value7}
                onChange={props.onChange7}
              />
            ) : null}
            {props.value8 !== undefined ? (
              <TextField
                id="outlined-basic"
                label={props.label8}
                variant="outlined"
                sx={{
                  width: "500px",
                  marginTop: "10px",
                }}
                value={props.value8}
                onChange={props.onChange8}
              />
            ) : null}
            {props.value9 !== undefined ? (
              <TextField
                id="outlined-basic"
                label={props.label9}
                variant="outlined"
                sx={{
                  width: "500px",
                  marginTop: "10px",
                }}
                value={props.value9}
                onChange={props.onChange9}
              />
            ) : null}
            {props.value10 !== undefined ? (
              <TextField
                id="outlined-basic"
                label={props.label10}
                variant="outlined"
                sx={{
                  width: "500px",
                  marginTop: "10px",
                }}
                value={props.value10}
                onChange={props.onChange10}
              />
            ) : null}

            {props.value11 !== undefined ? (
              <TextField
                id="outlined-basic"
                label={props.label11}
                variant="outlined"
                sx={{
                  width: "500px",
                  marginTop: "10px",
                }}
                value={props.value11}
                onChange={props.onChange11}
              />
            ) : null}
            {props.value12 !== undefined ? (
              <TextField
                id="outlined-basic"
                label={props.label12}
                variant="outlined"
                sx={{
                  width: "500px",
                  marginTop: "10px",
                }}
                value={props.value12}
                onChange={props.onChange12}
              />
            ) : null}
            {props.value13 !== undefined ? (
              <TextField
                id="outlined-basic"
                label={props.label13}
                variant="outlined"
                sx={{
                  width: "500px",
                  marginTop: "10px",
                }}
                value={props.value13}
                onChange={props.onChange13}
              />
            ) : null}
            {props.value14 !== undefined ? (
              <TextField
                id="outlined-basic"
                label={props.label14}
                variant="outlined"
                sx={{
                  width: "500px",
                  marginTop: "10px",
                }}
                value={props.value14}
                onChange={props.onChange14}
              />
            ) : null}
            {props.value15 !== undefined ? (
              <TextField
                id="outlined-basic"
                label={props.label15}
                variant="outlined"
                sx={{
                  width: "500px",
                  marginTop: "10px",
                }}
                value={props.value15}
                onChange={props.onChange15}
              />
            ) : null}
            {props.value16 !== undefined ? (
              <>
                {formFields.map((item, index) => {
                  return (
                    <div key={index}>
                      <Box
                        display="flex"
                        alignItem="center"
                        justifyContent="space-between"
                        flexDirection="row"
                        sx={{ width: "500px" }}
                      >
                        <TextField
                          id="outlined-basic"
                          label={props.label16}
                          variant="outlined"
                          sx={{
                            width: "235px",
                            marginTop: "10px",
                          }}
                          onChange={(e) => {
                            onChangeIndustryName(e.target.value, index);
                          }}
                          value={item.name}
                        />
                        <TextField
                          id="outlined-basic"
                          label={props.label17}
                          variant="outlined"
                          sx={{
                            width: "235px",
                            marginTop: "10px",
                          }}
                          onChange={(e) => {
                            onChangeIndustryDesc(e.target.value, index);
                          }}
                          value={item.description}
                        />
                        {index !== 0 && (
                          <Box
                            sx={{
                              marginTop: "10px",
                            }}
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <img
                              src={deleteIcon}
                              onClick={() => removeFields(index)}
                              alt="add"
                              // style={{ marginLeft: "5px" }}
                            />
                          </Box>
                        )}
                      </Box>
                    </div>
                  );
                })}
                <Box
                  sx={{
                    // backgroundColor: "pink",
                    width: "500px",
                    marginTop: "10px",
                  }}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <img
                    src={addPlusIcon}
                    onClick={addFields}
                    alt="add"
                    // style={{ marginLeft: "5px" }}
                  />
                </Box>
              </>
            ) : null}
            {props.value18 !== undefined ? (
              <>
                {subformFields.map((item, index) => {
                  return (
                    <div key={index}>
                      <Box
                        display="flex"
                        alignItem="center"
                        justifyContent="space-between"
                        flexDirection="row"
                        sx={{ width: "500px" }}
                      >
                        <TextField
                          id="outlined-basic"
                          label={props.label18}
                          variant="outlined"
                          sx={{
                            width: "235px",
                            marginTop: "10px",
                          }}
                          // value={props.value18}
                          // onChange={props.onChange18}
                          onChange={(e) => {
                            onChangeSubIndustryName(e.target.value, index);
                          }}
                        />
                        <TextField
                          id="outlined-basic"
                          label={props.label19}
                          variant="outlined"
                          sx={{
                            width: "235px",
                            marginTop: "10px",
                          }}
                          // value={props.value19}
                          // onChange={props.onChange19}
                          onChange={(e) => {
                            onChangeSubIndustryDesc(e.target.value, index);
                          }}
                        />
                        {index !== 0 && (
                          <Box
                            sx={{
                              marginTop: "10px",
                            }}
                            display="flex"
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <img
                              src={deleteIcon}
                              onClick={() => removeSubFields(index)}
                              alt="add"
                              // style={{ marginLeft: "5px" }}
                            />
                          </Box>
                        )}
                      </Box>
                    </div>
                  );
                })}
                <Box
                  sx={{
                    // backgroundColor: "pink",
                    width: "500px",
                    marginTop: "10px",
                  }}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <img
                    src={addPlusIcon}
                    onClick={addsubFields}
                    alt="add"
                    // style={{ marginLeft: "5px" }}
                  />
                </Box>
              </>
            ) : null}
            {props.value20 !== undefined ? (
              <>
                {sopformFields.map((item, index) => {
                  return (
                    <div key={index}>
                      <Box
                        display="flex"
                        alignItem="center"
                        flexDirection="column"
                      >
                        <Box
                          display="flex"
                          alignItem="center"
                          justifyContent="space-between"
                          flexDirection="row"
                          sx={{ width: "500px" }}
                        >
                          <TextField
                            id="outlined-basic"
                            label={props.label20}
                            variant="outlined"
                            sx={{
                              width: "230px",
                              marginTop: "10px",
                            }}
                            onChange={(e) => {
                              onChangeSpocName(e.target.value, index);
                            }}
                          />
                          <TextField
                            id="outlined-basic"
                            label={props.label21}
                            variant="outlined"
                            sx={{
                              width: "230px",
                              marginTop: "10px",
                            }}
                            onChange={(e) => {
                              onChangeSpocDesig(e.target.value, index);
                            }}
                          />
                        </Box>
                        <Box
                          display="flex"
                          alignItem="center"
                          justifyContent="space-between"
                          flexDirection="row"
                          sx={{ width: "500px" }}
                        >
                          <TextField
                            id="outlined-basic"
                            label={props.label22}
                            variant="outlined"
                            sx={{
                              width: "230px",
                              marginTop: "10px",
                            }}
                            onChange={(e) => {
                              onChangeSpocEmail(e.target.value, index);
                            }}
                          />
                          <TextField
                            id="outlined-basic"
                            label={props.label23}
                            variant="outlined"
                            sx={{
                              width: "230px",
                              marginTop: "10px",
                            }}
                            onChange={(e) => {
                              onChangeSpocMobileNo(e.target.value, index);
                            }}
                          />
                          {index !== 0 && (
                            <Box
                              sx={{
                                marginTop: "10px",
                              }}
                              display="flex"
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <img
                                src={deleteIcon}
                                onClick={() => removeSopFields(index)}
                                alt="add"
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </div>
                  );
                })} */}
            {/* <Box
                  sx={{
                    // backgroundColor: "pink",
                    width: "500px",
                    marginTop: "10px",
                  }}
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <img src={addPlusIcon} onClick={addSopFields} alt="add" />
                </Box>
              </>
            ) : null} */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClickclose}>Cancel</Button>
          <Button
            id="btn"
            onClick={() => {
              // onCreateClick();
              Validation();
            }}
            color="primary"
            autoFocus
          >
            {props.text}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Dialogcomp;
