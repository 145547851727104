import React, { useEffect, useRef, useContext } from "react";
import Rating from "@mui/material/Rating";

import style from "./SliderQuestion.module.scss";

import { saveAnswer } from "../../context/chatActions";

import { ChatContext } from "../../context/chatContext";

const SliderQuestion = () => {
  const { chatDispatch, chatConversationState } = useContext(ChatContext);
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (value > 0) saveAnswer(value.toString(), chatDispatch);
  }, [value]);

  return (
    <div
      className={style.questionContainer}
    >
      <Rating
        name="simple-controlled"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        max={10}
        style={{ fontSize: "45px" }}
      />
    </div>
  );
};
export default SliderQuestion;
