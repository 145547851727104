import * as React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  Select,
  Pagination,
} from "@mui/material";
import Table from "@mui/material/Table";
import API from "../../../../../E2E/Axios.Utils";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import SearchNotFound from "../../../../../Utils/SearchNotFound/SearchNotFount";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@mui/material/MenuItem";

const headCells = [
  {
    id: "Name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "Position",
    numeric: true,
    disablePadding: false,
    label: "Position",
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "Role",
    numeric: true,
    disablePadding: false,
    label: "Role",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sx={{ bgcolor: "#128CB0" }}>
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
            sx={{ fontWeight: "bold", color: "white" }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sx={{ fontWeight: "bold", bgcolor: "#128CB0", color: "white" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  search,
  setAlert,
  setAlertType,
  setAlertContent,
  update,
  age,
  gender,
  location,
  setemployeeIdData,
  orgIdData,
}) {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  const [pages, setPages] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [companiesData, setCompaniesData] = React.useState([]);
  const [companiesSearch, setCompaniesSearch] = React.useState([]);
  const [clientFilter, setClientFilter] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selecteds, setSelecteds] = React.useState("");
  const [maxrow, setMaxRow] = React.useState(10);
  const [pageCount, setPageCount] = React.useState(null);
  const [totalRecord, setTotalRecord] = React.useState("");

  const dispatch = useDispatch();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = companiesData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const employeedata = selected;

  React.useEffect(() => {
    setemployeeIdData(employeedata);
  }, [employeedata]);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    // console.log("newSelected", newSelected);
    setSelected(newSelected);
  };
  // console.log("Selected", selected);

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    console.log("Pages:::", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPages(1);
    console.log("rowPerPage:::", rowsPerPage);
  };

  const token = localStorage.getItem("token").toString();

  const getClientFilter = () => {
    API.get(
      `/app/secure/employee?page=${pages}&max_rows=${maxrow}&gender=${gender}&age=${age}&location=${location}&org_id=${orgIdData}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((res) => {
      setClientFilter(res.data.employeelist);
      setPageCount(res?.data?.total_page_count);
      setTotalRecord(res?.data?.total_record_count);
    });
  };

  const getCompaniesSearch = () => {
    API.get(`/app/secure/employee?page=1&max_row=4&query=${search}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setCompaniesSearch(res.data.employeelist);
      })
      .catch((err) => {
        setCompaniesSearch([]);
      });
  };

  // const getCompaniesData = () => {
  //   setLoading(true);
  //   API.get(`/app/secure/employee?page=${pages}&max_rows=${maxrow}`, {
  //     headers: { Authorization: `Bearer ${token}` },
  //   })
  //     .then((res) => {
  //       // console.log("resEmployee", res);
  //       setCompaniesData(res.data.employeelist);
  //       setPageCount(res?.data?.total_page_count);
  //       setTotalRecord(res?.data?.total_record_count);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       // console.log(err);
  //       if (err?.response?.status == 401) {
  //         setAlertContent("Token is invalid or expired.");
  //         setAlertType("error");
  //         setAlert(true);
  //         navigate("/");
  //       } else {
  //         setAlertContent(err.data.message);
  //         setAlertType("error");
  //         setAlert(true);
  //         setLoading(false);
  //       }
  //     });
  // };

  // React.useEffect(() => {
  //   getCompaniesData();
  // }, [update, pages, maxrow]);

  React.useEffect(() => {
    if (search) getCompaniesSearch();
  }, [search]);

  React.useEffect(() => {
    getClientFilter();
  }, [gender, location, age, orgIdData, update, pages, maxrow]);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - companiesData.length) : 0;

  // console.log("orgIdData", orgIdData);

  const selectionChangeHandler = (event) => {
    setSelecteds(event.target.value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "98%", mb: 2 }}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="Companies Table"
            sx={{ minWidth: 750 }}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={companiesData.length}
            />

            {(search &&
              (companiesSearch?.length > 0 ? (
                <TableBody>
                  {companiesSearch
                    // .slice(
                    //   page * rowsPerPage,
                    //   page * rowsPerPage + rowsPerPage
                    // )
                    .map((employee) => {
                      const {
                        first_name_of_the_employee,
                        last_name_of_the_employee,
                        type_of_role,
                        employee_id,
                        designation,
                        status,
                      } = employee;
                      const isItemSelected = isSelected(employee_id);
                      const labelId = `enhanced-table-checkbox-${employee_id}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, employee_id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={employee_id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={employee_id}
                            scope="company"
                            padding="none"
                          >
                            {first_name_of_the_employee}
                          </TableCell>
                          <TableCell>{designation}</TableCell>
                          <TableCell>{status}</TableCell>
                          <TableCell>{type_of_role}</TableCell>
                        </TableRow>
                      );
                    })}
                  {/* {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: 53 * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )} */}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <SearchNotFound />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))) ||
              ((orgIdData ||
                gender ||
                age ||
                location ||
                (gender && age) ||
                (gender && location) ||
                (age && location) ||
                (gender && age && location)) &&
                (clientFilter?.length > 0 ? (
                  <TableBody>
                    {clientFilter
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((employee) => {
                        const {
                          first_name_of_the_employee,
                          last_name_of_the_employee,
                          type_of_role,
                          employee_id,
                          designation,
                          status,
                        } = employee;
                        const isItemSelected = isSelected(employee_id);
                        const labelId = `enhanced-table-checkbox-${employee_id}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, employee_id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={employee_id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={employee_id}
                              scope="company"
                              padding="none"
                            >
                              {first_name_of_the_employee}
                            </TableCell>
                            <TableCell>{designation}</TableCell>
                            <TableCell>{status}</TableCell>
                            <TableCell>{type_of_role}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )))}
          </Table>
        </TableContainer>
        <Grid container spacing={2} sx={{ padding: "10px" }}>
          <Grid item xs={7}>
            <Box display="flex" alignItems={"center"} flexDirection="row">
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                Row per Page
              </Typography>
              <FormControl variant="standard" sx={{ m: 0, minWidth: 50 }}>
                {/* <InputLabel
                  style={{
                    marginLeft: "20px",
                    position: "absolute",
                    marginTop: "20px",
                  }}
                >
                  {selecteds === "" ? 10 : selected}
                </InputLabel> */}
                <Select
                  size="small"
                  value={selecteds}
                  onChange={selectionChangeHandler}
                  disableUnderline
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    paddingLeft: 10,
                    paddingTop: 8,
                    ":before": {
                      borderColor: "#fafafc",
                    },
                    ":after": {
                      borderColor: "#fafafc",
                    },
                    height: 30,
                    justifyContent: "center",
                  }}
                  //   SelectProps={{ disableUnderline: true }}
                >
                  <MenuItem
                    value={1}
                    onClick={() => {
                      setMaxRow(10);
                    }}
                  >
                    10
                  </MenuItem>
                  <MenuItem
                    value={2}
                    onClick={() => {
                      setMaxRow(20);
                    }}
                  >
                    20
                  </MenuItem>
                  <MenuItem
                    value={3}
                    onClick={() => {
                      setMaxRow(50);
                    }}
                  >
                    50
                  </MenuItem>
                  <MenuItem
                    value={4}
                    onClick={() => {
                      setMaxRow(100);
                    }}
                  >
                    100
                  </MenuItem>
                </Select>
              </FormControl>
              {/* <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "20px",
                }}
              >
                of {totalRecord}
              </Typography> */}
            </Box>
          </Grid>
          <Grid item xs={4} sx={{ marginLeft: "40px" }}>
            <Pagination
              style={{ marginTop: "2%", marginLeft: "5%" }}
              onChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={pages}
              rowsPerPage={rowsPerPage}
              count={pageCount || 0}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
