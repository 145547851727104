import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {
  TextField,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import API from "../../../../../E2E/Axios.Utils";

export default function TeamHeaders({
  teamName,
  setTeamName,
  age,
  setAge,
  gender,
  setGender,
  location,
  setLocation,
}) {
  const [oragnisationData, setoragnisationData] = React.useState([]);
  // const [age, setAge] = React.useState("");
  // const [gender, setGender] = React.useState("");

  const handleChangeAge = (event) => {
    setAge(event.target.value);
  };
  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const token = localStorage.getItem("token").toString();

  const getOrganisationData = () => {
    API.get("/app/secure/list_customer?page=1&max_rows=6", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        // console.log("resOrganisation", res);
        setoragnisationData(res.data.customer_list);
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };
  React.useEffect(() => {
    getOrganisationData();
  }, []);

  return (
    <>
      <Grid container sx={{ mt: 3, gap: "3em" }} xs={12}>
        <Grid item sx={{ minWidth: 120 }} xs={3.5}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={gender}
              label="Gender"
              onChange={handleChangeGender}
            >
              <MenuItem value={"male"}>Male</MenuItem>
              <MenuItem value={"female"}>Female</MenuItem>
              <MenuItem value={"transgender"}>Transgender</MenuItem>
              <MenuItem value={"prefer not to respond"}>
                Prefer not to respond
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item sx={{ minWidth: 120 }} xs={3.5}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Age"
              onChange={handleChangeAge}
            >
              <MenuItem value={"20,30"}>20 to 30</MenuItem>
              <MenuItem value={"30,40"}>30 to 40</MenuItem>
              <MenuItem value={"40,50"}>40 to 50</MenuItem>
              <MenuItem value={"50,60"}>50 to 60</MenuItem>
              <MenuItem value={"60,70"}>60 to 70</MenuItem>
              <MenuItem value={"70,80"}>70 to 80</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3.5}>
          <TextField
            label="Location"
            name="Location"
            placeholder="Mumbai"
            fullWidth
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </Grid>
      </Grid>
    </>
  );
}
