// API url lists
export const api = {
  // Base URL
  // baseURL: "http://13.233.216.27:30080",

  // Auth URL
  login: "/app/auth/user/login",
  getsurvey: "/app/secure/survey?",
  getearchsurvey: "/app/secure/survey?page=1&max_rows=4&query=",
  forgetpassword: "/app/auth/reseting_password_request",
  createsurvey: "/app/secure/survey",
  updatesurvey: "/app/secure/survey",
  deletesurvey: "/app/secure/survey?survey_id=",

  getquestionbank: "/app/secure/question_bank?",
  getsearchquestionbank: "/app/secure/question_bank?page=1&max_rows=10&query=",
  createquestionbank: "/app/secure/question_bank",
  updatequestionbank: "/app/secure/question_bank?id=",
  deletequestionbank: "/app/secure/question_bank?id=",

  getcustomer: "/app/secure/list_customer?",
  getsearchcustomer: "/app/secure/search_customer?page=1&max_row=10&query=",
  createcustomer: "/app/secure/register_customer",
  updatecustomer: "/app/secure/update_customer?id=",
  deletecustomer: "/app/secure/deactivate_customer?id=",

  getemployee: "/app/secure/employee?",
  getSearchEmployee: "/app/secure/employee?page=1&max_row=4&query=",
  createemployee: "/app/secure/employee",
  updateemployee: "/app/secure/employee",
  deleteemployee: "/app/secure/employee",
  createbulkemployee: "/app/secure/employee",

  fetchsurveymapping: "/app/secure/survey_mapping?",
  surveyschedule: "/app/secure/list_survey_schedule?",
  createsurveyschedule: "/app/secure/survey_schedule",

  getquestions: "/app/secure/question?",

  createquestions: "/app/secure/question",
  updatequestions: "/app/secure/question?question_id=",
  deletequestions: "/app/secure/question?question_id=",

  getquestionstype: "/app/secure/question_type?page=1&max_rows=10",
  createsurvayquestion: "/app/secure/survey_question",

  getsurvayactivity: "/app/secure/list_survey_activity?",
  samplefile: "/app/secure/download_sample_file?sample_file=",

  organizationoverview: "/app/secure/organization_overview?org_id=",
  dashboarddetail: "/app/secure/dashboard",
  getsidemenu: "app/secure/refresh_user_detail",
  createbulkquestion: "/app/secure/question",

  dashboardDetails: `/app/secure/dashboard_a?gender=${"genderValue"}&age_group=${"ageGroup"}&start_date=${"startDate"}&end_date=${"endDate"}&location=${"locationValue"}&org_id=${"organisationIds"}&team_id=${"teamIds"}`,
  dashboardMoodScoreDetails: `/app/secure/mood_score?gender=${"genderValue"}&age_group=${"ageGroup"}&start_date=${"startDate"}&end_date=${"endDate"}&location=${"locationValue"}&org_id=${"organisationIds"}&team_id=${"teamIds"}`,
  dashboardEngagementDriver: `/app/secure/dashboard_b?gender=${"genderValue"}&age_group=${"ageGroup"}&start_date=${"startDate"}&end_date=${"endDate"}&location=${"locationValue"}&org_id=${"organisationIds"}&team_id=${"teamIds"}`,
  dashboardManagerDetails: `/app/secure/manager_dashboard?gender=${"genderValue"}&age_group=${"ageGroup"}&start_date=${"startDate"}&end_date=${"endDate"}&location=${"locationValue"}&org_id=${"organisationIds"}&team_id=${"teamIds"}`,
  dashboardRoleDetails: `/app/secure/role_dashboard?gender=${"genderValue"}&age_group=${"ageGroup"}&start_date=${"startDate"}&end_date=${"endDate"}&location=${"locationValue"}&org_id=${"organisationIds"}&team_id=${"teamIds"}`,
  dashboardTeamDetails: `/app/secure/team_dashboard?gender=${"genderValue"}&age_group=${"ageGroup"}&start_date=${"startDate"}&end_date=${"endDate"}&location=${"locationValue"}&org_id=${"organisationIds"}&team_id=${"teamIds"}`,
  dashboardCommunicationDetails: `/app/secure/communication_dashboard?gender=${"genderValue"}&age_group=${"ageGroup"}&start_date=${"startDate"}&end_date=${"endDate"}&location=${"locationValue"}&org_id=${"organisationIds"}&team_id=${"teamIds"}`,
  dashboardAppreciationDetails: `/app/secure/appreciation_dashboard?gender=${"genderValue"}&age_group=${"ageGroup"}&start_date=${"startDate"}&end_date=${"endDate"}&location=${"locationValue"}&org_id=${"organisationIds"}&team_id=${"teamIds"}`,
  dashboardLearnDevDetails: `/app/secure/learning_development_dashboard?gender=${"genderValue"}&age_group=${"ageGroup"}&start_date=${"startDate"}&end_date=${"endDate"}&location=${"locationValue"}&org_id=${"organisationIds"}&team_id=${"teamIds"}`,
  dashboardDiversityDetails: `/app/secure/diversity_dashboard?gender=${"genderValue"}&age_group=${"ageGroup"}&start_date=${"startDate"}&end_date=${"endDate"}&location=${"locationValue"}&org_id=${"organisationIds"}&team_id=${"teamIds"}`,
  dashboardWorkLifeDetails: `/app/secure/work_life_dashboard?gender=${"genderValue"}&age_group=${"ageGroup"}&start_date=${"startDate"}&end_date=${"endDate"}&location=${"locationValue"}&org_id=${"organisationIds"}&team_id=${"teamIds"}`,
};
