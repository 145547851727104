import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";

import Login from "./pages/login/Login";
// import About from "./screens/About";
import Home from "./pages/Home/Home";
import Dashboard from "./pages/dashboard/Dashboard";
import Client from "./pages/client/Client";
import Questionbank from "./pages/question/Question";
import Compose from "./pages/compose/Compose";
import Survey from "./pages/survey/Survey";
import SurveyQuestion from "./pages/survey/SurveyQuestion/SurveyQuestion";
import Employee from "./pages/employee/Employee";
import { Provider } from "react-redux";
import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
// import store from "./redux/store/Store";
import rootReducer from "./thunk/index";
import ClientOld from "./pages/clientOld/ClientOld";
import ClientTabScreen from "./pages/client/tabsContent/ClientTabScreen";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import ResetPasswordF from "./pages/ResetPasswordf/ResetPassword";
import RoleManagement from "./pages/RoleManagement/RoleManagement";
import Profile from "./pages/Profile/Profile";
import UserQuestion from "./pages/question/userQuestion/UserQuestion";

import TeamManagement from "./pages/Team Management/TeamManagement";
import SurveyAssigned from "./pages/SurveyAssigned/SurveyAssigned";
import CustomerEmployee from "./pages/customerEmployee/customerEmployee";
import CustomerSurvay from "./pages/customerSurvay/CustomerSurvay";
import CustomerBatch from "./pages/customerBatches/CustomerBatch";
import RewardManagement from "./pages/RewardManagement/RewardManagement";
import PageNotFound from "./pages/PageNotFound";
import Layout from "./component/leftDrawer/Layout";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(thunk))
);

function App() {
  const token = localStorage.getItem("token");

  // https://api-chatbot.acengage.com/app/auth/user/login

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
          <Route path="/resetpassword/:token" element={<ResetPasswordF />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Navigate to="dashboard" replace />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/compose" element={<Compose />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/Client" element={<Client />} /> {/* path change */}
            <Route path="/ClientOld" element={<ClientOld />} />
            <Route path="/teammanagement" element={<TeamManagement />} />{" "}
            {/* path change */}
            <Route path="/surveyassigned" element={<SurveyAssigned />} />
            {/* path change */}
            <Route path="/Survey" element={<Survey />} />
            {/* path change */}
            {/* <Route
            path="/Survey/surveyQuestion/:id"
            element={<SurveyQuestion />}
          /> */}
            <Route path="/question" element={<Questionbank />} />{" "}
            {/* path change */}
            <Route
              path="/question/userQuestion/:id/:name"
              element={<UserQuestion />}
            />
            <Route path="/employee" element={<Employee />} />{" "}
            {/* path change */}
            <Route path="/customerEmployee" element={<CustomerEmployee />} />
            <Route
              path="/Client/clientTabScreen/:id"
              element={<ClientTabScreen />}
            />
            <Route path="/rolemanagement" element={<RoleManagement />} />{" "}
            {/* path change */}
            <Route path="/CustomerSurvay" element={<CustomerSurvay />} />
            {/* path change */}
            <Route path="/CustomerBatch" element={<CustomerBatch />} />
            <Route path="/rewards" element={<RewardManagement />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
          {/* path change */}
        </Routes>

        {/* </div> */}
      </Router>
    </Provider>
  );
}

const ProtectedRoute = ({ redirectPath = "/login", children }) => {
  const token = localStorage.getItem("token");
  if (!token) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Layout />;
};

export default App;
