import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  datepicker: {
    "& .react-datepicker__input-container> input": {
      // width: "100%",
      // height: "50px",
      // marginTop:"10px",
      // marginLeft:"-96%"
      //position:"absolute"

      font: "inherit",
      letterSpacing: "inherit",
      color: "currentColor",
      padding: "16.5px 14px",
      border: "0",
      boxSizing: "content-box",
      background: "none",
      height: "1.4375em",
      margin: "0",
      WebkitTapHighlightColor: "transparent",
      display: "block",
      minWidth: "0",
      width: "94.5%",
      // WebkitAnimationName: "mui-auto-fill-cancel",
      // animationName: "mui-auto-fill-cancel",
      // WebkitAnimationDuration: "10ms",
      // animationDuration: "10ms",
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderStyle: "solid",
      borderWidth: "1px",
      borderRadius: "4px",
    },
    "& .react-datepicker-popper": {
      zIndex: "100",
    },
  },

  TextField_Style: {
    width: "100%",
  },
});

export { useStyles };
