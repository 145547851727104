import * as React from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  usePagination,
  Grid,
  FormControl,
  Select,
  Button,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Arrow } from "../../../component/Images";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchNotFound from "../../../Utils/SearchNotFound/SearchNotFount";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { Avatar, Tooltip, Typography } from "@mui/material";
import CustomModel from "../../../Utils/CustomModel/CustomModel";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
// import UpdateRoleCard from "./UpdateRoleCard";
import { useNavigate } from "react-router";
// import UpdateTeamForm from "./UpdateTeam/UpdateTeamForm";
import API from "../../../E2E/Axios.Utils";
import InputLabel from "@material-ui/core/InputLabel";
import AvatarComp from "../../../component/UIElements/Avatar";

const headCells = [
  {
    id: "Team Name",
    numeric: false,
    disablePadding: true,
    label: "TEAM NAME",
  },
  {
    id: "Created On",
    numeric: true,
    disablePadding: false,
    label: "CREATED DATE",
  },
  {
    id: "Created By",
    numeric: true,
    disablePadding: false,
    label: "CREATED BY",
  },
  {
    id: "STATUS",
    numeric: true,
    disablePadding: false,
    label: "STATUS",
  },
  {
    id: "ACTIONS",
    numeric: true,
    disablePadding: false,
    label: "ACTIONS",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const handlesort = (val) => {
    props.setSortBy(val);
    props.setSortOrder(val);
    console.log(val);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: "bold", bgcolor: "#128CB0", color: "white" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                fontFamily: "Montserrat",
              }}
            >
              {headCell.label}
              {headCell.label !== "" ? (
                <>
                  <Box
                    sx={{ marginLeft: "5px", paddingTop: "3px" }}
                    onClick={() => {
                      handlesort(headCell.label);
                    }}
                  >
                    <AvatarComp image={Arrow} height={15} />
                  </Box>
                </>
              ) : null}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  search,
  update,
  setUpdate,
  setAlert,
  setAlertType,
  setAlertContent,
  setToggleEditTeam,
  setEditUserData,
  setOveralldata,
  setActive,
  setInactive,
  teamCount,
}) {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  const [pages, setPages] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [usersData, setUsersData] = React.useState([]);
  const [usersSearch, setUsersSearch] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(null);
  const [maxrow, setMaxRow] = React.useState(10);
  const [selecteds, setSelecteds] = React.useState("");
  const [totalRecord, setTotalRecord] = React.useState("");
  const [sortby, setSortBy] = React.useState("");
  const [sortorder, setSortOrder] = React.useState(false);
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    console.log("Pages:::", newPage);
  };

  const handleAlert = () => {
    setAlert(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPages(1);
    console.log("rowPerPage:::", rowsPerPage);
  };
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const token = localStorage.getItem("token").toString();

  const getUsersData = () => {
    setLoading(true);
    API.get(
      `/app/secure/teams?page=${pages}&max_rows=${maxrow}&sort_by=${sortby}&status=${teamCount}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        console.log("resTeamManagement", res);
        setUsersData(res.data.teams_list);
        // setAlertContent(res.data.message);
        // setAlertType("success");
        // setAlert(true)
        setActive(res?.data?.total_active_teams);
        setInactive(res?.data?.total_inactive_teams);
        setOveralldata(res?.data?.total_teams);
        setPageCount(res?.data?.total_page_count);
        setTotalRecord(res?.data?.total_record_count);
        setSortBy(sortby);
        setLoading(false);
      })
      .catch((err) => {
        // console.log("err", err);
        if (err?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          setLoading(false);
          navigate("/login");
        } else {
          setAlertContent(err.data.message);
          setAlertType("error");
          setAlert(true);
          setLoading(false);
        }
      });
  };

  React.useEffect(() => {
    getUsersData();
  }, [update, pages, maxrow, sortby, teamCount]);

  const Reorder = (val) => {
    console.log("REORDERVAL::", val);
    // console.log("LOG:::",!sortorder );
    setSortOrder(!sortorder);

    if (val === "TEAM NAME" && sortorder) {
      setSortBy("-team_name");
    }
    if (val === "CREATED DATE" && sortorder) {
      setSortBy("-created_date");
    }
    if (val === "CREATED BY" && !sortorder) {
      setSortBy("-created_by");
    }

    if (val === "STATUS" && sortorder) {
      setSortBy("-status");
    }
  };

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersData.length) : 0;

  // console.log("usersData", usersData.length);
  // console.log("editUserData", editUserData);
  // React.useEffect(() => {
  //   let arr = [];
  //   let arr2 = [];
  //   usersData.map((user) => {
  //     if (user.status == "Active") {
  //       arr.push(user.status);
  //     } else {
  //       arr2.push(user.status);
  //     }
  //   });
  //   setInactive(arr2.length);
  //   setActive(arr.length);
  // }, [usersData]);

  // const tableRowData = usersData.length;
  // React.useEffect(() => {
  //   setOveralldata(tableRowData);
  // }, [tableRowData]);

  const selectionChangeHandler = (event) => {
    setSelecteds(event.target.value);
  };

  const sortbyFunction = (val) => {
    console.log("val::", val);
    if (val === "TEAM NAME") {
      setSortBy("team_name");
    }
    if (val === "CREATED DATE") {
      setSortBy("created_date");
    }
    if (val === "CREATED BY") {
      setSortBy("created_by");
    }
    if (val === "STATUS") {
      setSortBy("status");
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ mb: 2 }}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="Companies Table"
            sx={{ minWidth: 750 }}
          >
            <EnhancedTableHead
              setSortBy={sortbyFunction}
              setSortOrder={Reorder}
            />

            {
              <TableBody>
                {usersData
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => {
                    const {
                      team_name,
                      team_id,
                      created_date,
                      created_by,
                      status,
                    } = user;

                    return (
                      <TableRow hover tabIndex={-1} key={team_id}>
                        <TableCell align="center">{team_name}</TableCell>
                        <TableCell align="center">
                          {moment(created_date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="center">{created_by}</TableCell>
                        <TableCell align="center">{status}</TableCell>
                        <TableCell align="center">
                          <TeamMoreMenu
                            setEditUserData={setEditUserData}
                            currentDetails={team_id}
                            details={user}
                            setAlert={setAlert}
                            setAlertType={setAlertType}
                            setAlertContent={setAlertContent}
                            update={update}
                            setUpdate={setUpdate}
                            setToggleEditTeam={setToggleEditTeam}
                            teamCount={teamCount}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {
                  // {emptyRows > 0 && (
                  // <TableRow
                  //   style={{
                  //     height: 53 * emptyRows,
                  //   }}
                  // >
                  // <TableRow>
                  //   <TableCell colSpan={6} />
                  // </TableRow>
                }
              </TableBody>
            }
          </Table>
        </TableContainer>
        {/* <TablePagination
          // rowsPerPageOptions={[4, 8, 12]}
          component="div"
          count={
            (search && (usersSearch ? usersSearch?.length : 0)) ||
            (usersData ? usersData.length : 0)
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        <Grid container spacing={2} sx={{ padding: "10px" }}>
          <Grid item xs={7}>
            <Box display="flex" alignItems={"center"} flexDirection="row">
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                Row Per Page
              </Typography>
              <FormControl variant="standard" sx={{ m: 0, minWidth: 50 }}>
                {/* <InputLabel
                  style={{
                    marginLeft: "20px",
                    position: "absolute",
                    marginTop: "20px",
                  }}
                >
                  {selecteds === "" ? 10 : selected}
                </InputLabel> */}
                <Select
                  size="small"
                  value={selecteds}
                  onChange={selectionChangeHandler}
                  disableUnderline
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    paddingLeft: 10,
                    paddingTop: 8,
                    ":before": {
                      borderColor: "#fafafc",
                    },
                    ":after": {
                      borderColor: "#fafafc",
                    },
                    height: 30,
                    justifyContent: "center",
                  }}
                  //   SelectProps={{ disableUnderline: true }}
                >
                  <MenuItem
                    value={1}
                    onClick={() => {
                      setMaxRow(10);
                    }}
                  >
                    10
                  </MenuItem>
                  <MenuItem
                    value={2}
                    onClick={() => {
                      setMaxRow(20);
                    }}
                  >
                    20
                  </MenuItem>
                  <MenuItem
                    value={3}
                    onClick={() => {
                      setMaxRow(50);
                    }}
                  >
                    50
                  </MenuItem>
                  <MenuItem
                    value={4}
                    onClick={() => {
                      setMaxRow(100);
                    }}
                  >
                    100
                  </MenuItem>
                </Select>
              </FormControl>
              {/* <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "20px",
                }}
              >
                of {totalRecord}
              </Typography> */}
            </Box>
          </Grid>
          <Grid item xs={4} sx={{ marginLeft: "40px" }}>
            <Pagination
              style={{ marginTop: "2%", marginLeft: "5%" }}
              onChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={pages}
              rowsPerPage={rowsPerPage}
              count={pageCount || 0}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
// ***************************************************
// function AssociatedUsers({ users }) {
//   const renderAvatar = (user) => {
//     const initials = user
//       .split(" ")
//       .map((item, index) => (index < 2 ? item.substr(0, 1) : ""));
//     return (
//       <Avatar
//         className="text-transform-capitalize small assign-user-avatar"
//         sx={{ bgcolor: "#2D404E" }}
//       >
//         {initials.join("")}
//       </Avatar>
//     );
//   };
//   const renderTooltip = () => (
//     <>
//       {users.map((user) => (
//         <Typography variant="body1">{user}</Typography>
//       ))}
//     </>
//   );
//   return (
//     <>
//       {users.length <= 3
//         ? users.map((user) => renderAvatar(user))
//         : users.length > 3 && (
//             <div style={{ display: "flex" }}>
//               {renderAvatar(users[0])}
//               {renderAvatar(users[1])}
//               <Tooltip title={renderTooltip()}>
//                 <Typography
//                   variant="body1"
//                   className="user-count"
//                   sx={{ display: "flex", alignItems: "center", ml: 0.3 }}
//                 >{`+${users.length - 2}`}</Typography>
//               </Tooltip>
//             </div>
//           )}
//     </>
//   );
// }

// ***************************************************
function TeamMoreMenu({
  currentDetails,
  setEditUserData,
  details,
  setAlert,
  setAlertType,
  setAlertContent,
  update,
  setUpdate,
  setToggleEditTeam,
  teamCount,
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [openEdit, setEditOpen] = React.useState(false);
  const [teamId, setTeamId] = React.useState(details.team_id);

  const employeedata = details;

  // console.log("employeedata", employeedata);
  // console.log("setEditUserData(employeedata)", setEditUserData(employeedata));
  // console.log("editUserData", editUserData);

  // setEditUserData(employeedata);

  // React.useEffect(() => {
  //   setEditUserData(employeedata);
  // }, []);

  const handleDialog = () => {
    setEditOpen(!openEdit);
  };

  const token = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  // /app/secure/teams?team_id=7

  const handleRoleDelete = () => {
    API.delete(
      `/app/secure/teams?team_id=${teamId}`,

      config
    )
      .then((res) => {
        // console.log("res-", res);
        setUpdate(update + 1);
        setAlertContent(res?.data?.message);
        setAlertType("success");
        setAlert(true);
      })

      .catch((error) => {
        // console.log(err);
        setAlertContent(error?.response?.data?.message);
        setAlertType("error");
        setAlert(true);
      });
  };

  const ref = React.useRef(openEdit);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertRoundedIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          to="#"
          sx={{ color: "text.secondary" }}
          onClick={() => {
            // handleDialog();
            setEditUserData(details);
            setToggleEditTeam(true);
          }}
        >
          <ListItemIcon>
            <EditIcon width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        {teamCount === "All" ? null : (
          <>
            {details.status === "Inactive" ? (
              <MenuItem onClick={() => handleRoleDelete(currentDetails)}>
                <ListItemIcon>
                  <LockOpenIcon
                    style={{
                      height: "20px",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Enable"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>
            ) : (
              <MenuItem onClick={() => handleRoleDelete(currentDetails)}>
                <ListItemIcon>
                  <LockIcon
                    style={{
                      height: "20px",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Disable"
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>
            )}
          </>
        )}
      </Menu>
    </>
  );
}
