import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .css-1191obr-MuiPaper-root-MuiAppBar-root": {
      // width: 'calc(100% - 240px)',
      width: "100%",
    },
    "& .MuiDrawer-paperAnchorLeft": {
      marginTop: "80px",

      background: "linear-gradient(91.25deg, #394857 -28.42%, #00212A 117.39%)",
      boxShadow: "0px 4px 4px #C8C8C8",
    },
    // Menu Icon
    "& .css-i4bv87-MuiSvgIcon-root": {
      color: "#C5C5C5",
      display: "none",
    },
    // Menu Icon
    "& .css-wa5fuu-MuiListItemIcon-root": {
      minWidth: "0px",
      marginRight: "15px",
    },
    // ******CSS Property Submenu item Bottom Changes
    // "& .css-1mk9mw3-MuiList-root": {
    //   marginBottom: "100px",
    // },
    // Menu Text
    "& .css-10hburv-MuiTypography-root": {
      fontFamily: "'Montserrat'",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "22px",
      display: "flex",
      alignItems: "center",
      textTransform: "capitalize",
      color: "#FFFFFF",
    },
  },
  active: {
    backgroundColor: "#F4F5F7",
    "& .css-10hburv-MuiTypography-root": {
      fontFamily: "'Montserrat'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "22px",
      display: "flex",
      alignItems: "center",
      textTransform: "capitalize",
      color: "#0066B3",
    },
  },
  submenu: {
    backgroundColor: "#F4F5F7",
    "& .css-10hburv-MuiTypography-root": {
      fontFamily: "'Montserrat'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "22px",
      display: "flex",
      alignItems: "center",
      textTransform: "capitalize",
      color: "#0066B3",
      paddingLeft: "40px",
    },
  },
  drawer: {
    backgroundColor: "#fff",
  },
});

export { useStyles };
