import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../context/auth-context";
import PageNotFound from "../../pages/PageNotFound";

const Permission = (props) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  //   const menus = JSON.parse(localStorage.getItem("Menus"));
  const subMenus = JSON.parse(localStorage.getItem("Submenus"));
  //   if (menus.includes(props.menu) || subMenus.includes(props.subMenus)) {
  if (subMenus.includes(props.menu)) {
    //   if (props.role == role) {
    return <>{props.children}</>;
  } else {
    auth.logout();
    navigate("/login");
    localStorage.removeItem("token");
  }
  // return (
  //   <>
  //     <PageNotFound />
  //   </>
  // );

  //   return <div>Permission</div>;
};

export default Permission;
