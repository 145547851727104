import React from "react";
import {
  Grid,
  TextField,
  FormControl,
  MenuItem,
  FormHelperText,
  InputLabel,
  Select,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@mui/material";
import API from "../../E2E/Axios.Utils";

const ChangeEmployeePassword = ({
  handleDialog,
  id,
  setAlert,
  setAlertType,
  setAlertContent,
}) => {
  const initialValues = {
    user_id: id,
    password1: "",
    password2: "",
  };

  console.log("id", id);

  const token = localStorage.getItem("token").toString();

  const onSubmit = (values, props) => {
    API.post("/app/secure/reset_employee_password", values, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleDialog();
        setAlertContent(res.data.message);
        setAlertType("success");
        setAlert(true);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
        } else {
          setAlertContent(err.data.message);
          setAlertType("error");
          setAlert(true);
        }
      });
    props.resetForm();
  };

  const validationSchema = Yup.object().shape({
    password1: Yup.string()
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
        "Please enter valid password"
      )
      .required("Password is required."),
    password2: Yup.string()
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
        "Please enter valid password"
      )
      .required("Password is required.")
      .oneOf([Yup.ref("password1")], "Both password need to be the same"),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(props) => (
        <Form noValidate>
          <Grid container xs={12} sx={{ gap: "1em" }}>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Password"
                name="password1"
                fullWidth
                error={props.errors.password1 && props.touched.password1}
                helperText={<ErrorMessage name="password1" />}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                as={TextField}
                label="Confirm Password"
                name="password2"
                fullWidth
                error={props.errors.password2 && props.touched.password2}
                helperText={<ErrorMessage name="password2" />}
                required
                sx={{ marginBottom: "2.5%" }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                type="submit"
                style={{ margin: "1em" }}
              >
                Submit
              </Button>
              <Button variant="contained" onClick={() => handleDialog()}>
                Cancel
              </Button>
            </div>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ChangeEmployeePassword;
