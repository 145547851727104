import React from "react";
import Permission from "../../component/Permission/Permission";
import SurveyQuestion from "./SurveyQuestion/SurveyQuestion";
import Surveys from "./Surveys";

const Survey = () => {
  const [surveyToggle, setSurveyToggle] = React.useState(true);
  const [surveyId, setSurveyId] = React.useState("");
  console.log("setSurveyId", surveyId);
  return (
    <Permission menu="Survey Bank">
      <>
        {surveyToggle ? (
          <Surveys
            setSurveyToggle={setSurveyToggle}
            setSurveyId={setSurveyId}
          />
        ) : (
          <SurveyQuestion
            setSurveyToggle={setSurveyToggle}
            surveyId={surveyId}
          />
        )}
      </>
    </Permission>
  );
};

export default Survey;
