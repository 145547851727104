import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import InputLabel from "@mui/material/InputLabel";
import CustomModel from "../../../../Utils/CustomModel/CustomModel";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@material-ui/core/Menu";
import UpdateSurveyForm from "../tabTeam/UpdateSurvey/UpdateSurveyForm";
import {
  Typography,
  Box,
  ButtonGroup,
  Button,
  Grid,
  MenuItem,
  Select,
  FormControl,
  Snackbar,
} from "@mui/material";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { SearchBlack, Dots, Arrow } from "../../../../component/Images";
import InputBox from "../../../../component/UIElements/InputBox";
import AvatarComp from "../../../../component/UIElements/Avatar";
import { useStyles } from "../tabTeam/TeamCards.style";

import {
  Pagination,
  PaginationItem,
  usePagination,
  TablePagination,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";

import {
  fetchSurveyMappingList,
  getSortSurvayActivityList,
  getSurvayActivity,
} from "../../../../thunk/Action";

//drawer
import Switch from "@mui/material/Switch";
import { useEffect } from "react";

const headCells = [
  {
    id: "CONTENT",
    numeric: false,
    disablePadding: true,
    label: "CONTENT",
  },

  {
    id: "STATUS",
    numeric: true,
    disablePadding: false,
    label: "STATUS",
  },
  {
    id: "AUTHOR",
    numeric: true,
    disablePadding: false,
    label: "AUTHOR",
  },
  {
    id: "LAST CHANGED",
    numeric: true,
    disablePadding: false,
    label: "LAST CHANGED",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const handlesort = (val) => {
    props.setSortBy(val);
    props.setSortOrder(val);
    console.log(val);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: "bold", bgcolor: "#128CB0", color: "white" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              {headCell.label}
              {headCell.label !== "" ? (
                <>
                  <div
                    style={{ marginLeft: "5px", paddingTop: "8px" }}
                    onClick={() => {
                      handlesort(headCell.label);
                    }}
                  >
                    <AvatarComp image={Arrow} height={15} />
                  </div>
                </>
              ) : null}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const Activity = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState([]);
  const [data, setData] = useState([]);
  const [dense, setDense] = React.useState(true);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [searchText, setSearchText] = React.useState("");
  const [selecteds, setSelecteds] = useState("");
  const [pagetable, setPageTable] = React.useState(0);

  const [totalRecord, setTotalRecord] = React.useState("");

  let { id } = useParams();
  const [maxrow, setMaxRow] = useState(10);
  const [searchPages, setSearchPages] = useState(1);
  const [pages, setPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(null);
  const [sortby, setSortBy] = useState("");
  const [sortorder, setSortOrder] = useState(false);

  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("");

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    console.log("Pages:::", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPages(1);
    console.log("rowPerPage:::", rowsPerPage);
  };

  const selectionChangeHandler = (event) => {
    setSelecteds(event.target.value);
  };

  const token = localStorage.getItem("token").toString();

  console.log(sortby);
  useEffect(() => {
    const request = {
      token: token,
      id: id,
      data: {
        page: pages,
        max_rows: maxrow,
        sort_by: sortby,
      },
      onSuccess: (res) => {
        setData(res?.data?.survey_activities_list);
        // setSortBy(sortby);
        setTotalRecord(res?.data?.total_record_count);
        setPageCount(res?.data?.total_page_count);
      },
      onFail: (error) => {
        // setIsLoading(false);
        if (error?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          navigate("/login");
        }
      },
    };
    dispatch(getSortSurvayActivityList(request)); //chnges
  }, [pages, maxrow, sortby]);

  const Reorder = (val) => {
    console.log("REORDERVAL::", val);
    // console.log("LOG:::",!sortorder );
    setSortOrder(!sortorder);

    if (val === "CONTENT" && sortorder) {
      setSortBy("-action");
    }
    if (val === "STATUS" && !sortorder) {
      setSortBy("-status");
    }
    if (val === "AUTHOR" && sortorder) {
      setSortBy("-created_by");
    }
    if (val === "LAST CHANGED" && !sortorder) {
      setSortBy("-modified_date");
    }
  };

  const sortbyFunction = (val) => {
    console.log("val::", val);
    if (val === "CONTENT") {
      setSortBy("action");
    }

    if (val === "STATUS") {
      setSortBy("status");
    }
    if (val === "AUTHOR") {
      setSortBy("created_by");
    }
    if (val === "LAST CHANGED") {
      setSortBy("modified_date");
    }
  };

  return (
    <Grid container spacing={3}>
      <Box
        sx={{ width: "100%" }}
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Paper sx={{ width: "95%", mb: 2, mt: 3 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                setSortBy={sortbyFunction}
                setSortOrder={Reorder}
              />
              <TableBody>
                {data.map((row, index) => {
                  return (
                    <TableRow key={index} hover tabIndex={-1}>
                      <TableCell align="center" style={{ cursor: "pointer" }}>
                        {row.action}
                      </TableCell>
                      <TableCell align="center">{row.status}</TableCell>

                      <TableCell align="center">{row.created_by}</TableCell>
                      <TableCell align="center">
                        {moment(row.modified_date).format("DD-MM-YYYY")}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid
            container
            spacing={2}
            sx={{ paddingLeft: "10px", display: "flex", alignItems: "center" }}
          >
            <Grid item xs={7}>
              <Box display="flex" alignItems={"center"} flexDirection="row">
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  Row Per Page
                </Typography>
                <FormControl variant="standard" sx={{ m: 0, minWidth: 50 }}>
                  <Select
                    size="small"
                    value={selecteds}
                    onChange={selectionChangeHandler}
                    disableUnderline
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      paddingLeft: 10,
                      paddingTop: 8,
                      ":before": {
                        borderColor: "#fafafc",
                      },
                      ":after": {
                        borderColor: "#fafafc",
                      },
                      height: 30,
                      justifyContent: "center",
                    }}
                  >
                    <MenuItem
                      value={1}
                      onClick={() => {
                        setMaxRow(10);
                      }}
                    >
                      10
                    </MenuItem>
                    <MenuItem
                      value={2}
                      onClick={() => {
                        setMaxRow(20);
                      }}
                    >
                      20
                    </MenuItem>
                    <MenuItem
                      value={3}
                      onClick={() => {
                        setMaxRow(50);
                      }}
                    >
                      50
                    </MenuItem>
                    <MenuItem
                      value={4}
                      onClick={() => {
                        setMaxRow(100);
                      }}
                    >
                      100
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={4} sx={{ marginLeft: "40px" }}>
              <Pagination
                style={{ marginTop: "2%", marginLeft: "5%" }}
                onChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={pages}
                rowsPerPage={rowsPerPage}
                count={pageCount || 0}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Grid>
  );
};

export default Activity;
