export const SIDE_MENU = [
  {
    label: "Workspace",
    name: "Workspace",
    children: [
      {
        label: "Dashboard",
        path: "/dashboard",
        name: "Dashboard",
        default: false,
      },
      {
        label: "Dashboard",
        path: "/dashboard",
        name: "Dashboard",
        default: true,
      },
      {
        label: "Employees",
        path: "/employee",
        name: "Employees",
        default: false,
      },
      {
        label: "Team",
        path: "/teammanagement",
        name: "Team",
        default: false,
      },
      {
        label: "Surveys",
        path: "/CustomerSurvay",
        name: "Surveys",
        default: false,
      },
      {
        label: "Survey Assigned",
        path: "/surveyassigned",
        name: "Survey Assigned",
        default: false,
      },
      {
        label: "Batches",
        path: "/CustomerBatch",
        name: "Batches",
        default: false,
      },
      {
        label: "Rewards",
        path: "/rewards",
        name: "Rewards",
        default: false,
      },
    ],
  },
  {
    label: "Administration",
    name: "Administration",
    children: [
      {
        label: "Clients",
        path: "/Client",
        name: "Clients",
        default: false,
      },
      {
        label: "Survey Bank",
        path: "/Survey",
        name: "Survey Bank",
        default: false,
      },
      {
        label: "Question Bank",
        path: "/question",
        name: "Question Bank",
        default: false,
      },
      {
        label: "Role",
        path: "/rolemanagement",
        name: "Role",
        default: false,
      },
    ],
  },
];
