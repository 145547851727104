import React from "react";
import { connect } from "react-redux";
import { Box, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useStyles } from "../../../dashboard/Dashboard.style";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CountCharts } from "../../../dashboard/Components/ChartComponents";
import {
  fetchDashboardDetails,
  fetchMoodDetails,
} from "../../../../thunk/Action";

const TabOverview = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { id } = useParams();
  const [selectedCompanies, setSelectedCompanies] = React.useState(id);

  const token = localStorage.getItem("token").toString();

  React.useEffect(() => {
    const queryParams = {
      organisationIds: selectedCompanies,
      gender: "",
      ageGroup: "",
      startDate: "",
      endDate: "",
      locationValue: "",
      teamIds: "",
    };
    dispatch(
      fetchDashboardDetails({
        token,
        ...queryParams,
      })
    );
    dispatch(fetchMoodDetails({ token, ...queryParams }));
  }, [selectedCompanies]);

  return (
    <Grid container className={classes.dashboardContainer}>
      <Grid item xs={12}></Grid>

      {props.loading ? (
        <Grid
          item
          xs={12}
          justifyContent="center"
          style={{ padding: "20px", display: "flex" }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <CountCharts
            styles={classes}
            data={props?.data?.dashboard_a_overview?.[0]}
            moodScore={props?.data?.mood_score?.[0]}
          />
        </>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return state.reducer.dashboard;
};

export default connect(mapStateToProps, {})(TabOverview);
