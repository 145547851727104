import React, { useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdChevronRight,
  MdIndeterminateCheckBox,
  MdKeyboardArrowDown,
} from "react-icons/md";

export default function Resource({ resource, onCheck, rolePermission }) {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const treeData = [];
  let expandedKeysList = [];
  const checkedKeys = [];

  const onExpand = (expandedKeys, expandedKey) => {
    setExpandedKeys(expandedKeys);
  };

  const onCheckCheckbox = (checkedKeys, checkedKey) => {
    onCheck(checkedKey);
  };

  const resourceNode = {
    label: resource.menu,
    value: `M_${resource.id}`,
    className: "resource-node",
    disabled: false,
    children: [],
  };

  resource.submenus.forEach((submenu) => {
    const submenuNode = {
      label: submenu.name,
      value: `S_${resource.id}_${submenu.id}`,
      disabled: false,
      className: "submenu-node",
    };

    resourceNode.children.push(submenuNode);
    expandedKeysList.push(submenuNode.value);
  });

  treeData.push(resourceNode);
  expandedKeysList.push(resourceNode.value);

  if (expandedKeys.length > 0) expandedKeysList = expandedKeys.concat([]);

  if (rolePermission) {
    // console.warn("Inside rolepermission");
    rolePermission.forEach((permission) => {
      permission.submenus.forEach((submenu) => {
        checkedKeys.push(`S_${permission.id}_${submenu}`);
      });
    });
  }

  return (
    <CheckboxTree
      nodes={treeData}
      checked={checkedKeys}
      expanded={expandedKeysList}
      onCheck={onCheckCheckbox}
      onExpand={onExpand}
      icons={{
        check: (
          <MdCheckBox color={"#1162fe"} className="rct-icon rct-icon-check" />
        ),
        uncheck: (
          <MdCheckBoxOutlineBlank
            color={"#1162fe"}
            className="rct-icon rct-icon-uncheck"
          />
        ),
        halfCheck: (
          <MdIndeterminateCheckBox
            color={"#1162fe"}
            className="rct-icon rct-icon-half-check"
          />
        ),
        expandClose: (
          <MdChevronRight className="rct-icon rct-icon-expand-close" />
        ),
        expandOpen: (
          <MdKeyboardArrowDown className="rct-icon rct-icon-expand-open" />
        ),
        expandAll: "",
        collapseAll: "",
        parentClose: "",
        parentOpen: "",
        leaf: "",
      }}
    />
  );
}
