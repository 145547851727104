import React, { useState } from "react";
import * as Yup from "yup";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { TextField, Grid, Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import API from "../../../E2E/Axios.Utils";

const EditCompanyProfile = ({
  currentUserDetails,
  handleDialog,
  open,
  setAlert,
  setAlertType,
  setAlertContent,
  update,
  setUpdate,
}) => {
  const initialValues = {
    Business_Name: currentUserDetails.Business_Name,
    Business_Phone_Number: currentUserDetails.Business_Phone_Number,
    Company_Registered_Number: currentUserDetails.Company_Registered_Number,
    Company_Website: currentUserDetails.Company_Website,
    Business_Address_First_Line: currentUserDetails.Business_Address_First_Line,
    Postal_code: currentUserDetails.Postal_code,
  };

  const token = localStorage.getItem("token").toString();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const onSubmit = (values, props) => {
    API.put(`/app/secure/update_profile`, values, config)
      .then((res) => {
        // console.log("res", res);
        // console.log(`Success : ${res.data.message}`);
        handleDialog();
        setUpdate(update + 1);
        // setAlertContent(res.data.message);
        // setAlertType("success");
        // setAlert(true);
      })
      .catch((err) => {
        // console.log(err.response);
        if (err.response.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
        } else {
          setAlertContent(`Error : Something went wrong`);
          setAlertType("error");
          setAlert(true);
        }
      });
  };

  const fullnameRegExp = /^([\w]{3,})+\s+([\w\s]{3,})+$/i;
  const phoneRegExp = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;
  const AlphaNumeric = /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/;
  const zipcodeRegExp =
    /^[A-Za-z]{1,2}[0-9]{1,2}[0-9A-Za-z ]*$|^\d{5,6}$|^\d{5}-\d{4}$/;
  const Number = /^[-+]?[0-9]+$/;
  const websites =
    /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  const validationSchema = Yup.object().shape({
    Business_Name: Yup.string()
      .matches(AlphaNumeric, "Please enter valid company name.")
      .max(50, "Company name must be at most 50 characters.")
      .required("This field is required."),
    Company_Registered_Number: Yup.string()
      .max(20, "Registered number must be at most 80 characters.")
      .required("This field is required."),
    Business_Phone_Number: Yup.string()
      .matches(phoneRegExp, "Please enter valid phone number.")
      .required("This field is required."),
    Company_Website: Yup.string()
      .matches(websites, "Please enter valid website.")
      .required("This field is required."),
    Postal_code: Yup.string()
      .matches(zipcodeRegExp, "Please enter valid postal/zip code.")
      .required("This field is required."),
  });

  return (
    <div>
      <Typography variant="h5" component="h6" sx={{ mb: 2 }}>
        Update Profile
      </Typography>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form noValidate>
            <Box>
              <Field
                as={TextField}
                label="Business Name"
                name="Business_Name"
                placeholder="Enter Business Name"
                fullWidth
                error={
                  props.errors.Business_Name && props.touched.Business_Name
                }
                size="small"
                helperText={<ErrorMessage name="Business_Name" />}
                required
                style={{ marginTop: 5 }}
                validateOnChange={true}
              />

              <Field
                as={TextField}
                label="Phone Number"
                name="Business_Phone_Number"
                placeholder="Enter Phone Number"
                fullWidth
                error={
                  props.errors.Business_Phone_Number &&
                  props.touched.Business_Phone_Number
                }
                size="small"
                helperText={<ErrorMessage name="Business_Phone_Number" />}
                required
                style={{ marginTop: 20 }}
              />
              <Field
                as={TextField}
                label="Registered Number"
                name="Company_Registered_Number"
                placeholder="Enter Registered Number"
                fullWidth
                error={
                  props.errors.Company_Registered_Number &&
                  props.touched.Company_Registered_Number
                }
                size="small"
                helperText={<ErrorMessage name="Company_Registered_Number" />}
                required
                style={{ marginTop: 20 }}
              />
              <Field
                as={TextField}
                label="Website Name"
                name="Company_Website"
                placeholder="Enter Website Name"
                fullWidth
                error={
                  props.errors.Company_Website && props.touched.Company_Website
                }
                size="small"
                helperText={<ErrorMessage name="Company_Website" />}
                required
                style={{ marginTop: 20 }}
              />
              <Field
                as={TextField}
                label="Address"
                name="Business_Address_First_Line"
                placeholder="Enter Address"
                fullWidth
                size="small"
                style={{ marginTop: 20 }}
              />
              <Field
                as={TextField}
                label="Postal Code / Zip Code"
                name="Postal_code"
                placeholder="Enter Postal Code / Zip Code"
                fullWidth
                error={props.errors.Postal_code && props.touched.Postal_code}
                size="small"
                helperText={<ErrorMessage name="Postal_code" />}
                required
                style={{ marginTop: 20 }}
              />
            </Box>
            <Grid
              style={{
                display: "flex",
                justifyContent: "right",
                marginTop: 10,
              }}
            >
              <Button onClick={handleDialog}>Discard</Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  padding: "10px",
                  borderRadius: "20px",
                  width: "101px",
                  height: "36px",
                }}
              >
                Update
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default EditCompanyProfile;
