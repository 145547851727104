import React, { useState } from "react";
import * as Yup from "yup";
import { Form, Formik, Field, ErrorMessage } from "formik";
import {
  TextField,
  Grid,
  Box,
  Typography,
  FormControl,
  MenuItem,
  FormHelperText,
  Select,
  InputLabel,
} from "@mui/material";
import Button from "@mui/material/Button";
import API from "../../../E2E/Axios.Utils";
import PhoneInput from "react-phone-number-input";
import { fieldToTextField } from "formik-material-ui";
import "react-phone-number-input/style.css";
import MobileNumber from "../../../component/UIElements/MobileNumber";
import InputTextField from "../../../component/UIElements/InputTextField";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Constants, {
  ONLY_BLANK_SPACES,
  PERMISSIONS_SELECTED,
  VALIDATE_NAME,
} from "../../../Utils/Constants";
import UtilHelper from "../../../Utils/UtilHelper";

const EditPersonalProfile = ({
  currentUserDetails,
  handleDialog,
  open,
  setAlert,
  setAlertType,
  setAlertContent,
  update,
  setUpdate,
}) => {
  const [fname, setFname] = React.useState(currentUserDetails.first_name);
  const [lname, setLname] = React.useState(currentUserDetails.last_name);
  const [phoneMobile, setPhoneMobile] = React.useState(
    currentUserDetails.phone
  );
  const [errorf, setErrorf] = React.useState(false);

  const [isValidMobile, setIsValidMobile] = useState(true);
  const [dialCode, setDialCode] = useState("");

  const token = localStorage.getItem("token").toString();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const onSubmit = (values, props) => {
    console.log("values", values);
    const data = {
      first_name: fname,
      last_name: lname,
      phone: phoneMobile,
    };
    if (phoneMobile != "" && isValidMobile) {
      API.put(`/app/secure/update_profile`, data, config)
        .then((res) => {
          handleDialog();
          setUpdate(update + 1);
          setAlertContent(res.data.message);
          setAlertType("success");
          setAlert(true);
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response.status == 401) {
            setAlertContent("Token is invalid or expired.");
            setAlertType("error");
            setAlert(true);
          } else {
            setAlertContent(`Error : Something went wrong`);
            setAlertType("error");
            setAlert(true);
          }
        });
    } else {
      setErrorf(true);
    }
  };
  const handleChange = (e) => {
    setPhoneMobile(e);
  };

  // const updateMobileNumber = (phone, validPhoneNumber, code, e) => {
  const updateMobileNumber = (phone, validPhoneNumber, code) => {
    // setIsValidMobile(phone);
    // setDialCode(code);
    handleChange(phone);
    console.log("phone");
    // setInputValue(phone);
    setIsValidMobile(validPhoneNumber);
    setDialCode(code);
  };

  React.useEffect(() => {
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );
    ValidatorForm.addValidationRule(VALIDATE_NAME, UtilHelper.validateName);
    // ValidatorForm.addValidationRule(
    //   PERMISSIONS_SELECTED,
    //   UtilHelper.validatePermissions
    // );

    return () => {
      // remove rule from form validator when it is not needed
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
      ValidatorForm.removeValidationRule(VALIDATE_NAME);
      // ValidatorForm.removeValidationRule(PERMISSIONS_SELECTED);
    };
  }, []);

  return (
    <div>
      <Typography variant="h5" component="h6" sx={{ mb: 2 }}>
        Update Profile
      </Typography>
      <ValidatorForm onSubmit={onSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextValidator
              fullWidth
              required
              label="First name"
              value={fname}
              onChange={(e) => setFname(e.target.value)}
              style={{ marginBottom: 16 }}
              validators={[
                "required",
                ONLY_BLANK_SPACES,
                VALIDATE_NAME,
                "maxStringLength:" +
                  Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_FIELD,
              ]}
              errorMessages={[
                Constants.FIELD_IS_REQUIRED,
                Constants.BLANK_SPACES_NOT_ALLOWED,
                Constants.ENTER_VALID_NAME,
                Constants.MAXIMUM_ALLOWED_CHARACTERS_FOR_TEXT_FIELD,
              ]}
            />
          </Grid>
          <Grid item xl={5} lg={12} md={12} sm={12} xs={12}>
            <TextValidator
              fullWidth
              required
              label="Last name"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              style={{ marginBottom: 16 }}
              validators={[
                "required",
                ONLY_BLANK_SPACES,
                VALIDATE_NAME,
                "maxStringLength:" +
                  Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_FIELD,
              ]}
              errorMessages={[
                Constants.FIELD_IS_REQUIRED,
                Constants.BLANK_SPACES_NOT_ALLOWED,
                Constants.ENTER_VALID_NAME,
                Constants.MAXIMUM_ALLOWED_CHARACTERS_FOR_TEXT_FIELD,
              ]}
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <MobileNumber
              required
              phone={currentUserDetails.phone}
              updateMobileNumber={updateMobileNumber}
              isValidMobile={isValidMobile}
              errorf={errorf}
              mobile_numberC={currentUserDetails.phone}
              employee
            />
            {!currentUserDetails.phone && errorf ? (
              <span
                style={{
                  color: "#F44336",
                  fontSize: "12px",
                  position: "relative",
                  left: "15px",
                }}
              >
                Required Mobile.
              </span>
            ) : (
              !isValidMobile && (
                <span
                  style={{
                    color: "#F44336",
                    fontSize: "12px",
                    position: "relative",
                    left: "15px",
                  }}
                >
                  Enter valid Mobile
                </span>
              )
            )}
          </Grid>
        </Grid>

        <Grid
          style={{
            display: "flex",
            justifyContent: "right",
            marginTop: 10,
          }}
        >
          <Button onClick={handleDialog}>Discard</Button>
          <Button
            type="submit"
            variant="contained"
            sx={{
              padding: "10px",
              borderRadius: "20px",
              width: "101px",
              height: "36px",
            }}
          >
            Update
          </Button>
        </Grid>
      </ValidatorForm>
    </div>
  );
};
export default EditPersonalProfile;
