import React from "react";
import "./RewardManagement.css";
import { useNavigate } from "react-router";
import Header from "../../component/header/Header";
import LeftDrawer from "../../component/leftDrawer/LeftDrawer";
import { Box, Grid, Typography, Button, Container } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import API from "../../E2E/Axios.Utils";
import Party from "../../assets/images/svgs/Party.svg";
import Medal from "../../assets/images/svgs/Medal.svg";
import ReactStepProgressBar from "./Components/ReactStepProgressBar";
import RewardTable from "./Components/RewardTable";

const RewardManagement = () => {
  const navigate = useNavigate();
  const [opendrawer, setOpen] = React.useState(true);
  const [name, setName] = React.useState("");
  const [usersData, setUsersData] = React.useState([]);

  const token = localStorage.getItem("token").toString();

  const getUsersData = () => {
    API.get(`/app/secure/reward?page=1&max_rows=10`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        console.log("resTeamManagement", res.data);
        // setUsersData(res.data.points_details);
        setUsersData(res.data);
      })
      .catch((err) => {
        // console.log("err", err);
        if (err?.response?.status == 401) {
          // setAlertContent("Token is invalid or expired.");
          // setAlertType("error");
          // setAlert(true);
          // setLoading(false);
          navigate("/login");
        } else {
          // setAlertContent(err.data.message);
          // setAlertType("error");
          // setAlert(true);
          // setLoading(false);
        }
      });
  };

  React.useEffect(() => {
    getUsersData();
  }, []);

  React.useEffect(() => {
    var firstname = localStorage.getItem("firstname");
    setName(firstname);
  }, []);

  const handleCompleteSurvey = () => {
    navigate("/surveyassigned");
  };

  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <LeftDrawer open={opendrawer} />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          style={{ background: "#EEEEEE" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <Avatar>{name[0]}</Avatar>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      Hi {name} !
                    </Typography>
                  </div>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: "#2D404E",
                        padding: "10px",
                        borderRadius: "20px",
                        width: "200px",
                        height: "36px",
                        color: "white",
                        marginRight: "1rem",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                      onClick={handleCompleteSurvey}
                    >
                      Complete Survey
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <div className="box">
                  <Grid
                    container
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "50px",
                      gap: "1rem",
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        bgcolor: "white",
                        height: 150,
                        width: 400,
                        borderRadius: "8px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={3}>
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <img src={Medal} alt="Medal" />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="h1"
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            {/* 10 */}
                            {usersData.total_survey_completed}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Typography
                        sx={{ textAlign: "center", fontFamily: "Montserrat" }}
                      >
                        Survey Completed
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      sx={{
                        bgcolor: "white",
                        height: 150,
                        width: 400,
                        borderRadius: "8px",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={3}>
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <img src={Party} alt="Party" />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            variant="h1"
                            sx={{ fontWeight: "bold", textAlign: "center" }}
                          >
                            {/* 15 */}
                            {usersData.total_coins_earned}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Typography
                        sx={{ textAlign: "center", fontFamily: "Montserrat" }}
                      >
                        Coins you have earned
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ justifyContent: "center" }}>
                    {/* BigBox */}
                    <Box
                      sx={{
                        bgcolor: "white",
                        height: 200,
                        width: 825,
                        borderRadius: "8px",
                        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          pt: 2,
                        }}
                      >
                        <Grid item>
                          <Typography
                            variant="h5"
                            sx={{ pl: 10, pt: 2, fontFamily: "Montserrat" }}
                          >
                            {`You are
                                           ${
                                             usersData.total_record_count > 0
                                               ? usersData.total_record_count -
                                                 usersData.total_survey_completed
                                               : 0
                                           }
                      survey away from the reward!`}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            sx={{
                              bgcolor: "#2D404E",
                              padding: "10px",
                              borderRadius: "20px",
                              width: "150px",
                              height: "36px",
                              color: "white",
                              marginRight: "1rem",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                            }}
                            onClick={handleCompleteSurvey}
                          >
                            Continue
                          </Button>
                        </Grid>
                      </Grid>

                      <Box
                        sx={{
                          pt: "-1",
                          pb: 1,
                        }}
                      >
                        {/* <CustomizedSteppers /> */}
                        <ReactStepProgressBar />
                      </Box>

                      <Box
                        sx={{
                          bgcolor: "#2D404E",
                          pt: 1,
                          pb: 1,
                          color: "white",
                          borderRadius: "8px",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            // pl: 10,
                            textAlign: "center",
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                          }}
                        >
                          You have finished {usersData.total_survey_completed}/
                          {usersData.total_record_count} surveys.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <div className="reward_table">
                <RewardTable />
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};
export default RewardManagement;

{
  /* <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>  */
}
