import React from "react";
import {
  Box,
  Button,
  Typography,
  Link,
  Card,
  CardContent,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Rectangle } from "../../component/Images";
import Avatar from "../../component/UIElements/Avatar";
import TextFieldcomp from "../../component/UIElements/TextFieldComp";
import { loginUser } from "../../thunk/Action";
import { useStyles } from "./Login.style";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Notification } from "../../Utils/SnackbarNotification/Notification";
import Ash from "../../chatBot/assets/ash.png";
const Login = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [values, setValues] = React.useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("");

  const handleAlert = () => {
    setAlert(false);
  };

  const handleClick = (newState) => () => {
    if (!email) {
      setAlertContent("Enter email.");
      setAlertType("error");
      setAlert(true);
    } else if (
      email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) === null
    ) {
      setAlertContent("Enter valid username or email.");
      setAlertType("error");
      setAlert(true);
      // } else if (email.match(/\s/) !== null) {
      //   setState({ open: true, ...newState });
      //   setMessage("Remove blank space in username or email.");
    } else if (!password) {
      setAlertContent("Enter password.");
      setAlertType("error");
      setAlert(true);
    } else {
      const request = {
        email: email,
        password: password,
        onSuccess: (res) => {
          if (res?.status === 200) {
            //  alert((res?.data?.role));
            // setState({ open: true, ...newState });
            // setMessage(res?.data?.message);
            setAlertContent(res?.data?.message);
            setAlertType("success");
            setAlert(true);
            localStorage.setItem("token", res?.data?.token);
            localStorage.setItem("orgId", res?.data?.Org_Id);
            localStorage.setItem("firstname", res?.data?.first_name);
            localStorage.setItem("role", res?.data?.role);
            localStorage.setItem("Menus", JSON.stringify(res?.data?.Menus));
            localStorage.setItem(
              "Submenus",
              JSON.stringify(res?.data?.Submenus)
            );
            localStorage.setItem("resdATA", res);

            setEmail(res?.data?.email);
            setTimeout(() => {
              navigate("/dashboard");
            }, 500);
          }
        },
        onFail: (error) => {
          // setIsLoading(false);
          // setState({ open: true, ...newState });
          // setMessage(error?.response?.data?.message);
          setAlertContent(error?.response?.data?.message);
          setAlertType("error");
          setAlert(true);
          if (error?.response?.status === 400) {
            // console.log("bad request");
            // Alert.alert(strings.error, error?.response?.data?.message);
          }
        },
      };
      dispatch(loginUser(request));
    }
  };
  const handleChangePassword = (value) => {
    const check = value.trim();
    setPassword(check);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{
          backgroundImage: `url(${Rectangle})`,
          backgroundRepeat: "no-repeat",
          height: "100vh",
          backgroundSize: "cover",
        }}
      >
        <Card className={classes.cardStyle}>
          <div style={{ padding: "25px" }}>
            {/* <Typography
              sx={{
                fontWeight: "800",
                fontSize: "20px",
                margin: "10px",
                textAlign: "center",
              }}
            >
              Sign In

            </Typography> */}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom:"4%"
              }}
            >
              <img src={Ash} style={{ height: "72px", width: "72px" }} />
              <div>
              <div style={{display: "flex", alignItems: 'baseline'}}>
              <h1
                style={{
                  fontSize: '40px',
                  fontWeight: '600',
                  fontFamily: 'Montserrat',
                  margin: 0,
                }}
              >
                A
              </h1>
              <h1
                style={{
                  fontSize: '32px',
                  fontWeight: '600',
                  fontFamily: 'Montserrat',
                  margin: 0,
                }}
              >
                sh
              </h1>
              </div>
              <p style={{fontSize: '16px', fontWeight: 'normal',  paddingBottom: '10px'}}></p>
              </div>
            </div>
            <TextFieldcomp
              label="Username or email address *"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextFieldcomp
              label="Password *"
              value={password}
              // onChange={(e) => setPassword(e.target.value)}
              onChange={(e) => handleChangePassword(e.target.value)}
              type={values.showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="start"
                  >
                    {values.showPassword ? (
                      <Visibility
                        sx={{
                          position: "absolute",
                          right: "12px",
                          bottom: "7px",
                        }}
                      />
                    ) : (
                      <VisibilityOff
                        sx={{
                          position: "absolute",
                          right: "12px",
                          bottom: "7px",
                        }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            {/* <Box display="flex" flexDirection="row" sx={{ width: "80%" }}> */}

            {/* </Box> */}
            <Button
              variant="contained"
              color="secondary"
              sx={{
                padding: "8px",
                borderRadius: "10px",
                width: "100%",
                marginTop: "10px",
                textTransform: "none",
                background:
                  "linear-gradient(93.39deg, #582FF9 1.3%, #AC2FF9 97.46%), #1B1C1D",
              }}
              onClick={handleClick({
                vertical: "bottom",
                horizontal: "center",
              })}
            >
              Sign In
            </Button>
            <Link
              href="/forgetPassword"
              sx={{
                // position: "absolute",
                // marginTop: "-85px",
                fontSize: 13,
                marginTop: "10%",
                color: "#1B1C1D",
              }}
            >
              Forgot Password?
            </Link>

            <Notification
              message={
                alertContent
                // ? alertContent
                // : `Success : Data Fetched Successfully.`
              }
              open={alert}
              severity={alertType}
              onClose={handleAlert}
            />

            {/* <div style={{ marginTop: "30px" }}>
              <Typography
                sx={{
                  fontSize: 14,
                  color: "#000",
                }}
              >
                By registering, I acccept the terms of use and
              </Typography>

              <Typography
                sx={{
                  fontSize: 14,
                  color: "#000",
                }}
              >
                confidentiality Policy
              </Typography>
            </div> */}
          </div>
        </Card>
      </Box>
    </>
  );
};
export default Login;
