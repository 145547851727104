import { Outlet } from "react-router-dom";
import { connect } from "react-redux";

const Layout = (props) => {
  return (
    <div className={`${props.showSurvey ? 'afterChat' : ''}`}>
      <Outlet />
    </div>
  );
};

const mapStateToProps = (state) => {
    return state.reducer;
}

export default connect(mapStateToProps, {})(Layout);
