import React from "react";
import "./LeftDrawer.css";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Typography, Button } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { useStyles } from "./LeftDrawer.style";
import { SIDE_MENU } from "./navigation";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const LeftDrawer = (props) => {
  const menus = JSON.parse(localStorage.getItem("Menus"));
  const subMenus = JSON.parse(localStorage.getItem("Submenus"));
  const role = localStorage.getItem("role");
  // console.log("menus", menus);
  // console.log("subMenus", subMenus);

  const { open } = props;

  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Drawer variant="permanent" open={open}>
        <List>
          {SIDE_MENU.map((menu) => {
            if (!menus || !menus.find((m) => m === menu.name)) {
              return null;
            }
            return (
              <>
                <div>
                  <div>
                    {menu.label === "Administration" ? (
                      <Divider
                        sx={{
                          borderColor: "white",
                          mb: "2%",
                          borderBottomWidth: 4,
                        }}
                        style={{ marginTop: "80px" }}
                      />
                    ) : null}
                  </div>
                </div>
                <div key={menu.label}>
                  <Typography
                    variant="body1"
                    className={
                      menu.label === "Administration"
                        ? "menu-title menu-title2"
                        : "menu-title"
                    }
                  >
                    {open ? menu.label : ""}
                  </Typography>
                  <List
                    component="div"
                    disablePadding
                    className="subMenuItem"
                    // style={{ paddingBottom: "70px" }}
                  >
                    {menu.children.map((subMenu) => {
                      if (
                        subMenu.default ||
                        !subMenus.find((menu) => menu === subMenu.name)
                      ) {
                        return null;
                      }
                      return (
                        <>
                          <ListItem
                            className={classes.item}
                            key={subMenu.name}
                            disableGutters
                          >
                            <NavLink
                              to={
                                subMenu.label === "Employees" &&
                                role === "Client"
                                  ? "/customerEmployee"
                                  : subMenu.path
                              }
                              key={subMenu.label}
                              className="link"
                              activeclassname="active"
                            >
                              {open ? subMenu.label : ""}
                            </NavLink>
                          </ListItem>
                          <Divider sx={{ borderColor: "white" }} />
                        </>
                      );
                    })}
                  </List>
                </div>
              </>
            );
          })}
        </List>
        <Divider style={{ paddingBottom: "100px" }} />
      </Drawer>
    </Box>
  );
};

export default LeftDrawer;
