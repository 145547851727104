import {
  LOGIN_USER,
  FORGET_PASSWORD,
  // RESET_PASSWORDF,
  GET_SURVEY_LIST,
  GET_SEARCH_SURVEY_LIST,
  UPDATE_SURVEY_LIST,
  DELETE_SURVEY_LIST,
  CREATE_SURVEY_LIST,
  GET_QBANK_LIST,
  GET_SEARCH_QBANK_LIST,
  CREATE_QBANK_LIST,
  UPDATE_QBANK_LIST,
  DELETE_QBANK_LIST,
  GET_CUSTOMER_LIST,
  UPDATE_CUSTOMER_LIST,
  DELETE_CUSTOMER_LIST,
  CREATE_CUSTOMER_LIST,
  GET_EMPLOYEE_LIST,
  UPDATE_EMPLOYEE_LIST,
  DELETE_EMPLOYEE_LIST,
  CREATE_EMPLOYEE_LIST,
  CREATE_BULK_EMPLOYEE_LIST,
  FETCH_SURVEY_MAPPING,
  SURVEY_SCHEDUAL_LIST,
  CREATE_SURVEY_SCHEDUAL,
  GET_SEARCH_EMPLOYEE_LIST,
  GET_SORT_EMPLOYEE_LIST,
  QUESTIONS_LIST,
  CREATE_QUESTIONS_LIST,
  DELETE_QUESTIONS_LIST,
  QUESTIONS_TYPE,
  GET_SEARCH_CUSTOMER_LIST,
  UPDATE_QUESTIONS_LIST,
  CREATE_SURVAY_QUESTION,
  GET_SURVAY_ACTIVITY,
  GET_SORT_CLIENT_LIST,
  GET_SORT_SURVAY_LIST,
  GET_SORT_QBANK_LIST,
  GET_SORT_SURVAY_ACTIVITY_LIST,
  GET_SORT_QUESTION_LIST,
  SAMPLE_FILE,
  GET_SORT_SURVAY_MAPPING_LIST,
  ORGANIZATION_OVERVIEW,
  GET_SIDE_MENU,
  START_SURVEY,
  CLOSE_SURVEY,
  RESET_START_SURVEY,
  GET_OVERVIEW_EMPLOYEE_LIST,
  DASHBOARD_DETAIL,
  CREATE_BULK_QUESTION_LIST,

  DASHBOARD_DETAILS_LOADING,
  DASHBOARD_DETAILS_SUCCESS,
  DASHBOARD_DETAILS_ERROR
} from "./types";

const INITIAL_STATE = {
  // Initial state list
  userData: null,
  forgetPasswordData: null,
  // resetPasswordDataf: null,

  getSurveyData: null,
  updateSurveyData: null,
  deleteSurveyData: null,
  createSurveyData: null,
  getSearchSurveyData: null,

  getQbankData: null,
  updateQbankData: null,
  createQbankData: null,
  deleteQbankData: null,
  getSearchQbankData: null,

  getCustomerData: null,
  getSearchCustomerData: null,
  updateCustomerData: null,
  createCustomerData: null,
  deleteCustomerData: null,

  getEmployeeData: null,
  updateEmployeeData: null,
  createEmployeeData: null,
  deleteEmployeeData: null,
  createBulkEmployeeData: null,
  getSearchEmployeeData: null,
  getSortEmployeeData: null,

  fetchSurveyMappingData: null,

  surveySchedualData: null,
  createSurveySchedualData: null,

  questionsData: null,
  createQuestionsData: null,
  deleteQuestionsData: null,
  updateQuestionsData: null,

  questionsTypeData: null,
  crateSurvayQuestionData: null,

  getSurvayActivityData: null,

  getSortClientData: null,
  getSortSurvayData: null,
  getSortQbankData: null,
  getSortActivityData: null,
  getSortQuestionData: null,
  getSortSurvayMappingData: null,
  samplefileData: null,
  organizationoverviewData: null,
  getSideMenuData: null,
  getoverViewEmployeeData: null,
  dashboardDetailData: null,
  createbulkquestionData: null,

  dashboard: {
    loading: true,
    data: {},
    error: false
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, userData: action.payload };
    case FORGET_PASSWORD:
      return { ...state, forgetPasswordData: action.payload };
    // case RESET_PASSWORDF:
    //   return { ...state, fresetPasswordDataf: action.payload };
    case GET_SURVEY_LIST:
      return { ...state, getSurveyData: action.payload };
    case UPDATE_SURVEY_LIST:
      return { ...state, updateSurveyData: action.payload };
    case DELETE_SURVEY_LIST:
      return { ...state, deleteSurveyData: action.payload };
    case CREATE_SURVEY_LIST:
      return { ...state, createSurveyData: action.payload };
    case GET_SEARCH_SURVEY_LIST:
      return { ...state, getSearchSurveyData: action.payload };

    case GET_QBANK_LIST:
      return { ...state, getQbankData: action.payload };
    case CREATE_QBANK_LIST:
      return { ...state, createQbankData: action.payload };
    case UPDATE_QBANK_LIST:
      return { ...state, updateQbankData: action.payload };
    case DELETE_QBANK_LIST:
      return { ...state, deleteQbankData: action.payload };
    case GET_SEARCH_QBANK_LIST:
      return { ...state, getSearchQbankData: action.payload };

    case GET_CUSTOMER_LIST:
      return { ...state, getCustomerData: action.payload };
    case GET_SEARCH_CUSTOMER_LIST:
      return { ...state, getSearchCustomerData: action.payload };
    case CREATE_CUSTOMER_LIST:
      return { ...state, createCustomerData: action.payload };
    case UPDATE_CUSTOMER_LIST:
      return { ...state, updateCustomerData: action.payload };
    case DELETE_CUSTOMER_LIST:
      return { ...state, deleteCustomerData: action.payload };

    case GET_EMPLOYEE_LIST:
      return { ...state, getEmployeeData: action.payload };
    case CREATE_EMPLOYEE_LIST:
      return { ...state, createEmployeeData: action.payload };
    case UPDATE_EMPLOYEE_LIST:
      return { ...state, updateEmployeeData: action.payload };
    case DELETE_EMPLOYEE_LIST:
      return { ...state, deleteEmployeeData: action.payload };
    case CREATE_BULK_EMPLOYEE_LIST:
      return { ...state, createBulkEmployeeData: action.payload };
    case GET_SEARCH_EMPLOYEE_LIST:
      return { ...state, getSearchEmployeeData: action.payload };
    case GET_SORT_EMPLOYEE_LIST:
      return { ...state, getSortEmployeeData: action.payload };

    case FETCH_SURVEY_MAPPING:
      return { ...state, fetchSurveyMappingData: action.payload };

    case SURVEY_SCHEDUAL_LIST:
      return { ...state, surveySchedualData: action.payload };
    case CREATE_SURVEY_SCHEDUAL:
      return { ...state, createSurveySchedualData: action.payload };

    case QUESTIONS_LIST:
      return { ...state, questionsData: action.payload };
    case CREATE_QUESTIONS_LIST:
      return { ...state, createQuestionsData: action.payload };
    case DELETE_QUESTIONS_LIST:
      return { ...state, deleteQuestionsData: action.payload };
    case UPDATE_QUESTIONS_LIST:
      return { ...state, updateQuestionsData: action.payload };

    case QUESTIONS_TYPE:
      return { ...state, questionsTypeData: action.payload };

    case CREATE_SURVAY_QUESTION:
      return { ...state, crateSurvayQuestionData: action.payload };

    case GET_SURVAY_ACTIVITY:
      return { ...state, getSurvayActivityData: action.payload };

    case GET_SORT_CLIENT_LIST:
      return { ...state, getSortClientData: action.payload };
    case GET_SORT_SURVAY_LIST:
      return { ...state, getSortSurvayData: action.payload };
    case GET_SORT_QBANK_LIST:
      return { ...state, getSortQbankData: action.payload };
    case GET_SORT_SURVAY_ACTIVITY_LIST:
      return { ...state, getSortActivityData: action.payload };
    case GET_SORT_QUESTION_LIST:
      return { ...state, getSortQuestionData: action.payload };
    case GET_SORT_SURVAY_MAPPING_LIST:
      return { ...state, getSortSurvayMappingData: action.payload };
    case GET_SIDE_MENU:
      return { ...state, getSideMenuData: action.payload };

    case SAMPLE_FILE:
      return { ...state, samplefileData: action.payload };

    case ORGANIZATION_OVERVIEW:
      return { ...state, organizationoverviewData: action.payload };

    case GET_OVERVIEW_EMPLOYEE_LIST:
      return { ...state, getoverViewEmployeeData: action.payload };
    case DASHBOARD_DETAIL:
      return { ...state, dashboardDetailData: action.payload };
    case CREATE_BULK_QUESTION_LIST:
      return { ...state, createbulkquestionData: action.payload };
    case START_SURVEY:
      return {
        ...state,
        survey: action.data,
        showSurvey: true,
      };
    case CLOSE_SURVEY:
      return {
        ...state,
        showSurvey: false,
        survey: {},
      };

    case DASHBOARD_DETAILS_LOADING:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loading: true,
          error: false
        }
      }
    case DASHBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loading: false,
          data: {...state.dashboard.data, ...action.payload},
          error: false,
        }
      }
    case DASHBOARD_DETAILS_ERROR:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          loading: false,
          data: [],
          error: true,
        }
      }
    default:
      return state;
  }
};
