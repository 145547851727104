import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  TextField,
  FormControlLabel,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import API from "../../../../../E2E/Axios.Utils";
import { useParams } from "react-router-dom";

const UpdateSurveyForm = ({
  handleDialog,
  update,
  setUpdate,
  setAlert,
  setAlertContent,
  setAlertType,
}) => {
  const [tabData, setTabData] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [mappingData, setMappingData] = React.useState([]);
  let { id } = useParams();

  useEffect(() => {
    getMappingData();
  }, []);

  React.useEffect(() => {
    let arr = [];
    mappingData.map((user) => {
      arr.push(user.survey_id.survey_id);
      return arr;
    });
    console.log("arr", arr);
    setSelected(arr);
  }, [mappingData]);

  const initialValues = {
    survey_ids: "",
  };

  const token = localStorage.getItem("token").toString();

  const updateUser = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const data = {
      survey_ids: selected,
    };
    console.log("data", data);
    await API.put(`/app/secure/survey_mapping?org_id=${id}`, data, config)
      .then((res) => {
        console.log("res", res);
        console.log(`Success : ${res.data.message}`);
        handleDialog();
        setUpdate(update + 1);
        setAlertContent(res.data.message);
        setAlertType("success");
        setAlert(true);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
        } else {
          setAlertContent(err.response.data.message);
          setAlertType("error");
          setAlert(true);
        }
      });
  };

  const getMappingData = async () => {
    await API.get(`/app/secure/survey_mapping?page=1&max_rows=5&org_id=${id}`, {
      // await API.get("/app/secure/survey_mapping?page=1&max_rows=5&org_id=119", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        // console.log("GetcompareData", res);
        console.log(
          "res.data.survey_mapped_list Get Compare",
          res.data.survey_mapped_list
        );
        setMappingData(res.data.survey_mapped_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log("mappingData", mappingData);

  const getTabData = () => {
    API.get("/app/secure/survey?page=1&max_rows=4", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        // console.log("tocompareData", res);
        console.log("res.data.survey_listtocompareData", res.data.survey_list);
        setTabData(res.data.survey_list);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getTabData();
  }, []);

  const AlphaNumeric = /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/;

  const validationSchema = Yup.object().shape({
    survey_id: Yup.array().min(1, "Select atleast one option"),
  });

  const handleChangeCheckBox = (e, label) => {
    if (e.target.checked == true) {
      if (!selected.includes(label)) {
        setSelected([...selected, label]);
      }
    } else if (e.target.checked == false) {
      const result = selected.filter((val) => {
        return val != label;
      });

      setSelected(result);
    }
    console.log("tabArray", selected);
  };

  // console.log("id", { id });

  return (
    <Box>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ marginBottom: "4%", fontFamily: "Montserrat", fontWeight: 600 }}
      >
        Update Survey
      </Typography>
      <Box>
        <Formik initialValues={initialValues}>
          {(props) => (
            <Form noValidate>
              <Typography variant="subtitle1" sx={{ textAlign: "left" }}>
                <span style={{ fontWeight: "bold" }}>Survey</span>
              </Typography>

              <div>
                {tabData.map((item) => {
                  return (
                    <>
                      {/* {console.log(
                        "defaultChecked",
                        tabId.includes(item.tab_id)
                      )} */}
                      <Box
                        key={item.survey_id}
                        style={{
                          display: "flex",
                          width: "150px",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <input
                              name="survey_id"
                              label={item.name}
                              checked={
                                selected.includes(item.survey_id) ? true : false
                              }
                              defaultChecked={
                                selected.includes(item.survey_id) ? true : false
                              }
                              onChange={(e) => {
                                handleChangeCheckBox(e, item.survey_id);
                              }}
                              style={{ margin: "8px" }}
                              type="checkbox"
                            />
                          }
                        />

                        <div style={{ margin: "8px" }}>{item.name}</div>
                      </Box>
                    </>
                  );
                })}
              </div>

              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "1rem",
                  mt: 2,
                }}
              >
                <Grid item>
                  <Button onClick={handleDialog}>Discard</Button>
                </Grid>
                <Grid item>
                  <Button autoFocus variant="contained" onClick={updateUser}>
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default UpdateSurveyForm;
