export const CHAT_LOADING = "CHAT_LOADING";
export const CHAT_LOAD_SUCCESS = "CHAT_LOAD_SUCCESS";
export const CHAT_LOAD_ERROR = "CHAT_LOAD_ERROR";

export const SAVE_ANSWER = "SAVE_ANSWER";

export const WELCOME_MESSAGE = 'WELCOME_MESSAGE';
export const RESET_CHAT_DATA = 'RESET_CHAT_DATA';
export const SET_ANSWER_ANONYMOUSLY = 'SET_ANSWER_ANONYMOUSLY';
export const SET_START_CHAT_FALSE = 'SET_START_CHAT_FALSE';
export const SET_LOAD_FEEDBACK = 'SET_LOAD_FEEDBACK';
export const SET_PREVIOUS_ANSWER_FEEDBACK_FALSE = 'SET_PREVIOUS_ANSWER_FEEDBACK_FALSE';
export const SET_END_SURVEY = 'SET_END_SURVEY';
export const SET_CHAT_LOADING = 'SET_CHAT_LOADING';
export const SET_CHAT_LOADING_FALSE = 'SET_CHAT_LOADING_FALSE';

export const chatConversation = (state, action) => {
   const { payload, type } = action;
    switch (type) {
        case CHAT_LOADING: {
            return {
                ...state,
                chat: {
                    ...state.chat,
                    loading: true,
                },
            };
        }

        case CHAT_LOAD_SUCCESS: {
            let questionType = [];
            let endSurvey = false;
            let previousAnswerFeedback = false;
            let previousAnswerFeedbackType = null;
            let nextQuestionPayload = [];
            let nextEndSurvey = false;
            let answerType = '';

            if(payload.length > 0) {
                const questionLength = payload[payload.length - 1].text ? payload[payload.length - 1].text.match(/.*<(\D*)>/) : [];

                if(questionLength && questionLength.length > 1 && questionLength[1] == 'end') {
                    const feedbackQuestionLength = payload[payload.length - 1].text ? payload[payload.length - 1].text.split(" ").reverse().join(" ").match(/.*<(\D*)>/) : [];
                    previousAnswerFeedbackType = feedbackQuestionLength && feedbackQuestionLength.length > 1 ? feedbackQuestionLength[1] : '';
                    previousAnswerFeedback = payload.length > 0 ? payload[payload.length - 1].text.match(/>([^<]*)</g) : '';
                    previousAnswerFeedback = previousAnswerFeedback?.length > 0 ? previousAnswerFeedback[0].replace('>', '').replace('<', '') : '';
                    endSurvey = previousAnswerFeedbackType.trim() == '' && previousAnswerFeedback.trim() == '' ? true : false;
                    nextEndSurvey = previousAnswerFeedbackType.trim() == '' && previousAnswerFeedback.trim() == '' ? false : true;
                }

                questionType = (questionLength && questionLength.length > 1) ? questionLength : [];
                answerType = questionType.length > 1 ? questionType[1] : '';

                if(payload.length > 1 && (questionLength && questionLength.length > 1 || payload[payload.length - 1].text == 'skip')) {
                    const feedbackQuestionLength = payload[0].text ? payload[0].text.match(/.*<(\D*)>/) : [];
                    previousAnswerFeedbackType = feedbackQuestionLength && feedbackQuestionLength.length > 1 ? feedbackQuestionLength[1] : '';
                    previousAnswerFeedback = payload.length > 0 ? payload[0].text.replace(/.*<\D*>/, '') : '';
                    nextQuestionPayload = [payload[1]];
                }
            }

            return {
                ...state,
                chat: {
                    ...state.chat,
                    loading: false,
                    data: payload
                },
                previousAnswerFeedbackType: previousAnswerFeedbackType,
                previousAnswerFeedback: previousAnswerFeedback,
                answerAnonymously: false,
                question: !previousAnswerFeedback ? payload.length > 0 ? payload[payload.length - 1]?.text?.replace(/.*<\D*>/, '') : '' : state.question,
                answerType: !previousAnswerFeedback ? nextQuestionPayload && nextQuestionPayload.length > 0 && answerType === 'text' ? '' : questionType.length > 1 ? questionType[1] : '' : state.answerType,
                endSurvey: endSurvey,
                buttons: !previousAnswerFeedback ? payload.length > 0 ? payload[payload.length - 1].buttons : [] : state.buttons,
                answer: !previousAnswerFeedback ? '' : state.answer,
                nextQuestionPayload: nextQuestionPayload,
                nextEndSurvey: nextEndSurvey,
            };
        }

        case CHAT_LOAD_ERROR:
            return {
                ...state,
                chat: {
                    ...state.chat,
                    loading: false,
                    error: true
                },
            };

        case SAVE_ANSWER:
            return {
                ...state,
                answer: payload,
            };

        case WELCOME_MESSAGE:
            return  {
                ...state,
                question: action.payload,
                answer: action.startCommand,
                start: true,
            }

        case RESET_CHAT_DATA:
            return  {
                ...state,
                ...chatInitialState
            }

        case SET_ANSWER_ANONYMOUSLY:
            return  {
                ...state,
                answerAnonymously: action.payload
            }

        case SET_START_CHAT_FALSE:
            return  {
                ...state,
                start: false
            }

        case SET_LOAD_FEEDBACK:
            return  {
                ...state,
                loadFeedback: true
            }

        case SET_PREVIOUS_ANSWER_FEEDBACK_FALSE:
            let questionType = [];
            let answerType = '';

            if(payload.length > 0) {
                const questionLength = payload[payload.length - 1].text ? payload[payload.length - 1].text.match(/.*<(\D*)>/) : [];

                questionType = (questionLength && questionLength.length > 1) ? questionLength : [];
                answerType = questionType.length > 1 ? questionType[1] : '';
            }

            return  {
                ...state,
                previousAnswerFeedback: false,
                previousAnswerFeedbackType: null,
                question: payload.length > 0 ? payload[payload.length - 1]?.text?.replace(/.*<\D*>/, '') : '',
                answerType: answerType,
                answer: '',
                buttons: payload.length > 0 ? payload[payload.length - 1].buttons : [],
            }

        case SET_END_SURVEY:
            return  {
                ...state,
                endSurvey: true,
            }
        case SET_CHAT_LOADING:
            return {
                ...state,
                chatLoading: true
            }
        case SET_CHAT_LOADING_FALSE:
            return {
                ...state,
                chatLoading: false
            }
        default:
            return state;
    }
};

export const chatInitialState = {
    chat: {
        loading: false,
        error: null,
        data: [],
    },
    previousAnswerFeedback: false,
    previousAnswerFeedbackType: null,
    question: '',
    answerType: '',
    questionId: '',
    employeeId: '',
    buttons: {},
    answer: '',
    loadFeedback: false,
    surveyScheduleId: '',
    answerAnonymously: false,
    previousChat: {},
    nextEndSurvey: false,
    endSurvey: false
};
