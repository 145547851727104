import {SAVE_ANSWER, WELCOME_MESSAGE, RESET_CHAT_DATA, SET_CHAT_LOADING, SET_CHAT_LOADING_FALSE, SET_ANSWER_ANONYMOUSLY, SET_START_CHAT_FALSE, SET_LOAD_FEEDBACK, SET_PREVIOUS_ANSWER_FEEDBACK_FALSE, SET_END_SURVEY} from "./chatReducer";

export const saveAnswer = (answer,dispatch) => {
    dispatch({
        type: SAVE_ANSWER,
        payload: answer,
    });
};

export const setWelcomeMessage = (message, startCommand, dispatch) => {
    dispatch({
        type: WELCOME_MESSAGE,
        payload: message,
        startCommand: startCommand,
        start: true
    });
}

export const resetChatData = (dispatch) => {
    dispatch({
        type: RESET_CHAT_DATA,
    });
}

export const setChatLoading = (dispatch) => {
    dispatch({
        type: SET_CHAT_LOADING,
    });
}

export const setChatLoadingFalse = (dispatch) => {
    dispatch({
        type: SET_CHAT_LOADING_FALSE,
    });
}

export const setAnswerAnonymously = (status, dispatch) => {
    dispatch({
        type: SET_ANSWER_ANONYMOUSLY,
        payload: status,
    });
}

export const setStartChatFalse = (dispatch) => {
    dispatch({
        type: SET_START_CHAT_FALSE,
    });
}

export const setChatFeedback = (dispatch) => {
    dispatch({
        type: SET_LOAD_FEEDBACK,
    });
}

export const setPreviousAnswerFeedbackFalse = (nextQuestionPayload, dispatch) => {
    dispatch({
        type: SET_PREVIOUS_ANSWER_FEEDBACK_FALSE,
        payload: nextQuestionPayload,
    });
}

export const setEndSurveyTrue = (dispatch) => {
    dispatch({
        type: SET_END_SURVEY,
    });
}
