import React, { createContext, useReducer } from 'react';
import {chatConversation, chatInitialState} from "./chatReducer";

export const ChatContext = createContext({});

export const ChatProvider = ({ children }) => {
    const [chatConversationState, chatDispatch] = useReducer(
        chatConversation,
        chatInitialState
    );

    return (
        <ChatContext.Provider
            value={{
                chatConversationState,
                chatDispatch,
            }}
        >
            {children}
        </ChatContext.Provider>
    );
};
