import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "../../../component/header/Header";
import LeftDrawer from "../../../component/leftDrawer/LeftDrawer";
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  Grid,
  Switch,
  Snackbar,
  Pagination,
  Menu,
  MenuItem,
  IconButton,
  FormControl,
  Select,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import InputLabel from "@material-ui/core/InputLabel";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@mui/material/Checkbox";
import MoreVertTwoToneIcon from "@mui/icons-material/MoreVertTwoTone";
import {
  createSurvayQuestion,
  getQuestionBankList,
  getQuestionsList,
} from "../../../thunk/Action";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// import { useStyles } from "./SurveyQuestion.style";

import { useDispatch } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import API from "../../../E2E/Axios.Utils";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Notification } from "../../../Utils/SnackbarNotification/Notification";
import { ErrorMessage } from "formik";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomModel from "../../../Utils/CustomModel/CustomModel";

//drawer

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));
const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
    marginLeft: "2%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  rightroot: {
    backgroundColor: "red",
    display: "flex",
    marginLeft: "40%",
    maginTop: "25%",
  },
  rightboxparent: {
    backgroundColor: "#BEDFFA",
    width: "200%",
    borderRadius: 10,
    marginBottom: "1rem",
  },
  ButtonSurvey: {
    backgroundColor: "rgb(45, 64, 78) !important",
    color: "white !important",
    textTransform: "capitalize",
    marginTop: 15,
  },
  rightboxchild: {
    backgroundColor: "white",
    // marginLeft: "5%",
    // marginTop: "-2%",
    // marginRight: "3%",
    // paddingLeft: 22,
    // paddingRight: 16,
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: "150px",
    justifyContent: "space-between",
    paddingLeft: "21px",
    paddingRight: "16px",
    borderRadius: 13,
  },
  textStyle: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#0B0B0C",

    marginBottom: "20px",
    cursor: "pointer",
  },
  roots: {
    // "& .css-1191obr-MuiPaper-root-MuiAppBar-root": {
    //   // width: 'calc(100% - 240px)',
    //   width: "100%",
    // },
    // "& .MuiDrawer-paperAnchorLeft": {
    //   marginTop: "80px",
    // },
    backgroundColor: "#EEEEEE",
  },
  body: {
    width: "100%",
    "& .MuiAppBar-colorDefault": {
      backgroundColor: "transparent",
      boxShadow: "none",
      borderBottom: "2px solid #555555",
    },
    "& .PrivateTabIndicator-colorPrimary-6": {
      backgroundColor: "#2D404E",
    },
    "& .MuiTab-root": {
      minWidth: "auto",
    },
    "& .MuiBox-root": {
      padding: "24px 0",
    },
    "& .MuiButtonBase-root": {
      "& .MuiTab-wrapper": {
        fontFamily: "'Inter'",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#2D404E",
        textTransform: "capitalize",
      },
    },
  },
}));
const SurveyQuestion = ({
  setToggleEditTeam,
  setSurveyToggle,
  surveyId,
  csurveyId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  let question_id = useParams();
  // console.log("id___survey_", id);
  const [opendrawer, setOpen] = React.useState(true);

  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [message, setMessage] = React.useState("");

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const [switch1, setSwitch1] = useState({});
  const [switch2, setSwitch2] = useState({});
  const [myCheck, setmyCheck] = useState({});
  const [StoreData] = useState([]);
  const [nextIndex, setNextIndex] = useState();
  const [expanded, setExpanded] = React.useState(false);
  const [surveysQuesList, setsurveysQuesList] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageCount, setPageCount] = React.useState(null);
  const [totalRecord, setTotalRecord] = React.useState("");
  const [selecteds, setSelecteds] = React.useState("");
  const [selected, setSelected] = React.useState([]);

  // Notification SnackBar
  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [role, setRole] = React.useState("");

  // console.log({ switch1 });
  const handleSwitch1 = (e) => {
    setSwitch1({ ...switch1, [e.target.name]: e.target.checked });
    // console.log(switch1);
  };
  // console.log({ switch2 });
  const handleSwitch2 = (e) => {
    setSwitch1({ ...switch2, [e.target.name]: e.target.checked });
  };

  const handleAlert = () => {
    setAlert(false);
  };

  const [queName, setQueName] = React.useState([]);
  const token = localStorage.getItem("token").toString();
  const [pages, setPages] = useState(1);
  const [maxrow, setMaxRow] = useState(10);
  const [update, setUpdate] = useState(0);

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    console.log("Pages:::", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPages(1);
    console.log("rowPerPage:::", rowsPerPage);
  };
  const selectionChangeHandler = (event) => {
    setSelecteds(event.target.value);
  };

  const getQuestionbankListcall = () => {
    API.get(`/app/secure/question_bank?page=${pages}&max_rows=${maxrow}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        console.log("res", res);
        setQueName(res?.data?.question_bank_list);
        setPageCount(res?.data?.total_page_count);
        setTotalRecord(res?.data?.total_record_count);
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  React.useEffect(() => {
    getQuestionbankListcall();
  }, [pages, maxrow]);

  // const getQuestionbankListcall = () => {
  //   const request = {
  //     token: token,
  //     data: {
  //       page: pages,
  //       max_rows: maxrow,
  //     },
  //     onSuccess: (res) => {
  //       console.log("Res ::--", res);
  //       if (res?.status === 200) {
  //         setQueName(res?.data?.question_bank_list);
  //         console.log("question::::", res?.data?.question_bank_list);
  //       }
  //     },
  //   };
  //   dispatch(getQuestionBankList(request));
  // };
  // React.useEffect(() => {
  //   getQuestionbankListcall();
  // }, [pages, maxrow]);

  const [queTitle, setQueTitle] = useState([]);
  const [queId, setQueId] = useState([]);

  // const getQuestionListcall = (qbankid) => {
  //   const request = {
  //     token: token,
  //     id: qbankid,
  //     data: {
  //       page: pages,
  //       max_rows: maxrow,
  //     },
  //     onSuccess: (res) => {
  //       console.log("Res ::--", res);
  //       if (res?.status === 200) {
  //         console.log("questionslist::::", res?.data?.questionslist);
  //         setQueTitle(res?.data?.questionslist);
  //         setQueId(res?.data?.question_id);
  //       }
  //     },
  //   };

  //   dispatch(getQuestionsList(request));
  // };

  const getQuestionListcall = (qbankid) => {
    API.get(
      `/app/secure/question?page=1&max_rows=10&question_bank_id=${qbankid}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        console.log("res", res);
        console.log("res?.data?.questionslist", res?.data?.questionslist);
        setQueTitle(res?.data?.questionslist);
        setQueId(res?.data?.question_id);
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  React.useEffect(() => {
    getQuestionListcall();
  }, []);

  const getSurveyQuestion = () => {
    API.get(
      `/app/secure/survey_question?page=1&max_rows=20&survey_id=${
        surveyId || csurveyId
      }`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        console.log("resgetSurveyQuestion ", res.data.survey_questionslist);
        setsurveysQuesList(res.data.survey_questionslist);
        // console.log("res?.data?.questionslist", res?.data?.questionslist);
        // setQueTitle(res?.data?.questionslist);
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };
  // console.log("setCSurveyId", csurveyId);
  // console.log("surveyId", surveyId);

  React.useEffect(() => {
    getSurveyQuestion();
  }, [update]);

  const createApiCall = () => {
    // console.log("org_id", org_idC);
    console.log("StoreData", StoreData);
    let finalData = {
      survey_id: surveyId || csurveyId,
      questions: StoreData,
    };
    // console.log("finalData======", finalData);
    const request = {
      token: token,
      data: finalData,
      onSuccess: (res) => {
        if (res?.status === 200) {
          // console.log("Create res:::", res);
          setAlertContent(res.data.message);
          setAlertType("success");
          setAlert(true);
          setUpdate(update + 1);
          // setState({ open: true, ...newState });
          // setMessage(res?.data?.message);
          // handleToCloseCreateDialog();
          // getApicall();
        }
      },
      onFail: (error) => {
        if (error?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          navigate("/login");
        } else {
          setAlertContent(error.data.message);
          setAlertType("error");
          setAlert(true);
        }
      },
    };
    dispatch(createSurvayQuestion(request));
    // handleToCloseCreateDialog();
  };

  const [list, setList] = useState([]);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [demo, setDemo] = useState([]);

  const removeFunction = () => {
    const data = [...list];
    data.splice(0, 1);
    setList(data);
  };
  const removeFunction1 = () => {
    const data = [...list];
    data.splice(1, 1);
    setList(data);
  };
  const removeFunction2 = () => {
    const data = [...list];
    data.splice(2, 1);
    setList(data);
  };
  const removeFunction3 = () => {
    const data = [...list];
    data.splice(3, 1);
    setList(data);
  };
  const removeFunction4 = () => {
    const data = [...list];
    data.splice(4, 1);
    setList(data);
  };
  const removeFunction5 = () => {
    const data = [...list];
    data.splice(5, 1);
    setList(data);
  };

  const handlecheckbox = (index) => {
    // console.log("index=========", index);
    if (index === 0) {
      setChecked(true);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [rowId, setRowId] = React.useState("");

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [question, setQuestion] = useState([
    { question_id: "", is_anonymous: "", is_required: "" },
  ]);

  const [checked, setChecked] = useState(null);
  const [myIndex, setMyIndex] = useState();
  const [newchecked, setNewChecked] = React.useState([]);
  const [Qid, setQid] = React.useState();
  const [myChecked, setMyChecked] = React.useState();
  const [optionList, setOptionList] = useState([]);

  useEffect(() => {
    if (newchecked?.length > 0) {
      let finalArray = [];
      newchecked?.map((item, index) => {
        let obj = {
          // ...item,
          question_id: item?.question_id,
          is_anonymous: item?.is_anonymous,
          is_required: item?.is_required,
        };

        finalArray.push(obj);
      });
      setQuestion(finalArray);
    }
    // console.log("data che",document.getElementById("12").value)
  }, [newchecked, switch1, myCheck]);

  // console.log("Question ::--", question);

  const handleChange = (data, queId, e) => {
    if (e.target.checked) {
      setmyCheck({
        ...myCheck,
        [e.target.name]: true,
      });
      if (newchecked?.indexOf(data) === -1) {
        setNewChecked([...newchecked, data]);
      } else {
        const localObj = newchecked?.filter(
          (item, index) => item.question_id !== queId
        );
        setNewChecked(localObj);
      }
    } else {
      const localObj = newchecked?.filter(
        (item, index) => item.question_id !== queId
      );
      setNewChecked(localObj);
      setQid(queId);
      setmyCheck({
        ...myCheck,
        [e.target.name]: false,
      });
    }
  };

  // console.log("newChecked:::", newchecked);

  const RemoveFunction = (queId) => {
    const localObj = newchecked?.filter(
      (item, index) => item.question_id !== queId
    );
    setmyCheck({ ...myCheck, [queId]: false });
    // console.log("removeMyCheck", myCheck);
    setNewChecked(localObj);
    setQid(queId);
  };
  const AddClick = () => {};

  const [anchorE1, setAnchorE1] = React.useState(null);
  const openE1 = Boolean(anchorE1);
  const handleClickE1 = (event) => {
    setAnchorE1(event.currentTarget);
  };
  const handleCloseE1 = () => {
    setAnchorE1(null);
  };

  const handleChangeAcoordian = (panel) => (event, isExpanded) => {
    // console.log(panel);
    // console.log(isExpanded);
    setExpanded(isExpanded ? panel : false);
  };

  // console.log("optionList", optionList);

  React.useEffect(() => {
    var role = localStorage.getItem("role");
    setRole(role);
  }, []);

  return (
    <>
      {/* className={classes.roots} sx={{ display: "flex" }} */}
      {/* {console.log("removeMyCheck1", myCheck)} */}
      <Box className={classes.roots}>
        <CssBaseline />
        <Header />
        <LeftDrawer open={opendrawer} />

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <>
            <Box className={classes.body}>
              <div
                onClick={() => {
                  setToggleEditTeam(false);
                }}
              >
                <Typography
                  className={classes.textStyle}
                  style={{ marginLeft: "18%" }}
                  onClick={() => setSurveyToggle(true)}
                  // onClick={() => navigate("/Survey")}
                >
                  Back
                </Typography>
              </div>

              <div style={{ marginLeft: "18%" }}>
                <Grid container spacing={2} style={{ marginLeft: -35 }}>
                  <Grid item xs={8}>
                    <div>
                      <div className={classes.root}>
                        {queName.map((val, idx) => {
                          // console.log(val.question_bank_id);
                          return (
                            <>
                              {/* question_bank_id */}
                              <Accordion
                                style={{ backgroundColor: "#BEDFFA" }}
                                expanded={expanded === val.question_bank_id}
                                key={val.question_bank_id}
                                onChange={handleChangeAcoordian(
                                  val.question_bank_id
                                )}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  onClick={() =>
                                    getQuestionListcall(val.question_bank_id)
                                  }
                                >
                                  <Typography className={classes.heading}>
                                    {val.name}
                                  </Typography>
                                </AccordionSummary>
                                {val.question_bank_id && queTitle.length > 0 ? (
                                  <>
                                    {queTitle.map((data, index) => {
                                      // console.log("data", data);
                                      return (
                                        <AccordionDetails>
                                          <Checkbox
                                            checked={myCheck[data.question_id]}
                                            name={data.question_id}
                                            onChange={(e) => {
                                              let queId = data?.question_id;

                                              handleChange(data, queId, e);
                                            }}
                                            sx={{
                                              "& .MuiSvgIcon-root": {
                                                fontSize: 28,
                                              },
                                            }}
                                          />

                                          <Typography>
                                            <>{data.title}</>
                                          </Typography>
                                        </AccordionDetails>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <div style={{ marginLeft: "5%" }}>
                                    opps! data not found
                                  </div>
                                )}
                              </Accordion>
                            </>
                          );
                        })}
                        {role === "Super Admin" ? (
                          <Grid container spacing={2} sx={{ padding: "10px" }}>
                            <Grid item xs={6}>
                              <Box
                                display="flex"
                                alignItems={"center"}
                                flexDirection="row"
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Montserrat",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                  }}
                                >
                                  Row per Page
                                </Typography>
                                <FormControl
                                  variant="standard"
                                  sx={{ m: 0, minWidth: 50 }}
                                >
                                  {/* <InputLabel
                                  style={{
                                    marginLeft: "20px",
                                    position: "absolute",
                                    marginTop: "20px",
                                  }}
                                >
                                  {selecteds === "" ? 10 : selected}
                                </InputLabel> */}
                                  <Select
                                    size="small"
                                    value={selecteds}
                                    onChange={selectionChangeHandler}
                                    disableUnderline
                                    style={{
                                      textAlign: "center",
                                      fontSize: "14px",
                                      paddingLeft: 10,
                                      paddingTop: 8,
                                      ":before": {
                                        borderColor: "#fafafc",
                                      },
                                      ":after": {
                                        borderColor: "#fafafc",
                                      },
                                      height: 30,
                                      justifyContent: "center",
                                    }}
                                    //   SelectProps={{ disableUnderline: true }}
                                  >
                                    <MenuItem
                                      value={1}
                                      onClick={() => {
                                        setMaxRow(10);
                                      }}
                                    >
                                      10
                                    </MenuItem>
                                    <MenuItem
                                      value={2}
                                      onClick={() => {
                                        setMaxRow(20);
                                      }}
                                    >
                                      20
                                    </MenuItem>
                                    <MenuItem
                                      value={3}
                                      onClick={() => {
                                        setMaxRow(50);
                                      }}
                                    >
                                      50
                                    </MenuItem>
                                    <MenuItem
                                      value={4}
                                      onClick={() => {
                                        setMaxRow(100);
                                      }}
                                    >
                                      100
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                                {/* <Typography
                                sx={{
                                  fontFamily: "Montserrat",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  fontSize: "13px",
                                  lineHeight: "20px",
                                }}
                              >
                                of {totalRecord}
                              </Typography> */}
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Pagination
                                style={{ marginTop: "20%" }}
                                onChange={handlePageChange}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                page={pages}
                                rowsPerPage={rowsPerPage}
                                count={pageCount || 0}
                              />
                            </Grid>
                          </Grid>
                        ) : null}
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={4} style={{ marginLeft: "-30%" }}>
                    <>
                      {surveysQuesList.map((item, index) => (
                        // console.log(
                        //   "item:::++++++++++++++++++++++++++++++++++:",
                        //   item
                        // ),
                        // console.log("index::::", index),
                        <div>
                          <Box className={classes.rightboxparent}>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                                columnGap: 15,
                              }}
                            >
                              <div
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                  paddingLeft: 10,
                                }}
                              ></div>

                              <div className={classes.rightboxchild}>
                                <div>
                                  <Typography>
                                    Q {index + 1} {item.title}
                                  </Typography>
                                  <Typography>
                                    Question Type: {item.question_type}
                                  </Typography>
                                </div>
                                <div
                                  onClick={() => {
                                    setOptionList(item);
                                  }}
                                >
                                  {/* <Button
                                    style={{
                                      backgroundColor: "#2D404E",
                                      color: "white",
                                      // display: "none",
                                      borderRadius: "none",
                                    }}
                                    id="basic-button"
                                    aria-controls={
                                      openE1 ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={openE1 ? "true" : undefined}
                                    onClick={handleClickE1}
                                    endIcon={<KeyboardArrowDownIcon />}
                                  >
                                    options
                                  </Button> */}
                                </div>
                              </div>
                              <DeleteMoreMenu
                                currentDetails={item.survey_question_id}
                                update={update}
                                setUpdate={setUpdate}
                              />
                              {/* <DeleteOutlineOutlinedIcon
                                style={{ marginRight: 7 }}
                                onClick={() => {
                                  handleDeleteSurveyQues(
                                    item.survey_question_id
                                  );
                                }}
                                // onClick={(e) => {
                                //   handleClickMenu(e);
                                //   setRowId(item);
                                // }}
                              /> */}
                            </div>
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorE1}
                              open={openE1}
                              onClose={handleCloseE1}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                            >
                              {optionList?.survey_option?.map((val, index) => {
                                return (
                                  <MenuItem onClick={handleCloseE1}>
                                    {val.title}
                                  </MenuItem>
                                );
                              })}
                            </Menu>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "space-between",
                                paddingLeft: "54px",
                                paddingRight: "41px",
                                paddingTop: "27px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  columnGap: "18px",

                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      // display: "flex",
                                      display: "none",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Anonymous User
                                  </Typography>
                                  <Switch
                                    {...label}
                                    color="default"
                                    name={item.question_id}
                                    checked={switch1[item.question_id]}
                                    onChange={handleSwitch1}
                                    sx={{ display: "none" }}
                                  />
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    style={{
                                      fontWeight: "bold",
                                      display: "none",
                                    }}
                                  >
                                    Required
                                  </Typography>

                                  <Switch
                                    {...label}
                                    color="default"
                                    name={item.question_type}
                                    checked={switch2[item.question_id]}
                                    onChange={handleSwitch2}
                                    sx={{ display: "none" }}
                                  />
                                </div>
                              </div>
                              <IconButton
                                style={{
                                  color: "white",
                                  background: "#2d404e",
                                  borderRadius: "7px",
                                  display: "none",
                                }}
                                onClick={() => {
                                  StoreData.push({
                                    question_id: item.question_id,
                                    is_anonymous:
                                      switch1[item.question_id] === true
                                        ? true
                                        : false,
                                    is_required:
                                      switch2[item.question_type] === true
                                        ? true
                                        : false,
                                  });
                                }}
                              >
                                <SaveIcon />
                              </IconButton>
                            </div>
                          </Box>
                        </div>
                      ))}{" "}
                      {newchecked &&
                        newchecked.map((item, index) => (
                          <div>
                            <Box className={classes.rightboxparent}>
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-between",
                                  columnGap: 15,
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    paddingLeft: 10,
                                  }}
                                ></div>

                                <div className={classes.rightboxchild}>
                                  <div>
                                    <Typography>Q 1 {item.title}</Typography>
                                    <Typography>
                                      Question Type: {item.question_type}
                                    </Typography>
                                  </div>

                                  {/* <Button
                                    style={{
                                      backgroundColor: "#2D404E",
                                      color: "white",

                                      borderRadius: "none",
                                    }}
                                    id="basic-button"
                                    aria-controls={
                                      openE1 ? "basic-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={openE1 ? "true" : undefined}
                                    onClick={handleClickE1}
                                    endIcon={<KeyboardArrowDownIcon />}
                                  >
                                    options
                                  </Button> */}
                                </div>
                                <MoreVertTwoToneIcon
                                  style={{ marginRight: 7 }}
                                  onClick={(e) => {
                                    handleClickMenu(e);
                                    setRowId(item);
                                  }}
                                />
                              </div>
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorE1}
                                open={openE1}
                                onClose={handleCloseE1}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}
                              >
                                {item.option.map((val, index) => {
                                  return (
                                    <MenuItem onClick={handleCloseE1}>
                                      {val.option}
                                    </MenuItem>
                                  );
                                })}
                              </Menu>
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  paddingLeft: "54px",
                                  paddingRight: "41px",
                                  paddingTop: "27px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    columnGap: "18px",

                                    alignItems: "center",
                                  }}
                                >
                                  {/* <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        display: "flex",

                                        fontWeight: "bold",
                                      }}
                                    >
                                      Anonymous User
                                    </Typography>
                                    <Switch
                                      {...label}
                                      color="default"
                                      name={item.question_id}
                                      checked={switch1[item.question_id]}
                                      onChange={handleSwitch1}
                                    />
                                  </div> */}

                                  {/* <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Required
                                    </Typography>

                                    <Switch
                                      {...label}
                                      color="default"
                                      name={item.question_type}
                                      checked={switch2[item.question_id]}
                                      onChange={handleSwitch2}
                                    />
                                  </div> */}
                                </div>
                                <IconButton
                                  style={{
                                    color: "white",
                                    background: "#2d404e",
                                    borderRadius: "7px",
                                  }}
                                  onClick={() => {
                                    StoreData.push({
                                      question_id: item.question_id,
                                      is_anonymous:
                                        switch1[item.question_id] === true
                                          ? true
                                          : false,
                                      is_required:
                                        switch2[item.question_type] === true
                                          ? true
                                          : false,
                                    });
                                  }}
                                >
                                  <SaveIcon />
                                </IconButton>
                              </div>
                            </Box>
                          </div>
                        ))}
                    </>
                    <Menu
                      id={`simple-menu`}
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                      elevation={0}
                    >
                      <MenuItem
                        onClick={() => {
                          setAnchorEl(null);
                          RemoveFunction(rowId.question_id);
                        }}
                      >
                        Remove
                      </MenuItem>
                    </Menu>
                    <Button
                      onClick={() => createApiCall()}
                      className={classes.ButtonSurvey}
                    >
                      Create Survey Question
                    </Button>
                  </Grid>
                  {/* <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open={open}
                    onClose={handleClose}
                    message={message}
                    key={vertical + horizontal}
                  /> */}
                  <Notification
                    message={
                      alertContent
                        ? alertContent
                        : `Success : Data Fetched Successfully.`
                    }
                    open={alert}
                    severity={alertType}
                    onClose={handleAlert}
                  />
                </Grid>
              </div>
            </Box>
          </>
        </Box>
      </Box>
    </>
  );
};

export default SurveyQuestion;

// ************************
function DeleteMoreMenu({
  currentDetails,
  setAlert,
  setAlertType,
  setAlertContent,
  update,
  setUpdate,
  setToggleEditTeam,
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [openEdit, setEditOpen] = React.useState(false);

  const handleDialog = () => {
    setEditOpen(!openEdit);
  };

  const token = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleDeleteSurveyQues = (id) => {
    API.delete(
      `/app/secure/survey_question?survey_question_id=${currentDetails}`,

      config
    )
      .then((res) => {
        // console.log("res-", res);
        setUpdate(update + 1);
        // setAlertContent(`Record Deleted Successfully.`);
        // setAlertType("success");
        // setAlert(true);
      })

      .catch((err) => {
        // console.log(err);
        // setAlertContent(`Record Delete Failed.`);
        // setAlertType("error");
        // setAlert(true);
      });
  };

  const ref = React.useRef(openEdit);

  return (
    <>
      <CustomModel handleDialog={handleDialog} open={openEdit}>
        <Typography variant="h4" sx={{ color: "#1976d2", fontWeight: 500 }}>
          Question
        </Typography>
        <Typography sx={{ pb: 2, pt: 2 }}>
          Are you sure you want to delete this Question ?
        </Typography>

        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Grid item>
            <Button
              onClick={() => {
                handleDialog();
              }}
            >
              Discard
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                handleDeleteSurveyQues();
                // setIsOpen(false);
                handleDialog();
              }}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </CustomModel>
      <IconButton
        ref={ref}
        onClick={() => setIsOpen(true)}
        style={{
          color: "#2d404e",
          height: 20,
        }}
      >
        <DeleteOutlineOutlinedIcon
          width={20}
          height={20}
          onClick={() => {
            handleDialog();
            setIsOpen(false);
          }}
        />
      </IconButton>
    </>
  );
}
