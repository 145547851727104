import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {},

  backBtn: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#0B0B0C",
    marginBottom: "20px",
    marginTop: "20px",
    cursor: "pointer",
    display: 'flex',
    alignItems: 'center',
    gap: '10px',

  },

  steperHeader: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
    background: "#128CB0",
    boxShadow: "0px 4px 4px rgba(235, 235, 235, 0.25)",
    height: "61px",
    padding: "0 16px",
    borderRadius: "4px 4px 0px 0px",

    "& .title": {
      fontFamily: "'Montserrat'",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "22px",
      color: "#FFFFFF",
      marginBottom: "0",
    },
    "& .activeStep": {
      fontWeight: 'bold'
    },
  },

  cardStyle: {
    borderRadius: "0 !important",
    boxShadow: "none !important",
  },

  TextField_Style: {
    width: "100%",
  },
  btnStyle:{
    backgroundColor:'#128CB0',
    color:'#fff'
  }
});

export { useStyles };
