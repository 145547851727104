import React from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Box,
} from "@mui/material";

export default function CustomModel(props) {
  const { children, title, message, fullScreen, open } = props;
  return (
    <Dialog open={open} fullScreen={fullScreen} fullWidth maxWidth="sm">
      <DialogTitle color="#1565c0" style={{ textAlign: "center" }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <Box style={{ padding: 5 }}>{children}</Box>
      </DialogContent>
    </Dialog>
  );
}
