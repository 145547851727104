import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {},

  backBtn: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#0B0B0C",
    marginBottom: "20px",
    marginTop: "20px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  steperHeader: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
    background: "#128CB0",
    boxShadow: "0px 4px 4px rgba(235, 235, 235, 0.25)",
    height: "61px",
    padding: "0 16px",
    borderRadius: "4px 4px 0px 0px",

    "& .title": {
      fontFamily: "'Montserrat'",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "22px",
      color: "#FFFFFF",
      marginBottom: "0",
    },
    "& .activeStep": {
      fontWeight: 'bold'
    },
  },

  cardStyle: {
    borderRadius: "0 !important",
    boxShadow: "none !important",
  },
  datepicker: {
    "& .react-datepicker__input-container> input": {
      // width: "100%",
      // height: "50px",
      // marginTop:"10px",
      // marginLeft:"-96%"
      //position:"absolute"

      font: "inherit",
      letterSpacing: "inherit",
      color: "currentColor",
      padding: "16.5px 14px",
      border: "0",
      boxSizing: "content-box",
      background: "none",
      height: "1.4375em",
      margin: "0",
      WebkitTapHighlightColor: "transparent",
      display: "block",
      minWidth: "0",
      width: "94.5%",
      // WebkitAnimationName: "mui-auto-fill-cancel",
      // animationName: "mui-auto-fill-cancel",
      // WebkitAnimationDuration: "10ms",
      // animationDuration: "10ms",
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderStyle: "solid",
      borderWidth: "1px",
      borderRadius: "4px",
    },
    "& .react-datepicker-popper": {
      zIndex: "100",
    },
  },
  btnStyle: {
    backgroundColor: "#128CB0",
    color: "#fff",
  },
});

export { useStyles };
