import React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Grid, TextField } from "@material-ui/core";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import DatePicker from "react-datepicker";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  InputBox__wrapper: {
    width: "100%",
  },

  Select_formControl: {
    width: "100%",
  },

  datepicker: {
    "& .react-datepicker__input-container> input": {
      // width: "100%",
      // height: "50px",
      // marginTop:"10px",
      // marginLeft:"-96%"
      //position:"absolute"

      font: "inherit",
      letterSpacing: "inherit",
      color: "currentColor",
      padding: "16.5px 14px",
      border: "0",
      boxSizing: "content-box",
      background: "none",
      height: "1.4375em",
      margin: "0",
      WebkitTapHighlightColor: "transparent",
      display: "block",
      minWidth: "0",
      width: "94.5%",
      // WebkitAnimationName: "mui-auto-fill-cancel",
      // animationName: "mui-auto-fill-cancel",
      // WebkitAnimationDuration: "10ms",
      // animationDuration: "10ms",
      borderColor: "rgba(0, 0, 0, 0.23)",
      borderStyle: "solid",
      borderWidth: "1px",
      borderRadius: "4px",
    },
    "& .react-datepicker-popper": {
      zIndex: "100",
    },
  },
});

const InputTextField = (props) => {
  const classes = useStyles();

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Grid item md={props.md || 6} xs={props.xs || 12}>
      {props.element === "input" && (
        <input
          id={props.id}
          type={props.type}
          placeholder={props.placeholder}
          value={props.value}
          className={`${classes.InputBox_for_input} ${props.className}`}
        />
      )}
      {props.element === "TextField" && (
        <TextField
          required={props.required}
          placeholder={props.placeholder}
          id={props.label}
          label={props.label}
          variant={props.variant || "outlined"}
          className={`${classes.InputBox__wrapper} ${props.className}`}
          style={props.style}
          onChange={props.onChange}
          value={props.value}
          error={props.error}
          helperText={props.helperText}
        />
      )}
      {props.element === "NumberField" && (
        <TextField
          required={props.required}
          placeholder={props.placeholder}
          id={props.label}
          label={props.label}
          variant={props.variant || "outlined"}
          className={`${classes.InputBox__wrapper} ${props.className}`}
          style={props.style}
          onChange={props.onChange}
          value={props.value}
          error={props.error}
          helperText={props.helperText}
          type="number"
        />
      )}

      {props.element === "date" && (
        <div className={classes.datepicker}>
          <DatePicker
            placeholderText={props.placeholder}
            // selected={selectedDate}
            // onChange={date =>setSelectedDate(date)}
            selected={props.selected}
            onChange={props.onChange}
            dateFormat="yyyy-MM-dd"
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
          />
        </div>
      )}

      {props.element === "select" && (
        <FormControl variant="outlined" className={classes.Select_formControl}>
          <InputLabel id="demo-simple-select-outlined-label">
            {props.label}
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={props.value}
            // onChange={handleChange}
            onChange={props.onChange}
            label="Age"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {props.option &&
              props.option.map((data) => (
                <MenuItem value={data.value}>{data.value}</MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
};

export default InputTextField;
