import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Grid, Typography } from "@mui/material";
// import axios from "axios";
import AddRoleCard from "./AddRoleCard";
import UtilHelper from "../../../Utils/UtilHelper";
import API from "../../../E2E/Axios.Utils";

let defaultPermissionsForUpdate = [];

const UpdateRoleCard = ({
  roleName,
  roleId,
  handleDialog,
  setAlert,
  setAlertType,
  setAlertContent,
  update,
  setUpdate,
  adminRole,
}) => {
  const [role, setRole] = useState(null);
  const [handleOpen, setHandleOpen] = useState(true);

  const createPermissionListForUpdate = (menus) => {
    const defaultPermissions = [];
    menus.forEach((menu) => {
      const newMenu = {
        id: menu.id,
        submenus: [],
      };
      menu.submenus.forEach((submenu) => {
        newMenu.submenus.push(submenu.id);
      });

      defaultPermissions.push(newMenu);
    });

    return defaultPermissions;
  };

  const getRoleDetailsById = (id) => {
    const token = localStorage.getItem("token").toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    API.get(`/app/secure/role_permissions_list?id=${id}`, config)
      .then((res) => {
        // console.log("responseofpermission", res);
        defaultPermissionsForUpdate = createPermissionListForUpdate(
          res.data.list
        );
        // console.log("defaultPermissionsForUpdate", defaultPermissionsForUpdate);
        const data = {
          role_name: roleName,
          permissions: JSON.parse(JSON.stringify(defaultPermissionsForUpdate)),
          is_admin_role: adminRole,
          download_permission: res.download_permission,
        };

        setRole(data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getRoleDetailsById(roleId);
  }, []);

  const handleUpdateRole = (role) => {
    // console.log("role", role);
    role.id = roleId;
    role.updatedPermissions = role.permissions;
    role.removedPermission = UtilHelper.collectListOfRemovePermission(
      defaultPermissionsForUpdate,
      role.permissions
    );
    delete role.permissions;

    const token = localStorage.getItem("token").toString();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    API.patch(`/app/secure/update_role`, role, config)
      .then((res) => {
        setUpdate(update + 1);
        handleDialog();
        setAlertContent(res.data.message);
        setAlertType("success");
        setAlert(true);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
        } else if (err?.response?.status == 403) {
          setAlertContent(err.response.data.message);
          setAlertType("error");
          setAlert(true);
        } else {
          setAlertContent(`Error : Something went wrong`);
          setAlertType("error");
          setAlert(true);
        }
      });
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={handleOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="role-dialog-form-content">
          <Grid container spacing={0}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant="h5"
                className="role-dialog-title"
                sx={{ mb: 2 }}
              >
                Update Role
              </Typography>
              <AddRoleCard
                handleDialog={handleDialog}
                submitFormData={handleUpdateRole}
                formData={role}
                isUpdate
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpdateRoleCard;
