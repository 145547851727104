import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "../../component/dialog/dialog";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "../../component/header/Header";
import LeftDrawer from "../../component/leftDrawer/LeftDrawer";
import Menu from "@material-ui/core/Menu";
import AddClient from "./addClient/AddClient";
import InputLabel from "@material-ui/core/InputLabel";
import {
  Pagination,
  PaginationItem,
  usePagination,
  TablePagination,
} from "@mui/material";
import {
  Typography,
  Box,
  ButtonGroup,
  Button,
  Grid,
  MenuItem,
  Select,
  FormControl,
  Snackbar,
} from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { SearchBlack, Dots, Arrow } from "../../component/Images";
import InputBox from "../../component/UIElements/InputBox";
import AvatarComp from "../../component/UIElements/Avatar";
import { useStyles } from "./client.style";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import UpdateClient from "./updateClient/UpdateClient";
import {
  getCustomerList,
  createCustomerList,
  updateCustomerList,
  deleteCustomerList,
  getSearchCustomerList,
  getSortCustomerList,
} from "../../thunk/Action";
import moment from "moment";

//drawer
import Switch from "@mui/material/Switch";
import { Notification } from "../../Utils/SnackbarNotification/Notification";
import API from "../../E2E/Axios.Utils";
import FileSaver from "file-saver";
import Permission from "../../component/Permission/Permission";
import SearchNotFound from "../../Utils/SearchNotFound/SearchNotFount";

const label = { inputProps: { "aria-label": "Switch demo" } };

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
});

const headCells = [
  {
    id: "COMPANY",
    numeric: false,
    disablePadding: true,
    label: "COMPANY",
  },
  {
    id: "CHRO_NAME",
    numeric: true,
    disablePadding: false,
    label: "CHRO_NAME",
  },
  {
    id: "SURVEY_COUNT",
    numeric: true,
    disablePadding: false,
    label: "SURVEY_COUNT",
  },
  {
    id: "EMPLOYEES",
    numeric: true,
    disablePadding: false,
    label: "EMPLOYEES",
  },
  {
    id: "CREATED DATE",
    numeric: true,
    disablePadding: false,
    label: "CREATED DATE",
  },
  {
    id: "STATUS",
    numeric: true,
    disablePadding: false,
    label: "STATUS",
  },
  {
    id: "LAST_CHANGED",
    numeric: true,
    disablePadding: false,
    label: "LAST CHANGED",
  },
  {
    id: "ACTIONS",
    numeric: true,
    disablePadding: false,
    label: "ACTIONS",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const handlesort = (val) => {
    props.setSortBy(val);
    props.setSortOrder(val);
    console.log(val);
  };

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "#128CB0", height: 50 }}>
        <TableCell></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              color: "#fff",
              "&.MuiTableRow-root:hover": {
                color: "#fff",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              {headCell.label}
              {headCell.label !== "" ? (
                <>
                  <Box
                    sx={{ marginLeft: "5px", paddingTop: "3px" }}
                    onClick={() => {
                      handlesort(headCell.label);
                    }}
                  >
                    {/* <AvatarComp image={Arrow} height={15} /> */}
                    {headCell.label === "EMPLOYEES" ? (
                      ""
                    ) : headCell.label === "SURVEY_COUNT" ? (
                      ""
                    ) : (
                      <AvatarComp image={Arrow} height={15} />
                    )}
                  </Box>
                </>
              ) : null}
            </div>

            {/* <TableSortLabel
              // active={orderBy === headCell.id}
              active={false}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {headCell.label !== "" ? (
                <>
                  <Box sx={{ marginLeft: "5px", paddingTop: "4px" }}>
                    <AvatarComp image={Arrow} height={15} />
                  </Box>
                </>
              ) : null}

              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  const classes = useStyles();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Box className={classes.searchBox}>
          <Box sx={{ paddingTop: "4px" }}>
            <AvatarComp image={SearchBlack} />
          </Box>

          <InputBox placeholder="Search" onChange={props.onChange} />
        </Box>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const Client = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [search, setSearch] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [clientCount, setClientCount] = React.useState("All");
  // For CSV File
  const [csvData, setCsvData] = React.useState([]);
  const handlechangesearch = (e) => {
    setSearchText(e.target.value);
  };
  React.useEffect(() => {
    if (searchText !== "") {
      const request = {
        token: token,
        data: {
          query: searchText,
          status: clientCount,
          page_no: "page",
        },
        onSuccess: (res) => {
          setSearch(res?.data?.customer_list);
        },
        onFail: (res) => {
          setSearch([]);
        },
      };
      dispatch(getSearchCustomerList(request)); //chnges
    } else {
      // const abc = [];
      // setSearch(abc);
    }
  }, [searchText, clientCount]);

  const [opendrawer, setOpen] = React.useState(true);
  const [data, setData] = useState([]);

  const [maxrow, setMaxRow] = useState(10);
  const [searchPages, setSearchPages] = useState(1);
  const [pages, setPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(null);
  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    console.log("Pages:::", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPages(1);
    console.log("rowPerPage:::", rowsPerPage);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [name, setName] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);

  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [pagetable, setPagetable] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [selecteds, setSelecteds] = useState("");
  const [total, setTotal] = useState("");
  const [disable, setDisable] = useState("");
  const [active, setActive] = useState("");
  const [totalRecord, setTotalRecord] = React.useState("");

  const [rowId, setRowId] = useState(0);
  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [content, setContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [sortby, setSortBy] = useState("");
  const [sortorder, setSortOrder] = useState(false);

  const handleAlert = () => {
    setAlert(false);
  };

  const token = localStorage.getItem("token").toString();

  const getApicall = () => {
    const request = {
      token: token,
      data: {
        page: pages,
        max_rows: maxrow,
        sort_by: sortby,
        status: clientCount,
      },
      onSuccess: (res) => {
        if (res?.status === 200) {
          setData(res?.data?.customer_list);
          setActive(res?.data?.total_active_org);
          setDisable(res?.data?.total_inactive_org);
          setTotal(res?.data?.total_organizations);
          setTotalRecord(res?.data?.total_record_count);
          setPageCount(res?.data?.total_page_count);
          //  setAlertContent(res?.data?.message)
          // setAlertType("success");
          // setAlert(true);
        }
      },
      onFail: (error) => {
        // setIsLoading(false);
        if (error?.response?.status === 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          navigate("/login");
        } else {
          setAlertContent(error?.response?.data?.message);
          setAlertType("error");
          setAlert(true);
        }
      },
    };
    dispatch(getSortCustomerList(request));
  };
  React.useEffect(() => {
    getApicall();
  }, [pages, maxrow, sortby, clientCount]);

  const Reorder = (val) => {
    console.log("REORDERVAL::", val);
    // console.log("LOG:::",!sortorder );
    setSortOrder(!sortorder);

    if (val === "COMPANY" && sortorder) {
      setSortBy("-client_entity_name");
    }

    if (val === "CHRO_NAME" && !sortorder) {
      setSortBy("-chro_name");
    }
    if (val === "STATUS" && sortorder) {
      setSortBy("-status");
    }
    if (val === "LAST CHANGED" && !sortorder) {
      setSortBy("-modified_date");
    }
    if (val === "CREATED DATE" && sortorder) {
      setSortBy("-created_date");
    }
  };
  // useEffect(() => {
  //   const request = {
  //     token: token,
  //     data: {
  //       page: pages,
  //       max_rows: maxrow,
  //       sort_by: sortby,
  //     },
  //     onSuccess: (res) => {
  //       setData(res?.data?.customer_list);
  //       setSortBy(sortby);
  //     },
  //   };
  //   dispatch(getSortCustomerList(request)); //chnges
  // }, [pages, maxrow, sortby]);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickToOpenDialog = (rowId) => {
    setOpenDialog(true);
    console.log("open dialog");
    console.log(JSON.stringify(rowId));
    setFnameU(rowId.chro_name);
    // setLnameU(rowId.last_name);
    setEntity_nameU(rowId.client_entity_name);
    setEntity_typeU(rowId.entity_type);
    setEmployee_sizeU(rowId.employee_size);
    setBilling_addressU(rowId.billing_address);
    setCorporate_addressU(rowId.corporate_address);
    setGst_noU(rowId.gst_no);
    setPan_noU(rowId.pan_no);
    setChro_emailU(rowId.chro_email);
    setChro_mobile_numberU(rowId.chro_mobile_number);
    setEngagement_typeU(rowId.engagement_type);
    setLogo_uploadU(rowId.upload_client_logo);
    setIndustynameU(rowId.industry);
    setSubIndustynameU(rowId.sub_industry);
    //  rowId.spoc_details.map((val)=>{
    //   let obj = {...val,
    //   spoc_name:val.spoc_name,
    //   spoc_email:val.spoc_email,
    //   spoc_designation:val.spoc_designation,
    //   spoc_mobile_number:val.spoc_mobile_number
    //   }
    //   console.log("obj::",obj);

    //   setSopnameU(obj.spoc_name);
    //   setSopEmailU(obj.spoc_email);
    //   setSopDesU(obj.spoc_designation);
    //   setSopNumberU(obj.spoc_mobile_number);

    //  });
    setSopnameU(rowId.spoc_details);

    // setCname(rowId.chro_name);
    // setBnumber(rowId.chro_mobile_number);
    // setBaddress(rowId.billing_address);
    // setGstno(rowId.gst_no);
    // setPanno(rowId.pan_no);
  };

  const handleToCloseDialog = () => {
    setOpenDialog(false);
  };

  const [fnameU, setFnameU] = React.useState("");
  const [lnameU, setLnameU] = React.useState("");
  const [entity_nameU, setEntity_nameU] = React.useState("");
  const [entity_typeU, setEntity_typeU] = React.useState("");
  const [employee_sizeU, setEmployee_sizeU] = React.useState("");
  const [corporate_addressU, setCorporate_addressU] = React.useState("");
  const [billing_addressU, setBilling_addressU] = React.useState("");
  const [gst_noU, setGst_noU] = React.useState("");
  const [pan_noU, setPan_noU] = React.useState("");
  const [chro_emailU, setChro_emailU] = React.useState("");
  const [chro_mobile_numberU, setChro_mobile_numberU] = React.useState("");
  const [engagement_typeU, setEngagement_typeU] = React.useState("");
  const [logo_uploadU, setLogo_uploadU] = React.useState("");
  const [IndustynameU, setIndustynameU] = React.useState("");
  const [subIndustynameU, setSubIndustynameU] = React.useState("");
  // const [subindusrtydescriptionU, setSubIndustydescriptionU] = React.useState("");
  const [sopnameU, setSopnameU] = React.useState([]);
  //  const [sopDetails,setSopDetails]=React.useState([])
  const updateApicall = (id, spoc, newState) => {
    const finaldata = {
      chro_name: fnameU,
      // last_name: lnameU,
      client_entity_name: entity_nameU,
      entity_type: entity_typeU,
      employee_size: employee_sizeU,
      corporate_address: corporate_addressU,
      billing_address: billing_addressU,
      gst_no: gst_noU,
      pan_no: pan_noU,
      role: "Client",
      chro_email: chro_emailU,
      chro_mobile_number: chro_mobile_numberU,
      engagement_type: engagement_typeU,
      industry: IndustynameU,
      sub_industry: subIndustynameU,
      spoc: spoc,
      logo: logo_uploadU,
    };
    const request = {
      token: token,
      id: id,
      data: finaldata,
      onSuccess: (res) => {
        if (res?.status === 200) {
          // console.log("Update res:::", res);

          setAlertContent(res?.data?.message);
          setAlertType("success");
          setAlert(true);
          getApicall();
          handleToCloseDialog();
        }
      },
      onFail: (error) => {
        // setIsLoading(false);
        if (error?.response?.status === 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          navigate("/login");
        } else {
          setAlertContent(error);
          setAlertType("error");
          setAlert(true);
        }
      },
      // onFail: (error) => {
      //   setAlertContent(error?.response?.data?.message);
      //   setAlertType("error");
      //   setAlert(true);
      //   if (error?.response?.status === 400) {
      //     // console.log("bad request");
      //     // Alert.alert(strings.error, error?.response?.data?.message);
      //   }
      // },
    };
    dispatch(updateCustomerList(request));
  };

  const handleClickToOpenCreateDialog = () => {
    setOpenCreateDialog(true);
    setFname("");
    setLname("");
    setEntity_name("");
    setEntity_type("");
    setEmployee_size("");
    setCorporate_address("");
    setBilling_address("");
    setGst_no("");
    setPan_no("");
    setChro_email("");
    setChro_mobile_number("");
    setEngagement_type("");
    setLogo_Upload("");
    setIndustyname("");
    setSubIndustydescription("");
    setSopname("");
    setSopDes("");
    setSopEmail("");
    setSubIndustynameU("");
  };

  const handleToCloseCreateDialog = () => {
    setOpenCreateDialog(false);
  };

  const [fname, setFname] = React.useState("");
  const [lname, setLname] = React.useState("");
  const [entity_name, setEntity_name] = React.useState("");
  const [entity_type, setEntity_type] = React.useState("");
  const [employee_size, setEmployee_size] = React.useState("");
  const [corporate_address, setCorporate_address] = React.useState("");
  const [billing_address, setBilling_address] = React.useState("");
  const [gst_no, setGst_no] = React.useState("");
  const [pan_no, setPan_no] = React.useState("");
  const [chro_email, setChro_email] = React.useState("");
  const [chro_mobile_number, setChro_mobile_number] = React.useState("");
  const [engagement_type, setEngagement_type] = React.useState("");
  const [logo_upload, setLogo_Upload] = React.useState("");

  const [Industyname, setIndustyname] = React.useState("");
  const [indusrtydescription, setIndustydescription] = React.useState("");
  const [subIndustyname, setSubIndustyname] = React.useState("");
  const [subindusrtydescription, setSubIndustydescription] = React.useState("");
  const [sopname, setSopname] = React.useState("");
  const [sopDes, setSopDes] = React.useState("");
  const [sopemail, setSopEmail] = React.useState("");
  const [sopNumber, setSopNumber] = React.useState("");

  const createApiCall = (spoc, newState) => {
    // console.log("indusrty::::", industry);
    const finalData = {
      first_name: fname,
      last_name: lname,
      client_entity_name: entity_name,
      entity_type: entity_type,
      employee_size: employee_size,
      corporate_address: corporate_address,
      billing_address: billing_address,
      gst_no: gst_no,
      pan_no: pan_no,
      chro_email: chro_email,
      chro_mobile_number: chro_mobile_number,
      engagement_type: engagement_type,
      industry: Industyname,
      sub_industry: subIndustyname,
      spoc: spoc,
      logo: logo_upload,
    };
    console.log("Data ::--", spoc);
    const request = {
      token: token,
      data: finalData,
      onSuccess: (res) => {
        if (res?.status === 200) {
          setAlertContent(res?.data?.message);
          setAlertType("success");
          setAlert(true);
          getApicall();
          handleToCloseCreateDialog();
        }
      },
      onFail: (error) => {
        console.log("error::", error?.response?.data?.message);
        // setIsLoading(false);
        // if (error?.response?.status === 401) {
        //   setAlertContent("Token is invalid or expired.");
        //   setAlertType("error");
        //   setAlert(true);
        //   navigate("/login");
        // }
        // if {
        setAlertContent(error?.response?.data?.message);
        // setContent(error?.response?.data?.message);
        setAlertType("error");
        setAlert(true);
        // }
      },
      // onFail: (error) => {
      //   setAlertContent(error?.response?.data?.message);
      //   setAlertType("error");
      //   setAlert(true);
      //   // handleToCloseCreateDialog();
      //   if (error?.response?.status === 400) {
      //     // console.log("bad request");
      //   }
      // },
    };
    dispatch(createCustomerList(request));
  };

  const downloadCsv = async (orgId) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await API.get(
      `/app/secure/export_orgnization_details?org_id=${orgId}`,
      config
    )
      .then((res) => {
        setCsvData(res.data.link);
        FileSaver.saveAs(res.data.link, "csvFile");
        handleCloseMenu();
        // getApicall();
        // console.log("data", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteApiCall = (id, newState) => {
    const request = {
      token: token,
      id: id,
      onSuccess: (res) => {
        if (res?.status === 200) {
          // console.log("Delete res:::", res);

          setAlertContent(res?.data?.message);
          setAlertType("success");
          setAlert(true);
          getApicall();
        }
      },
      onFail: (error) => {
        // setIsLoading(false);
        if (error?.response?.status === 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          navigate("/login");
        } else {
          setAlertContent(error?.response?.data?.message);
          setAlertType("error");
          setAlert(true);
        }
      },
      // onFail: (error) => {
      //   setAlertContent(error?.response?.data?.message);
      //   setAlertType("error");
      //   setAlert(true);
      //   if (error?.response?.status === 400) {
      //     // console.log("bad request");
      //     // Alert.alert(strings.error, error?.response?.data?.message);
      //   }
      // },
    };
    dispatch(deleteCustomerList(request));
    handleCloseMenu();
  };

  const selectionChangeHandler = (event) => {
    setSelecteds(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    pagetable > 0
      ? Math.max(0, (1 + pagetable) * rowsPerPage - data.length)
      : 0;
  const sortbyFunction = (val) => {
    console.log("val::", val);
    if (val === "COMPANY") {
      setSortBy("client_entity_name");
    }
    if (val === "CHRO_NAME") {
      setSortBy("chro_name");
    }
    if (val === "STATUS") {
      setSortBy("status");
    }
    if (val === "LAST CHANGED") {
      setSortBy("modified_date");
    }
    if (val === "CREATED DATE") {
      setSortBy("created_date");
    }
  };

  //logo upload
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    setLogo_Upload(base64.replace(/^data:image\/[a-z]+;base64,/, ""));
  };

  //upfate logo

  const handleFileReadU = async (event) => {
    // const file = event.target.files[0];
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    setLogo_uploadU(base64.replace(/^data:image\/[a-z]+;base64,/, ""));
  };

  return (
    <Permission menu="Clients">
      <Header />
      <div style={{ display: "flex" }}>
        <LeftDrawer open={opendrawer} />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          style={{ background: "#EEEEEE" }}
        >
          {/* <Loader open={surveyRes.loader} /> */}
          {/*<DrawerHeader/>*/}
          <>
            {openCreateDialog ? (
              <AddClient
                setOpenCreateDialog={setOpenCreateDialog}
                label1="First Name of Client"
                label2="Last Name of Client"
                label3="Client Entity Name"
                label4="Entity Type"
                label5="Employee Size"
                label6="Corporate Address"
                label7="Billing Address"
                label8="GST/TAX ID"
                label9="PAN No"
                // label10="role"
                label11="CHRO Email"
                label12="CHRO Mobile Number"
                label13="Engagement Type"
                // label14="Logo Upload"
                labelIndustry="Industry Name"
                label17="Industry Description"
                labelsubIndustry="Sub Industry Name"
                label19="Sub Industry Description"
                label20="Spoc Name"
                label21="Spoc Designation"
                label22="Spoc Email"
                label23="Spoc Mobile Number"
                value1={fname}
                onChange1={(e) => setFname(e.target.value)}
                value2={lname}
                onChange2={(e) => setLname(e.target.value)}
                value3={entity_name}
                onChange3={(e) => setEntity_name(e.target.value)}
                value4={entity_type}
                onChange4={(e) => setEntity_type(e.target.value)}
                value5={employee_size}
                onChange5={(e) => setEmployee_size(e.target.value)}
                value6={corporate_address}
                onChange6={(e) => setCorporate_address(e.target.value)}
                value7={billing_address}
                onChange7={(e) => setBilling_address(e.target.value)}
                value8={gst_no}
                onChange8={(e) => setGst_no(e.target.value)}
                value9={pan_no}
                onChange9={(e) => setPan_no(e.target.value)}
                // value10={role}
                // onChange10={(e) => setRole(e.target.value)}
                value11={chro_email}
                onChange11={(e) => setChro_email(e.target.value)}
                value12={chro_mobile_number}
                onChange12C={(e) => setChro_mobile_number(e)}
                value13={engagement_type}
                onChange13={(e) => setEngagement_type(e.target.value)}
                // value14={logo_upload}
                onChange14={(e) => handleFileRead(e)}
                valueIndustry={Industyname}
                onChangeIndustry={(e) => setIndustyname(e.target.value)}
                value17={indusrtydescription}
                onChange17={(e) => setIndustydescription(e.target.value)}
                valuesubIndustry={subIndustyname}
                onChangesubIndustry={(e) => setSubIndustyname(e.target.value)}
                value19={subindusrtydescription}
                onChange19={(e) => setSubIndustydescription(e.target.value)}
                value20={sopname}
                onChange20={(e) => setSopname(e.target.value)}
                value21={sopDes}
                onChange21={(e) => setSopDes(e.target.value)}
                value22={sopemail}
                onChange22={(e) => setSopEmail(e.target.value)}
                value23={sopNumber}
                onChange23={(e) => setSopNumber(e.target.value)}
                onClick={(spoc) => createApiCall(spoc)}
                // content={content}
                alertContent={alertContent}
                alert={alert}
                alertType={alertType}
                handleAlert={handleAlert}
              />
            ) : openDialog ? (
              <UpdateClient
                setOpenDialog={() => setOpenDialog()}
                // label1="Business Name"
                // label2="Contact Name"
                // label3="Business Phone_Number"
                // label4="Business Address"
                // label5="gst no"
                // label6="pan no"
                // value1={bname}
                // onChange1={(e) => setBname(e.target.value)}
                // value2={cname}
                // onChange2={(e) => setCname(e.target.value)}
                // value3={bnumber}
                // onChange3={(e) => setBnumber(e.target.value)}
                // value4={baddress}
                // onChange4={(e) => setBaddress(e.target.value)}
                // value5={gstno}
                // onChange5={(e) => setGstno(e.target.value)}
                // value6={panno}
                // onChange6={(e) => setPanno(e.target.value)}
                label1="CHRO Name"
                // label2="Last Name of Client"
                label3="Client Entity Name"
                label4="Entity Type"
                label5="Employee Size"
                label6="Corporate Address"
                label7="Billing Address"
                label8="GST/TAX ID"
                label9="PAN No"
                // label10="role"
                label11="CHRO Email"
                label12="CHRO Mobile Number"
                label13="Engagement Type"
                // label14="Logo Upload"
                labelIndustry="Industry Name"
                label17="industry Description"
                labelsubIndustry="Sub Industry Name"
                label19="Sub Industry Description"
                value1={fnameU}
                onChange1={(e) => setFnameU(e.target.value)}
                // value2={lnameU}
                // onChange2={(e) => setLnameU(e.target.value)}
                value3={entity_nameU}
                onChange3={(e) => setEntity_nameU(e.target.value)}
                value4={entity_typeU}
                onChange4={(e) => setEntity_typeU(e.target.value)}
                value5={employee_sizeU}
                onChange5={(e) => setEmployee_sizeU(e.target.value)}
                value6={corporate_addressU}
                onChange6={(e) => setCorporate_addressU(e.target.value)}
                value7={billing_addressU}
                onChange7={(e) => setBilling_addressU(e.target.value)}
                value8={gst_noU}
                onChange8={(e) => setGst_noU(e.target.value)}
                value9={pan_noU}
                onChange9={(e) => setPan_noU(e.target.value)}
                // value10={roleU}
                // onChange10={(e) => setRoleU(e.target.value)}
                value11={chro_emailU}
                onChange11={(e) => setChro_emailU(e.target.value)}
                value12={chro_mobile_numberU}
                onChange12={(e) => setChro_mobile_numberU(e)}
                value13={engagement_typeU}
                onChange13={(e) => setEngagement_typeU(e.target.value)}
                // value14={logo_uploadU}
                onChange14={(e) => handleFileReadU(e)}
                valueIndustry={IndustynameU}
                onChangeIndustry={(e) => setIndustynameU(e.target.value)}
                valuesubIndustry={subIndustynameU}
                onChangesubIndustry={(e) => setSubIndustynameU(e.target.value)}
                spocDetails={sopnameU}
                onClick={(spoc) => updateApicall(rowId.org_id, spoc)}
              />
            ) : (
              <Box sx={{ backgroundColor: "#EEEEEE" }}>
                {/* <Typography
                sx={{
                  marginTop: "10px",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "900",
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#0B0B0C",
                }}
              >
                Back
              </Typography> */}
                <Typography
                  sx={{
                    marginTop: "15px",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "20px",
                    lineHeight: "25px",
                    color: "#0B0B0C",
                  }}
                >
                  List of Clients
                </Typography>
                <Grid container>
                  {/* <Grid container sx={{ width: "95%" }}> */}
                  <Box>
                    <ButtonGroup
                      variant="text"
                      size="large"
                      color="inherit"
                      sx={{ marginTop: "12px" }}
                    >
                      <Button
                        sx={{
                          backgroundColor:
                            clientCount === "All" ? "rgb(193,193,193)" : "",
                          textTransform: "none",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          ":hover": {
                            fontWeight: 700,
                          },
                        }}
                        onClick={() => {
                          setClientCount("All");
                        }}
                      >
                        {`All (${total})`}
                        {/* All(20) */}
                      </Button>
                      <Button
                        sx={{
                          backgroundColor:
                            clientCount === "Inactive"
                              ? "rgb(193,193,193)"
                              : "",
                          textTransform: "none",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          ":hover": {
                            fontWeight: 700,
                          },
                        }}
                        onClick={() => {
                          setClientCount("Inactive");
                        }}
                      >
                        {`Disabled (${disable})`}
                        {/* Disable(05) */}
                      </Button>
                      <Button
                        sx={{
                          backgroundColor:
                            clientCount === "Active" ? "rgb(193,193,193)" : "",
                          textTransform: "none",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          ":hover": {
                            fontWeight: 700,
                          },
                        }}
                        onClick={() => {
                          setClientCount("Active");
                        }}
                      >
                        {`Active (${active})`}
                        {/* Draf(02) */}
                      </Button>
                    </ButtonGroup>
                  </Box>

                  <Button
                    color="primary"
                    variant="contained"
                    sx={{
                      bgcolor: "#2D404E",
                      padding: "10px",
                      borderRadius: "20px",
                      width: "200px",
                      height: "36px",
                      marginLeft: "auto",
                      marginTop: 2,
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                    }}
                    onClick={handleClickToOpenCreateDialog}
                  >
                    Create Client
                  </Button>
                </Grid>
                <Box sx={{ width: "100%" }}>
                  <Paper sx={{ width: "100%", mb: 2, marginTop: "20px" }}>
                    <EnhancedTableToolbar
                      numSelected={selected.length}
                      onChange={handlechangesearch}
                    />
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 750, padding: 5 }}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                      >
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={data.length}
                          setSortBy={sortbyFunction}
                          setSortOrder={Reorder}
                        />

                        <TableBody>
                          {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                          {(searchText &&
                            (search?.length > 0 ? (
                              <>
                                {search.map((row, index) => {
                                  return (
                                    <TableRow
                                      key={index}
                                      hover
                                      // onClick={(event) => handleClick(event, row.name)}
                                      role="checkbox"
                                      // aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      // key={row.name}
                                      // selected={isItemSelected}
                                    >
                                      <TableCell padding="checkbox">
                                        {/* <Checkbox
                                        color="primary"
                                        // checked={isItemSelected}
                                        inputProps={
                                          {
                                            // "aria-labelledby": labelId,
                                          }
                                        }
                                      /> */}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        // id={labelId}
                                        scope="row"
                                        padding="none"
                                        onClick={() =>
                                          navigate(
                                            `/Client/clientTabScreen/${row.org_id}`
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {row.client_entity_name}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.chro_name}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.survey_count}
                                      </TableCell>
                                      <TableCell align="center">
                                        {row.employee_count}
                                      </TableCell>{" "}
                                      <TableCell align="left">
                                        {moment(row.created_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.status}
                                      </TableCell>
                                      <TableCell align="left">
                                        {moment(row.modified_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                        {/* 4 hours 13 min ago */}
                                      </TableCell>
                                      <TableCell align="left">
                                        <Box
                                          justifyContent="center"
                                          flexDirection="row"
                                          display="flex"
                                          alignItem="center"
                                          sx={{ margin: "7px" }}
                                        >
                                          <IconButton
                                            onClick={(e) => {
                                              handleClickMenu(e);
                                              setRowId(row);
                                            }}
                                          >
                                            <MoreVertRoundedIcon
                                              width={20}
                                              height={20}
                                            />
                                          </IconButton>
                                        </Box>

                                        <Menu
                                          // className={classes.menu}
                                          id={`simple-menu-${rowId}`}
                                          anchorEl={anchorEl}
                                          keepMounted
                                          open={Boolean(anchorEl)}
                                          onClose={handleCloseMenu}
                                          elevation={0}
                                        >
                                          <MenuItem
                                            onClick={() =>
                                              downloadCsv(rowId.org_id)
                                            }
                                          >
                                            Download
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              setAnchorEl(null);
                                              handleClickToOpenDialog(rowId);
                                            }}
                                          >
                                            Update
                                          </MenuItem>
                                          {clientCount === "All" ? null : (
                                            <>
                                              {row.status === "Inactive" ? (
                                                <MenuItem
                                                  onClick={() =>
                                                    deleteApiCall(rowId.org_id)
                                                  }
                                                >
                                                  Enable
                                                  <LockOpenIcon
                                                    style={{
                                                      height: "20px",
                                                      marginLeft: "10px",
                                                    }}
                                                  />
                                                </MenuItem>
                                              ) : (
                                                <MenuItem
                                                  onClick={() =>
                                                    deleteApiCall(
                                                      rowId.employee_id
                                                    )
                                                  }
                                                >
                                                  Disable
                                                  <LockIcon
                                                    style={{
                                                      height: "20px",
                                                      marginLeft: "10px",
                                                    }}
                                                  />
                                                </MenuItem>
                                              )}
                                            </>
                                          )}
                                        </Menu>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </>
                            ) : (
                              <TableRow>
                                <TableCell
                                  align="center"
                                  colSpan={12}
                                  sx={{ py: 3 }}
                                >
                                  <SearchNotFound />
                                </TableCell>
                              </TableRow>
                            ))) || (
                            <>
                              {data.map((row, index) => {
                                return (
                                  <TableRow
                                    key={index}
                                    hover
                                    // onClick={(event) => handleClick(event, row.name)}
                                    role="checkbox"
                                    // aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    // key={row.name}
                                    // selected={isItemSelected}
                                  >
                                    <TableCell padding="checkbox">
                                      {/* <Checkbox
                                        color="primary"
                                        // checked={isItemSelected}
                                        inputProps={
                                          {
                                            // "aria-labelledby": labelId,
                                          }
                                        }
                                      /> */}
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="none"
                                      onClick={() =>
                                        navigate(
                                          `/Client/clientTabScreen/${row.org_id}`
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {row.client_entity_name}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.chro_name}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.survey_count}
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.employee_count}
                                    </TableCell>
                                    <TableCell align="left">
                                      {moment(row.created_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </TableCell>

                                    <TableCell align="left">
                                      {row.status}
                                    </TableCell>
                                    <TableCell align="left">
                                      {moment(row.modified_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                      {/* 4 hours 13 min ago */}
                                    </TableCell>

                                    <TableCell align="left">
                                      <Box
                                        justifyContent="center"
                                        flexDirection="row"
                                        display="flex"
                                        alignItem="center"
                                        sx={{ margin: "7px" }}
                                      >
                                        <IconButton
                                          onClick={(e) => {
                                            handleClickMenu(e);
                                            setRowId(row);
                                          }}
                                        >
                                          <MoreVertRoundedIcon
                                            width={20}
                                            height={20}
                                          />
                                        </IconButton>
                                      </Box>

                                      <Menu
                                        // className={classes.menu}
                                        id={`simple-menu-${rowId}`}
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleCloseMenu}
                                        elevation={0}
                                      >
                                        <MenuItem
                                          onClick={() =>
                                            downloadCsv(rowId.org_id)
                                          }
                                        >
                                          Download
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() => {
                                            setAnchorEl(null);
                                            handleClickToOpenDialog(rowId);
                                          }}
                                        >
                                          Update
                                        </MenuItem>
                                        {clientCount === "All" ? null : (
                                          <>
                                            {row.status === "Inactive" ? (
                                              <MenuItem
                                                onClick={() =>
                                                  deleteApiCall(rowId.org_id)
                                                }
                                              >
                                                Enable
                                                <LockOpenIcon
                                                  style={{
                                                    height: "20px",
                                                    marginLeft: "10px",
                                                  }}
                                                />
                                              </MenuItem>
                                            ) : (
                                              <MenuItem
                                                onClick={() =>
                                                  deleteApiCall(rowId.org_id)
                                                }
                                              >
                                                Disable
                                                <LockIcon
                                                  style={{
                                                    height: "20px",
                                                    marginLeft: "10px",
                                                  }}
                                                />
                                              </MenuItem>
                                            )}
                                          </>
                                        )}
                                      </Menu>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </>
                          )}
                          {emptyRows > 0 && (
                            <TableRow
                              style={{
                                height: (dense ? 38 : 38) * emptyRows,
                              }}
                            >
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <Pagination count={10} shape="rounded" /> */}
                    <Grid container spacing={2} sx={{ padding: "10px" }}>
                      <Grid item xs={7}>
                        <Box
                          display="flex"
                          alignItems={"center"}
                          flexDirection="row"
                        >
                          <Typography
                            sx={{
                              fontFamily: "Montserrat",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                            }}
                          >
                            Row Per Page
                          </Typography>
                          <FormControl
                            variant="standard"
                            sx={{ m: 0, minWidth: 50 }}
                          >
                            {/* <InputLabel
                            style={{
                              marginLeft: "20px",
                              position: "absolute",
                              marginTop: "20px",
                            }}
                          >
                            {selecteds === "" ? 10 : selected}
                          </InputLabel> */}
                            <Select
                              size="small"
                              value={selecteds}
                              onChange={selectionChangeHandler}
                              disableUnderline
                              style={{
                                textAlign: "center",
                                fontSize: "14px",
                                paddingLeft: 10,
                                paddingTop: 8,
                                ":before": {
                                  borderColor: "#fafafc",
                                },
                                ":after": {
                                  borderColor: "#fafafc",
                                },
                                height: 30,
                                justifyContent: "center",
                              }}
                              //   SelectProps={{ disableUnderline: true }}
                            >
                              <MenuItem
                                value={1}
                                onClick={() => {
                                  setMaxRow(10);
                                }}
                              >
                                10
                              </MenuItem>
                              <MenuItem
                                value={2}
                                onClick={() => {
                                  setMaxRow(20);
                                }}
                              >
                                20
                              </MenuItem>
                              <MenuItem
                                value={3}
                                onClick={() => {
                                  setMaxRow(50);
                                }}
                              >
                                50
                              </MenuItem>
                              <MenuItem
                                value={4}
                                onClick={() => {
                                  setMaxRow(100);
                                }}
                              >
                                100
                              </MenuItem>
                            </Select>
                          </FormControl>
                          {/* <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "13px",
                            lineHeight: "20px",
                          }}
                        >
                          of {totalRecord}
                        </Typography> */}
                        </Box>
                      </Grid>
                      <Grid item xs={4} sx={{ marginLeft: "40px" }}>
                        <Pagination
                          style={{ marginTop: "2%", marginLeft: "5%" }}
                          onChange={handlePageChange}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          page={pages}
                          rowsPerPage={rowsPerPage}
                          count={pageCount || 0}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>

                <Notification
                  message={
                    alertContent
                    // ? alertContent
                    // : `Success : Data Fetched Successfully.`
                  }
                  open={alert}
                  severity={alertType}
                  onClose={handleAlert}
                />
              </Box>
            )}
          </>{" "}
        </Box>
      </div>
    </Permission>
  );
};

export default Client;
