import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import Constants, {
  ONLY_BLANK_SPACES,
  PERMISSIONS_SELECTED,
  VALIDATE_NAME,
} from "../../../Utils/Constants";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
// import axios from "axios";
import UtilHelper from "../../../Utils/UtilHelper";
import Resource from "./Resource";
import API from "../../../E2E/Axios.Utils";

export default function AddRoleCard({
  handleDialog,
  update,
  setUpdate,
  submitFormData,
  isUpdate,
  formData,
  setAlert,
  setAlertContent,
  setAlertType,
  onDiscardDialog,
  getAllResource,
}) {
  const [role, setRole] = useState({
    role_name: "",
    permissions: [],
    is_admin_role: false,
    download_permission: false,
  });
  const [resources, setResources] = useState([]);
  const [formUpdated, setFormUpdated] = useState(false);

  const token = localStorage.getItem("token").toString();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    API.get("/app/secure/menu_submenu_list", config)
      .then((res) => {
        setResources(res.data.list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (isUpdate && formData) {
      setRole(JSON.parse(JSON.stringify(formData)));
    }
  }, [isUpdate, formData]);

  const handleChangeInput = (event, value) => {
    const data = { ...role };
    data[event.target.name] = data[event.target.name] =
      event.target.name === "is_admin_role" ||
      event.target.name === "download_permission"
        ? event.target.checked
        : event.target.value;

    setRole(data);
    if (UtilHelper.isFormUpdated(data, formData)) {
      setFormUpdated(true);
    } else {
      setFormUpdated(false);
    }
  };

  const handleSubmit = () => {
    const data = UtilHelper.trimObject({ ...role });
    if (isUpdate) {
      submitFormData(data);
    } else {
      API.post("/app/secure/add_role", data, config)
        .then((res) => {
          setResources(res.data.list);
          handleDialog();
          setUpdate(update + 1);
          setAlertContent(res.data.message);
          setAlertType("success");
          setAlert(true);
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            setAlertContent("Token is invalid or expired.");
            setAlertType("error");
            setAlert(true);
          } else {
            setAlertContent(err.data.message);
            // setAlertContent(`Error : Something went wrong`);
            setAlertType("error");
            setAlert(true);
          }
        });
    }
  };

  const onCheck = (checkBoxValue) => {
    const data = UtilHelper.onCheckField(checkBoxValue, { ...role }, resources);

    setRole(data);
    if (UtilHelper.isFormUpdated(data, formData)) {
      setFormUpdated(true);
    } else {
      setFormUpdated(false);
    }
  };

  useEffect(() => {
    ValidatorForm.addValidationRule(
      ONLY_BLANK_SPACES,
      UtilHelper.validateBlankSpaces
    );
    ValidatorForm.addValidationRule(VALIDATE_NAME, UtilHelper.validateName);
    ValidatorForm.addValidationRule(
      PERMISSIONS_SELECTED,
      UtilHelper.validatePermissions
    );

    return () => {
      // remove rule from form validator when it is not needed
      ValidatorForm.removeValidationRule(ONLY_BLANK_SPACES);
      ValidatorForm.removeValidationRule(VALIDATE_NAME);
      ValidatorForm.removeValidationRule(PERMISSIONS_SELECTED);
    };
  }, []);

  return (
    <ValidatorForm onSubmit={handleSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
          <TextValidator
            fullWidth
            required
            variant="outlined"
            size="small"
            label="Name"
            onChange={handleChangeInput}
            name="role_name"
            value={role.role_name || ""}
            validators={[
              "required",
              ONLY_BLANK_SPACES,
              VALIDATE_NAME,
              "maxStringLength:" +
                Constants.VALIDATIONS.MAXIMUM_CHARACTERS_TEXT_FIELD,
            ]}
            errorMessages={[
              Constants.FIELD_IS_REQUIRED,
              Constants.BLANK_SPACES_NOT_ALLOWED,
              Constants.ENTER_VALID_NAME,
              Constants.MAXIMUM_ALLOWED_CHARACTERS_FOR_TEXT_FIELD,
            ]}
          />
        </Grid>
        {/* <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
        > */}
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={role.is_admin_role}
                onChange={handleChangeInput}
                name="is_admin_role"
                color="primary"
              />
            }
            label="Admin Role"
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={role.download_permission}
                onChange={handleChangeInput}
                name="download_permission"
                color="primary"
              />
            }
            label="Download Permission"
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h6" className="margin-bottom-16">
            Permissions
          </Typography>
          <Grid container spacing={2}>
            {resources?.length > 0 &&
              resources.map((resource, index) => (
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="resource-container"
                >
                  <Resource
                    resource={resource}
                    rolePermission={role.permissions}
                    onCheck={(checkBoxValue) => onCheck(checkBoxValue)}
                  />
                </Grid>
              ))}
          </Grid>

          <TextValidator
            className="permission-list-input"
            fullWidth
            required
            value={role.permissions.length}
            validators={[PERMISSIONS_SELECTED]}
            errorMessages={[Constants.PERMISSION_REQUIRED]}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{ display: "flex", justifyContent: "right" }}>
          <Button
            type="submit"
            color="primary"
            variant="outlined"
            disabled={!formUpdated}
            className="invite-add-btn text-transform-capitalize"
          >
            {isUpdate ? "Update" : "Add"}
          </Button>

          <Button
            color="primary"
            variant="text"
            className="cancel-btn text-transform-capitalize"
            onClick={handleDialog}
          >
            Discard
          </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}
