import * as React from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  usePagination,
  Grid,
  FormControl,
  Select,
  Dialog,
  DialogContent,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchNotFound from "../../../../../../Utils/SearchNotFound/SearchNotFount";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { Avatar, Tooltip, Typography } from "@mui/material";
import CustomModel from "../../../../../../Utils/CustomModel/CustomModel";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
// import UpdateRoleCard from "./UpdateRoleCard";
import { useNavigate } from "react-router";
// import UpdateTeamForm from "./UpdateTeam/UpdateTeamForm";
import API from "../../../../../../E2E/Axios.Utils";
import InputLabel from "@material-ui/core/InputLabel";
import SendIcon from "@mui/icons-material/Send";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BatchTableResponse from "./BatchTableResponse";

const headCells = [
  {
    id: "Name",
    numeric: false,
    disablePadding: true,
    label: "NAME",
  },
  {
    id: "Survey Status",
    numeric: true,
    disablePadding: false,
    label: "SURVEY STATUS",
  },
  {
    id: "user_status",
    numeric: true,
    disablePadding: false,
    label: "USER STATUS",
  },
  {
    id: "survey_submit_date",
    numeric: true,
    disablePadding: false,
    label: "SURVEY SUBMIT DATE",
  },
  {
    id: "survey_start_date",
    numeric: true,
    disablePadding: false,
    label: "SURVEY START DATE",
  },
  {
    id: "survey_end_date",
    numeric: true,
    disablePadding: false,
    label: "SURVEY END DATE",
  },
  {
    id: "ACTIONS",
    numeric: true,
    disablePadding: false,
    label: "ACTIONS",
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sx={{ fontWeight: "bold", bgcolor: "#128CB0", color: "white" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  search,
  update,
  setUpdate,
  setAlert,
  setAlertType,
  setAlertContent,
  setToggleEditTeam,
  // setEditUserData,
  setOveralldata,
  setActive,
  setInactive,
  surveyScheduleID,
}) {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState([]);
  // const [page, setPage] = React.useState(0);
  const [pages, setPages] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [usersData, setUsersData] = React.useState([]);
  const [usersSearch, setUsersSearch] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(null);
  const [maxrow, setMaxRow] = React.useState(10);
  const [selecteds, setSelecteds] = React.useState("");
  const [totalRecord, setTotalRecord] = React.useState("");
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    console.log("Pages:::", newPage);
  };

  const handleAlert = () => {
    setAlert(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPages(1);
    console.log("rowPerPage:::", rowsPerPage);
  };
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const token = localStorage.getItem("token").toString();

  const getUsersData = () => {
    setLoading(true);
    API.get(
      `/app/secure/get_survey_user?survey_schedule_id=${surveyScheduleID}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        console.log("resUserCardDadara", res);
        setUsersData(res.data.userlist);
        // setAlertContent(res.data.message);
        // setAlertType("success");
        // setAlert(true)
        setLoading(false);
      })
      .catch((err) => {
        // console.log("err", err);
        if (err?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          setLoading(false);
          navigate("/login");
        } else {
          setAlertContent(err.data.message);
          setAlertType("error");
          setAlert(true);
          setLoading(false);
        }
      });
  };

  React.useEffect(() => {
    getUsersData();
  }, [update, pages, maxrow]);

  const selectionChangeHandler = (event) => {
    setSelecteds(event.target.value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ mb: 2 }}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="Companies Table"
            sx={{ minWidth: 750 }}
          >
            <EnhancedTableHead />

            {
              <TableBody>
                {usersData.map((user) => {
                  const {
                    employee_id,
                    survey_schedule_id,
                    status,
                    modified_date,
                  } = user;

                  return (
                    <TableRow hover tabIndex={-1} key={employee_id.employee_id}>
                      <TableCell align="center">
                        {employee_id.first_name_of_the_employee}
                      </TableCell>
                      <TableCell align="center">
                        {survey_schedule_id.status}
                      </TableCell>
                      <TableCell align="center">{status}</TableCell>
                      <TableCell align="center">
                        {status == "Pending"
                          ? "Not available"
                          : moment(modified_date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {moment(survey_schedule_id.start_date).format(
                          "DD-MM-YYYY"
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {moment(survey_schedule_id.end_date).format(
                          "DD-MM-YYYY"
                        )}
                      </TableCell>
                      {/* <TableCell align="center">
                        {moment(created_date).format("DD-MM-YYYY")}
                      </TableCell> */}
                      <TableCell align="center">
                        <TeamMoreMenu
                          // setEditUserData={setEditUserData}
                          surveyScheduleID={
                            survey_schedule_id.survey_schedule_id
                          }
                          employeeId={[employee_id.employee_id]}
                          details={user}
                          setAlert={setAlert}
                          setAlertType={setAlertType}
                          setAlertContent={setAlertContent}
                          update={update}
                          setUpdate={setUpdate}
                          // setToggleEditTeam={setToggleEditTeam}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            }
          </Table>
        </TableContainer>

        <Grid container spacing={2} sx={{ padding: "10px" }}>
          <Grid item xs={7}>
            <Box display="flex" alignItems={"center"} flexDirection="row">
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                Row per Page
              </Typography>
              <FormControl variant="standard" sx={{ m: 0, minWidth: 50 }}>
                <InputLabel
                  style={{
                    marginLeft: "20px",
                    position: "absolute",
                    marginTop: "20px",
                  }}
                >
                  {selecteds === "" ? 10 : selected}
                </InputLabel>
                <Select
                  size="small"
                  value={selecteds}
                  onChange={selectionChangeHandler}
                  disableUnderline
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    paddingLeft: 10,
                    paddingTop: 8,
                    ":before": {
                      borderColor: "#fafafc",
                    },
                    ":after": {
                      borderColor: "#fafafc",
                    },
                    height: 30,
                    justifyContent: "center",
                  }}
                  //   SelectProps={{ disableUnderline: true }}
                >
                  <MenuItem
                    value={1}
                    onClick={() => {
                      setMaxRow(10);
                    }}
                  >
                    10
                  </MenuItem>
                  <MenuItem
                    value={2}
                    onClick={() => {
                      setMaxRow(20);
                    }}
                  >
                    20
                  </MenuItem>
                  <MenuItem
                    value={3}
                    onClick={() => {
                      setMaxRow(50);
                    }}
                  >
                    50
                  </MenuItem>
                  <MenuItem
                    value={4}
                    onClick={() => {
                      setMaxRow(100);
                    }}
                  >
                    100
                  </MenuItem>
                </Select>
              </FormControl>
              {/* <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "13px",
                  lineHeight: "20px",
                }}
              >
                of {totalRecord}
              </Typography> */}
            </Box>
          </Grid>
          <Grid item xs={4} sx={{ marginLeft: "40px" }}>
            <Pagination
              style={{ marginTop: "2%", marginLeft: "5%" }}
              onChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={pages}
              rowsPerPage={rowsPerPage}
              count={pageCount || 0}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

// ***************************************************
function TeamMoreMenu({
  currentDetails,
  setEditUserData,
  details,
  surveyScheduleID,
  employeeId,
  setAlert,
  setAlertType,
  setAlertContent,
  update,
  setUpdate,
  setToggleEditTeam,
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [openEdit, setEditOpen] = React.useState(false);
  const [teamId, setTeamId] = React.useState(details.team_id);

  const handleDialog = () => {
    setEditOpen(!openEdit);
  };

  const token = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleReminder = () => {
    const data = {
      survey_schedule_id: surveyScheduleID,
      employee_ids: employeeId,
    };

    API.post("/app/secure/reminder_for_survey", data, config)
      .then((res) => {
        console.log("res.data.msg", res);
        setAlertContent(res.data.message);
        setAlertType("success");
        setAlert(true);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
        } else {
          setAlertContent(err.data.message);
          setAlertType("error");
          setAlert(true);
        }
      });
  };

  const ref = React.useRef(openEdit);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={openEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <BatchTableResponse
            surveyScheduleID={surveyScheduleID}
            employeeId={employeeId}
            handleDialog={handleDialog}
            setAlert={setAlert}
            setAlertType={setAlertType}
            setAlertContent={setAlertContent}
          />
        </DialogContent>
      </Dialog>

      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertRoundedIcon width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          to="#"
          sx={{ color: "text.secondary" }}
          onClick={() => {
            handleReminder();
            setIsOpen(false);
          }}
        >
          <ListItemIcon>
            <SendIcon width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Send Reminder"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <MenuItem
          sx={{ color: "text.secondary" }}
          onClick={() => {
            handleDialog();
            setIsOpen(false);
          }}
        >
          <ListItemIcon>
            <VisibilityIcon width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="View Response"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
