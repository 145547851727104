export const LOGIN_USER = "LOGIN_USER";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
// export const RESET_PASSWORDF = "RESET_PASSWORDF";
export const GET_SURVEY_LIST = "GET_SURVEY_LIST";
export const GET_SEARCH_SURVEY_LIST = "GET_SEARCH_SURVEY_LIST";
export const UPDATE_SURVEY_LIST = "UPDATE_SURVEY_LIST";
export const DELETE_SURVEY_LIST = "DELETE_SURVEY_LIST";
export const CREATE_SURVEY_LIST = "CREATE_SURVEY_LIST";
export const GET_QBANK_LIST = "GET_QBANK_LIST";
export const GET_SEARCH_QBANK_LIST = "GET_SEARCH_QBANK_LIST";
export const UPDATE_QBANK_LIST = "UPDATE_QBANK_LIST";
export const DELETE_QBANK_LIST = "DELETE_QBANK_LIST";
export const CREATE_QBANK_LIST = "CREATE_QBANK_LIST";
export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const GET_SEARCH_CUSTOMER_LIST = "GET_SEARCH_CUSTOMER_LIST";
export const UPDATE_CUSTOMER_LIST = "UPDATE_CUSTOMER_LIST";
export const DELETE_CUSTOMER_LIST = "DELETE_CUSTOMER_LIST";
export const CREATE_CUSTOMER_LIST = "CREATE_CUSTOMER_LIST";
export const GET_EMPLOYEE_LIST = "GET_EMPLOYEE_LIST";
export const UPDATE_EMPLOYEE_LIST = "UPDATE_CUSTOMER_LIST";
export const DELETE_EMPLOYEE_LIST = "DELETE_CUSTOMER_LIST";
export const CREATE_EMPLOYEE_LIST = "CREATE_CUSTOMER_LIST";
export const CREATE_BULK_EMPLOYEE_LIST = "CREATE_BULK_EMPLOYEE_LIST";
export const FETCH_SURVEY_MAPPING = "FETCH_SURVEY_MAPPING";
export const SURVEY_SCHEDUAL_LIST = "SURVEY_SCHEDUAL_LIST";
export const CREATE_SURVEY_SCHEDUAL = "CREATE_SURVEY_SCHEDUAL";
export const GET_SEARCH_EMPLOYEE_LIST = "GET_SEARCH_EMPLOYEE_LIST";
export const GET_SORT_EMPLOYEE_LIST = "GET_SORT_EMPLOYEE_LIST";
export const QUESTIONS_LIST = "QUESTIONS_LIST";
export const CREATE_QUESTIONS_LIST = "CREATE_QUESTIONS_LIST";
export const DELETE_QUESTIONS_LIST = "DELETE_QUESTIONS_LIST";
export const UPDATE_QUESTIONS_LIST = "UPDATE_QUESTIONS_LIST";

export const QUESTIONS_TYPE = "QUESTIONS_TYPE";
export const CREATE_SURVAY_QUESTION = "CREATE_SURVAY_QUESTION";

export const GET_SURVAY_ACTIVITY = "GET_SURVAY_ACTIVITY";

export const GET_SORT_SURVAY_MAPPING_LIST = "GET_SORT_SURVAY_MAPPING_LIST";
export const SAMPLE_FILE = "SAMPLE_FILE";
export const GET_SORT_CLIENT_LIST = "GET_SORT_CLIENT_LIST";
export const GET_SORT_SURVAY_LIST = "GET_SORT_SURVAY_LIST";
export const GET_SORT_QBANK_LIST = "GET_SORT_QBANK_LIST";
export const GET_SORT_SURVAY_ACTIVITY_LIST = "GET_SORT_SURVAY_ACTIVITY_LIST";
export const GET_SORT_QUESTION_LIST = "GET_SORT_QUESTION_LIST";
export const ORGANIZATION_OVERVIEW = "ORGANIZATION_OVERVIEW";
export const GET_OVERVIEW_EMPLOYEE_LIST = "GET_OVERVIEW_EMPLOYEE_LIST";
export const DASHBOARD_DETAIL = "DASHBOARD_DETAIL";
export const START_SURVEY = "START_SURVEY";
export const CLOSE_SURVEY = "CLOSE_SURVEY";

export const GET_SIDE_MENU = "GET_SIDE_MENU";
export const CREATE_BULK_QUESTION_LIST = 'CREATE_BULK_QUESTION_LIST';

export const DASHBOARD_DETAILS_LOADING = 'DASHBOARD_DETAILS_LOADING';
export const DASHBOARD_DETAILS_SUCCESS = 'DASHBOARD_DETAILS_SUCCESS';
export const DASHBOARD_DETAILS_ERROR = 'DASHBOARD_DETAILS_ERROR';
