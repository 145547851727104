import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "../../../component/header/Header";
import LeftDrawer from "../../../component/leftDrawer/LeftDrawer";
import { MaterialuiDropzone } from "../../../component/Upload/MaterialuiDropzone";
import { Form } from "usetheform";
import addPlusIcon from "../../../assets/images/svgs/plus_add_club.svg";
import deleteIcon from "../../../assets/images/svgs/close.png";
import {
  Typography,
  Box,
  ButtonGroup,
  Link,
  Button,
  Grid,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  // Menu,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DialogComp from "../../../component/dialog/dialog";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";

import {
  SearchBlack,
  Dots,
  Arrow,
  downArrow,
  Plus,
  downArrowWhite,
} from "../../../component/Images";
import InputBox from "../../../component/UIElements/InputBox";
import AvatarComp from "../../../component/UIElements/Avatar";
import { useStyles } from "./UserQuestion.style";
import { Menu } from "@material-ui/core";
import {
  Pagination,
  PaginationItem,
  usePagination,
  TablePagination,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  // getQuestionBankList,
  getQuestionsList,
  getQuestionsType,
  createQuestionsList,
  deleteQuestionsList,
  updateQuestionsList,
  getSortQuestionsList,
  sampleFile,
  createBulkQuestion,
} from "../../../thunk/Action";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../../component/UIElements/Modal";

import CloseIcon from "@mui/icons-material/Close";
import InputTextField from "../../../component/UIElements/InputTextField";
import InputLabel from "@mui/material/InputLabel";
import { Notification } from "../../../Utils/SnackbarNotification/Notification";
import UpdateQuestion from "./updateQuestion";
//drawer

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
});

const headCells = [
  {
    id: "TITLE",
    numeric: false,
    disablePadding: true,
    label: "TITLE",
  },
  {
    id: "QUESTION TYPE",
    numeric: true,
    disablePadding: false,
    label: "QUESTION TYPE",
  },
  {
    id: "STATUS",
    numeric: true,
    disablePadding: false,
    label: "STATUS",
  },
  // {
  //   id: "IS REQUIRED",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "IS REQUIRED",
  // },
  // {
  //   id: "IS ANONYMOUS",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "IS ANONYMOUS",
  // },
  {
    id: "CREATED_BY",
    numeric: true,
    disablePadding: false,
    label: "CREATED_BY",
  },
  {
    id: "CREATED_DATE",
    numeric: true,
    disablePadding: false,
    label: "CREATED_DATE",
  },
  {
    id: "ACTIONS",
    numeric: true,
    disablePadding: false,
    label: "ACTIONS",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const handlesort = (val) => {
    props.setSortBy(val);
    props.setSortOrder(val);
    console.log(val);
  };

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "#128CB0", height: 50 }}>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
            style={{
              color: "#fff",
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              color: "#fff",
              "&.MuiTableRow-root:hover": {
                color: "#fff",
              },
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              {headCell.label}
              {headCell.label !== "" ? (
                <>
                  <Box
                    sx={{ marginLeft: "5px", paddingTop: "3px" }}
                    onClick={() => {
                      handlesort(headCell.label);
                    }}
                  >
                    <AvatarComp image={Arrow} height={15} />
                  </Box>
                </>
              ) : null}
            </div>
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {headCell.label !== "" ? (
                <>
                  <Box sx={{ marginLeft: "5px", paddingTop: "4px" }}>
                    <AvatarComp image={Arrow} height={15} />
                  </Box>
                </>
              ) : null}

              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  const classes = useStyles();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 &&
          {
            // bgcolor: (theme) =>
            //   alpha(
            //     theme.palette.primary.main,
            //     theme.palette.action.activatedOpacity
            //   ),
          }),
      }}
    >
      {/* {numSelected > 0 ? (
        <Box className={classes.searchBox}>
        <Box sx={{ paddingTop: "4px" }}>
          <AvatarComp image={SearchBlack} />
        </Box>

        <InputBox placeholder="Search" />
      </Box>
      ) : (
        <Box className={classes.searchBox}>
          <Box sx={{ paddingTop: "4px" }}>
            <AvatarComp image={SearchBlack} />
          </Box>

          <InputBox placeholder="Search" />
        </Box>
      )} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const UserQuestion = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  let { id } = useParams();
  // console.log("id____", id);
  let { name } = useParams();
  // console.log("id____", name);

  const { items } = usePagination({
    count: 120,
  });

  const [que, setQue] = React.useState([]);

  const [maxrow, setMaxRow] = useState(10);
  const [searchPages, setSearchPages] = useState(1);
  const [pages, setPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(null);
  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    console.log("Pages:::", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPages(1);
    console.log("rowPerPage:::", rowsPerPage);
  };

  const [modalOpenAddQuestion, setModalOpenAddQuestion] = React.useState(false);
  const [modalOpenUpdateQuestion, setModalOpenUpdateQuestion] =
    React.useState(false);

  const handleModalOpenAddQuestion = () => {
    setModalOpenAddQuestion(true);
  };

  const handleModalCloseAddQuestion = () => {
    setModalOpenAddQuestion(false);
    setQuestionTitle("");
    setOptions("");
  };

  const [questiontitleU, setQuestionTitleU] = useState("");
  const [optionsU, setOptionsU] = useState("");
  const [opmcq, setOpmcq] = useState("");
  // const [comcq, setComcq] = useState("");
  //************update dynamic field state and function */
  //mCQ update
  const [formFieldsU, setFormFieldsU] = useState([
    {
      option: " ",
      sub_question: " ",
      optionInput: true,
      commentInput: true,
    },
  ]);
  const addFieldsU = () => {
    setFormFieldsU([
      ...formFieldsU,
      { option: " ", sub_question: "", optionInput: true, commentInput: true },
    ]);
  };
  console.log("data of array", formFieldsU);
  const removeFieldsU = (index) => {
    let data = [...formFieldsU];
    data.splice(index, 1);
    setFormFieldsU(data);
  };

  const onChangeOptionsU = (text, idx) => {
    const localObj = formFieldsU.map((item, index) =>
      index === idx
        ? {
            ...item,
            option: text,
            optionInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setFormFieldsU(localObj);
  };
  const onChangeCommentsU = (text, idx) => {
    const localObj = formFieldsU.map((item, index) =>
      index === idx
        ? {
            ...item,
            sub_question: text,
            commentInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setFormFieldsU(localObj);
  };
  //rating update
  const [ratingU, setRatingU] = useState([
    { option: "1", sub_question: "", optionInput: true, commentInput: true },
    { option: "2", sub_question: "", optionInput: true, commentInput: true },
    { option: "3", sub_question: "", optionInput: true, commentInput: true },
    { option: "4", sub_question: "", optionInput: true, commentInput: true },
    { option: "5", sub_question: "", optionInput: true, commentInput: true },
  ]);
  const addRatingFieldsU = () => {
    setRatingU([
      ...ratingU,
      { option: "", sub_question: "", optionInput: true, commentInput: true },
    ]);
  };
  const removeRatingFieldsU = (index) => {
    let data = [...ratingU];
    data.splice(index, 1);
    setRatingU(data);
  };

  const onChangeRatingOptionsU = (text, idx) => {
    const localObj = ratingU.map((item, index) =>
      index === idx
        ? {
            ...item,
            option: text,
            optionInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setRatingU(localObj);
  };

  const onChangeRatingCommentsU = (text, idx) => {
    const localObj = ratingU.map((item, index) =>
      index === idx
        ? {
            ...item,
            sub_question: text,
            commentInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setRatingU(localObj);
  };
  //checkbox update

  const [checkboxU, setCheckboxU] = useState([
    { option: "", sub_question: "", optionInput: true, commentInput: true },
  ]);
  const addCheckboxFieldsU = () => {
    setCheckboxU([
      ...checkboxU,
      { option: "", sub_question: "", optionInput: true, commentInput: true },
    ]);
  };
  const removeCheckboxFieldsU = (index) => {
    let data = [...checkboxU];
    data.splice(index, 1);
    setCheckboxU(data);
  };

  const onChangeCheckboxOptionsU = (text, idx) => {
    const localObj = checkboxU.map((item, index) =>
      index === idx
        ? {
            ...item,
            option: text,
            optionInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setCheckboxU(localObj);
  };

  const onChangeCheckboxCommentsU = (text, idx) => {
    const localObj = checkboxU.map((item, index) =>
      index === idx
        ? {
            ...item,
            sub_question: text,
            commentInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setCheckboxU(localObj);
  };
  //textbox update
  const [textboxU, setTextboxU] = useState([
    { option: "", sub_question: "", optionInput: true, commentInput: true },
  ]);
  const addTextboxFieldsU = () => {
    setTextboxU([
      ...textboxU,
      { option: "", sub_question: "", optionInput: true, commentInput: true },
    ]);
  };
  const removeTextboxFieldsU = (index) => {
    let data = [...textboxU];
    data.splice(index, 1);
    setTextbox(data);
  };

  const onChangeTextboxOptionsU = (text, idx) => {
    const localObj = textboxU.map((item, index) =>
      index === idx
        ? {
            ...item,
            option: text,
            optionInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setTextboxU(localObj);
  };
  const onChangeTextboxCommentsU = (text, idx) => {
    const localObj = textboxU.map((item, index) =>
      index === idx
        ? {
            ...item,
            sub_question: text,
            commentInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setTextboxU(localObj);
  };
  //slider update
  const [sliderU, setSliderU] = useState([
    { option: "", sub_question: "", optionInput: true, commentInput: true },
  ]);
  const addSliderFieldsU = () => {
    setSliderU([
      ...sliderU,
      { option: "", sub_question: "", optionInput: true, commentInput: true },
    ]);
  };
  const removeSliderFieldsU = (index) => {
    let data = [...sliderU];
    data.splice(index, 1);
    setSliderU(data);
  };

  const onChangeSliderOptionsU = (text, idx) => {
    const localObj = sliderU.map((item, index) =>
      index === idx
        ? {
            ...item,
            option: text,
            optionInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setSliderU(localObj);
  };

  const onChangeSliderCommentsU = (text, idx) => {
    const localObj = sliderU.map((item, index) =>
      index === idx
        ? {
            ...item,
            sub_question: text,
            commentInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setSliderU(localObj);
  };

  //star rating update
  const [starratingU, setStarRatingU] = useState([
    { option: "1", sub_question: "", optionInput: true, commentInput: true },
    { option: "2", sub_question: "", optionInput: true, commentInput: true },
    { option: "3", sub_question: "", optionInput: true, commentInput: true },
    { option: "4", sub_question: "", optionInput: true, commentInput: true },
    { option: "5", sub_question: "", optionInput: true, commentInput: true },
  ]);
  const addStarRatingFieldsU = () => {
    setRatingU([
      ...starratingU,
      { option: "", sub_question: "", optionInput: true, commentInput: true },
    ]);
  };
  const removeStarRatingFieldsU = (index) => {
    let data = [...starratingU];
    data.splice(index, 1);
    setStarRatingU(data);
  };

  const onChangeStarRatingOptionsU = (text, idx) => {
    const localObj = starratingU.map((item, index) =>
      index === idx
        ? {
            ...item,
            option: text,
            optionInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setStarRatingU(localObj);
  };

  const onChangeStarRatingCommentsU = (text, idx) => {
    const localObj = starratingU.map((item, index) =>
      index === idx
        ? {
            ...item,
            sub_question: text,
            commentInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setStarRatingU(localObj);
  };

  const handleModalOpenUpdateQuestion = (rowId) => {
    console.log("mcq is tuning12:::");
    setModalOpenUpdateQuestion(true);
    setQuestionTitleU(rowId.title);
    setOptionsU(rowId.question_type);
    setFormFieldsU(rowId.option);
    console.log("rowId.question_type", rowId.question_type);
    if (rowId.question_type === "Multiple Choice Question") {
      console.log("mcq is runing:::");
      setFormFieldsU(rowId.option);
    } else if (rowId.question_type === "Rating") {
      setRatingU(rowId.option);
    } else if (rowId.question_type === "Checkboxes") {
      console.log("checkbox is runing:::");
      setCheckboxU(rowId.option);
    } else if (rowId.question_type === "Textbox") {
      setTextboxU(rowId.option);
    } else if (rowId.question_type === "Slider") {
      setSliderU(rowId.option);
    } else if (rowId.question_type === "Start Rating") {
      setStarRatingU(rowId.option);
    } else {
      console.log("empty");
    }
  };
  const updateQuestionsApicall = (data, Qid) => {
    console.log("data:::", data);
    let finalData = {
      title: questiontitleU,
      options: data,
      question_type_id: Qid,
    };
    console.log("data final submit ::::", finalData);
    const request = {
      token: token,
      id: rowId.question_id,
      data: finalData,
      onSuccess: (res) => {
        if (res?.status === 200) {
          console.log("res:::", res);
          setAlertContent(res?.data?.message);
          setAlertType("success");
          setAlert(true);
          handleModalCloseUpdateQuestion();
          getApicall();
        }
      },
      onFail: (error) => {
        // setIsLoading(false);
        setAlertContent(error?.response?.data?.message);
        setAlertType("error");
        setAlert(true);
        if (error?.response?.status === 400) {
          console.log("bad request");
          // Alert.alert(strings.error, error?.response?.data?.message);
        }
      },
    };
    dispatch(updateQuestionsList(request));
  };

  const [updatedata, setUpdateData] = useState([]);
  const [qtype, setQtype] = useState("");

  const updatevalidation = () => {
    if (optionsU === "Mutliple Choice Question") {
      updateQuestionsApicall(formFieldsU, question_type[0].question_type_id);
    } else if (optionsU === "Rating") {
      updateQuestionsApicall(ratingU, question_type[1].question_type_id);
    } else if (optionsU === "Checkboxes") {
      updateQuestionsApicall(checkboxU, question_type[2].question_type_id);
    } else if (optionsU === "Textbox") {
      updateQuestionsApicall(textboxU, question_type[3].question_type_id);
    } else if (optionsU === "Slider") {
      updateQuestionsApicall(sliderU, question_type[4].question_type_id);
    } else if (optionsU === "Start Rating") {
      updateQuestionsApicall(starratingU, question_type[5].question_type_id);
    } else {
      console.log("empty");
    }
  };
  const handleModalCloseUpdateQuestion = () => {
    setModalOpenUpdateQuestion(false);
  };

  const [rowId, setRowId] = useState(0);
  const [opendrawer, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [addEmployee, setAddEmployee] = React.useState(null);
  const [options, setOptions] = React.useState("");
  // const [name, setName] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  // const [id, setId] = React.useState();
  const [nameC, setNameC] = React.useState("");
  const [descC, setDescC] = React.useState("");
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [pagetable, setPageTable] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [openuploadDialog, setOpenuploadDialog] = React.useState(false);
  const openFileUpload = () => {
    setOpenuploadDialog(true);
  };

  const handleCloseUpload = () => {
    setOpenuploadDialog(false);
  };
  const handleClickMenuAddEmployee = (event) => {
    setAddEmployee(event.currentTarget);
  };

  const handleCloseMenuAddEmployee = () => {
    setAddEmployee(null);
  };
  const [selecteds, setSelecteds] = useState("");

  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("");

  const [sortby, setSortBy] = useState("");

  const [sortorder, setSortOrder] = useState(false);

  const handleAlert = () => {
    setAlert(false);
  };
  const token = localStorage.getItem("token").toString();

  const [total_record_count, setTotal_Record_count] = useState("");

  const getApicall = () => {
    const request = {
      token: token,
      id: id,
      data: {
        page: pages,
        max_rows: maxrow,
        sort_by: sortby,
      },
      onSuccess: (res) => {
        if (res?.status === 200) {
          setQue(res?.data?.questionslist);
          setTotal_Record_count(res?.data?.total_record_count);
          setPageCount(res?.data?.total_page_count);
        }
      },
    };
    dispatch(getSortQuestionsList(request));
  };

  React.useEffect(() => {
    getApicall();
  }, [pages, maxrow, sortby]);

  const Reorder = (val) => {
    console.log("REORDERVAL::", val);
    // console.log("LOG:::",!sortorder );
    setSortOrder(!sortorder);

    if (val === "TITLE" && sortorder) {
      setSortBy("-title");
    }

    if (val === "QUESTION TYPE" && !sortorder) {
      setSortBy("-question_type");
    }

    if (val === "STATUS" && sortorder) {
      setSortBy("-status");
    }
    if (val === "CREATED_BY" && !sortorder) {
      setSortBy("-created_by");
    }
    if (val === "CREATED_DATE" && sortorder) {
      setSortBy("-created_date");
    }
  };
  // useEffect(() => {
  //   const request = {
  //     token: token,
  //     id: id,
  //     data: {
  //       page: pages,
  //       max_rows: maxrow,
  //       sort_by: sortby,
  //     },
  //     onSuccess: (res) => {
  //       setQue(res?.data?.questionslist);
  //       setSortBy(sortby);
  //     },
  //   };
  //   dispatch(getSortQuestionsList(request)); //chnges
  // }, [pages, maxrow, sortby]);

  const sortbyFunction = (val) => {
    console.log("val::", val);
    if (val === "TITLE") {
      setSortBy("title");
    }
    if (val === "QUESTION TYPE") {
      setSortBy("question_type");
    }
    if (val === "STATUS") {
      setSortBy("status");
    }
    if (val === "CREATED_BY") {
      setSortBy("created_by");
    }
    if (val === "CREATED_DATE") {
      setSortBy("created_date");
    }
  };
  const [question_type, setQuestion_type] = useState([]);

  const getQuestionTypeApicall = () => {
    const request = {
      token: token,
      id: id,
      onSuccess: (res) => {
        if (res?.status === 200) {
          setQuestion_type(res?.data?.question_type_list);
        }
      },
    };
    dispatch(getQuestionsType(request));
  };

  const [questiontitle, setQuestionTitle] = useState("");
  const createQuestionsApicall = (data) => {
    console.log("data:::", data);
    let finalData = {
      question_bank_id: id,
      title: questiontitle,
      options: data,
      is_required: "True",
      is_anonymous: "True",
      question_type_id: options,
    };
    const request = {
      token: token,
      data: finalData,
      onSuccess: (res) => {
        if (res?.status === 200) {
          console.log("res:::", res);
          setAlertContent(res?.data?.message);
          setAlertType("success");
          setAlert(true);
          handleModalCloseAddQuestion();
          getApicall();
        }
      },
      onFail: (error) => {
        // setIsLoading(false);
        setAlertContent(error?.response?.data?.message);
        setAlertType("error");
        setAlert(true);
        if (error?.response?.status === 400) {
          console.log("bad request");
          // Alert.alert(strings.error, error?.response?.data?.message);
        }
      },
    };
    dispatch(createQuestionsList(request));
  };

  const [error, setError] = useState(false);
  const [optionerror, setOptionError] = useState(false);
  const [optionserror, setOptionsError] = useState(false);

  const QuestionValidation = () => {
    if (!questiontitle) {
      setError(true);
    } else if (options === 1) {
      if (
        formFields.filter((item, idx) => item.option === "").length > 0 ||
        formFields.filter((item, idx) => item.sub_question === "").length > 0
      ) {
        let finalData = formFields.map((item, idx) => {
          return {
            ...item,
            optionInput: item?.option.length === 0 ? false : true,
            commentInput: item?.sub_question.length === 0 ? false : true,
          };
        });
        setFormFields(finalData);
      } else {
        createQuestionsApicall(formFields);
      }
    } else if (options === 2) {
      if (
        rating.filter((item, idx) => item.option === "").length > 0 ||
        rating.filter((item, idx) => item.sub_question === "").length > 0
      ) {
        let finalData = rating.map((item, idx) => {
          return {
            ...item,
            optionInput: item?.option.length === 0 ? false : true,
            commentInput: item?.sub_question.length === 0 ? false : true,
          };
        });
        setRating(finalData);
      } else {
        createQuestionsApicall(rating);
      }
    } else if (options === 3) {
      if (
        checkbox.filter((item, idx) => item.option === "").length > 0 ||
        checkbox.filter((item, idx) => item.sub_question === "").length > 0
      ) {
        let finalData = checkbox.map((item, idx) => {
          return {
            ...item,
            optionInput: item?.option.length === 0 ? false : true,
            commentInput: item?.sub_question.length === 0 ? false : true,
          };
        });
        setCheckbox(finalData);
      } else {
        createQuestionsApicall(checkbox);
      }
    } else if (options === 4) {
      if (
        textbox.filter((item, idx) => item.option === "").length > 0 ||
        textbox.filter((item, idx) => item.sub_question === "").length > 0
      ) {
        let finalData = textbox.map((item, idx) => {
          return {
            ...item,
            optionInput: item?.option.length === 0 ? false : true,
            commentInput: item?.sub_question.length === 0 ? false : true,
          };
        });
        setTextbox(finalData);
      } else {
        createQuestionsApicall(textbox);
      }
    } else if (options === 9) {
      if (
        slider.filter((item, idx) => item.option === "").length > 0 ||
        slider.filter((item, idx) => item.sub_question === "").length > 0
      ) {
        let finalData = slider.map((item, idx) => {
          return {
            ...item,
            optionInput: item?.option.length === 0 ? false : true,
            commentInput: item?.sub_question.length === 0 ? false : true,
          };
        });
        setSlider(finalData);
      } else {
        createQuestionsApicall(slider);
      }
    } else if (options === 11) {
      if (
        starrating.filter((item, idx) => item.option === "").length > 0 ||
        starrating.filter((item, idx) => item.sub_question === "").length > 0
      ) {
        let finalData = starrating.map((item, idx) => {
          return {
            ...item,
            optionInput: item?.option.length === 0 ? false : true,
            commentInput: item?.sub_question.length === 0 ? false : true,
          };
        });
        setStarRating(finalData);
      } else {
        createQuestionsApicall(starrating);
      }
    } else {
      console.log("Else Called!!!");
      createQuestionsApicall();
    }
  };
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const deleteApiCall = (id) => {
    const request = {
      token: token,
      id: id,
      onSuccess: (res) => {
        if (res?.status === 200) {
          console.log("Delete res:::", res);
          setAlertContent(res?.data?.message);
          setAlertType("success");
          setAlert(true);
          getApicall();
        }
      },
      onFail: (error) => {
        // setIsLoading(false);
        setAlertContent(error?.response?.data?.message);
        setAlertType("error");
        setAlert(true);
        if (error?.response?.status === 400) {
          console.log("bad request");
          // Alert.alert(strings.error, error?.response?.data?.message);
        }
      },
    };
    dispatch(deleteQuestionsList(request));
    handleCloseMenu();
  };

  const selectionChangeHandler = (event) => {
    setSelecteds(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = que.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  // const [anchorElC, setAnchorElC] = React.useState(null);
  // const openC = Boolean(anchorElC);
  // const handleClickC = (event) => {
  //   setAnchorElC(event.currentTarget);
  // };
  // const handleCloseC = () => {
  //   setAnchorElC(null);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    pagetable > 0 ? Math.max(0, (1 + pagetable) * rowsPerPage - que.length) : 0;

  //options 1
  const [formFields, setFormFields] = useState([
    { option: "", sub_question: "", optionInput: true, commentInput: true },
  ]);
  const addFields = () => {
    setFormFields([
      ...formFields,
      { option: "", sub_question: "", optionInput: true, commentInput: true },
    ]);
  };
  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  const onChangeOptions = (text, idx) => {
    const localObj = formFields.map((item, index) =>
      index === idx
        ? {
            ...item,
            option: text,
            optionInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setFormFields(localObj);
  };

  const onChangeComments = (text, idx) => {
    const localObj = formFields.map((item, index) =>
      index === idx
        ? {
            ...item,
            sub_question: text,
            commentInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setFormFields(localObj);
  };

  //option2
  const [rating, setRating] = useState([
    { option: "1", sub_question: "", optionInput: true, commentInput: true },
    { option: "2", sub_question: "", optionInput: true, commentInput: true },
    { option: "3", sub_question: "", optionInput: true, commentInput: true },
    { option: "4", sub_question: "", optionInput: true, commentInput: true },
    { option: "5", sub_question: "", optionInput: true, commentInput: true },
  ]);
  const addRatingFields = () => {
    setRating([
      ...rating,
      { option: "", sub_question: "", optionInput: true, commentInput: true },
    ]);
  };
  const removeRatingFields = (index) => {
    let data = [...rating];
    data.splice(index, 1);
    setRating(data);
  };

  const onChangeRatingOptions = (text, idx) => {
    const localObj = rating.map((item, index) =>
      index === idx
        ? {
            ...item,
            option: text,
            optionInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setRating(localObj);
  };

  const onChangeRatingComments = (text, idx) => {
    const localObj = rating.map((item, index) =>
      index === idx
        ? {
            ...item,
            sub_question: text,
            commentInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setRating(localObj);
  };
  //option 4
  const [textbox, setTextbox] = useState([
    { option: "", sub_question: "", optionInput: true, commentInput: true },
  ]);
  const addTextboxFields = () => {
    setTextbox([
      ...textbox,
      { option: "", sub_question: "", optionInput: true, commentInput: true },
    ]);
  };
  const removeTextboxFields = (index) => {
    let data = [...textbox];
    data.splice(index, 1);
    setTextbox(data);
  };

  const onChangeTextboxOptions = (text, idx) => {
    const localObj = textbox.map((item, index) =>
      index === idx
        ? {
            ...item,
            option: text,
            optionInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setTextbox(localObj);
  };
  const onChangeTextboxComments = (text, idx) => {
    const localObj = textbox.map((item, index) =>
      index === idx
        ? {
            ...item,
            sub_question: text,
            commentInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setTextbox(localObj);
  };
  //options 3

  const [checkbox, setCheckbox] = useState([
    { option: "", sub_question: "", optionInput: true, commentInput: true },
  ]);
  const addCheckboxFields = () => {
    setCheckbox([
      ...checkbox,
      { option: "", sub_question: "", optionInput: true, commentInput: true },
    ]);
  };
  const removeCheckboxFields = (index) => {
    let data = [...checkbox];
    data.splice(index, 1);
    setCheckbox(data);
  };

  const onChangeCheckboxOptions = (text, idx) => {
    const localObj = checkbox.map((item, index) =>
      index === idx
        ? {
            ...item,
            option: text,
            optionInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setCheckbox(localObj);
  };

  const onChangeCheckboxComments = (text, idx) => {
    const localObj = checkbox.map((item, index) =>
      index === idx
        ? {
            ...item,
            sub_question: text,
            commentInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setCheckbox(localObj);
  };

  //option 9
  const [slider, setSlider] = useState([
    { option: "", sub_question: "", optionInput: true, commentInput: true },
  ]);
  const addSliderFields = () => {
    setSlider([
      ...slider,
      { option: "", sub_question: "", optionInput: true, commentInput: true },
    ]);
  };
  const removeSliderFields = (index) => {
    let data = [...slider];
    data.splice(index, 1);
    setSlider(data);
  };

  const onChangeSliderOptions = (text, idx) => {
    const localObj = slider.map((item, index) =>
      index === idx
        ? {
            ...item,
            option: text,
            optionInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setSlider(localObj);
  };

  const onChangeSliderComments = (text, idx) => {
    const localObj = slider.map((item, index) =>
      index === idx
        ? {
            ...item,
            sub_question: text,
            commentInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setSlider(localObj);
  };
  //option 11
  const [starrating, setStarRating] = useState([
    { option: "1", sub_question: "", optionInput: true, commentInput: true },
    { option: "2", sub_question: "", optionInput: true, commentInput: true },
    { option: "3", sub_question: "", optionInput: true, commentInput: true },
    { option: "4", sub_question: "", optionInput: true, commentInput: true },
    { option: "5", sub_question: "", optionInput: true, commentInput: true },
  ]);
  const addStarRatingFields = () => {
    setRating([
      ...starrating,
      { option: "", sub_question: "", optionInput: true, commentInput: true },
    ]);
  };
  const removeStarRatingFields = (index) => {
    let data = [...starrating];
    data.splice(index, 1);
    setStarRating(data);
  };

  const onChangeStarRatingOptions = (text, idx) => {
    const localObj = starrating.map((item, index) =>
      index === idx
        ? {
            ...item,
            option: text,
            optionInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setStarRating(localObj);
  };

  const onChangeStarRatingComments = (text, idx) => {
    const localObj = starrating.map((item, index) =>
      index === idx
        ? {
            ...item,
            sub_question: text,
            commentInput: text?.length === 0 ? false : true,
          }
        : item
    );
    setStarRating(localObj);
  };
  const handleDownloadClick = () => {
    const element = document.createElement("a");
    const request = {
      token: token,
      sample_file: "question",
      onSuccess: (res) => {
        try {
          if (res?.message === "File not found.") {
            setAlertContent(res?.data?.message);
            setAlertType("error");
            setAlert(true);
          } else {
            element.setAttribute("href", res?.data?.link);
            element.setAttribute("download", res?.data?.link);
            document.body.appendChild(element);
            element.click();
          }
          console.log("Resp", res);
        } catch (error) {
          console.log("Catch Error ::--", error);
        }
      },
    };
    dispatch(sampleFile(request));
  };

  const [csvFile, setCsvFile] = useState([]);

  const bulkDataUpload = () => {
    const formdata = new FormData();
    // formdata.append('file_csv', JSON.stringify(csvFile));
    formdata.append("file_csv", csvFile[0]);
    formdata.append("question_bank_id", id);
    const request = {
      token: token,
      data: formdata,
      onSuccess: (res) => {
        if (res?.status === 200) {
          // console.log("bulk res:::", res);

          setAlertContent(res?.data?.message);
          setAlertType("success");
          setAlert(true);
          getApicall();
          handleCloseUpload();
        }
      },
      onFail: (error) => {
        setAlertContent(error?.response?.data?.message);
        setAlertType("error");
        setAlert(true);
        if (error?.response?.status === 400) {
          // console.log("bad request");
        }
      },
    };
    dispatch(createBulkQuestion(request));
  };
  return (
    <>
      <Header />
      <Box className={classes.roots} sx={{ display: "flex" }}>
        <LeftDrawer open={opendrawer} />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          style={{ background: "#EEEEEE" }}
        >
          <>
            <Box>
              <Typography
                sx={{
                  marginTop: "10px",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "900",
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#0B0B0C",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/question")}
              >
                Back
              </Typography>
              <Typography
                sx={{
                  marginTop: "40px",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "20px",
                  lineHeight: "25px",
                  color: "#0B0B0C",
                }}
              >
                {/* {que.map((data) => {
                if (data.question_bank_id == id) return data.name;
              })} */}
                {name}
                {/* Question Bank */}
              </Typography>

              <Grid container sx={{ width: "95%" }}>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    bgcolor: "#2D404E",
                    padding: "10px",
                    borderRadius: "20px",
                    width: "200px",
                    height: "36px",
                    marginLeft: "auto",
                    marginTop: 2,
                    display: "flex",
                    gap: "10px",
                  }}
                  onClick={
                    // handleClickToOpenCreateDialog}
                    handleClickMenuAddEmployee
                  }
                >
                  <img src={Plus} /> Add Question <img src={downArrowWhite} />
                </Button>
                <Menu
                  // className={classes.menu}
                  id={`simple-menu-${rowId}`}
                  anchorEl={addEmployee}
                  keepMounted
                  open={Boolean(addEmployee)}
                  onClose={handleCloseMenuAddEmployee}
                  elevation={0}
                >
                  <MenuItem
                    onClick={() => {
                      setAddEmployee(null);
                      handleModalOpenAddQuestion();
                      getQuestionTypeApicall();
                    }}
                  >
                    Add Question
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAddEmployee(null);
                      openFileUpload();
                    }}
                  >
                    Upload a file
                  </MenuItem>
                </Menu>
              </Grid>

              <Modal
                open={modalOpenAddQuestion}
                handleClose={handleModalCloseAddQuestion}
                className={classes.modal_AddQuestionStyle}
                content={
                  <>
                    <Box className={classes.modal_content_AddQuestionStyle}>
                      <header>
                        <h4 className="titleModal">Write</h4>
                        <CloseIcon onClick={handleModalCloseAddQuestion} />
                      </header>
                      <Box className={classes.modalContent_AddQue}>
                        <Grid container spacing={3}>
                          <Grid item md={1}>
                            <h4 className="que">Q 1</h4>
                          </Grid>

                          <InputTextField
                            error={error && !questiontitle ? true : false}
                            helperText={
                              error && !questiontitle
                                ? "required Question Title"
                                : ""
                            }
                            md={7}
                            element="TextField"
                            label="Type your question here"
                            value={questiontitle}
                            onChange={(e) => setQuestionTitle(e.target.value)}
                          />

                          <Grid item md={4} xs={12}>
                            <FormControl
                              fullWidth
                              // error={optionerror && !options ? true : false}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Options
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={options}
                                label="Gender"
                                onChange={(e) => setOptions(e.target.value)}
                              >
                                {question_type.map((item) => {
                                  console.log("item", item);
                                  return (
                                    <MenuItem value={item.question_type_id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              {/* <FormHelperText>
                                {optionerror && !options
                                  ? "required select field"
                                  : ""}
                              </FormHelperText> */}
                            </FormControl>
                          </Grid>
                        </Grid>
                        {/* <Grid item md={1}>
                            <Button onClick={openOptions}>proceed</Button>
                          </Grid> */}
                        {options === 17 ? (
                          <>
                            {formFields.map((item, index) => {
                              console.log("item::", item);
                              return (
                                <>
                                  <Grid
                                    key={index}
                                    container
                                    spacing={3}
                                    style={{ marginTop: "0.2%" }}
                                  >
                                    <Grid item md={1} xs={12}></Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        error={!item.optionInput}
                                        helperText={
                                          !item.optionInput
                                            ? "required options"
                                            : ""
                                        }
                                        id="outlined-basic"
                                        label={"Options"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeOptions(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.option}
                                      />
                                    </Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        error={!item.commentInput}
                                        helperText={
                                          !item.commentInput
                                            ? "required comments"
                                            : ""
                                        }
                                        id="outlined-basic"
                                        label={"Comments"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeComments(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.sub_question}
                                      />
                                    </Grid>
                                    <Grid item md={1} xs={12}>
                                      {index !== 0 && (
                                        <Box
                                          sx={{
                                            marginTop: "10px",
                                          }}
                                          display="flex"
                                          alignItems={"center"}
                                          justifyContent={"space-between"}
                                        >
                                          <img
                                            src={deleteIcon}
                                            onClick={() => removeFields(index)}
                                            alt="add"
                                          />
                                        </Box>
                                      )}
                                    </Grid>
                                  </Grid>
                                </>
                              );
                            })}

                            <Grid item md={12}>
                              <Grid item md={1}></Grid>
                              <Grid item md={1} sx={{ marginTop: "1%" }}>
                                <img
                                  src={addPlusIcon}
                                  onClick={addFields}
                                  alt="add"
                                />
                              </Grid>
                              <Grid item md={10}></Grid>
                            </Grid>
                          </>
                        ) : options === 18 ? (
                          <>
                            {rating.map((item, index) => {
                              return (
                                <>
                                  <Grid
                                    key={index}
                                    container
                                    spacing={3}
                                    style={{ marginTop: "0.2%" }}
                                  >
                                    <Grid item md={1} xs={12}></Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        error={!item.optionInput}
                                        helperText={
                                          !item.optionInput
                                            ? "required options"
                                            : ""
                                        }
                                        id="outlined-basic"
                                        label={"Options"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeRatingOptions(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.option}
                                      />
                                    </Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        error={!item.commentInput}
                                        helperText={
                                          !item.commentInput
                                            ? "required comments"
                                            : ""
                                        }
                                        id="outlined-basic"
                                        label={"Comments"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeRatingComments(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.sub_question}
                                      />
                                    </Grid>
                                    <Grid item md={1} xs={12}>
                                      {index >= 5 && (
                                        <Box
                                          sx={{
                                            marginTop: "10px",
                                          }}
                                          display="flex"
                                          alignItems={"center"}
                                          justifyContent={"space-between"}
                                        >
                                          <img
                                            src={deleteIcon}
                                            onClick={() =>
                                              removeRatingFields(index)
                                            }
                                            alt="add"
                                          />
                                        </Box>
                                      )}
                                    </Grid>
                                  </Grid>
                                </>
                              );
                            })}
                            <Grid item md={12}>
                              <Grid item md={1}></Grid>
                              <Grid item md={1} sx={{ marginTop: "1%" }}>
                                <img
                                  src={addPlusIcon}
                                  onClick={addRatingFields}
                                  alt="add"
                                />
                              </Grid>
                              <Grid item md={10}></Grid>
                            </Grid>
                          </>
                        ) : options === 3 ? (
                          <>
                            {checkbox.map((item, index) => {
                              console.log("item::", item);
                              return (
                                <>
                                  <Grid
                                    key={index}
                                    container
                                    spacing={3}
                                    style={{ marginTop: "0.2%" }}
                                  >
                                    <Grid item md={1} xs={12}></Grid>

                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        error={!item.optionInput}
                                        helperText={
                                          !item.optionInput
                                            ? "required options"
                                            : ""
                                        }
                                        id="outlined-basic"
                                        label={"Options"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeCheckboxOptions(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.option}
                                      />
                                    </Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        error={!item.commentInput}
                                        helperText={
                                          !item.commentInput
                                            ? "required comments"
                                            : ""
                                        }
                                        id="outlined-basic"
                                        label={"Comments"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeCheckboxComments(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.sub_question}
                                      />
                                    </Grid>
                                    <Grid item md={1} xs={12}>
                                      {index !== 0 && (
                                        <Box
                                          sx={{
                                            marginTop: "10px",
                                          }}
                                          display="flex"
                                          alignItems={"center"}
                                          justifyContent={"space-between"}
                                        >
                                          <img
                                            src={deleteIcon}
                                            onClick={() =>
                                              removeCheckboxFields(index)
                                            }
                                            alt="add"
                                          />
                                        </Box>
                                      )}
                                    </Grid>
                                  </Grid>
                                </>
                              );
                            })}
                            <Grid item md={12}>
                              <Grid item md={1}></Grid>
                              <Grid item md={1} sx={{ marginTop: "1%" }}>
                                <img
                                  src={addPlusIcon}
                                  onClick={addCheckboxFields}
                                  alt="add"
                                />
                              </Grid>
                              <Grid item md={10}></Grid>
                            </Grid>
                          </>
                        ) : options === 19 ? (
                          <>
                            {textbox.map((item, index) => {
                              return (
                                <>
                                  <Grid
                                    key={index}
                                    container
                                    spacing={3}
                                    style={{ marginTop: "0.2%" }}
                                  >
                                    <Grid item md={1} xs={12}></Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        error={!item.optionInput}
                                        helperText={
                                          !item.optionInput
                                            ? "required maximum character length"
                                            : " "
                                        }
                                        id="outlined-basic"
                                        label={"Maximum length"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeTextboxOptions(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.option}
                                      />
                                    </Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        error={!item.commentInput}
                                        helperText={
                                          !item.commentInput
                                            ? "required minimum character length"
                                            : " "
                                        }
                                        id="outlined-basic"
                                        label={"Minimum length"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeTextboxComments(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.sub_question}
                                      />
                                    </Grid>
                                    <Grid item md={1} xs={12}>
                                      {index !== 0 && (
                                        <Box
                                          sx={{
                                            marginTop: "10px",
                                          }}
                                          display="flex"
                                          alignItems={"center"}
                                          justifyContent={"space-between"}
                                        >
                                          <img
                                            src={deleteIcon}
                                            onClick={() =>
                                              removeTextboxFields(index)
                                            }
                                            alt="add"
                                          />
                                        </Box>
                                      )}
                                    </Grid>
                                  </Grid>
                                </>
                              );
                            })}
                            <Grid item md={12}>
                              <Grid item md={1}></Grid>
                              <Grid item md={1} sx={{ marginTop: "1%" }}>
                                <img
                                  src={addPlusIcon}
                                  onClick={addTextboxFields}
                                  alt="add"
                                />
                              </Grid>
                              <Grid item md={10}></Grid>
                            </Grid>
                          </>
                        ) : options === 20 ? (
                          <>
                            {slider.map((item, index) => {
                              return (
                                <>
                                  <Grid
                                    key={index}
                                    container
                                    spacing={3}
                                    style={{ marginTop: "0.2%" }}
                                  >
                                    <Grid item md={1} xs={12}></Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        error={!item.optionInput}
                                        helperText={
                                          !item.optionInput
                                            ? "required options"
                                            : ""
                                        }
                                        id="outlined-basic"
                                        label={"Options"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeSliderOptions(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.option}
                                      />
                                    </Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        error={!item.commentInput}
                                        helperText={
                                          !item.commentInput
                                            ? "required comments"
                                            : ""
                                        }
                                        id="outlined-basic"
                                        label={"Comments"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeSliderComments(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.sub_question}
                                      />
                                    </Grid>
                                    <Grid item md={1} xs={12}>
                                      {index !== 0 && (
                                        <Box
                                          sx={{
                                            marginTop: "10px",
                                          }}
                                          display="flex"
                                          alignItems={"center"}
                                          justifyContent={"space-between"}
                                        >
                                          <img
                                            src={deleteIcon}
                                            onClick={() =>
                                              removeSliderFields(index)
                                            }
                                            alt="add"
                                          />
                                        </Box>
                                      )}
                                    </Grid>
                                  </Grid>
                                </>
                              );
                            })}
                            <Grid item md={12}>
                              <Grid item md={1}></Grid>
                              <Grid item md={1} sx={{ marginTop: "1%" }}>
                                <img
                                  src={addPlusIcon}
                                  onClick={addSliderFields}
                                  alt="add"
                                />
                              </Grid>
                              <Grid item md={10}></Grid>
                            </Grid>
                          </>
                        ) : options === 10 ? (
                          <></>
                        ) : options === 11 ? (
                          <>
                            {starrating.map((item, index) => {
                              return (
                                <>
                                  <Grid
                                    key={index}
                                    container
                                    spacing={3}
                                    style={{ marginTop: "0.2%" }}
                                  >
                                    <Grid item md={1} xs={12}></Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        error={!item.optionInput}
                                        helperText={
                                          !item.optionInput
                                            ? "required options"
                                            : ""
                                        }
                                        id="outlined-basic"
                                        label={"Options"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeStarRatingOptions(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.option}
                                      />
                                    </Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        error={!item.commentInput}
                                        helperText={
                                          !item.commentInput
                                            ? "required comments"
                                            : ""
                                        }
                                        id="outlined-basic"
                                        label={"Comments"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeStarRatingComments(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.sub_question}
                                      />
                                    </Grid>
                                    <Grid item md={1} xs={12}>
                                      {index >= 5 && (
                                        <Box
                                          sx={{
                                            marginTop: "10px",
                                          }}
                                          display="flex"
                                          alignItems={"center"}
                                          justifyContent={"space-between"}
                                        >
                                          <img
                                            src={deleteIcon}
                                            onClick={() =>
                                              removeStarRatingFields(index)
                                            }
                                            alt="add"
                                          />
                                        </Box>
                                      )}
                                    </Grid>
                                  </Grid>
                                </>
                              );
                            })}

                            <Grid item md={12}>
                              <Grid item md={1}></Grid>
                              <Grid item md={1} sx={{ marginTop: "1%" }}>
                                <img
                                  src={addPlusIcon}
                                  onClick={addStarRatingFields}
                                  alt="add"
                                />
                              </Grid>
                              <Grid item md={10}></Grid>
                            </Grid>
                          </>
                        ) : null}
                        <Grid item md={12} sx={{ paddingTop: "20px" }}>
                          {options !== "" ? (
                            <Button
                              variant="contained"
                              className={classes.btnStyle}
                              onClick={() => QuestionValidation()}
                            >
                              submit
                            </Button>
                          ) : null}
                        </Grid>
                      </Box>
                    </Box>
                  </>
                }
              />
              <Modal
                open={modalOpenUpdateQuestion}
                handleClose={handleModalCloseUpdateQuestion}
                className={classes.modal_AddQuestionStyle}
                content={
                  <>
                    <Box className={classes.modal_content_AddQuestionStyle}>
                      <header>
                        <h4 className="titleModal">Write</h4>
                        <CloseIcon onClick={handleModalCloseUpdateQuestion} />
                      </header>
                      <Box className={classes.modalContent_AddQue}>
                        <Grid container spacing={3}>
                          <Grid item md={1}>
                            <h4 className="que">Q 1</h4>
                          </Grid>

                          <InputTextField
                            error={error && !questiontitleU ? true : false}
                            helperText={
                              error && !questiontitleU
                                ? "Required Question Title"
                                : ""
                            }
                            md={7}
                            element="TextField"
                            label="Type your question here"
                            value={questiontitleU}
                            onChange={(e) => setQuestionTitleU(e.target.value)}
                          />

                          <Grid item md={4} xs={12}>
                            <FormControl
                              fullWidth
                              // error={optionerror && !options ? true : false}
                            >
                              <InputLabel id="demo-simple-select-label">
                                Options
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={optionsU}
                                label="Gender"
                                onChange={(e) => setOptionsU(e.target.value)}
                                // defaultValue={rowId.question_type}
                              >
                                {question_type.map((item) => {
                                  console.log("item", item);
                                  return (
                                    <MenuItem value={item.name}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        {optionsU === "Multiple Choice Question" ? (
                          <>
                            {formFieldsU.map((item, index) => {
                              console.log("item update::", item);
                              return (
                                <>
                                  <Grid
                                    key={index}
                                    container
                                    spacing={3}
                                    style={{ marginTop: "0.2%" }}
                                  >
                                    <Grid item md={1} xs={12}></Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        id="outlined-basic"
                                        label={"Options"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeOptionsU(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.option}
                                      />
                                    </Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        id="outlined-basic"
                                        label={"Comments"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeCommentsU(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.sub_question}
                                      />
                                    </Grid>
                                    <Grid item md={1} xs={12}>
                                      {index !== 0 && (
                                        <Box
                                          sx={{
                                            marginTop: "10px",
                                          }}
                                          display="flex"
                                          alignItems={"center"}
                                          justifyContent={"space-between"}
                                        >
                                          <img
                                            src={deleteIcon}
                                            onClick={() => removeFieldsU(index)}
                                            alt="add"
                                          />
                                        </Box>
                                      )}
                                    </Grid>
                                  </Grid>
                                </>
                              );
                            })}

                            <Grid item md={12}>
                              <Grid item md={1}></Grid>
                              <Grid item md={1} sx={{ marginTop: "1%" }}>
                                <img
                                  src={addPlusIcon}
                                  onClick={addFieldsU}
                                  alt="add"
                                />
                              </Grid>
                              <Grid item md={10}></Grid>
                            </Grid>
                          </>
                        ) : optionsU === "Rating" ? (
                          <>
                            {" "}
                            {ratingU.map((item, index) => {
                              return (
                                <>
                                  <Grid
                                    key={index}
                                    container
                                    spacing={3}
                                    style={{ marginTop: "0.2%" }}
                                  >
                                    <Grid item md={1} xs={12}></Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        id="outlined-basic"
                                        label={"Options"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeRatingOptionsU(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.option}
                                      />
                                    </Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        id="outlined-basic"
                                        label={"Comments"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeRatingCommentsU(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.sub_question}
                                      />
                                    </Grid>
                                    <Grid item md={1} xs={12}>
                                      {index >= 5 && (
                                        <Box
                                          sx={{
                                            marginTop: "10px",
                                          }}
                                          display="flex"
                                          alignItems={"center"}
                                          justifyContent={"space-between"}
                                        >
                                          <img
                                            src={deleteIcon}
                                            onClick={() =>
                                              removeRatingFieldsU(index)
                                            }
                                            alt="add"
                                          />
                                        </Box>
                                      )}
                                    </Grid>
                                  </Grid>
                                </>
                              );
                            })}
                            <Grid item md={12}>
                              <Grid item md={1}></Grid>
                              <Grid item md={1} sx={{ marginTop: "1%" }}>
                                <img
                                  src={addPlusIcon}
                                  onClick={addRatingFieldsU}
                                  alt="add"
                                />
                              </Grid>
                              <Grid item md={10}></Grid>
                            </Grid>
                          </>
                        ) : optionsU === "Checkboxes" ? (
                          <>
                            {checkboxU.map((item, index) => {
                              console.log("item::", item);
                              return (
                                <>
                                  <Grid
                                    key={index}
                                    container
                                    spacing={3}
                                    style={{ marginTop: "0.2%" }}
                                  >
                                    <Grid item md={1} xs={12}></Grid>

                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        id="outlined-basic"
                                        label={"Options"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeCheckboxOptionsU(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.option}
                                      />
                                    </Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        id="outlined-basic"
                                        label={"Comments"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeCheckboxCommentsU(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.sub_question}
                                      />
                                    </Grid>
                                    <Grid item md={1} xs={12}>
                                      {index !== 0 && (
                                        <Box
                                          sx={{
                                            marginTop: "10px",
                                          }}
                                          display="flex"
                                          alignItems={"center"}
                                          justifyContent={"space-between"}
                                        >
                                          <img
                                            src={deleteIcon}
                                            onClick={() =>
                                              removeCheckboxFieldsU(index)
                                            }
                                            alt="add"
                                          />
                                        </Box>
                                      )}
                                    </Grid>
                                  </Grid>
                                </>
                              );
                            })}
                            <Grid item md={12}>
                              <Grid item md={1}></Grid>
                              <Grid item md={1} sx={{ marginTop: "1%" }}>
                                <img
                                  src={addPlusIcon}
                                  onClick={addCheckboxFieldsU}
                                  alt="add"
                                />
                              </Grid>
                              <Grid item md={10}></Grid>
                            </Grid>
                          </>
                        ) : optionsU === "Textbox" ? (
                          <>
                            {textboxU.map((item, index) => {
                              return (
                                <>
                                  <Grid
                                    key={index}
                                    container
                                    spacing={3}
                                    style={{ marginTop: "0.2%" }}
                                  >
                                    <Grid item md={1} xs={12}></Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        id="outlined-basic"
                                        label={"Maximum length"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeTextboxOptionsU(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.option}
                                      />
                                    </Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        id="outlined-basic"
                                        label={"Minimum length"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeTextboxCommentsU(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.sub_question}
                                      />
                                    </Grid>
                                    <Grid item md={1} xs={12}>
                                      {index !== 0 && (
                                        <Box
                                          sx={{
                                            marginTop: "10px",
                                          }}
                                          display="flex"
                                          alignItems={"center"}
                                          justifyContent={"space-between"}
                                        >
                                          <img
                                            src={deleteIcon}
                                            onClick={() =>
                                              removeTextboxFieldsU(index)
                                            }
                                            alt="add"
                                          />
                                        </Box>
                                      )}
                                    </Grid>
                                  </Grid>
                                </>
                              );
                            })}
                            <Grid item md={12}>
                              <Grid item md={1}></Grid>
                              <Grid item md={1} sx={{ marginTop: "1%" }}>
                                <img
                                  src={addPlusIcon}
                                  onClick={addTextboxFieldsU}
                                  alt="add"
                                />
                              </Grid>
                              <Grid item md={10}></Grid>
                            </Grid>
                          </>
                        ) : optionsU === "Slider" ? (
                          <>
                            {" "}
                            {sliderU.map((item, index) => {
                              return (
                                <>
                                  <Grid
                                    key={index}
                                    container
                                    spacing={3}
                                    style={{ marginTop: "0.2%" }}
                                  >
                                    <Grid item md={1} xs={12}></Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        id="outlined-basic"
                                        label={"Options"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeSliderOptionsU(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.option}
                                      />
                                    </Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        id="outlined-basic"
                                        label={"Comments"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeSliderCommentsU(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.sub_question}
                                      />
                                    </Grid>
                                    <Grid item md={1} xs={12}>
                                      {index !== 0 && (
                                        <Box
                                          sx={{
                                            marginTop: "10px",
                                          }}
                                          display="flex"
                                          alignItems={"center"}
                                          justifyContent={"space-between"}
                                        >
                                          <img
                                            src={deleteIcon}
                                            onClick={() =>
                                              removeSliderFieldsU(index)
                                            }
                                            alt="add"
                                          />
                                        </Box>
                                      )}
                                    </Grid>
                                  </Grid>
                                </>
                              );
                            })}
                            <Grid item md={12}>
                              <Grid item md={1}></Grid>
                              <Grid item md={1} sx={{ marginTop: "1%" }}>
                                <img
                                  src={addPlusIcon}
                                  onClick={addSliderFieldsU}
                                  alt="add"
                                />
                              </Grid>
                              <Grid item md={10}></Grid>
                            </Grid>
                          </>
                        ) : optionsU === "Star Rating" ? (
                          <>
                            {starratingU.map((item, index) => {
                              return (
                                <>
                                  <Grid
                                    key={index}
                                    container
                                    spacing={3}
                                    style={{ marginTop: "0.2%" }}
                                  >
                                    <Grid item md={1} xs={12}></Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        id="outlined-basic"
                                        label={"Options"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeStarRatingOptionsU(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.option}
                                      />
                                    </Grid>
                                    <Grid item md={5} xs={12}>
                                      <TextField
                                        id="outlined-basic"
                                        label={"Comments"}
                                        variant="outlined"
                                        className={classes.TextField_Style}
                                        onChange={(e) => {
                                          onChangeStarRatingCommentsU(
                                            e.target.value,
                                            index
                                          );
                                        }}
                                        value={item.sub_question}
                                      />
                                    </Grid>
                                    <Grid item md={1} xs={12}>
                                      {index >= 5 && (
                                        <Box
                                          sx={{
                                            marginTop: "10px",
                                          }}
                                          display="flex"
                                          alignItems={"center"}
                                          justifyContent={"space-between"}
                                        >
                                          <img
                                            src={deleteIcon}
                                            onClick={() =>
                                              removeStarRatingFieldsU(index)
                                            }
                                            alt="add"
                                          />
                                        </Box>
                                      )}
                                    </Grid>
                                  </Grid>
                                </>
                              );
                            })}

                            <Grid item md={12}>
                              <Grid item md={1}></Grid>
                              <Grid item md={1} sx={{ marginTop: "1%" }}>
                                <img
                                  src={addPlusIcon}
                                  onClick={addStarRatingFieldsU}
                                  alt="add"
                                />
                              </Grid>
                              <Grid item md={10}></Grid>
                            </Grid>
                          </>
                        ) : optionsU === "Date/Time" ? (
                          <></>
                        ) : null}
                        <Grid item md={12} sx={{ paddingTop: "20px" }}>
                          {optionsU !== "" ? (
                            <Button
                              variant="contained"
                              className={classes.btnStyle}
                              onClick={() => updatevalidation()}
                            >
                              update
                            </Button>
                          ) : null}
                        </Grid>
                      </Box>
                    </Box>
                  </>
                }
              />
              <Box sx={{ width: "100%" }}>
                <Paper sx={{ width: "95%", mb: 2, marginTop: "20px" }}>
                  <EnhancedTableToolbar numSelected={selected.length} />
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 750, padding: 5 }}
                      aria-labelledby="tableTitle"
                      size={dense ? "small" : "medium"}
                    >
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={10}
                        setSortBy={sortbyFunction}
                        setSortOrder={Reorder}
                      />
                      <TableBody>
                        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                        {que.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row.title)}
                              role="checkbox"
                              // aria-checked={isItemSelected}
                              tabIndex={-1}
                              // key={row.name}
                              // selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                {/* <Checkbox
                                  color="primary"
                                  // checked={isItemSelected}
                                  inputProps={
                                    {
                                      // "aria-labelledby": labelId,
                                    }
                                  }
                                /> */}
                              </TableCell>
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {row.title}
                              </TableCell>
                              <TableCell align="left">
                                {row.question_type}
                              </TableCell>
                              <TableCell align="left">{row.status}</TableCell>
                              {/* <TableCell align="left">{row.is_required}</TableCell>
              <TableCell align="left">{row.is_anonymous}</TableCell> */}

                              <TableCell align="left">
                                {row.created_by}
                              </TableCell>
                              <TableCell align="left">
                                {moment(row.created_date).format("DD-MM-YYYY")}
                              </TableCell>
                              <TableCell align="left">
                                <Box
                                  justifyContent="center"
                                  flexDirection="row"
                                  display="flex"
                                  alignItems={"center"}
                                  sx={{ margin: "7px" }}
                                >
                                  <IconButton
                                    onClick={(e) => {
                                      handleClickMenu(e);
                                      setRowId(row);
                                    }}
                                  >
                                    <MoreVertRoundedIcon
                                      width={20}
                                      height={20}
                                    />
                                  </IconButton>
                                </Box>
                                {/* 
                {openDialog ? (
                  <DialogComp
                    title={"Edit Question Bank"}
                    open={openDialog}
                    onClose={handleToCloseDialog}
                    label1="Name"
                    label2="Description"
                    value1={name}
                    // onChange1={(e) => setName(e.target.value)}
                    value2={desc}
                    onChange2={(e) => setDesc(e.target.value)}
                    text={"save"}
                    onClick={() => updateApicall(rowId.question_bank_id)}
                    onClickclose={handleToCloseDialog}
                  />
                ) : (
                  ""
                )}
                {openCreateDialog ? (
                  <DialogComp
                    title={"Create Question Bank "}
                    open={openCreateDialog}
                    onClose={handleToCloseCreateDialog}
                    label1="Name"
                    label2="Description"
                    value1={nameC}
                    onChange1={(e) => setNameC(e.target.value)}
                    value2={descC}
                    onChange2={(e) => setDescC(e.target.value)}
                    // value3={statusC}
                    // onChange3={(e) => setStatusC(e.target.value)}
                    text={"create"}
                    onClick={() => createApiCall()}
                    onClickclose={handleToCloseCreateDialog}
                  />
                ) : (
                  ""
                )} */}
                                <Menu
                                  // className={classes.menu}
                                  id={`simple-menu-${rowId}`}
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={handleCloseMenu}
                                  elevation={0}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      setAnchorEl(null);
                                      handleModalOpenUpdateQuestion(rowId);
                                      getQuestionTypeApicall();
                                      console.log(
                                        "rowId",
                                        JSON.stringify(rowId)
                                      );
                                    }}
                                  >
                                    Edit
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      deleteApiCall(rowId.question_id)
                                    }
                                  >
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </TableCell>
                            </TableRow>
                          );
                        })}

                        {emptyRows > 0 && (
                          <TableRow
                            style={{
                              height: (dense ? 38 : 38) * emptyRows,
                            }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid container spacing={2} sx={{ padding: "10px" }}>
                    <Grid item xs={7}>
                      <Box
                        display="flex"
                        alignItems={"center"}
                        flexDirection="row"
                      >
                        <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "14px",
                            lineHeight: "20px",
                          }}
                        >
                          Rows Per Page
                        </Typography>
                        <FormControl
                          variant="standard"
                          sx={{ m: 0, minWidth: 50 }}
                        >
                          {/* <InputLabel
                            style={{
                              marginLeft: "20px",
                              position: "absolute",
                              marginTop: "0px",
                            }}
                          >
                            {selecteds === "" ? 10 : selected}
                          </InputLabel> */}
                          <Select
                            size="small"
                            value={selecteds}
                            onChange={selectionChangeHandler}
                            disableUnderline
                            style={{
                              textAlign: "center",
                              fontSize: "14px",
                              paddingLeft: 10,
                              paddingTop: 8,
                              ":before": {
                                borderColor: "#fafafc",
                              },
                              ":after": {
                                borderColor: "#fafafc",
                              },
                              height: 30,
                              justifyContent: "center",
                            }}
                            //   SelectProps={{ disableUnderline: true }}
                          >
                            <MenuItem
                              value={1}
                              onClick={() => {
                                setMaxRow(10);
                              }}
                            >
                              10
                            </MenuItem>
                            <MenuItem
                              value={2}
                              onClick={() => {
                                setMaxRow(20);
                              }}
                            >
                              20
                            </MenuItem>
                            <MenuItem
                              value={3}
                              onClick={() => {
                                setMaxRow(50);
                              }}
                            >
                              50
                            </MenuItem>
                            <MenuItem
                              value={4}
                              onClick={() => {
                                setMaxRow(100);
                              }}
                            >
                              100
                            </MenuItem>
                          </Select>
                        </FormControl>
                        {/* <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "13px",
                            lineHeight: "20px",
                          }}
                        >
                          of {total_record_count}
                        </Typography> */}
                      </Box>
                    </Grid>
                    <Grid item xs={4} sx={{ marginLeft: "40px" }}>
                      <Pagination
                        style={{ marginTop: "2%", marginLeft: "5%" }}
                        onChange={handlePageChange}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        page={pages}
                        rowsPerPage={rowsPerPage}
                        count={pageCount || 0}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
              <Notification
                message={
                  alertContent
                    ? alertContent
                    : `Success : Data Fetched Successfully.`
                }
                open={alert}
                severity={alertType}
                onClose={handleAlert}
              />
              <Dialog open={openuploadDialog} onClose={handleCloseUpload}>
                <DialogTitle>{"Upload File"}</DialogTitle>
                <DialogContent>
                  <Box
                    flexDirection={"column"}
                    display="flex"
                    alignItems="center"
                    sx={{ width: "500px" }}
                  >
                    <Form
                      onChange={(state) => console.log("onChange =>", state)}
                    >
                      <MaterialuiDropzone handleChange={(e) => setCsvFile(e)} />
                    </Form>
                    <Link href="#" onClick={() => handleDownloadClick()}>
                      Click here to download sample file
                    </Link>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseUpload}>Discard</Button>
                  <Button
                    id="btn"
                    color="primary"
                    autoFocus
                    onClick={() => bulkDataUpload()}
                  >
                    {"upload"}
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </>
        </Box>
      </Box>
    </>
  );
};

export default UserQuestion;
