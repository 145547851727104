import React, { useEffect, useState, useRef } from "react";
import { Bar } from "react-chartjs-2";

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  aspectRatio: 14,
  indexAxis: "y",
  responsive: true,
  scales: {
    x: {
      stacked: true,
      display: true,
      ticks: {
        padding: 1,
        display: true,
        callback: function (val, index) {
          return val <= 1
              ? `${val * 100}%`
              : `${val}%`
        },
        font: {
          size: 10,
          weight: 500,
        },
      },
      grid: {
        display: false,
        drawBorder: false,
      },
    },
    y: {
      stacked: true,
      display: false,
    },
  },
};

const labels = ["2014"];

const data = {
  labels,
  datasets: [
    {
      label: "yes",
      data: [727],
      borderRadius: "50",
      backgroundColor: "#29FD2E",
      hoverBackgroundColor: "#29FD2E",
      borderSkipped: "middle",
      barThickness: 15,
    },
    {
      label: "no",
      data: [238],
      borderRadius: "50",
      backgroundColor: "#D44753",
      hoverBackgroundColor: "#D44753",
      borderSkipped: "middle",
      barThickness: 15,
    },
  ],
};

const listItemStyle = {
  color: "#333",
  listStyle: "none",
  textAlign: "left",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

const StackHorizontalBar = (props) => {
  const chartRef = useRef();
  const [legends, setLegends] = React.useState([]);
  const [data, setData] = React.useState([]);

  const { ulStyles = {} } = props;

  useEffect(() => {
    const component = chartRef;
    setData(component.current.data.datasets);
    setLegends(component.current.legend.legendItems);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flex: "1 1 auto",
        ...props.containerStyle,
      }}
    >
      <ul
        className="mt-8"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
          ...ulStyles,
        }}
      >
        {legends.length &&
          legends.map((item, key) => {
            return (
              <li key={item.text} style={listItemStyle}>
                <div
                  style={{
                    marginRight: "10px",
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: item.fillStyle,
                    display: 'flex',
                    flexShrink: '0'
                  }}
                />
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    margin: 0,
                    color: "#1D2D3A",
                  }}
                >
                  {item.text}{" "}
                  <span style={{fontWeight: "700"}}>{` (${
                      data[key].data[0] || 0
                  }%)`}</span>
                </p>
              </li>
            );
          })}
      </ul>
      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'flex-end'}}>
        <Bar
            ref={chartRef}
            options={{...options, ...props.options}}
            data={props.data}
        />
      </div>
    </div>
  );
};

export default StackHorizontalBar;
