import moment from "moment";
import React from "react";

class UtilHelper {
  static validateName = (value) => {
    const regex = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    return !(value && !regex.test(value));
  };

  static validateNameWithNumber = (value) => {
    const regex = /^[a-zA-Z]+(([',. -][a-zA-Z0-9 ])?[a-zA-Z10-9]*)*$/;
    return !(value && !regex.test(value));
  };

  static validatePassword = (value) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;
    return !(value && !regex.test(value));
  };

  static validateZipcode = (value) => {
    const regex =
      /^[A-Za-z]{1,2}[0-9]{1,2}[0-9A-Za-z ]*$|^\d{5,6}$|^\d{5}-\d{4}$/;
    return !(value && !regex.test(value));
  };

  static validateBlankSpaces = (value) => {
    if (!Array.isArray(value)) {
      return !(value && value.trim().length === 0);
    }
    return true;
  };

  static validatePattern = (value, regex) => {
    return !regex.test(value);
  };

  static validateAlphaNumeric = (value) => {
    const regex = /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/;
    return !(value && !regex.test(value));
  };

  static validateSpecialCharacter = (value) => {
    const regex = /^[a-zA-Z0-9]+([a-zA-Z0-9 _-]*)*$/;
    return !(value && !regex.test(value));
  };

  static validatePositiveNumberOnly = (value) => {
    if (/^[+-]?\d+(\.\d+)?$/.test(value)) {
      return value >= 0;
    }
    return false;
  };

  static validatePercentage = (value) => {
    return value <= 100;
  };

  static validatePermissions = (value) => {
    return value > 0;
  };

  static trimObject = (myObject) => {
    const keys = Object.keys(myObject);
    // const values = Object.values();
    for (let i = 0; i < keys.length; i += 1) {
      if (typeof myObject[keys[i]] === "string" && myObject[keys[i]] !== "") {
        myObject[keys[i]] = myObject[keys[i]].trim();
      }
      // for (const key in myObject) {
      //   if (typeof myObject[key] === 'string' && myObject[key] !== '') {
      //     myObject[key] = myObject[key].trim();
      //   }
    }

    return myObject;
  };

  static isPasswordMatch = (newPassword, confirmPassword) => {
    return newPassword === confirmPassword;
  };

  static validateWebsite(str) {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  static moveElementsFromDragToDrop(
    source,
    destination,
    draggableSource,
    droppableDestination
  ) {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(draggableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[draggableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  }

  // static getUpdatedDifference = (date) => {
  //   const dateReceived = moment(date, 'DD-MM-YYYY hh:mm:ss');
  //   const today = moment();
  //   days
  //   const days = today.diff(dateReceived, 'days');
  //   if (days > 0) {
  //     return days + (days === 1 ? ' day' : ' days') + ' ago';
  //   } else {
  //     const hours = today.diff(dateReceived, 'hours');
  //     if (hours > 0) {
  //       return hours + (hours === 1 ? ' hour' : ' hours') + ' ago';
  //     } else {
  //       const minutes = today.diff(dateReceived, 'minutes');
  //       if (minutes > 0) {
  //         return minutes + (minutes === 1 ? ' minute' : ' minutes') + ' ago';
  //       } else {
  //         return 'just now';
  //       }

  //   }
  // };

  static addAllSubmenuToMenu = (menuId, menu) => {
    const submenus = [];
    menu.submenus.forEach((submenu) => {
      submenus.push(submenu.id);
    });

    return {
      id: menuId,
      submenus,
    };
  };

  static addSubmenuAndMenu = (menuId, submenu) => {
    const submenus = [];

    submenus.push(submenu);

    return {
      id: menuId,
      submenus,
    };
  };

  static onCheckField = (checkBoxValue, roleDetails, menus) => {
    const checkedKey = checkBoxValue.value;
    console.warn("roleDetails-", roleDetails);
    let menuId = null;
    let submenuId = null;
    let menu = null;
    let menuIndex;
    let submenuIndex;

    switch (checkedKey.substr(0, 1)) {
      case "M":
        menuId = parseInt(checkBoxValue.value.split("_")[1], 10);
        menuIndex = roleDetails.permissions.findIndex(
          (perm) => perm.id === menuId
        );
        menu = menus.find((menu) => menuId === menu.id);

        if (menuIndex > -1) {
          roleDetails.permissions.splice(menuIndex, 1);
        } else {
          const menuPermission = UtilHelper.addAllSubmenuToMenu(menuId, menu);
          roleDetails.permissions.push(menuPermission);
        }
        break;

      case "S":
        menuId = parseInt(checkBoxValue.value.split("_")[1], 10);
        submenuId = parseInt(checkBoxValue.value.split("_")[2], 10);
        menuIndex = roleDetails.permissions.findIndex(
          (perm) => perm.id === menuId
        );
        menu = menus.find((menu) => menuId === menu.id);

        if (menuIndex > -1) {
          submenuIndex = roleDetails.permissions[menuIndex].submenus.findIndex(
            (submenu) => submenu === submenuId
          );
          if (submenuIndex > -1) {
            roleDetails.permissions[menuIndex].submenus.splice(submenuIndex, 1);
          } else {
            roleDetails.permissions[menuIndex].submenus.push(submenuId);
          }

          if (roleDetails.permissions[menuIndex].submenus.length === 0) {
            roleDetails.permissions.splice(menuIndex, 1);
          }
        } else {
          const menuPermission = UtilHelper.addSubmenuAndMenu(
            menuId,
            submenuId
          );
          roleDetails.permissions.push(menuPermission);
        }
        break;

      default:
        break;
    }

    return roleDetails;
  };
  static onCheckField = (checkBoxValue, roleDetails, menus) => {
    const checkedKey = checkBoxValue.value;
    console.warn("roleDetails-", roleDetails);
    let menuId = null;
    let submenuId = null;
    let menu = null;
    let menuIndex;
    let submenuIndex;

    switch (checkedKey.substr(0, 1)) {
      case "M":
        menuId = parseInt(checkBoxValue.value.split("_")[1], 10);
        menuIndex = roleDetails.permissions.findIndex(
          (perm) => perm.id === menuId
        );
        menu = menus.find((menu) => menuId === menu.id);

        if (menuIndex > -1) {
          roleDetails.permissions.splice(menuIndex, 1);
        } else {
          const menuPermission = UtilHelper.addAllSubmenuToMenu(menuId, menu);
          roleDetails.permissions.push(menuPermission);
        }
        break;

      case "S":
        menuId = parseInt(checkBoxValue.value.split("_")[1], 10);
        submenuId = parseInt(checkBoxValue.value.split("_")[2], 10);
        menuIndex = roleDetails.permissions.findIndex(
          (perm) => perm.id === menuId
        );
        menu = menus.find((menu) => menuId === menu.id);

        if (menuIndex > -1) {
          submenuIndex = roleDetails.permissions[menuIndex].submenus.findIndex(
            (submenu) => submenu === submenuId
          );
          if (submenuIndex > -1) {
            roleDetails.permissions[menuIndex].submenus.splice(submenuIndex, 1);
          } else {
            roleDetails.permissions[menuIndex].submenus.push(submenuId);
          }

          if (roleDetails.permissions[menuIndex].submenus.length === 0) {
            roleDetails.permissions.splice(menuIndex, 1);
          }
        } else {
          const menuPermission = UtilHelper.addSubmenuAndMenu(
            menuId,
            submenuId
          );
          roleDetails.permissions.push(menuPermission);
        }
        break;

      default:
        break;
    }

    return roleDetails;
  };

  static rangeGenerator = (a, b, step) => {
    const A = [];
    A[0] = a.toString();
    step = step || 1;
    while (a + step <= b) {
      A[A.length] = (a += step).toString();
    }

    return A;
  };

  /**
   * Function is used to create the permission list when role is update
   * @param menus
   */
  static createPermissionListForUpdate = (menus) => {
    const defaultPermissions = [];
    menus.forEach((menu) => {
      const newMenu = {
        id: menu.id,
        submenus: [],
      };
      menu.submenus.forEach((submenu) => {
        newMenu.submenus.push(submenu.id);
      });

      defaultPermissions.push(newMenu);
    });

    return defaultPermissions;
  };

  /**
   * Function is used to collect removed permission list
   */
  static collectListOfRemovePermission = (
    defaultPermissionsForUpdate,
    newPermissions
  ) => {
    const removedPermissions = [];
    defaultPermissionsForUpdate.forEach((item) => {
      const data = {
        id: item.id,
        submenus: [],
      };
      const findArr = newPermissions.find((newP) => newP.id === item.id);
      if (findArr) {
        item.submenus.forEach((submenu) => {
          const findSubmenu = findArr.submenus.find((sm) => sm === submenu);
          if (!findSubmenu) data.submenus.push(submenu);
        });
        if (data.submenus.length > 0) removedPermissions.push(data);
      } else {
        removedPermissions.push(item);
      }
    });

    return removedPermissions;
  };

  static isFormUpdated = (newData, oldData) => {
    let formUpdated = false;
    if (oldData) {
      Object.keys(newData).forEach((key) => {
        if (JSON.stringify(newData[key]) !== JSON.stringify(oldData[key])) {
          formUpdated = true;
        }
      });
    } else {
      formUpdated = true;
    }
    return formUpdated;
  };

  static capitalizeLetterByUnderscore = (words) => {
    const separateWord = words.toLowerCase().split("_");
    for (let i = 0; i < separateWord.length; i += 1) {
      separateWord[i] =
        separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
    }
    return separateWord.join(" ");
  };

  static numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
}

export default UtilHelper;
