import React from "react";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  profile: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    "& .profileTitle": {
      fontFamily: "'Montserrat'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
      marginBottom: "8px",
    },
    "& .profileSubTitle": {},
  },
});

const ProfileImgTitle = (props) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.profile} ${props.className}`}
      style={props.style}
    >
      {props.image !== undefined ? (
        <img src={props.image} style={{ height: "70px", width: "100px" }} />
      ) : null}
      <div>
        <h2 className="profileTitle">{props.title}</h2>
        <h6 className="profileSubTitle">{props.subTitle}</h6>
      </div>
    </div>
  );
};

export default ProfileImgTitle;
