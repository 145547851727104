import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "../../component/header/Header";
import LeftDrawer from "../../component/leftDrawer/LeftDrawer";
import { MaterialuiDropzone } from "../../component/Upload/MaterialuiDropzone";
import { Form } from "usetheform";
import InputLabel from "@material-ui/core/InputLabel";
import {
  Pagination,
  PaginationItem,
  usePagination,
  TablePagination,
} from "@mui/material";
import {
  Typography,
  Box,
  ButtonGroup,
  Button,
  Grid,
  MenuItem,
  Select,
  FormControl,
  // Menu,
  TextField,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DialogComp from "../../component/dialog/dialog";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import {
  SearchBlack,
  Dots,
  Arrow,
  downArrow,
  Plus,
  downArrowWhite,
} from "../../component/Images";
import InputBox from "../../component/UIElements/InputBox";
import AvatarComp from "../../component/UIElements/Avatar";
import { useStyles } from "./Question.style";
import { Menu } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
  getQuestionBankList,
  createQuestionBankList,
  updateQuestionBankList,
  deleteQuestionBankList,
  getSearchQuestionBankList,
  getSortQbankList,
} from "../../thunk/Action";
import { useNavigate } from "react-router-dom";
import { Notification } from "../../Utils/SnackbarNotification/Notification";
import { useEffect } from "react";
import moment from "moment";
import API from "../../E2E/Axios.Utils";
import FileSaver from "file-saver";
import Permission from "../../component/Permission/Permission";
import SearchNotFound from "../../Utils/SearchNotFound/SearchNotFount";

//drawer

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
});

const headCells = [
  {
    id: "NAME",
    numeric: false,
    disablePadding: true,
    label: "NAME",
  },
  {
    id: "DESCRIPTION",
    numeric: true,
    disablePadding: false,
    label: "DESCRIPTION",
  },
  {
    id: "STATUS",
    numeric: true,
    disablePadding: false,
    label: "STATUS",
  },
  {
    id: "CREATED_BY",
    numeric: true,
    disablePadding: false,
    label: "CREATED_BY",
  },
  {
    id: "CREATED_DATE",
    numeric: true,
    disablePadding: false,
    label: "CREATED_DATE",
  },
  {
    id: "ACTIONS",
    numeric: true,
    disablePadding: false,
    label: "ACTIONS",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const handlesort = (val) => {
    props.setSortBy(val);
    props.setSortOrder(val);
    console.log(val);
  };
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "#128CB0", height: 50 }}>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
            style={{
              color: "#fff",
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              color: "#fff",
              "&.MuiTableRow-root:hover": {
                color: "#fff",
              },
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              {headCell.label}
              {headCell.label !== "" ? (
                <>
                  <Box
                    sx={{ marginLeft: "5px", paddingTop: "3px" }}
                    onClick={() => {
                      handlesort(headCell.label);
                    }}
                  >
                    <AvatarComp image={Arrow} height={15} />
                  </Box>
                </>
              ) : null}
            </div>
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {headCell.label !== "" ? (
                <>
                  <Box sx={{ marginLeft: "5px", paddingTop: "4px" }}>
                    <AvatarComp image={Arrow} height={15} />
                  </Box>
                </>
              ) : null}

              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  const classes = useStyles();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 &&
          {
            // bgcolor: (theme) =>
            //   alpha(
            //     theme.palette.primary.main,
            //     theme.palette.action.activatedOpacity
            //   ),
          }),
      }}
    >
      {numSelected > 0 ? (
        // <Typography
        //   sx={{ flex: "1 1 100%" }}
        //   color="inherit"
        //   variant="subtitle1"
        //   component="div"
        // >
        //   {/* {numSelected} selected */}
        // </Typography>
        <Box className={classes.searchBox}>
          <Box sx={{ paddingTop: "4px" }}>
            <AvatarComp image={SearchBlack} />
          </Box>

          <InputBox placeholder="Search" onChange={props.onChange} />
        </Box>
      ) : (
        <Box className={classes.searchBox}>
          <Box sx={{ paddingTop: "4px" }}>
            <AvatarComp image={SearchBlack} />
          </Box>

          <InputBox placeholder="Search" onChange={props.onChange} />
        </Box>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const Questionbank = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [searchPages, setSearchPages] = useState(1);
  const [pages, setPages] = useState(1);
  const [pageCount, setPageCount] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = React.useState([]);
  // console.log("searchhhhhh", search);
  const [searchText, setSearchText] = React.useState("");
  const [questionCount, setQuestionCount] = React.useState("All");
  // console.log("searchText", searchText);

  // For CSV File

  const [csvData, setCsvData] = React.useState([]);

  const handlechangesearch = (e) => {
    setSearchText(e.target.value);
    // console.log("e===>", e.target.value);
  };
  React.useEffect(() => {
    if (searchText !== "") {
      const request = {
        token: token,
        data: {
          query: searchText,
          status: questionCount,
          page_no: "page",
        },
        onSuccess: (res) => {
          setSearch(res?.data?.question_bank_list);
        },
        onFail: (res) => {
          setSearch([]);
        },
      };
      dispatch(getSearchQuestionBankList(request)); //chnges
    } else {
      // const abc = [];
      // setSearch(abc);
    }
  }, [searchText, questionCount]);

  const navigate = useNavigate();

  const theme = useTheme();

  const [que, setQue] = React.useState([]);

  const [rowId, setRowId] = useState(0);
  const [opendrawer, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [addEmployee, setAddEmployee] = React.useState(null);
  const [total, setTotal] = useState("");
  const [disable, setDisable] = useState("");
  const [active, setActive] = useState("");
  const [name, setName] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [id, setId] = React.useState();
  const [nameC, setNameC] = React.useState("");
  const [descC, setDescC] = React.useState("");
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [pagetable, setPageTable] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [totalRecord, setTotalRecord] = React.useState("");
  const [sortby, setSortBy] = useState("");
  const [sortorder, setSortOrder] = useState(false);

  const [openuploadDialog, setOpenuploadDialog] = React.useState(false);
  const openFileUpload = () => {
    setOpenuploadDialog(true);
  };

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    console.log("Pages:::", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPages(1);
    console.log("rowPerPage:::", rowsPerPage);
  };

  const handleCloseUpload = () => {
    setOpenuploadDialog(false);
  };
  const handleClickMenuAddEmployee = (event) => {
    setAddEmployee(event.currentTarget);
  };

  const handleCloseMenuAddEmployee = () => {
    setAddEmployee(null);
  };
  const [selecteds, setSelecteds] = useState("");

  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("");

  const handleAlert = () => {
    setAlert(false);
  };

  const token = localStorage.getItem("token").toString();
  const [maxrow, setMaxRow] = useState("");

  const getApicall = () => {
    const request = {
      token: token,
      data: {
        page: pages,
        max_rows: maxrow,
        sort_by: sortby,
        status: questionCount,
      },
      onSuccess: (res) => {
        if (res?.status === 200) {
          setQue(res?.data?.question_bank_list);
          setActive(res?.data?.total_active_question_bank);
          setDisable(res?.data?.total_inactive_question_bank);
          setTotal(res?.data?.total_question_bank);
          setTotalRecord(res?.data?.total_record_count);
          setPageCount(res?.data?.total_page_count);
          setSortBy(sortby);
          //  setAlertContent(res?.data?.message)
          setAlertType("success");
          setAlert(true);
        }
      },
      onFail: (error) => {
        // setIsLoading(false);
        if (error?.response?.status === 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          navigate("/login");
        } else {
          setAlertContent(error?.response?.data?.message);
          setAlertType("error");
          setAlert(true);
        }
      },
    };
    dispatch(getSortQbankList(request));
  };
  React.useEffect(() => {
    getApicall();
  }, [pages, maxrow, sortby, questionCount]);

  const Reorder = (val) => {
    console.log("REORDERVAL::", val);
    // console.log("LOG:::",!sortorder );
    setSortOrder(!sortorder);

    if (val === "NAME" && sortorder) {
      setSortBy("-name");
    }

    if (val === "DESCRIPTION" && !sortorder) {
      setSortBy("-description");
    }

    if (val === "STATUS" && sortorder) {
      setSortBy("-status");
    }
    if (val === "CREATED_BY" && !sortorder) {
      setSortBy("-created_by");
    }
    if (val === "CREATED_DATE" && sortorder) {
      setSortBy("-created_date");
    }
  };

  // useEffect(() => {
  //   const request = {
  //     token: token,
  //     data: {
  //       page: pages,
  //       max_rows: maxrow,
  //       sort_by: sortby,
  //     },
  //     onSuccess: (res) => {
  //       setQue(res?.data?.question_bank_list);
  //       setSortBy(sortby);
  //     },
  //   };
  //   dispatch(getSortQbankList(request)); //chnges
  // }, [pages, maxrow, sortby]);

  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickToOpenDialog = (rowId) => {
    setOpenDialog(true);
    setName(rowId.name);
    setDesc(rowId.description);
  };

  const handleToCloseDialog = () => {
    setOpenDialog(false);
  };

  const updateApicall = (id, newState) => {
    const request = {
      token: token,
      id: id,
      name: name,
      description: desc,
      onSuccess: (res) => {
        if (res?.status === 200) {
          // console.log("res:::", res);
          setAlertContent(res?.data?.message);
          setAlertType("success");
          setAlert(true);
          getApicall();
        }
      },
      onFail: (error) => {
        // setIsLoading(false);
        if (error?.response?.status === 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          navigate("/login");
        } else {
          setAlertContent(error?.response?.data?.message);
          setAlertType("error");
          setAlert(true);
        }
      },
      // onFail: (error) => {
      //   // setIsLoading(false);
      //   setAlertContent(error?.response?.data?.message);
      //   setAlertType("error");
      //   setAlert(true);
      //   if (error?.response?.status === 400) {
      //     // console.log("bad request");
      //     // Alert.alert(strings.error, error?.response?.data?.message);
      //   }
      // },
    };
    dispatch(updateQuestionBankList(request));
    handleToCloseDialog();
    // dispatch(QuestionRequest());
  };
  const handleClickToOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleToCloseCreateDialog = () => {
    setOpenCreateDialog(false);
  };

  const createApiCall = (newState) => {
    const request = {
      token: token,
      name: nameC,
      description: descC,
      onSuccess: (res) => {
        if (res?.status === 200) {
          // console.log("res:::", res);
          setAlertContent(res?.data?.message);
          setAlertType("success");
          setAlert(true);
          getApicall();
        }
      },
      onFail: (error) => {
        // setIsLoading(false);
        if (error?.response?.status === 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          navigate("/login");
        } else {
          setAlertContent(error?.response?.data?.message);
          setAlertType("error");
          setAlert(true);
        }
      },
      // onFail: (error) => {
      //   // setIsLoading(false);
      //   setAlertContent(error?.response?.data?.message);
      //   setAlertType("error");
      //   setAlert(true);
      //   if (error?.response?.status === 400) {
      //     // console.log("bad request");
      //     // Alert.alert(strings.error, error?.response?.data?.message);
      //   }
      // },
    };
    dispatch(createQuestionBankList(request));
    handleToCloseCreateDialog();
  };

  const downloadCsv = async (QuestionId) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await API.get(
      `/app/secure/export_que_bank_details?que_bank_id=${QuestionId}`,
      config
    )
      .then((res) => {
        setCsvData(res.data.link);
        FileSaver.saveAs(res.data.link, "csvFile");
        handleCloseMenu();
        getApicall();
        // console.log("data", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteApiCall = (id, newState) => {
    const request = {
      token: token,
      id: id,
      onSuccess: (res) => {
        if (res?.status === 200) {
          // console.log("res:::", res);
          setAlertContent(res?.data?.message);
          setAlertType("success");
          setAlert(true);
          getApicall();
        }
      },
      onFail: (error) => {
        // setIsLoading(false);
        if (error?.response?.status === 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          navigate("/login");
        } else {
          setAlertContent(error?.response?.data?.message);
          setAlertType("error");
          setAlert(true);
        }
      },
      // onFail: (error) => {
      //   // setIsLoading(false);
      //   setAlertContent(error?.response?.data?.message);
      //   setAlertType("error");
      //   setAlert(true);
      //   if (error?.response?.status === 400) {
      //     // console.log("bad request");
      //     // Alert.alert(strings.error, error?.response?.data?.message);
      //   }
      // },
    };
    dispatch(deleteQuestionBankList(request));
    handleCloseMenu();
  };

  const selectionChangeHandler = (event) => {
    setSelecteds(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = que.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const [anchorElC, setAnchorElC] = React.useState(null);
  const openC = Boolean(anchorElC);
  const handleClickC = (event) => {
    setAnchorElC(event.currentTarget);
  };
  const handleCloseC = () => {
    setAnchorElC(null);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    pagetable > 0 ? Math.max(0, (1 + pagetable) * rowsPerPage - que.length) : 0;

  const sortbyFunction = (val) => {
    console.log("val::", val);
    if (val === "NAME") {
      setSortBy("name");
    }
    if (val === "DESCRIPTION") {
      setSortBy("description");
    }
    if (val === "STATUS") {
      setSortBy("status");
    }
    if (val === "CREATED_BY") {
      setSortBy("created_by");
    }
    if (val === "CREATED_DATE") {
      setSortBy("created_date");
    }
  };
  return (
    <Permission menu="Question Bank">
      <>
        <Header />
        <Box className={classes.roots} sx={{ display: "flex" }}>
          <LeftDrawer open={opendrawer} />
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3 }}
            style={{ background: "#EEEEEE" }}
          >
            <>
              <Box>
                <Typography
                  sx={{
                    marginTop: "15px",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "20px",
                    lineHeight: "25px",
                    color: "#0B0B0C",
                  }}
                >
                  Question Bank
                </Typography>

                <Grid container>
                  {/* <Grid container sx={{ width: "95%" }}> */}
                  <Box>
                    <ButtonGroup
                      variant="text"
                      size="large"
                      color="inherit"
                      sx={{ marginTop: "12px" }}
                    >
                      <Button
                        sx={{
                          backgroundColor:
                            questionCount === "All" ? "rgb(193,193,193)" : "",
                          textTransform: "none",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          ":hover": {
                            fontWeight: 700,
                          },
                        }}
                        onClick={() => {
                          setQuestionCount("All");
                        }}
                      >
                        {`All (${total})`}
                      </Button>
                      <Button
                        sx={{
                          backgroundColor:
                            questionCount === "Inactive"
                              ? "rgb(193,193,193)"
                              : "",
                          textTransform: "none",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          ":hover": {
                            fontWeight: 700,
                          },
                        }}
                        onClick={() => {
                          setQuestionCount("Inactive");
                        }}
                      >
                        {`Disabled (${disable})`}
                      </Button>
                      <Button
                        sx={{
                          backgroundColor:
                            questionCount === "Active"
                              ? "rgb(193,193,193)"
                              : "",
                          textTransform: "none",
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "14px",
                          ":hover": {
                            fontWeight: 700,
                          },
                        }}
                        onClick={() => {
                          setQuestionCount("Active");
                        }}
                      >
                        {`Active (${active})`}
                      </Button>
                    </ButtonGroup>
                  </Box>

                  <Button
                    color="primary"
                    variant="contained"
                    sx={{
                      bgcolor: "#2D404E",
                      padding: "10px",
                      borderRadius: "20px",
                      width: "200px",
                      height: "36px",
                      marginLeft: "auto",
                      marginTop: 2,
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      display: "flex",
                      gap: "10px",
                    }}
                    onClick={handleClickToOpenCreateDialog}
                  >
                    Add Question Bank
                  </Button>
                  <Menu
                    // className={classes.menu}
                    id={`simple-menu-${rowId}`}
                    anchorEl={addEmployee}
                    keepMounted
                    open={Boolean(addEmployee)}
                    onClose={handleCloseMenuAddEmployee}
                    elevation={0}
                  >
                    <MenuItem
                      onClick={() => {
                        setAddEmployee(null);
                        handleClickToOpenCreateDialog();
                      }}
                    >
                      Add Question Bank
                    </MenuItem>
                    {/* <MenuItem
                  onClick={() => {
                    setAddEmployee(null);
                    openFileUpload();
                  }}
                >
                  Upload a file
                </MenuItem> */}
                  </Menu>
                </Grid>

                <Box sx={{ width: "100%" }}>
                  <Paper sx={{ mb: 2, marginTop: "20px" }}>
                    <EnhancedTableToolbar
                      numSelected={selected.length}
                      onChange={handlechangesearch}
                    />
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 750, padding: 5 }}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                      >
                        <EnhancedTableHead
                          numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          rowCount={10}
                          setSortBy={sortbyFunction}
                          setSortOrder={Reorder}
                        />
                        <TableBody>
                          {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                          {(searchText &&
                            (search?.length > 0 ? (
                              <>
                                {search.map((row, index) => {
                                  return (
                                    <TableRow
                                      hover
                                      onClick={(event) =>
                                        handleClick(event, row.name)
                                      }
                                      role="checkbox"
                                      // aria-checked={isItemSelected}
                                      tabIndex={-1}
                                      // key={row.name}
                                      // selected={isItemSelected}
                                    >
                                      <TableCell padding="checkbox">
                                        {/* <Checkbox
                                        color="primary"
                                        // checked={isItemSelected}
                                        inputProps={
                                          {
                                            // "aria-labelledby": labelId,
                                          }
                                        }
                                      /> */}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        // id={labelId}
                                        scope="row"
                                        padding="none"
                                        onClick={() =>
                                          navigate(
                                            `/question/userQuestion/${row.question_bank_id}/${row.name}`
                                          )
                                        }
                                      >
                                        {row.name}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.description}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.status}
                                      </TableCell>
                                      <TableCell align="left">
                                        {row.created_by}
                                      </TableCell>
                                      <TableCell align="left">
                                        {moment(row.created_date).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </TableCell>
                                      <TableCell align="left">
                                        <Box
                                          justifyContent="center"
                                          flexDirection="row"
                                          display="flex"
                                          alignItems={"center"}
                                          sx={{ margin: "7px" }}
                                        >
                                          <IconButton
                                            onClick={(e) => {
                                              handleClickMenu(e);
                                              setRowId(row);
                                            }}
                                          >
                                            <MoreVertRoundedIcon
                                              width={20}
                                              height={20}
                                            />
                                          </IconButton>
                                        </Box>

                                        {openDialog ? (
                                          <DialogComp
                                            title={"Edit Question Bank"}
                                            open={openDialog}
                                            onClose={handleToCloseDialog}
                                            label1="Name"
                                            label2="Description"
                                            value1={name}
                                            onChange1={(e) =>
                                              setName(e.target.value)
                                            }
                                            value2={desc}
                                            onChange2={(e) =>
                                              setDesc(e.target.value)
                                            }
                                            text={"save"}
                                            onClick={() =>
                                              updateApicall(
                                                rowId.question_bank_id
                                              )
                                            }
                                            onClickclose={handleToCloseDialog}
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {openCreateDialog ? (
                                          <DialogComp
                                            title={"Create Question Bank "}
                                            open={openCreateDialog}
                                            onClose={handleToCloseCreateDialog}
                                            label1="Name"
                                            label2="Description"
                                            value1={nameC}
                                            onChange1={(e) =>
                                              setNameC(e.target.value)
                                            }
                                            value2={descC}
                                            onChange2={(e) =>
                                              setDescC(e.target.value)
                                            }
                                            // value3={statusC}
                                            // onChange3={(e) => setStatusC(e.target.value)}
                                            text={"create"}
                                            onClick={() => createApiCall()}
                                            onClickclose={
                                              handleToCloseCreateDialog
                                            }
                                          />
                                        ) : (
                                          ""
                                        )}
                                        <Menu
                                          // className={classes.menu}
                                          id={`simple-menu-${rowId}`}
                                          anchorEl={anchorEl}
                                          keepMounted
                                          open={Boolean(anchorEl)}
                                          onClose={handleCloseMenu}
                                          elevation={0}
                                        >
                                          <MenuItem
                                            onClick={() =>
                                              downloadCsv(
                                                rowId.question_bank_id
                                              )
                                            }
                                          >
                                            Download
                                          </MenuItem>
                                          <MenuItem
                                            onClick={() => {
                                              setAnchorEl(null);
                                              handleClickToOpenDialog(rowId);
                                            }}
                                          >
                                            Edit
                                          </MenuItem>
                                          {/* <MenuItem
                                            onClick={() =>
                                              deleteApiCall(
                                                rowId.question_bank_id
                                              )
                                            }
                                          >
                                            Delete
                                          </MenuItem> */}
                                          {questionCount === "All" ? null : (
                                            <>
                                              {row.status === "Inactive" ? (
                                                <MenuItem
                                                  onClick={() =>
                                                    deleteApiCall(
                                                      rowId.question_bank_id
                                                    )
                                                  }
                                                >
                                                  Enable
                                                  <LockOpenIcon
                                                    style={{
                                                      height: "20px",
                                                      marginLeft: "10px",
                                                    }}
                                                  />
                                                </MenuItem>
                                              ) : (
                                                <MenuItem
                                                  onClick={() =>
                                                    deleteApiCall(
                                                      rowId.question_bank_id
                                                    )
                                                  }
                                                >
                                                  Disable
                                                  <LockIcon
                                                    style={{
                                                      height: "20px",
                                                      marginLeft: "10px",
                                                    }}
                                                  />
                                                </MenuItem>
                                              )}
                                            </>
                                          )}
                                        </Menu>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </>
                            ) : (
                              <TableRow>
                                <TableCell
                                  align="center"
                                  colSpan={12}
                                  sx={{ py: 3 }}
                                >
                                  <SearchNotFound />
                                </TableCell>
                              </TableRow>
                            ))) || (
                            <>
                              {que.map((row, index) => {
                                return (
                                  <TableRow
                                    hover
                                    onClick={(event) =>
                                      handleClick(event, row.name)
                                    }
                                    role="checkbox"
                                    // aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    // key={row.name}
                                    // selected={isItemSelected}
                                  >
                                    <TableCell padding="checkbox">
                                      {/* <Checkbox
                                        color="primary"
                                        // checked={isItemSelected}
                                        inputProps={
                                          {
                                            // "aria-labelledby": labelId,
                                          }
                                        }
                                      /> */}
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      // id={labelId}
                                      scope="row"
                                      padding="none"
                                      onClick={() =>
                                        navigate(
                                          `/question/userQuestion/${row.question_bank_id}/${row.name}`
                                        )
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      {row.name}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.description}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.status}
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.created_by}
                                    </TableCell>
                                    <TableCell align="left">
                                      {moment(row.created_date).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </TableCell>
                                    <TableCell align="left">
                                      <Box
                                        justifyContent="center"
                                        flexDirection="row"
                                        display="flex"
                                        alignItems={"center"}
                                        sx={{ margin: "7px" }}
                                      >
                                        <IconButton
                                          onClick={(e) => {
                                            handleClickMenu(e);
                                            setRowId(row);
                                          }}
                                        >
                                          <MoreVertRoundedIcon
                                            width={20}
                                            height={20}
                                          />
                                        </IconButton>
                                      </Box>

                                      {openDialog ? (
                                        <DialogComp
                                          title={"Edit Question Bank"}
                                          open={openDialog}
                                          onClose={handleToCloseDialog}
                                          label1="Name"
                                          label2="Description"
                                          value1={name}
                                          onChange1={(e) =>
                                            setName(e.target.value)
                                          }
                                          value2={desc}
                                          onChange2={(e) =>
                                            setDesc(e.target.value)
                                          }
                                          text={"save"}
                                          onClick={() =>
                                            updateApicall(
                                              rowId.question_bank_id
                                            )
                                          }
                                          onClickclose={handleToCloseDialog}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {openCreateDialog ? (
                                        <DialogComp
                                          title={"Create Question Bank "}
                                          open={openCreateDialog}
                                          onClose={handleToCloseCreateDialog}
                                          label1="Name"
                                          label2="Description"
                                          value1={nameC}
                                          onChange1={(e) =>
                                            setNameC(e.target.value)
                                          }
                                          value2={descC}
                                          onChange2={(e) =>
                                            setDescC(e.target.value)
                                          }
                                          // value3={statusC}
                                          // onChange3={(e) => setStatusC(e.target.value)}
                                          text={"create"}
                                          onClick={() => createApiCall()}
                                          onClickclose={
                                            handleToCloseCreateDialog
                                          }
                                        />
                                      ) : (
                                        ""
                                      )}
                                      <Menu
                                        // className={classes.menu}
                                        id={`simple-menu-${rowId}`}
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleCloseMenu}
                                        elevation={0}
                                      >
                                        <MenuItem
                                          onClick={() =>
                                            downloadCsv(rowId.question_bank_id)
                                          }
                                        >
                                          Download
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() => {
                                            setAnchorEl(null);
                                            handleClickToOpenDialog(rowId);
                                          }}
                                        >
                                          Edit
                                        </MenuItem>
                                        {questionCount === "All" ? null : (
                                          <>
                                            {row.status === "Inactive" ? (
                                              <MenuItem
                                                onClick={() =>
                                                  deleteApiCall(
                                                    rowId.question_bank_id
                                                  )
                                                }
                                              >
                                                Enable
                                                <LockOpenIcon
                                                  style={{
                                                    height: "20px",
                                                    marginLeft: "10px",
                                                  }}
                                                />
                                              </MenuItem>
                                            ) : (
                                              <MenuItem
                                                onClick={() =>
                                                  deleteApiCall(
                                                    rowId.question_bank_id
                                                  )
                                                }
                                              >
                                                Disable
                                                <LockIcon
                                                  style={{
                                                    height: "20px",
                                                    marginLeft: "10px",
                                                  }}
                                                />
                                              </MenuItem>
                                            )}
                                          </>
                                        )}
                                      </Menu>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </>
                          )}
                          {emptyRows > 0 && (
                            <TableRow
                              style={{
                                height: (dense ? 38 : 38) * emptyRows,
                              }}
                            >
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Grid container spacing={2} sx={{ padding: "10px" }}>
                      <Grid item xs={7}>
                        <Box
                          display="flex"
                          alignItems={"center"}
                          flexDirection="row"
                        >
                          <Typography
                            sx={{
                              fontFamily: "Montserrat",
                              fontStyle: "normal",
                              fontWeight: "400",
                              fontSize: "14px",
                              lineHeight: "20px",
                            }}
                          >
                            Row Per Page
                          </Typography>
                          <FormControl
                            variant="standard"
                            sx={{ m: 0, minWidth: 50 }}
                          >
                            {/* <InputLabel
                            style={{
                              marginLeft: "20px",
                              position: "absolute",
                              marginTop: "20px",
                            }}
                          >
                            {selecteds === "" ? 10 : selected}
                          </InputLabel> */}
                            <Select
                              size="small"
                              value={selecteds}
                              onChange={selectionChangeHandler}
                              disableUnderline
                              style={{
                                textAlign: "center",
                                fontSize: "14px",
                                paddingLeft: 10,
                                paddingTop: 8,
                                ":before": {
                                  borderColor: "#fafafc",
                                },
                                ":after": {
                                  borderColor: "#fafafc",
                                },
                                height: 30,
                                justifyContent: "center",
                              }}
                            >
                              <MenuItem
                                value={1}
                                onClick={() => {
                                  setMaxRow(10);
                                }}
                              >
                                10
                              </MenuItem>
                              <MenuItem
                                value={2}
                                onClick={() => {
                                  setMaxRow(20);
                                }}
                              >
                                20
                              </MenuItem>
                              <MenuItem
                                value={3}
                                onClick={() => {
                                  setMaxRow(50);
                                }}
                              >
                                50
                              </MenuItem>
                              <MenuItem
                                value={4}
                                onClick={() => {
                                  setMaxRow(100);
                                }}
                              >
                                100
                              </MenuItem>
                            </Select>
                          </FormControl>
                          {/* <Typography
                          sx={{
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "13px",
                            lineHeight: "20px",
                          }}
                        >
                          of {totalRecord}
                        </Typography> */}
                        </Box>
                      </Grid>
                      <Grid item xs={4} sx={{ marginLeft: "60px" }}>
                        <Pagination
                          style={{ marginTop: "2%", marginLeft: "5%" }}
                          onChange={handlePageChange}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          page={pages}
                          rowsPerPage={rowsPerPage}
                          count={pageCount || 0}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Box>
                {/* <Notification
                message={
                  alertContent
                  ? ` Something went wrong`
                  : `Success : Data Fetched Successfully.`
                }
                open={alert}
                severity={alertType}
                onClose={handleAlert}
              /> */}
                <Dialog open={openuploadDialog} onClose={handleCloseUpload}>
                  <DialogTitle>{"Upload File"}</DialogTitle>
                  <DialogContent>
                    <Box
                      flexDirection={"column"}
                      display="flex"
                      alignItems="center"
                      sx={{ width: "500px" }}
                    >
                      <Form
                        onChange={(state) => console.log("onChange =>", state)}
                      >
                        <MaterialuiDropzone />
                      </Form>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseUpload}>Discard</Button>
                    <Button id="btn" color="primary" autoFocus>
                      {"upload"}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </>
          </Box>
        </Box>
      </>
    </Permission>
  );
};

export default Questionbank;
//
