import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .css-1191obr-MuiPaper-root-MuiAppBar-root": {
      // width: 'calc(100% - 240px)',
      width: "100%",
    },
    "& .MuiDrawer-paperAnchorLeft": {
      marginTop: "80px",
    },
    backgroundColor:'#EEEEEE'
  },
  drawerOnOff: {
    position: "absolute",
      width: "46px",
      height: "46px",
      borderRadius: "50%",
    "& .MuiButtonBase-root": {
      backgroundColor: "#FFC20E",

      // display: "flex",
      // justifyContent: "center",
      // alignItems: "center",
    },
  },
});

export { useStyles };
