import React from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import "./MobileNumber.css";

/**
 * Component to show mobile number field
 * @type {React.NamedExoticComponent<{readonly updateMobileNumber?: *, readonly required?: *, readonly phone?: *}>}
 */
const MobileNumber = React.memo(
  ({
    phone,
    updateMobileNumber,
    required,
    index,
    isValidMobile,
    errorf,
    mobile_numberC,
    employee,
    numbers,
    sopformFields,
    spocerr,
  }) => {
    console.log("mobile_numberC", mobile_numberC);
    console.log("phone", phone);
    console.log("index", index);
    // console.log("numbers", numbers);
    // console.log("sopformFields", sopformFields);
    // console.log("sopformFields[index]", sopformFields[index]);

    return (
      <>
        <ReactPhoneInput
          inputStyle={{
            height: "57px",
          }}
          className="react-tel-input"
          specialLabel="Phone *"
          defaultCountry="in"
          country="in"
          enableSearchField={true}
          disableAreaCodes={true}
          countryCodeEditable={false}
          inputProps={{
            autoFocus: false,
          }}
          isValid={
            numbers || (errorf && spocerr && sopformFields)
              ? (numbers && numbers.includes(index)) ||
                ((sopformFields[index].spoc_mobile_number === "" ||
                  phone === "") &&
                  errorf &&
                  spocerr)
                ? false
                : true
              : employee
              ? !isValidMobile || (!mobile_numberC && errorf && spocerr)
                ? false
                : true
              : true
          }
          value={phone}
          onChange={(phone, data) => {
            if (phone) {
              let phoneNumber = "+" + phone.replace(/[^0-9]+/g, "");
              const dialCode = "+" + data.dialCode;
              updateMobileNumber(
                phoneNumber,
                isValidPhoneNumber(phoneNumber),
                dialCode,
                index
              );
            }
          }}
        />
      </>
    );
  }
);

export default MobileNumber;
