import {
  Box,
  Typography,
  Pagination,
  usePagination,
  Grid,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Menu,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import {
  filterVariant,
  roundPercentage,
  serachIcon,
} from "../../../../component/Images";
import Card from "../../../../component/UIElements/Card";
import { DataSurvey } from "../../../../content/DataSurvey";
import { useStyles } from "./SurveyCards.style";
import moment from "moment";

import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link } from "react-router-dom";
import { surveyScheduleList } from "../../../../thunk/Action";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import PageNotFound from "../../../PageNotFound";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import API from "../../../../E2E/Axios.Utils";
import FileSaver from "file-saver";

// const SurveyCards = (props) => {
const SurveyCards = ({ setTabBatchCard, setbatchCardData }) => {
  // const { setNavigate } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [selecteds, setSelecteds] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [maxrow, setMaxRow] = React.useState(10);
  const [pages, setPages] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRecord, setTotalRecord] = React.useState("");
  const [searchBatch, setSearchBatch] = useState("");
  const [batchSearch, setBatchSearch] = React.useState([]);
  let { id } = useParams();

  const selectionChangeHandler = (event) => {
    setSelecteds(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    console.log("Pages:::", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPages(1);
    console.log("rowPerPage:::", rowsPerPage);
  };

  const token = localStorage.getItem("token").toString();

  const getApicall = () => {
    const request = {
      token: token,
      id: id,
      data: {
        page: pages,
        max_rows: maxrow,
        query: searchBatch,
      },
      onSuccess: (res) => {
        if (res?.status === 200) {
          console.log("resSurvey", res);
          setData(res?.data?.survey_schedule_list);
          setBatchSearch(res?.data?.survey_schedule_list);
          setPageCount(res?.data?.total_page_count);
          setTotalRecord(res?.data?.total_record_count);
        }
      },
    };
    dispatch(surveyScheduleList(request));
  };
  React.useEffect(() => {
    getApicall();
  }, [pages, maxrow, searchBatch]);

  const ChartTitle = (props) => {
    return (
      <div className="item">
        <h5 className="number">{props.number}</h5>
        <h5 className="label">{props.title}</h5>
      </div>
    );
  };
  console.log("batchSearch", batchSearch);

  return (
    <>
      <Grid container className={classes.tabSurvey} spacing={3}>
        <Grid item xs={12}>
          <Box className={classes.serachBar}>
            <img src={serachIcon} />

            <input
              type="text"
              placeholder="search "
              value={searchBatch}
              onChange={(e) => setSearchBatch(e.target.value)}
            />
          </Box>
        </Grid>
        {(searchBatch &&
          (batchSearch?.length > 0 ? (
            batchSearch.map((data) => (
              <Grid
                className={`${classes.progressBar} `}
                sx={{
                  ":after": {
                    width: data.progressbar,
                  },
                }}
                item
                lg={3}
                md={4}
                xs={12}
                onClick={() => {
                  setTabBatchCard(true);
                  setbatchCardData(data);
                }}
              >
                <Card className={classes.cardStyle}>
                  <header>
                    <div>
                      <h4 className="cardTitle">{data.daySurvey}</h4>
                      <p className="detail">{data.name}</p>
                      <p className="detail">
                        Survey name: {data.survey_id.name}
                      </p>
                      <p className="detail">{data.survey_id.description}</p>

                      <p className="detail">
                        Start Date:{" "}
                        {moment(data.start_date).format("DD-MMM-YYYY")}
                      </p>
                      <p className="detail">
                        End Date: {moment(data.end_date).format("DD-MMM-YYYY")}
                      </p>
                    </div>
                    <div
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <EditMoreMenu
                        SurveyScheduleId={data.survey_schedule_id}
                      />
                    </div>
                  </header>
                  <Box className="chart">
                    <CircularProgressbar
                      value={
                        data.Total_finish_count === 0
                          ? 0
                          : (data.Total_finish_count / data.user_counts) * 100
                      }
                      text={`${
                        data.Total_finish_count === 0
                          ? 0
                          : (
                              (data.Total_finish_count / data.user_counts) *
                              100
                            ).toFixed(2)
                      }%`}
                    />
                    <div className="items">
                      <ChartTitle
                        number={data.user_counts}
                        title="Total User"
                      />
                      <ChartTitle
                        number={data.Total_finish_count}
                        title="Survey Submitted"
                      />
                      <ChartTitle
                        number={data.Total_start_count}
                        title="Count of click"
                      />
                      <ChartTitle
                        number={data.Total_email_count}
                        title="Number of email sent"
                      />
                    </div>
                  </Box>
                </Card>
              </Grid>
            ))
          ) : (
            <PageNotFound />
          ))) ||
          data.map((data) => (
            <Grid
              className={`${classes.progressBar} `}
              sx={{
                ":after": {
                  width: data.progressbar,
                },
              }}
              item
              lg={3}
              md={4}
              xs={12}
              onClick={() => {
                setTabBatchCard(true);
                setbatchCardData(data);
              }}
            >
              <Card className={classes.cardStyle}>
                <header>
                  <div>
                    <h4 className="cardTitle">{data.daySurvey}</h4>
                    <p className="detail">{data.name}</p>
                    <p className="detail">Survey name: {data.survey_id.name}</p>
                    <p className="detail">{data.survey_id.description}</p>
                    <p className="detail">
                      Start Date:{" "}
                      {moment(data.start_date).format("DD-MMM-YYYY")}
                    </p>
                    <p className="detail">
                      End Date: {moment(data.end_date).format("DD-MMM-YYYY")}
                    </p>
                  </div>
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <EditMoreMenu SurveyScheduleId={data.survey_schedule_id} />
                  </div>
                </header>
                <Box className="chart">
                  <CircularProgressbar
                    value={
                      data.Total_finish_count === 0
                        ? 0
                        : (data.Total_finish_count / data.user_counts) * 100
                    }
                    text={`${
                      data.Total_finish_count === 0
                        ? 0
                        : (
                            (data.Total_finish_count / data.user_counts) *
                            100
                          ).toFixed(2)
                    }%`}
                  />
                  <div className="items">
                    <ChartTitle number={data.user_counts} title="Total User" />
                    <ChartTitle
                      number={data.Total_finish_count}
                      title="Survey Submitted"
                    />
                    <ChartTitle
                      number={data.Total_start_count}
                      title="Count of click"
                    />
                    <ChartTitle
                      number={data.Total_email_count}
                      title="Number of email sent"
                    />
                  </div>
                </Box>
              </Card>
            </Grid>
          ))}

        <Grid container spacing={2} sx={{ padding: "10px" }}>
          <Grid item xs={7}>
            <Box display="flex" alignItems={"center"} flexDirection="row">
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                Row per Page
              </Typography>
              <FormControl variant="standard" sx={{ m: 0, minWidth: 50 }}>
                <Select
                  size="small"
                  value={selecteds}
                  onChange={selectionChangeHandler}
                  disableUnderline
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    paddingLeft: 10,
                    paddingTop: 8,
                    ":before": {
                      borderColor: "#fafafc",
                    },
                    ":after": {
                      borderColor: "#fafafc",
                    },
                    height: 30,
                    justifyContent: "center",
                  }}
                >
                  <MenuItem
                    value={1}
                    onClick={() => {
                      setMaxRow(10);
                    }}
                  >
                    10
                  </MenuItem>
                  <MenuItem
                    value={2}
                    onClick={() => {
                      setMaxRow(20);
                    }}
                  >
                    20
                  </MenuItem>
                  <MenuItem
                    value={3}
                    onClick={() => {
                      setMaxRow(50);
                    }}
                  >
                    50
                  </MenuItem>
                  <MenuItem
                    value={4}
                    onClick={() => {
                      setMaxRow(100);
                    }}
                  >
                    100
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={4} sx={{ marginLeft: "40px" }}>
            <Pagination
              sx={{ marginTop: 4, marginLeft: "5%" }}
              onChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={pages}
              rowsPerPage={rowsPerPage}
              count={pageCount || 0}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SurveyCards;

// **********************************************

function EditMoreMenu({ setEditUserData, details, SurveyScheduleId }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [openEdit, setEditOpen] = React.useState(false);
  const [csvData, setCsvData] = React.useState([]);

  const handleDialog = () => {
    setEditOpen(!openEdit);
  };
  const token = localStorage.getItem("token").toString();

  const downloadCsv = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    await API.get(
      `/app/secure/export_batch_details?survey_schedule_id=${SurveyScheduleId}`,
      config
    )
      .then((res) => {
        setCsvData(res.data.link);
        FileSaver.saveAs(res.data.link, "csvFile");
        console.log("data", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ref = React.useRef(isOpen);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertRoundedIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          sx={{ color: "text.secondary" }}
          onClick={() => {
            downloadCsv();
            setIsOpen(false);
          }}
        >
          <ListItemIcon>
            <FileDownloadIcon width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Download"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
