import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import API from "../../../E2E/Axios.Utils";

export default function ReactStepProgressBar() {
  const [steps, setSteps] = React.useState([]);

  const token = localStorage.getItem("token").toString();

  const getUsersData = () => {
    API.get(`/app/secure/reward?page=1&max_rows=100`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        // console.log("resTeamManagement", res.data);

        setSteps(res.data.points_details);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
        } else {
        }
      });
  };

  React.useEffect(() => {
    getUsersData();
  }, []);

  const getStepPosition = () => {
    return steps.filter((val) => val.status === "Completed").length;
  };

  //   console.log("status", getStepPosition());

  return (
    <>
      <div style={{ marginTop: 50, marginBottom: 50, marginLeft: 10 }}>
        <ProgressBar
          width={805}
          percent={100 * (getStepPosition() / steps.length)}
          //   percent={100 * (getStepPosition(transfer.status) / steps.length)}
          filledBackground="linear-gradient(to right, #07E76E, #07E76E)"
        >
          {steps.map((step, index, arr) => {
            return (
              <Step
                position={100 * (index / arr.length)}
                transition="scale"
                children={({ accomplished }) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      width: 20,
                      height: 20,
                      color: "white",
                      //   backgroundColor: accomplished
                      //     ? getcolor(step.coins_earned)
                      //     : "gray",
                      // backgroundColor: accomplished ? "#07E76E" : "gray",
                      backgroundColor: accomplished
                        ? step.coins_earned
                          ? "#07E76E"
                          : "red"
                        : "gray",
                    }}
                  >
                    {/* {index + 1} */}
                    {<KeyboardArrowDownIcon />}
                  </div>
                )}
              />
            );
          })}
        </ProgressBar>
      </div>
    </>
  );
}
