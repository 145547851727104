import React, { useState } from "react";

import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";

import CssBaseline from "@mui/material/CssBaseline";
import Header from "../../component/header/Header";

import LeftDrawer from "../../component/leftDrawer/LeftDrawer";

import {
  Typography,
  Box,
  ButtonGroup,
  Button,
  Pagination,
  Grid,
  MenuItem,
  Select,
  FormControl,
  Avatar,
} from "@mui/material";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import {
  SearchBlack,
  Dots,
  Arrow,
  P1,
  P2,
  P3,
  P4,
} from "../../component/Images";
import InputBox from "../../component/UIElements/InputBox";
import AvatarComp from "../../component/UIElements/Avatar";
// import { useStyles } from "./compose.style";
import AvatarGroup from "@mui/material/AvatarGroup";
import { useStyles } from "./clientOld.style";

import usePagination from "@mui/material/usePagination";

//drawer

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
});

function createData(company, survey, employee, created, status) {
  return {
    company,
    survey,
    employee,
    created,
    status,
  };
}

const rows = [
  createData("TCS", "10", "5000", "27.07.2022", "Active"),
  createData("Sunpharma", "20", "500", "11.05.2022", "Inactive"),
  createData("Bajaj Auto", "17", "1000", "04.04.2022", "Active"),
  createData("Finolex Industries", "08", "1027", "03.02.2022", "Active"),
  createData("JCB", "10", "5000", "27,07,2022", "Active"),
  createData("Bajaj Alliance", "20", "500", "11.05.2022", "Active"),
  createData(
    "The New India Assurance Co ltd.",
    "17",
    "1000",
    "04.04.2022",
    "Active"
  ),
  createData("Microsoft", "08", "1027", "03.02.2022", "Active"),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "COMPANY",
    numeric: false,
    disablePadding: true,
    label: "COMPANY",
  },
  {
    id: "SURVEY",
    numeric: true,
    disablePadding: false,
    label: "SURVEY",
  },
  {
    id: "EMPLOYEES",
    numeric: true,
    disablePadding: false,
    label: "EMPLOYEES",
  },
  {
    id: "CREATED",
    numeric: true,
    disablePadding: false,
    label: "CREATED",
  },
  {
    id: "STATUS",
    numeric: true,
    disablePadding: false,
    label: "STATUS",
  },
  {
    id: "CONTACT PERSSON",
    numeric: true,
    disablePadding: false,
    label: "CONTACT PERSSON",
  },
  {
    id: "CONTACT PERSSON",
    numeric: true,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "#128CB0", height: 50 }}>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
            style={{
              color: "#fff",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              color: "#fff",
              "&.MuiTableRow-root:hover": {
                color: "#fff",
              },
            }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {headCell.label !== "" ? (
                <>
                  <Box sx={{ marginLeft: "5px", paddingTop: "4px" }}>
                    <AvatarComp image={Arrow} height={15} />
                  </Box>
                </>
              ) : null}

              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  const classes = useStyles();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Box className={classes.searchBox}>
          <Box sx={{ paddingTop: "4px" }}>
            <AvatarComp image={SearchBlack} />
          </Box>

          <InputBox placeholder="Search" />
        </Box>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const ClientOld = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const { items } = usePagination({
    count: 120,
  });

  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [selecteds, setSelecteds] = useState("");

  const selectionChangeHandler = (event) => {
    setSelecteds(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  return (
    <Box className={classes.root} sx={{ display: "flex" }}>
      <CssBaseline />

      <Header />

      <LeftDrawer open={open} />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <>
          <Box sx={{ backgroundColor: "#EEEEEE" }}>
            <Typography
              sx={{
                marginTop: "10px",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "900",
                fontSize: "16px",
                lineHeight: "20px",
                color: "#0B0B0C",
              }}
            >
              Back
            </Typography>
            <Typography
              sx={{
                marginTop: "30px",
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "20px",
                lineHeight: "25px",
                color: "#0B0B0C",
              }}
            >
              List of Companies
            </Typography>
            <Box>
              <ButtonGroup
                variant="text"
                size="large"
                color="inherit"
                sx={{ marginTop: "12px" }}
              >
                <Button
                  sx={{
                    textTransform: "none",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    ":hover": {
                      fontWeight: 700,
                    },
                  }}
                >
                  All(20)
                </Button>
                <Button
                  sx={{
                    textTransform: "none",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    ":hover": {
                      fontWeight: 700,
                    },
                  }}
                >
                  Disable(05)
                </Button>
                <Button
                  sx={{
                    textTransform: "none",
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    ":hover": {
                      fontWeight: 700,
                    },
                  }}
                >
                  Draf(02)
                </Button>
              </ButtonGroup>
            </Box>
            <Box sx={{ width: "100%" }}>
              <Paper sx={{ width: "95%", mb: 2, marginTop: "20px" }}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750, padding: 5 }}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody>
                      {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                      {stableSort(rows, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.company);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) =>
                                handleClick(event, row.company)
                              }
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.company}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {row.company}
                              </TableCell>
                              <TableCell align="left">{row.survey}</TableCell>
                              <TableCell align="left">{row.employee}</TableCell>
                              <TableCell align="left">{row.created}</TableCell>
                              <TableCell align="left">{row.status}</TableCell>
                              <TableCell align="left">
                                {/* <Avatar image={ECo7} height={30} width={30} /> */}
                                <Box
                                  justifyContent="flex-start"
                                  flexDirection="row"
                                  display="flex"
                                  alignItem="center"
                                >
                                  <AvatarGroup max={4}>
                                    <Avatar src={P1} />
                                    <Avatar src={P2} />
                                    <Avatar src={P3} />
                                    <Avatar src={P4} />
                                  </AvatarGroup>
                                  {/* <AvatarGroup max={4}>
                                <Avatar src={Ellipse} />{" "}
                                <Avatar src={Ellipse} />{" "}
                                <Avatar src={Ellipse} />
                                <Avatar src={Ellipse} />
                              </AvatarGroup> */}
                                </Box>
                              </TableCell>
                              <TableCell align="left">
                                <AvatarComp image={Dots} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: (dense ? 38 : 38) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* <Pagination count={10} shape="rounded" /> */}
                <Grid container spacing={2} sx={{ padding: "10px" }}>
                  <Grid item xs={7}>
                    <Box
                      display="flex"
                      alignItems={"center"}
                      flexDirection="row"
                    >
                      <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "20px",
                        }}
                      >
                        Row per Page
                      </Typography>
                      <FormControl
                        variant="standard"
                        sx={{ m: 0, minWidth: 50 }}
                      >
                        <Select
                          size="small"
                          value={selecteds}
                          onChange={selectionChangeHandler}
                          disableUnderline
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            paddingLeft: 10,
                            paddingTop: 8,
                            ":before": {
                              borderColor: "#fafafc",
                            },
                            ":after": {
                              borderColor: "#fafafc",
                            },
                            height: 30,
                            justifyContent: "center",
                          }}
                          //   SelectProps={{ disableUnderline: true }}
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={1}>2</MenuItem>
                          <MenuItem value={2}>3</MenuItem>
                          <MenuItem value={3}>4</MenuItem>
                        </Select>
                      </FormControl>
                      {/* <Typography
                        sx={{
                          fontFamily: "Montserrat",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "13px",
                          lineHeight: "20px",
                        }}
                      >
                        of 120
                      </Typography> */}
                    </Box>
                  </Grid>
                  <Grid item xs={4} sx={{ marginLeft: "60px" }}>
                    <nav>
                      <List>
                        {items.map(
                          ({ page, type, selected, ...item }, index) => {
                            let children = null;

                            if (
                              type === "start-ellipsis" ||
                              type === "end-ellipsis"
                            ) {
                              children = "." + ".";
                            } else if (type === "page") {
                              children = (
                                <Box
                                  display="flex"
                                  alignItem="center"
                                  justifyContent="center"
                                  sx={[
                                    {
                                      width: 25,
                                      height: 25,
                                      borderRadius: "2px",
                                      margin: "5px",
                                      fontSize: 12,
                                      paddingTop: "4px",
                                    },
                                    selected
                                      ? {
                                          backgroundColor: "#2D404E",
                                          color: "#fff",
                                        }
                                      : { color: "#2D404E" },
                                  ]}
                                >
                                  {page}
                                </Box>
                              );
                            } else {
                              children = (
                                <Box
                                  display="flex"
                                  alignItem="center"
                                  justifyContent="center"
                                >
                                  <Button
                                    {...item}
                                    sx={[
                                      {
                                        width: 25,
                                        height: 25,
                                        borderRadius: "2px",
                                        margin: "5px",
                                        fontSize: 12,
                                        paddingTop: "4px",
                                      },
                                      selected
                                        ? { color: "#fff" }
                                        : { color: "#2D404E" },
                                    ]}
                                  >
                                    {type}
                                  </Button>
                                </Box>
                              );
                            }

                            return <li key={index}>{children}</li>;
                          }
                        )}
                      </List>
                    </nav>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Box>
        </>{" "}
      </Box>
    </Box>
  );
};

export default ClientOld;
