import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    padding: "24px",
    backgroundColor: "#EEEEEE",
    "& .MuiGrid-spacing-xs-3": {
      margin: "0",
    },
  },

  backBtn: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#0B0B0C",
    marginBottom: "0px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  cardStyle: {
    borderRadius: "0 !important",
    boxShadow: "none !important",
  },

  headerCard: {
    backgroundColor: "#BEDFFA",
  },
});

export { useStyles };
