import React, { useRef } from "react";
import { Grid, Typography, Container, Paper, Button } from "@mui/material";
import SearchBarRole from "../../../RoleManagement/Components/SearchBarRole";
import TableClientTeam from "./Components/TableClientTeam";
import { Box } from "@mui/material";
import Header from "../../../../component/header/Header";
import LeftDrawer from "../../../../component/leftDrawer/LeftDrawer";
import { useStyles } from "../../../question/Question.style";
import { Notification } from "../../../../Utils/SnackbarNotification/Notification";
import TeamHeaders from "./Components/TeamHeaders";
import UpdateTeamForm from "./Components/UpdateTeamForm";

const UpdateTeam = ({ setToggleEditTeam, editUserData }) => {
  const [search, setSearch] = React.useState("");
  const [teamName, setTeamName] = React.useState("");
  const [age, setAge] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [employeeIdData, setemployeeIdData] = React.useState("");
  const [orgIdData, setOrgIdData] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(0);
  const [opendrawer, setOpenDrawer] = React.useState(true);

  const classes = useStyles();

  const submitRef = useRef();

  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("");

  const handleDialog = () => {
    setOpen(false);
  };
  const ref = React.useRef(open);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAlert = () => {
    setAlert(false);
  };

  const handleChangefooter = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <LeftDrawer open={opendrawer} />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          style={{ background: "#EEEEEE" }}
        >
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: "900",
              fontSize: "16px",
              lineHeight: "20px",
              color: "#0B0B0C",
              marginBottom: "20px",
              cursor: "pointer",
            }}
            onClick={() => setToggleEditTeam(false)}
          >
            Back
          </Typography>

          <Paper sx={{ p: 3, mt: 3 }}>
            <Box>
              <UpdateTeamForm
                // handleform={handleform}
                setOrgIdData={setOrgIdData}
                currentUserDetails={editUserData}
                employeeIdData={employeeIdData}
                setAlert={setAlert}
                setAlertType={setAlertType}
                setAlertContent={setAlertContent}
                submitRef={submitRef}
                setToggleEditTeam={setToggleEditTeam}
              />
            </Box>
            <Box>
              <TeamHeaders
                teamName={teamName}
                setTeamName={setTeamName}
                age={age}
                setAge={setAge}
                gender={gender}
                setGender={setGender}
                location={location}
                setLocation={setLocation}
              />
            </Box>
            <Box>
              <SearchBarRole setSearch={setSearch} search={search} />
            </Box>

            <Box sx={{ mt: 3.5 }}>
              <TableClientTeam
                search={search}
                update={update}
                setAlert={setAlert}
                setAlertType={setAlertType}
                setAlertContent={setAlertContent}
                age={age}
                gender={gender}
                location={location}
                setemployeeIdData={setemployeeIdData}
                employeeIdData={employeeIdData}
                currentUserDetails={editUserData}
                orgIdData={orgIdData}
              />
            </Box>
          </Paper>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper sx={{ bgcolor: "#128CB0", p: 3 }}>
              <Box sx={{ textAlign: "center" }}>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <Grid item sx={{ bgcolor: "white" }} xs={9}>
                    <Typography sx={{ p: 0.8, fontFamily: "Montserrat" }}>
                      {`Engagement will be shared with ${
                        employeeIdData.length
                      } ${
                        employeeIdData.length > 1 ? "Employees" : "Employee"
                      }`}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ bgcolor: "#2D404E" }} xs={2}>
                    <Button
                      onClick={() => submitRef.current.click()}
                      sx={{
                        bgcolor: "#2D404E",
                        color: "white",
                        minWidth: 170,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            {/* <Typography>Shailendra</Typography> */}
          </Box>
          <Notification
            message={
              alertContent
                ? alertContent
                : `Success : Data Fetched Successfully.`
            }
            open={alert}
            severity={alertType}
            onClose={handleAlert}
          />
        </Box>
      </div>
    </>
  );
};

export default UpdateTeam;
