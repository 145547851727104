import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor:"red",
    "& .css-19z1ozs-MuiPaper-root-MuiAppBar-root": {
      height: "80px",
      display: "flex",
      justifyContent: "center",
      background: "linear-gradient(91.25deg, #394857 -28.42%, #00212A 117.39%)",
    },
    "& .avatar": {
      display: "flex",
      alignItems: "center",
      "& img": {
        cursor: "pointer",
      },
    },
  },
  logo: {},
  searchBox: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#fcfcfb",
    borderRadius: "4px",
    paddingLeft:'15px',
    "& input": {
      width: "625px",
      // height: "54px",
      background: "#FFF",
      borderRadius: "4px",
    },
  },
  searchButton: {
    marginLeft: "auto",
    width: "50px",
    height: "50px",
    backgroundColor: "#fcfcfb",
    borderRadius: "4px",
  },
  notificationBox: {
    display: "flex",
    alignItems: "center",
    // backgroundColor:'red',
    borderRadius: "4px",
  },
  dropdownBox: {
    borderRadius: "4px",
    backgroundColor: "#FAFEFF",
    padding: "5px 10px",
  },
  titleText: {
    color: "black",
    marginLeft: "1px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: "700",
  },
  contentText: {
    color: "black",
    marginLeft: "1px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: "400",
  },
  root: {
    "& .css-1191obr-MuiPaper-root-MuiAppBar-root": {
      // width: 'calc(100% - 240px)',
      width: "100%",
    },
    "& .MuiDrawer-paperAnchorLeft": {
      marginTop: "80px",
    },
    backgroundColor:'#EEEEEE'
  },
  drawerOnOff: {
    position: "absolute",
      width: "46px",
      height: "46px",
      borderRadius: "50%",
    "& .MuiButtonBase-root": {
      backgroundColor: "#FFC20E",
 
    },
  },
  pagenumber:{
    listStyle:"none",
    display:"flex",
    
    
  },
  pagenumberLi :{
    padding:"10px",
    border:"1px solid white",
    cursor:"pointer",
    justifyContent:"space-between"
  },
  Pagination:{
    width: 25,
    height: 25,
    borderRadius: "2px",
    margin: "5px",
    fontSize: 12,
    paddingTop: "4px",
    backgroundColor: "#2D404E",
    color: "#ffff",
    display:"flex",
    alignItem:"center",
    justifyContent:"center",
    border:"none"
  },
  PaginationDisable:{
    width: 25,
    height: 25,
    borderRadius: "2px",
    margin: "5px",
    fontSize: 12,
    paddingTop: "4px",
    backgroundColor: "#ffffff",
    color: "#000",
    display:"flex",
    alignItem:"center",
    justifyContent:"center",
    border:"none"
  },
  active: {
    backgroundColor: "#F4F5F7",
    "& .css-10hburv-MuiTypography-root": {
      fontFamily: "'Montserrat'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "22px",
      display: "flex",
      alignItems: "center",
      textTransform: "capitalize",
      color: "#0066B3",
    },
  },
});

export { useStyles };
