import React from "react";
import { api } from "../helper/apiConstant";
import { method } from "../helper/constant";
import { makeAPIRequest } from "../helper/globalFunction";
import {
  LOGIN_USER,
  FORGET_PASSWORD,
  // RESET_PASSWORDF,
  GET_SURVEY_LIST,
  UPDATE_SURVEY_LIST,
  DELETE_SURVEY_LIST,
  CREATE_SURVEY_LIST,
  GET_QBANK_LIST,
  CREATE_QBANK_LIST,
  UPDATE_QBANK_LIST,
  DELETE_QBANK_LIST,
  GET_CUSTOMER_LIST,
  UPDATE_CUSTOMER_LIST,
  DELETE_CUSTOMER_LIST,
  CREATE_CUSTOMER_LIST,
  GET_EMPLOYEE_LIST,
  UPDATE_EMPLOYEE_LIST,
  DELETE_EMPLOYEE_LIST,
  CREATE_EMPLOYEE_LIST,
  CREATE_BULK_EMPLOYEE_LIST,
  FETCH_SURVEY_MAPPING,
  SURVEY_SCHEDUAL_LIST,
  CREATE_SURVEY_SCHEDUAL,
  GET_SEARCH_EMPLOYEE_LIST,
  QUESTIONS_LIST,
  CREATE_QUESTIONS_LIST,
  DELETE_QUESTIONS_LIST,
  QUESTIONS_TYPE,
  GET_SEARCH_SURVEY_LIST,
  GET_SEARCH_QBANK_LIST,
  GET_SEARCH_CUSTOMER_LIST,
  UPDATE_QUESTIONS_LIST,
  CREATE_SURVAY_QUESTION,
  GET_SURVAY_ACTIVITY,
  GET_SORT_EMPLOYEE_LIST,
  GET_SORT_CLIENT_LIST,
  GET_SORT_SURVAY_LIST,
  GET_SORT_QBANK_LIST,
  GET_SORT_SURVAY_ACTIVITY_LIST,
  GET_SORT_QUESTION_LIST,
  SAMPLE_FILE,
  GET_SORT_SURVAY_MAPPING_LIST,
  START_SURVEY,
  CLOSE_SURVEY,
  ORGANIZATION_OVERVIEW,
  GET_SIDE_MENU,
  GET_OVERVIEW_EMPLOYEE_LIST,
  DASHBOARD_DETAIL,
  CREATE_BULK_QUESTION_LIST,
  DASHBOARD_DETAILS_LOADING,
  DASHBOARD_DETAILS_SUCCESS,
  DASHBOARD_DETAILS_ERROR,
} from "./types";

// Login API
export const loginUser = (request) => async (dispatch) => {
  console.log("email:::", request);

  return makeAPIRequest({
    method: method.post,
    url: api.login,
    data: {
      email: request?.email,
      password: request?.password,
    },
  })
    .then((response) => {
      dispatch({ type: LOGIN_USER, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
export const forgetPassword = (request) => async (dispatch) => {
  console.log("email:::", request);
  return makeAPIRequest({
    method: method.post,
    url: api.forgetpassword,

    data: {
      email: request?.email,
      // password: request?.password,
    },
  })
    .then((response) => {
      dispatch({ type: FORGET_PASSWORD, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
// export const resetPasswordf = (request) => async (dispatch) => {
//   console.log("email:::", request);
//   return makeAPIRequest({
//     method: method.post,
//     url: api.resetpasswordf,
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//     data: {
//       // email: request?.email,
//       password: request?.confirmpassword,
//     },
//   }).then((response) => {
//     dispatch({ type: RESET_PASSWORDF, payload: response?.data });
//     if (request.onSuccess) request.onSuccess(response);
//     console.log("response::::", response);
//   });
//   // .catch((err) => {
//   //   if (request.onFail) request.onFail(err);
//   // });
// };

// var token = localStorage.getItem("token");
// console.log("TOKEN++++++++++++++++++++", token);

//surveyApi
export const getSurveyList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.get,
    url:
      api.getsurvey +
      `page=${request?.data?.page}&max_rows=${request?.data?.max_rows}`,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: GET_SURVEY_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
export const getSortSurvayList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.get,
    url:
      api.getsurvey +
      `page=${request?.data?.page}&max_rows=${request?.data?.max_rows}&sort_by=${request?.data?.sort_by}&status=${request?.data?.status}`,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: GET_SORT_SURVAY_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
export const getSearchSurveyList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.get,
    // url: api.getearchsurvey + request?.data?.query,
    url:
      api.getearchsurvey +
      `${request?.data?.query}&status=${request?.data?.status}`,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: GET_SEARCH_SURVEY_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
export const updateSurveyList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.put,
    url: api.updatesurvey,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: {
      id: request?.id,
      name: request?.name,
      description: request?.description,
    },
  })
    .then((response) => {
      dispatch({ type: UPDATE_SURVEY_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

export const deleteSurveyList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.delete,
    url: api.deletesurvey + request?.id,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: DELETE_SURVEY_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
export const createSurveyList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.post,
    url: api.createsurvey,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: {
      name: request?.name,
      description: request?.description,
      status: "Active",
    },
  })
    .then((response) => {
      dispatch({ type: CREATE_SURVEY_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
//questionbankApi

export const getQuestionBankList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.get,
    url:
      api.getquestionbank +
      `page=${request?.data?.page}&max_rows=${request?.data?.max_rows}`,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: GET_QBANK_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
export const getSortQbankList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.get,
    url:
      api.getquestionbank +
      `page=${request?.data?.page}&max_rows=${request?.data?.max_rows}&sort_by=${request?.data?.sort_by}&status=${request?.data?.status}`,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: GET_SORT_QBANK_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
export const getSearchQuestionBankList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.get,
    // url: api.getsearchquestionbank + request?.data?.query,
    url:
      api.getsearchquestionbank +
      `${request?.data?.query}&status=${request?.data?.status}`,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: GET_SEARCH_QBANK_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

export const updateQuestionBankList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.put,
    url: api.updatequestionbank + request?.id,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: {
      name: request?.name,
      description: request?.description,
    },
  })
    .then((response) => {
      dispatch({ type: UPDATE_QBANK_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

export const deleteQuestionBankList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.delete,
    url: api.deletequestionbank + request?.id,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: DELETE_QBANK_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
export const createQuestionBankList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.post,
    url: api.createquestionbank,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: {
      name: request?.name,
      description: request?.description,
      status: "Active",
    },
  })
    .then((response) => {
      dispatch({ type: CREATE_QBANK_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

//organization
export const getCustomerList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.get,
    url:
      api.getcustomer +
      `page=${request?.data?.page}&max_row=${request?.data?.max_rows}`,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: GET_CUSTOMER_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
export const getSortCustomerList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.get,
    url:
      api.getcustomer +
      `page=${request?.data?.page}&max_rows=${request?.data?.max_rows}&sort_by=${request?.data?.sort_by}&status=${request?.data?.status}`,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: GET_SORT_CLIENT_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
export const getSearchCustomerList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.get,
    // url: api.getsearchcustomer + request?.data?.query,
    url:
      api.getsearchcustomer +
      `${request?.data?.query}&status=${request?.data?.status}`,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: GET_SEARCH_CUSTOMER_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

export const updateCustomerList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.put,
    url: api.updatecustomer + request?.id,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: UPDATE_CUSTOMER_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

export const deleteCustomerList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.delete,
    url: api.deletecustomer + request?.id,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: DELETE_CUSTOMER_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
export const createCustomerList = (request) => async (dispatch) => {
  // console.log("request:::::::", request);
  return makeAPIRequest({
    method: method.post,
    url: api.createcustomer,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      // console.log("response::::", response);
      dispatch({ type: CREATE_CUSTOMER_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
//EmployeeApi
export const getEmployeeList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.get,
    url:
      api.getemployee +
      `page=${request?.data?.page}&max_rows=${request?.data?.max_rows}`,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: GET_EMPLOYEE_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
export const getSortbyEmployeeList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.get,
    url:
      api.getemployee +
      `page=${request?.data?.page}&max_rows=${request?.data?.max_rows}&sort_by=${request?.data?.sort_by}&status=${request?.data?.status}`,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: GET_SORT_EMPLOYEE_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
export const getSearchEmployeeList = (request) => async (dispatch) => {
  // console.log("request____Search::::::", request?.data?.query);
  return makeAPIRequest({
    method: method.get,
    // url: api.getSearchEmployee + request?.data?.query,
    url:
      api.getSearchEmployee +
      `${request?.data?.query}&status=${request?.data?.status}`,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: GET_SEARCH_EMPLOYEE_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

export const updateEmployeeList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.put,
    url: api.updateemployee,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: UPDATE_EMPLOYEE_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

export const deleteEmployeeList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.delete,
    url: api.deleteemployee,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: {
      employee_id: [request?.id],
    },
  })
    .then((response) => {
      dispatch({ type: DELETE_EMPLOYEE_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
export const createEmployeeList = (request) => async (dispatch) => {
  // console.log("request:::::::", request);
  return makeAPIRequest({
    method: method.post,
    url: api.createemployee,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: CREATE_EMPLOYEE_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

export const createBulkEmployeeList = (request) => async (dispatch) => {
  // console.log("request:::::::", request);
  return makeAPIRequest({
    method: method.post,
    url: api.createbulkemployee,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: CREATE_BULK_EMPLOYEE_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

// Survey Mapping

export const fetchSurveyMappingList = (request) => async (dispatch) => {
  // console.log("request:::::::", request);
  return makeAPIRequest({
    method: method.get,
    url:
      api.fetchsurveymapping +
      `page=${request?.data?.page}&max_row=${request?.data?.max_rows}&org_id=` +
      request?.id,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: FETCH_SURVEY_MAPPING, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
export const getSortSurvayMappingList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.get,
    url:
      api.fetchsurveymapping +
      `page=${request?.data?.page}&max_row=${request?.data?.max_rows}&sort_by=${request?.data?.sort_by}`,
    // &org_id=` +
    // request?.id
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_SORT_SURVAY_MAPPING_LIST,
        payload: response?.data,
      });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

export const surveyScheduleList = (request) => async (dispatch) => {
  // console.log("request:::::::", request);
  return makeAPIRequest({
    method: method.get,
    url:
      api.surveyschedule +
      `page=${request?.data?.page}&max_rows=${request?.data?.max_rows}&query=${request?.data?.query}&org_id=` +
      request?.id,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    // data: request?.data,
  })
    .then((response) => {
      dispatch({ type: SURVEY_SCHEDUAL_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
export const createSurveySchedule = (request) => async (dispatch) => {
  // console.log("request:::::::", request);
  return makeAPIRequest({
    method: method.post,
    url: api.createsurveyschedule,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: CREATE_SURVEY_SCHEDUAL, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

//questions api

export const getQuestionsList = (request) => async (dispatch) => {
  // console.log("request:::::::", request);
  return makeAPIRequest({
    method: method.get,
    url:
      api.getquestions +
      `page=${request?.data?.page}&max_rows=${request?.data?.max_rows}&question_bank_id=${request?.id}`,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: QUESTIONS_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

export const getSortQuestionsList = (request) => async (dispatch) => {
  // console.log("request:::::::", request);
  return makeAPIRequest({
    method: method.get,
    url:
      api.getquestions +
      `page=${request?.data?.page}&max_rows=${request?.data?.max_rows}&question_bank_id=${request?.id}&sort_by=${request?.data?.sort_by}`,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: GET_SORT_QUESTION_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

export const createQuestionsList = (request) => async (dispatch) => {
  // console.log("request:::::::", request);
  return makeAPIRequest({
    method: method.post,
    url: api.createquestions,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: CREATE_QUESTIONS_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

export const updateQuestionsList = (request) => async (dispatch) => {
  // console.log("requestupdate:::::::", request);

  return makeAPIRequest({
    method: method.put,
    url: api.updatequestions + request?.id,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: UPDATE_QUESTIONS_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
      // console.log("err:::::", err);
    });
};

export const deleteQuestionsList = (request) => async (dispatch) => {
  // console.log("request:::::::", request);
  return makeAPIRequest({
    method: method.delete,
    url: api.deletequestions + request?.id,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: DELETE_QUESTIONS_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

//question Type api

export const getQuestionsType = (request) => async (dispatch) => {
  // console.log("request:::::::", request);
  return makeAPIRequest({
    method: method.get,
    url: api.getquestionstype,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: QUESTIONS_TYPE, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

export const createSurvayQuestion = (request) => async (dispatch) => {
  // console.log("request:::::::", request);
  return makeAPIRequest({
    method: method.post,
    url: api.createsurvayquestion,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: CREATE_SURVAY_QUESTION, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
//Survay_activity

export const getSurvayActivity = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.get,
    url:
      api.getsurvayactivity +
      `page=${request?.data?.page}&max_rows=${request?.data?.max_rows}&org_id=` +
      request?.id,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: GET_SURVAY_ACTIVITY, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
export const getSortSurvayActivityList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.get,
    url:
      api.getsurvayactivity +
      `page=${request?.data?.page}&max_rows=${request?.data?.max_rows}&sort_by=${request?.data?.sort_by}&org_id=${request?.id}`,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_SORT_SURVAY_ACTIVITY_LIST,
        payload: response?.data,
      });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

//sample_file api
export const sampleFile = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.get,
    url: api.samplefile + request?.sample_file,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: SAMPLE_FILE, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

//organization overview
export const organizationOverview = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.get,
    url: api.organizationoverview + request?.id,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: ORGANIZATION_OVERVIEW, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
//overViewEmployee
export const getoverViewEmployeeList = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.get,
    url:
      api.getemployee +
      `page=${request?.data?.page}&max_rows=${request?.data?.max_rows}&org_id=${request?.data?.id}`,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: GET_OVERVIEW_EMPLOYEE_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
export const startSurvey = (data) => ({
  type: START_SURVEY,
  data,
});

export const closeSurvey = () => ({
  type: CLOSE_SURVEY,
});

//menu-submenu

export const getSidemenu = (request) => async (dispatch) => {
  // localStorage.getItem('token')
  const Token = await localStorage.getItem("token");
  console.log("sidemenuToken:", Token);

  return makeAPIRequest({
    method: method.get,
    url: api.getsidemenu,
    headers: {
      Authorization: `Bearer ${Token}`,
    },
  })
    .then((response) => {
      dispatch({ type: GET_SIDE_MENU, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

//Dashboard detail
export const getDashboardDetail = (request) => async (dispatch) => {
  return makeAPIRequest({
    method: method.get,
    url: api.dashboarddetail,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: DASHBOARD_DETAIL, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};
//bulk Question bank

export const createBulkQuestion = (request) => async (dispatch) => {
  // console.log("request:::::::", request);
  return makeAPIRequest({
    method: method.post,
    url: api.createbulkquestion,
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: CREATE_BULK_QUESTION_LIST, payload: response?.data });
      if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      if (request.onFail) request.onFail(err);
    });
};

export const fetchDashboardDetails = (request) => async (dispatch) => {
  dispatch({ type: DASHBOARD_DETAILS_LOADING });
  return makeAPIRequest({
    method: method.get,
    url: api.dashboardDetails
      .replace("genderValue", request.gender)
      .replace("ageGroup", request.ageGroup)
      .replace("startDate", request.startDate)
      .replace("endDate", request.endDate)
      .replace("locationValue", request.locationValue)
      .replace("organisationIds", request.organisationIds)
      .replace("teamIds", request.teamIds),
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
  })
    .then((response) => {
      dispatch({ type: DASHBOARD_DETAILS_SUCCESS, payload: response?.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: DASHBOARD_DETAILS_ERROR });
    });
};

export const fetchMoodDetails = (request) => async (dispatch) => {
  dispatch({ type: DASHBOARD_DETAILS_LOADING });
  return makeAPIRequest({
    method: method.get,
    url: api.dashboardMoodScoreDetails
      .replace("genderValue", request.gender)
      .replace("ageGroup", request.ageGroup)
      .replace("startDate", request.startDate)
      .replace("endDate", request.endDate)
      .replace("locationValue", request.locationValue)
      .replace("organisationIds", request.organisationIds)
      .replace("teamIds", request.teamIds),
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: DASHBOARD_DETAILS_SUCCESS, payload: response?.data });
      // if (request.onSuccess) request.onSuccess(response);
      // console.log("response::::", response);
    })
    .catch((err) => {
      dispatch({ type: DASHBOARD_DETAILS_ERROR });
      // if (request.onFail) request.onFail(err);
    });
};

export const fetchEngagementDrivers = (request) => async (dispatch) => {
  dispatch({ type: DASHBOARD_DETAILS_LOADING });
  return makeAPIRequest({
    method: method.get,
    url: api.dashboardEngagementDriver
      .replace("genderValue", request.gender)
      .replace("ageGroup", request.ageGroup)
      .replace("startDate", request.startDate)
      .replace("endDate", request.endDate)
      .replace("locationValue", request.locationValue)
      .replace("organisationIds", request.organisationIds)
      .replace("teamIds", request.teamIds),
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: DASHBOARD_DETAILS_SUCCESS, payload: response?.data });
    })
    .catch((err) => {
      dispatch({ type: DASHBOARD_DETAILS_ERROR });
      // if (request.onFail) request.onFail(err);
    });
};

export const fetchManagerDashboard = (request) => async (dispatch) => {
  dispatch({ type: DASHBOARD_DETAILS_LOADING });
  return makeAPIRequest({
    method: method.get,
    url: api.dashboardManagerDetails
      .replace("genderValue", request.gender)
      .replace("ageGroup", request.ageGroup)
      .replace("startDate", request.startDate)
      .replace("endDate", request.endDate)
      .replace("locationValue", request.locationValue)
      .replace("organisationIds", request.organisationIds)
      .replace("teamIds", request.teamIds),
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: DASHBOARD_DETAILS_SUCCESS, payload: response?.data });
    })
    .catch((err) => {
      dispatch({ type: DASHBOARD_DETAILS_ERROR });
    });
};

export const fetchRoleDashboard = (request) => async (dispatch) => {
  dispatch({ type: DASHBOARD_DETAILS_LOADING });
  return makeAPIRequest({
    method: method.get,
    url: api.dashboardRoleDetails
      .replace("genderValue", request.gender)
      .replace("ageGroup", request.ageGroup)
      .replace("startDate", request.startDate)
      .replace("endDate", request.endDate)
      .replace("locationValue", request.locationValue)
      .replace("organisationIds", request.organisationIds)
      .replace("teamIds", request.teamIds),
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: DASHBOARD_DETAILS_SUCCESS, payload: response?.data });
    })
    .catch((err) => {
      dispatch({ type: DASHBOARD_DETAILS_ERROR });
    });
};
export const fetchTeamDashboard = (request) => async (dispatch) => {
  dispatch({ type: DASHBOARD_DETAILS_LOADING });
  return makeAPIRequest({
    method: method.get,
    url: api.dashboardTeamDetails
      .replace("genderValue", request.gender)
      .replace("ageGroup", request.ageGroup)
      .replace("startDate", request.startDate)
      .replace("endDate", request.endDate)
      .replace("locationValue", request.locationValue)
      .replace("organisationIds", request.organisationIds)
      .replace("teamIds", request.teamIds),
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: DASHBOARD_DETAILS_SUCCESS, payload: response?.data });
    })
    .catch((err) => {
      dispatch({ type: DASHBOARD_DETAILS_ERROR });
    });
};
export const fetchCommunicationDashboard = (request) => async (dispatch) => {
  dispatch({ type: DASHBOARD_DETAILS_LOADING });
  return makeAPIRequest({
    method: method.get,
    url: api.dashboardCommunicationDetails
      .replace("genderValue", request.gender)
      .replace("ageGroup", request.ageGroup)
      .replace("startDate", request.startDate)
      .replace("endDate", request.endDate)
      .replace("locationValue", request.locationValue)
      .replace("organisationIds", request.organisationIds)
      .replace("teamIds", request.teamIds),
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: DASHBOARD_DETAILS_SUCCESS, payload: response?.data });
    })
    .catch((err) => {
      dispatch({ type: DASHBOARD_DETAILS_ERROR });
    });
};
export const fetchAppreciationDashboard = (request) => async (dispatch) => {
  dispatch({ type: DASHBOARD_DETAILS_LOADING });
  return makeAPIRequest({
    method: method.get,
    url: api.dashboardAppreciationDetails
      .replace("genderValue", request.gender)
      .replace("ageGroup", request.ageGroup)
      .replace("startDate", request.startDate)
      .replace("endDate", request.endDate)
      .replace("locationValue", request.locationValue)
      .replace("organisationIds", request.organisationIds)
      .replace("teamIds", request.teamIds),
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: DASHBOARD_DETAILS_SUCCESS, payload: response?.data });
    })
    .catch((err) => {
      dispatch({ type: DASHBOARD_DETAILS_ERROR });
    });
};
export const fetchLearnDevDashboard = (request) => async (dispatch) => {
  dispatch({ type: DASHBOARD_DETAILS_LOADING });
  return makeAPIRequest({
    method: method.get,
    url: api.dashboardLearnDevDetails
      .replace("genderValue", request.gender)
      .replace("ageGroup", request.ageGroup)
      .replace("startDate", request.startDate)
      .replace("endDate", request.endDate)
      .replace("locationValue", request.locationValue)
      .replace("organisationIds", request.organisationIds)
      .replace("teamIds", request.teamIds),
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: DASHBOARD_DETAILS_SUCCESS, payload: response?.data });
    })
    .catch((err) => {
      dispatch({ type: DASHBOARD_DETAILS_ERROR });
    });
};
export const fetchDiversityDashboard = (request) => async (dispatch) => {
  dispatch({ type: DASHBOARD_DETAILS_LOADING });
  return makeAPIRequest({
    method: method.get,
    url: api.dashboardDiversityDetails
      .replace("genderValue", request.gender)
      .replace("ageGroup", request.ageGroup)
      .replace("startDate", request.startDate)
      .replace("endDate", request.endDate)
      .replace("locationValue", request.locationValue)
      .replace("organisationIds", request.organisationIds)
      .replace("teamIds", request.teamIds),
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: DASHBOARD_DETAILS_SUCCESS, payload: response?.data });
    })
    .catch((err) => {
      dispatch({ type: DASHBOARD_DETAILS_ERROR });
    });
};
export const fetchWorkLifeDashboard = (request) => async (dispatch) => {
  dispatch({ type: DASHBOARD_DETAILS_LOADING });
  return makeAPIRequest({
    method: method.get,
    url: api.dashboardWorkLifeDetails
      .replace("genderValue", request.gender)
      .replace("ageGroup", request.ageGroup)
      .replace("startDate", request.startDate)
      .replace("endDate", request.endDate)
      .replace("locationValue", request.locationValue)
      .replace("organisationIds", request.organisationIds)
      .replace("teamIds", request.teamIds),
    headers: {
      Authorization: `Bearer ${request?.token}`,
    },
    data: request?.data,
  })
    .then((response) => {
      dispatch({ type: DASHBOARD_DETAILS_SUCCESS, payload: response?.data });
    })
    .catch((err) => {
      dispatch({ type: DASHBOARD_DETAILS_ERROR });
    });
};
