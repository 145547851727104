import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { styled, useTheme, alpha } from "@mui/material/styles";
import { useStyles } from "./Dashboard.style";
import Header from "../../component/header/Header";
import LeftDrawer from "../../component/leftDrawer/LeftDrawer";
import CircularProgress from "@mui/material/CircularProgress";
import ReactSpeedometer from "react-d3-speedometer";
import { Pie, Doughnut, Bar, Line } from "react-chartjs-2";
import { Chart } from "chart.js/auto";
import { Grid, Button } from "@material-ui/core";
import {
  fetchDashboardDetails,
  fetchMoodDetails,
  fetchEngagementDrivers,
  fetchManagerDashboard,
  fetchRoleDashboard,
} from "../../thunk/Action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Notification } from "../../Utils/SnackbarNotification/Notification";
import Permission from "../../component/Permission/Permission";
import StackHorizontalBar from "./Components/StackHorizontalBar";
import DashboardFilters from "./Components/DashboardFilters";
import DownloadReport from "./Components/DownloadReport";
import {
  CountCharts,
  EngagementDrivers,
  Manager,
  Role,
  Team,
  Communication,
  Appreciation,
  LAndD,
  WorkLifeBalance,
  Diversity,
} from "./Components/ChartComponents";

const AllDashboard = (props) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [showDetailedDashboard, setShowDetailedDashboard] =
    React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const optionsOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const handleAlert = () => {
    setAlert(false);
  };
  const [dashboardDetail, setDashboardDetail] = useState([]);
  const token = localStorage.getItem("token").toString();

  const role = localStorage.getItem("role");

  return (
    <Permission menu="Dashboard">
      <Header />
      <div className={classes.contentContainer}>
        <LeftDrawer open={open} />
        <Grid container className={classes.dashboardContainer}>
          <Grid item>
            <h2 className={classes.dashboardTitle}>Dashboard</h2>
          </Grid>
          <Grid item>
            {role === "Super Admin" ? (
              <DownloadReport
                dashboard_a_overview={props?.data?.dashboard_a_overview}
                mood_score={props?.data?.mood_score}
                dashboard_b_overview={props?.data?.dashboard_b_overview}
                manager_dashboard={props?.data?.manager_dashboard}
                role_dashboard={props?.data?.role_dashboard}
                team_dashboard={props?.data?.team_dashboard}
                communication_dashboard={props?.data?.communication_dashboard}
                appreciation_dashboard={props?.data?.appreciation_dashboard}
                learning_dev_dashboard={props?.data?.learning_dev_dashboard}
                diversity_dashboard={props?.data?.diversity_dashboard}
                work_life_dashboard={props?.data?.work_life_dashboard}
              />
            ) : null}
          </Grid>
          <DashboardFilters />
          {props.loading ? (
            <Grid
              item
              xs={12}
              justifyContent="center"
              style={{ padding: "20px", display: "flex" }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <>
              <CountCharts
                styles={classes}
                data={props?.data?.dashboard_a_overview?.[0]}
                moodScore={props?.data?.mood_score?.[0]}
              />
              <EngagementDrivers
                styles={classes}
                data={props?.data?.dashboard_b_overview?.[0]}
              />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "10px 0",
                }}
                onClick={() => setShowDetailedDashboard(!showDetailedDashboard)}
              >
                <p
                  style={{
                    cursor: "pointer",
                    margin: 0,
                    textDecoration: "underline",
                    color: "blue",
                  }}
                >
                  {!showDetailedDashboard ? "Show" : "Hide"} Detailed Dashboard
                </p>
              </div>
              {showDetailedDashboard ? (
                <>
                  <Manager
                    styles={classes}
                    data={props?.data?.manager_dashboard?.[0]}
                  />
                  <Role
                    styles={classes}
                    data={props?.data?.role_dashboard?.[0]}
                  />
                  <Team
                    styles={classes}
                    data={props?.data?.team_dashboard?.[0]}
                  />
                  <Communication
                    styles={classes}
                    data={props?.data?.communication_dashboard?.[0]}
                  />
                  <Appreciation
                    styles={classes}
                    data={props?.data?.appreciation_dashboard?.[0]}
                  />
                  <LAndD
                    styles={classes}
                    data={props?.data?.learning_dev_dashboard?.[0]}
                  />
                  <Diversity
                    styles={classes}
                    data={props?.data?.diversity_dashboard?.[0]}
                  />
                  <WorkLifeBalance
                    styles={classes}
                    data={props?.data?.work_life_dashboard?.[0]}
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </Grid>
      </div>
    </Permission>
  );
};

const mapStateToProps = (state) => {
  return state.reducer.dashboard;
};

export default connect(mapStateToProps, {})(AllDashboard);
