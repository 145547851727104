import React, {useState, useContext} from "react";
import {ChatContext} from "../context/chatContext";

import styles from './Input.module.scss';

import getChat, {sendPrevAnswerFeedback, surveyEndApi} from "../context/chatApi";
import {setEndSurveyTrue} from "../context/chatActions";
import {closeSurvey, startSurvey,} from '../../thunk/Action';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

const Input = (props) => {

    const {onSend} = props;
    const [text, setText] = useState("");
    const {chatDispatch, chatConversationState} = useContext(ChatContext);

    const handleInputChange = e => {
        setText(e.target.value);
    };

    const handleSend = e => {
        if(chatConversationState.answerType !== 'text' && chatConversationState.previousAnswerFeedbackType != 'text') {
            e.preventDefault();
            return
        }
        e.preventDefault();
        if(chatConversationState.previousAnswerFeedback) {
            if (chatConversationState.nextEndSurvey == true) setEndSurveyTrue(chatDispatch);
            let endSurvey = chatConversationState.nextEndSurvey ? chatConversationState.nextEndSurvey : false;

            sendPrevAnswerFeedback(text,
                chatConversationState.previousAnswerFeedback,
                props.employee_id,
                props.survey_schedule_id,
                props.survey_id,
                props.surveySenderId,
                chatConversationState.answerAnonymously,
                chatConversationState.nextQuestionPayload,
                endSurvey
            )(chatDispatch);
        } else {
            let endSurvey = chatConversationState.nextEndSurvey ? chatConversationState.nextEndSurvey : false;
            getChat(
                text,
                chatConversationState.question,
                props.employee_id,
                props.survey_schedule_id,
                props.survey_id,
                props.surveySenderId,
                chatConversationState.answerAnonymously,
                endSurvey
            )(chatDispatch)
        }
        onSend(text);
        setText("");
    };

    return (
        <div className={`${styles.input} ${chatConversationState.answerType == 'text' || chatConversationState.previousAnswerFeedbackType == 'text' ? styles.active : ''}`}>
            <form onSubmit={handleSend}>
                <input
                    disabled={chatConversationState.previousAnswerFeedback && !chatConversationState.previousAnswerFeedbackType ? true : false}
                    className={chatConversationState.previousAnswerFeedback && !chatConversationState.previousAnswerFeedbackType ? styles.inputDisabled : '' }
                    type="text"
                    onChange={handleInputChange}
                    value={text}
                    placeholder="Tap here to send your message"
                />
                <button>
                    <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 500 500"
                    >
                        <g>
                            <g>
                                <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75" />
                            </g>
                        </g>
                    </svg>
                </button>
            </form>
        </div>
    );
}


const mapStateToProps = state => {
    return state.reducer.survey;
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            closeSurvey,
            startSurvey,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Input);
