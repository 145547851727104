import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import InputLabel from "@mui/material/InputLabel";
import CustomModel from "../../../../Utils/CustomModel/CustomModel";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@material-ui/core/Menu";
import UpdateSurveyForm from "../tabTeam/UpdateSurvey/UpdateSurveyForm";
import {
  Typography,
  Box,
  ButtonGroup,
  Button,
  Grid,
  MenuItem,
  Select,
  FormControl,
  Snackbar,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { SearchBlack, Dots, Arrow } from "../../../../component/Images";
import InputBox from "../../../../component/UIElements/InputBox";
import AvatarComp from "../../../../component/UIElements/Avatar";
import { useStyles } from "../tabTeam/TeamCards.style";

import {
  Pagination,
  PaginationItem,
  usePagination,
  TablePagination,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";

import {
  fetchSurveyMappingList,
  getSortSurvayActivityList,
  getSurvayActivity,
} from "../../../../thunk/Action";

//drawer
import Switch from "@mui/material/Switch";
import { useEffect } from "react";

const label = { inputProps: { "aria-label": "Switch demo" } };

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
}));

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
});

const headCells = [
  {
    id: "SURVEY NAME",
    numeric: false,
    disablePadding: true,
    label: "SURVEY NAME",
  },
  {
    id: "SURVEY DESCRIPTION",
    numeric: true,
    disablePadding: false,
    label: "SURVEY DESCRIPTION",
  },

  {
    id: "STATUS",
    numeric: true,
    disablePadding: false,
    label: "STATUS",
  },
  {
    id: "CREATED",
    numeric: true,
    disablePadding: false,
    label: "CREATED",
  },

  {
    id: "ACTIONS",
    numeric: true,
    disablePadding: false,
    label: "ACTIONS",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const handlesort = (val) => {
    props.setSortBy(val);
    props.setSortOrder(val);
    console.log(val);
  };
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: "#128CB0", height: "50px" }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              color: "#fff",
              "&.MuiTableRow-root:hover": {
                color: "#fff",
              },
            }}
          >
            <div style={{ fontFamily: "Montserrat", fontWeight: 600 }}>
              {headCell.label}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  const classes = useStyles();
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {}),
      }}
    >
      {numSelected > 0 ? (
        <Box className={classes.searchBox}>
          <Box sx={{ paddingTop: "4px" }}>
            <AvatarComp image={SearchBlack} />
          </Box>

          <InputBox placeholder="Search" />
        </Box>
      ) : (
        <Box className={classes.searchBox}>
          <Box sx={{ paddingTop: "4px" }}>
            <AvatarComp image={SearchBlack} />
          </Box>

          <InputBox placeholder="Search" />
        </Box>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const TeamCards = (props) => {
  const { setTabTeamActive } = props;
  let { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [opendrawer, setOpen] = React.useState(true);
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [name, setName] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);

  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [pagetable, setPageTable] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [selecteds, setSelecteds] = useState("");
  const [total, setTotal] = useState("");
  const [disable, setDisable] = useState("");
  const [active, setActive] = useState("");

  const [update, setUpdate] = React.useState(0);

  const [rowId, setRowId] = useState(0);
  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [message, setMessage] = React.useState("");

  const { vertical, horizontal, open } = state;

  const [updateSurveyopen, setUpdateSurveyopen] = React.useState(false);

  //Pagination
  const [total_record_count, setTotal_Record_count] = useState("");

  const [maxrow, setMaxRow] = useState(10);
  const [searchPages, setSearchPages] = useState(1);
  const [pages, setPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(null);

  const handleDialog = () => {
    setUpdateSurveyopen(false);
  };

  const ref = React.useRef(updateSurveyopen);

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    console.log("Pages:::", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPages(1);
    console.log("rowPerPage:::", rowsPerPage);
  };

  const token = localStorage.getItem("token").toString();

  const getApicall = () => {
    const request = {
      token: token,
      id: id,
      data: {
        page: pages,
        max_rows: maxrow,
      },
      onSuccess: (res) => {
        if (res?.status === 200) {
          setData(res?.data?.survey_mapped_list);
          setTotal_Record_count(res?.data?.total_record_count);
          setPageCount(res?.data?.total_page_count);
        }
      },
      onFail: (error) => {
        // setIsLoading(false);
        if (error?.response?.status === 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          navigate("/login");
        } else {
          setAlertContent(error?.response?.data?.message);
          setAlertType("error");
          setAlert(true);
        }
      },
    };
    dispatch(fetchSurveyMappingList(request));
  };
  React.useEffect(() => {
    getApicall();
  }, [update, pages, maxrow]);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickToOpenCreateDialog = () => {
    setOpenCreateDialog(true);
  };

  const handleToCloseCreateDialog = () => {
    setOpenCreateDialog(false);
  };

  const selectionChangeHandler = (event) => {
    setSelecteds(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const emptyRows =
    pagetable > 0
      ? Math.max(0, (1 + pagetable) * rowsPerPage - data.length)
      : 0;

  return (
    <Grid container spacing={3}>
      <Box style={{ marginLeft: "75%" }}>
        <CustomModel handleDialog={handleDialog} open={updateSurveyopen}>
          <UpdateSurveyForm
            handleDialog={handleDialog}
            setUpdate={setUpdate}
            update={update}
          />
        </CustomModel>
        <LoadingButton
          color="primary"
          variant="contained"
          ref={ref}
          onClick={() => setUpdateSurveyopen(true)}
          sx={{
            bgcolor: "#2D404E",
            padding: "10px",
            borderRadius: "20px",
            width: "180px",
            height: "36px",
            fontFamily: "Montserrat",
            fontWeight: 600,
          }}
        >
          Update survey
        </LoadingButton>
      </Box>
      <Box
        sx={{ width: "100%" }}
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Paper sx={{ width: "95%", mb: 2, mt: 3 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                rowCount={data.length}
              />
              <TableBody>
                {data.map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      hover
                      tabIndex={-1}
                      sx={{ height: "50px" }}
                    >
                      <TableCell
                        component="th"
                        align="center"
                        scope="row"
                        padding="none"
                        style={{ cursor: "pointer" }}
                      >
                        {row.survey_id.name}
                      </TableCell>
                      <TableCell align="center">
                        {row.survey_id.description}
                      </TableCell>

                      <TableCell align="center">{row.status}</TableCell>
                      <TableCell align="center">
                        {moment(row.created_date).format("DD-MM-YYYY")}
                      </TableCell>

                      <TableCell align="center">
                        <Box
                          justifyContent="center"
                          flexDirection="row"
                          display="flex"
                          alignItem="center"
                          sx={{ padding: "0px !important" }}
                        >
                          <IconButton
                            onClick={(e) => {
                              handleClickMenu(e);
                              setRowId(row);
                            }}
                          >
                            <MoreVertRoundedIcon width={20} height={20} />
                          </IconButton>
                        </Box>

                        <Menu
                          id={`simple-menu-${rowId}`}
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleCloseMenu}
                          elevation={0}
                        >
                          <MenuItem
                            onClick={() => {
                              setAnchorEl(null);
                              setTabTeamActive(false);
                              localStorage.setItem(
                                "surveyId",
                                rowId.survey_id.survey_id
                              );
                            }}
                          >
                            Schedule
                          </MenuItem>

                          <MenuItem>
                            Disable <Switch {...label} defaultChecked />
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                })}

                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 38 : 38) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container spacing={0} sx={{}}>
            <Grid item xs={7}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingLeft: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  Row per Page
                </Typography>
                <FormControl variant="standard" sx={{ m: 0, minWidth: 50 }}>
                  <Select
                    size="small"
                    value={selecteds}
                    onChange={selectionChangeHandler}
                    disableUnderline
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      paddingLeft: 10,
                      paddingTop: 8,
                      ":before": {
                        borderColor: "#fafafc",
                      },
                      ":after": {
                        borderColor: "#fafafc",
                      },
                      height: 30,
                      justifyContent: "center",
                    }}
                  >
                    <MenuItem
                      value={1}
                      onClick={() => {
                        setMaxRow(10);
                      }}
                    >
                      10
                    </MenuItem>
                    <MenuItem
                      value={2}
                      onClick={() => {
                        setMaxRow(20);
                      }}
                    >
                      20
                    </MenuItem>
                    <MenuItem
                      value={3}
                      onClick={() => {
                        setMaxRow(50);
                      }}
                    >
                      50
                    </MenuItem>
                    <MenuItem
                      value={4}
                      onClick={() => {
                        setMaxRow(100);
                      }}
                    >
                      100
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={4} sx={{ marginLeft: "40px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  style={{ marginTop: "2%", marginLeft: "5%" }}
                  onChange={handlePageChange}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  page={pages}
                  rowsPerPage={rowsPerPage}
                  count={pageCount || 0}
                />
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Grid>
  );
};

export default TeamCards;
