import { Grid } from "@material-ui/core";
import { Pie } from "react-chartjs-2";
import ChartPluginLabels from "chartjs-plugin-datalabels";

import StackHorizontalBar from "./StackHorizontalBar";

import NPS_IMAGE1 from "../../../assets/images/svgs/NPS_IMAGE1.jpg";
import NPS_IMAGE2 from "../../../assets/images/svgs/NPS_IMAGE2.jpg";
import NPS_IMAGE3 from "../../../assets/images/svgs/NPS_IMAGE3.jpg";

export const chartColors = [
  "#29FD2E",
  "#51DC53",
  "#4DCF4F",
  "#FFD56D",
  "#FFC536",
  "#E6B131",
  "#E8A500",
];

const BooleanLabels = ["Yes", "No"];
const ToolTipTitle = [""];
const BooleanColors = ["#29FD2E", "#D44753"];

const pieOptions = {
  legend: {
    display: false,
    position: "right",
  },
  aspectRatio: 1,
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  plugins: {
    datalabels: {
      color: "black",
      formatter: function (value, context) {
        return value > 0 ? `${value}%` : "";
      },
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.label || "";
          return `${label} ${context.formattedValue}%`;
        },
      },
    },
  },
};

const stackedBarConstants = {
  borderRadius: "50",
  borderSkipped: "middle",
  barThickness: 15,
};

export const CountCharts = (props) => {
  const { styles: classes = {}, data = {}, moodScore = {} } = props;
  let chartInstance = null;

  const {
    survey_submitted = 0,
    detractors = 0,
    passive = 0,
    promoters = 0,
  } = data;

  const pieData = {
    maintainAspectRatio: false,
    responsive: false,
    labels: Object.keys(moodScore),
    datasets: [
      {
        data: Object.values(moodScore),
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors,
      },
    ],
  };
  const totalNps =
    parseFloat(detractors) + parseFloat(passive) + parseFloat(promoters);

  return (
    <Grid container style={{ marginTop: "20px" }} spacing={2}>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container xs={12}>
              <Grid item xs={6} style={{ paddingRight: "30px" }}>
                <div className={classes.gridContainerEmployee}>
                  <p className={classes.tileLabel}>Employees</p>
                  <h4 className={classes.employeeCount}>
                    {data?.employee_count}
                  </h4>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.gridContainer}>
                  <p className={classes.tileLabel}>Surveys Submitted</p>
                  <h4
                    className={classes.employeeCount}
                  >{`${survey_submitted}%`}</h4>
                </div>
              </Grid>
            </Grid>
            <Grid
              style={{ marginTop: "24px" }}
              xs={12}
              container
              className={classes.gridContainerEmailCount}
            >
              <Grid item xs={6}>
                <p className={classes.tileLabel}>Email Counts</p>
                <h4 className={classes.employeeCount}>
                  {data?.total_email_count}
                </h4>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <p className={classes.reminderEmailTitle}>Reminder Emails</p>
                  <h4 className={classes.reminderEmailValue}>
                    {data?.reminder_email_count}
                  </h4>
                </div>
                <div>
                  <p className={classes.reminderEmailTitle}>Survey Emails</p>
                  <h4 className={classes.reminderEmailValue}>
                    {data?.survey_email_count}
                  </h4>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ maxHeight: "314px", overflowY: "scroll" }}>
            <div className={classes.gridContainer}>
              <p className={classes.tileLabel}>Count of click</p>
              <div style={{ marginTop: "20px" }}>
                <p className={classes.reminderEmailTitle}>
                  Submitted in 1 click
                </p>
                <h4 className={classes.reminderEmailValue}>
                  {data?.one_click}
                </h4>
              </div>
              <div>
                <p className={classes.reminderEmailTitle}>
                  Submitted in 2 clicks
                </p>
                <h4 className={classes.reminderEmailValue}>
                  {data?.two_click}
                </h4>
              </div>
              <div>
                <p className={classes.reminderEmailTitle}>
                  Submitted in 3 clicks
                </p>
                <h4 className={classes.reminderEmailValue}>
                  {data?.three_click}
                </h4>
              </div>
              <div>
                <p className={classes.reminderEmailTitle}>
                  Submitted in more than 3 click
                </p>
                <h4 className={classes.reminderEmailValue}>
                  {data?.more_than_three_click}
                </h4>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: "30px" }}>
          <div
            className={classes.gridContainer}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <p
              className={classes.tileLabel}
              style={{ fontFamily: "Montserrat" }}
            >
              NPS Score
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flex: "0.5",
                }}
              >
                <div className={classes.alignCenterClass}>
                  <div className={classes.alignCenterClass}>
                    <img src={NPS_IMAGE1} alt="NPS_ScoreImage" height="40px" />
                    <div style={{ textAlign: "center" }}>
                      <p
                        style={{
                          margin: "0",
                          fontSize: "14px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Detractors
                      </p>
                      <span>{detractors}</span>
                      {`(${
                        ((parseFloat(detractors) / totalNps) * 100).toFixed(
                          2
                        ) || 0
                      }%)`}
                    </div>
                  </div>
                </div>
                <div className={classes.alignCenterClass}>
                  <div className={classes.alignCenterClass}>
                    <img src={NPS_IMAGE2} alt="NPS_ScoreImage" height="40px" />
                    <div style={{ textAlign: "center" }}>
                      <p
                        style={{
                          margin: "0",
                          fontSize: "14px",
                          marginTop: "5px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Passive
                      </p>
                      <span>{passive}</span>
                      {`(${
                        ((parseFloat(passive) / totalNps) * 100).toFixed(2) || 0
                      }%)`}
                    </div>
                  </div>
                </div>
                <div className={classes.alignCenterClass}>
                  <div className={classes.alignCenterClass}>
                    <img src={NPS_IMAGE3} alt="NPS_ScoreImage" height="40px" />
                    <div style={{ textAlign: "center" }}>
                      <p
                        style={{
                          margin: "0",
                          fontSize: "14px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Promoters
                      </p>
                      <span>{promoters}</span>
                      {`(${
                        ((parseFloat(promoters) / totalNps) * 100).toFixed(2) ||
                        0
                      }%)`}
                    </div>
                  </div>
                </div>
              </div>
              <h2 className={classes.npsScoreEqual}>=</h2>
              <div style={{ textAlign: "center" }}>
                <h4 className={classes.employeeCount}>{data?.nps_score}</h4>
                <p
                  className={classes.npsInfo}
                  style={{ fontFamily: "Montserrat" }}
                >
                  eNPS Score YTD
                </p>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <div className={classes.gridContainer}>
          <p className={classes.tileLabel}> Mood score</p>
          <Pie
            data={pieData}
            options={pieOptions}
            ref={(input) => {
              chartInstance = input;
            }}
            plugins={[
              ChartPluginLabels,
              {
                afterDraw(chart, args, options) {
                  const positiveData = chart.data.datasets[0].data.filter(
                    (data) => data > 0
                  );
                  if (positiveData < 1) {
                    let ctx = chart.ctx;
                    let width = chart.width;
                    let height = chart.height;
                    ctx.textAlign = "center";
                    ctx.textBaseline = "middle";
                    ctx.font = "30px Arial";
                    ctx.fillText("No data to display", width / 2, height / 2);
                    ctx.restore();
                  }
                },
              },
            ]}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export const EngagementDrivers = (props) => {
  const { styles: classes = {}, data = {} } = props;

  return (
    <Grid
      container
      style={{
        marginTop: "60px",
        border: "1px solid #000000",
        padding: "40px",
      }}
    >
      <h3 className={classes.sectionTitle}>Engagement Drivers</h3>
      <Grid container spacing={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <div className={classes.gridContainerDrivers}>
              <h5
                className={classes.sectionSubTitle}
                style={{ marginBottom: "6px" }}
              >
                Recruitment
              </h5>
              <StackHorizontalBar
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...BooleanLabels.map((label, key) => ({
                      label: BooleanLabels[key],
                      data: [
                        data?.recriutment?.[
                          `recriutment_${label.toLowerCase()}`
                        ],
                      ],
                      backgroundColor: BooleanColors[key],
                      hoverBackgroundColor: BooleanColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
                options={{
                  aspectRatio: 9,
                  scales: {
                    x: {
                      stacked: true,
                      display: false,
                    },
                    y: {
                      stacked: true,
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <div className={classes.gridContainerDrivers}>
              <h5
                className={classes.sectionSubTitle}
                style={{ marginBottom: "6px" }}
              >
                Induction
              </h5>
              <StackHorizontalBar
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...BooleanLabels.map((label, key) => ({
                      label: BooleanLabels[key],
                      data: [
                        data?.induction?.[`induction_${label.toLowerCase()}`],
                      ],
                      backgroundColor: BooleanColors[key],
                      hoverBackgroundColor: BooleanColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
                options={{
                  aspectRatio: 9,
                  scales: {
                    x: {
                      stacked: true,
                      display: false,
                    },
                    y: {
                      stacked: true,
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <div className={classes.gridContainerDrivers}>
              <h5
                className={classes.sectionSubTitle}
                style={{ marginBottom: "6px" }}
              >
                Learning and Development
              </h5>
              <StackHorizontalBar
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...BooleanLabels.map((label, key) => ({
                      label: BooleanLabels[key],
                      data: [
                        data?.learning_development?.[
                          `learning_development_${label.toLowerCase()}`
                        ],
                      ],
                      backgroundColor: BooleanColors[key],
                      hoverBackgroundColor: BooleanColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
                options={{
                  aspectRatio: 9,
                  scales: {
                    x: {
                      stacked: true,
                      display: false,
                    },
                    y: {
                      stacked: true,
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <div className={classes.gridContainerDrivers}>
              <h5
                className={classes.sectionSubTitle}
                style={{ marginBottom: "6px" }}
              >
                Skip Level Manager
              </h5>
              <StackHorizontalBar
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...BooleanLabels.map((label, key) => ({
                      label: BooleanLabels[key],
                      data: [
                        data?.skip_level_manager?.[
                          `skip_level_manager_${label.toLowerCase()}`
                        ],
                      ],
                      backgroundColor: BooleanColors[key],
                      hoverBackgroundColor: BooleanColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
                options={{
                  aspectRatio: 9,
                  scales: {
                    x: {
                      stacked: true,
                      display: false,
                    },
                    y: {
                      stacked: true,
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: "20px" }}>
          <Grid item xs={12} md={6} lg={3}>
            <div className={classes.gridContainerDrivers}>
              <h5
                className={classes.sectionSubTitle}
                style={{ marginBottom: "6px" }}
              >
                Team
              </h5>
              <StackHorizontalBar
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...BooleanLabels.map((label, key) => ({
                      label: BooleanLabels[key],
                      data: [data?.team?.[`team_${label.toLowerCase()}`]],
                      backgroundColor: BooleanColors[key],
                      hoverBackgroundColor: BooleanColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
                options={{
                  aspectRatio: 9,
                  scales: {
                    x: {
                      stacked: true,
                      display: false,
                    },
                    y: {
                      stacked: true,
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <div className={classes.gridContainerDrivers}>
              <h5
                className={classes.sectionSubTitle}
                style={{ marginBottom: "6px" }}
              >
                Communication
              </h5>
              <StackHorizontalBar
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...BooleanLabels.map((label, key) => ({
                      label: BooleanLabels[key],
                      data: [
                        data?.communication?.[
                          `communication_${label.toLowerCase()}`
                        ],
                      ],
                      backgroundColor: BooleanColors[key],
                      hoverBackgroundColor: BooleanColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
                options={{
                  aspectRatio: 9,
                  scales: {
                    x: {
                      stacked: true,
                      display: false,
                    },
                    y: {
                      stacked: true,
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <div className={classes.gridContainerDrivers}>
              <h5
                className={classes.sectionSubTitle}
                style={{ marginBottom: "6px" }}
              >
                Appreciation
              </h5>
              <StackHorizontalBar
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...BooleanLabels.map((label, key) => ({
                      label: BooleanLabels[key],
                      data: [
                        data?.appreciation?.[
                          `appreciation_${label.toLowerCase()}`
                        ],
                      ],
                      backgroundColor: BooleanColors[key],
                      hoverBackgroundColor: BooleanColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
                options={{
                  aspectRatio: 9,
                  scales: {
                    x: {
                      stacked: true,
                      display: false,
                    },
                    y: {
                      stacked: true,
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <div className={classes.gridContainerDrivers}>
              <h5
                className={classes.sectionSubTitle}
                style={{ marginBottom: "6px" }}
              >
                Organisation Policies
              </h5>
              <StackHorizontalBar
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...BooleanLabels.map((label, key) => ({
                      label: BooleanLabels[key],
                      data: [
                        data?.org_policies?.[
                          `org_policies_${label.toLowerCase()}`
                        ],
                      ],
                      backgroundColor: BooleanColors[key],
                      hoverBackgroundColor: BooleanColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
                options={{
                  aspectRatio: 9,
                  scales: {
                    x: {
                      stacked: true,
                      display: false,
                    },
                    y: {
                      stacked: true,
                      display: false,
                    },
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const Manager = (props) => {
  const { styles: classes = {}, data } = props;

  const ImpressionLabels = [
    "Fun Guy",
    "Approachable",
    "Walks the talk",
    "Strict",
    "Dependable",
    "Too early to comment",
    "Not spent any time at all",
  ];
  const ImpressionValueLabels = [
    "fun_guy",
    "approachable",
    "walks_the_talk",
    "strict",
    "dependable",
    "too_early_to_comment",
    "not_spent_any_time_at_all",
  ];

  const EffortLabels = [
    "Oh yes, my Manager is awesome",
    "My Manager is good but also very busy",
    "So far, has never spent time proactively",
  ];
  const EffortValueLabels = [
    "my_Manager_awesome",
    "my_Manager_good",
    "proactively",
  ];

  const GoalLabels = [
    "Yes that’s wonderful",
    "yup we've had a brief discussion, ok that's cool, you should reach out to your manager if you need more clarity ",
    "Nope, haven't had a discussion so far. Why don’t you slot a time with your manager to have a discussion",
  ];
  const GoalValueLabels = ["thats_wonderful", "discussion", "havnt_discussion"];

  const ResolveLabels = [
    "Yes Always",
    "I don’t go to my manager, I try and resolve it myself",
    "No no one has time here",
  ];
  const ResolveValueLabels = [
    "yes_always",
    "resolve_myself",
    "no_one_has_time_here",
  ];

  return (
    <Grid
      container
      style={{
        marginTop: "60px",
        border: "1px solid #000000",
        padding: "40px",
      }}
    >
      <h3 className={classes.sectionTitle} style={{ fontFamily: "Montserrat" }}>
        Manager
      </h3>
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className={classes.gridContainerManager}>
              <h5 className={classes.sectionSubTitle}>
                Whats your first impression about your manager
              </h5>
              <StackHorizontalBar
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...ImpressionLabels.map((label, key) => ({
                      label: ImpressionLabels[key],
                      data: [
                        data?.[
                          "Whats your first impression about your Manager ?"
                        ]?.[ImpressionValueLabels[key]],
                      ],
                      backgroundColor: chartColors[key],
                      hoverBackgroundColor: chartColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
                options={{
                  aspectRatio: 9,
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.gridContainerManager}>
              <h5 className={classes.sectionSubTitle}>
                Does your manager make effort to spend time with you
              </h5>
              <StackHorizontalBar
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...EffortLabels.map((label, key) => ({
                      label: EffortLabels[key],
                      data: [
                        data?.[
                          "Does your manager make effort to spend time with you ?"
                        ]?.[EffortValueLabels[key]],
                      ],
                      backgroundColor: chartColors[key],
                      hoverBackgroundColor: chartColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
                options={{
                  aspectRatio: 9,
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "20px" }}>
          <Grid item xs={12} md={6}>
            <div className={classes.gridContainerManager}>
              <h5 className={classes.sectionSubTitle}>
                Have your goals been clearly defined
              </h5>
              <StackHorizontalBar
                ulStyles={{ height: "78px" }}
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...GoalLabels.map((label, key) => ({
                      label: GoalLabels[key],
                      data: [
                        data?.["Have your goals been clearly defined ?"]?.[
                          GoalValueLabels[key]
                        ],
                      ],
                      backgroundColor: chartColors[key],
                      hoverBackgroundColor: chartColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
                options={{
                  aspectRatio: 9,
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.gridContainerManager}>
              <h5 className={classes.sectionSubTitle}>
                If i go to my manager with a problem he/she does his best to
                resolve
              </h5>
              <StackHorizontalBar
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...ResolveLabels.map((label, key) => ({
                      label: ResolveLabels[key],
                      data: [
                        data?.[
                          "If I go to my Manager with a problem he/she does his best to resolve it ?"
                        ]?.[ResolveValueLabels[key]],
                      ],
                      backgroundColor: chartColors[key],
                      hoverBackgroundColor: chartColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
                options={{
                  aspectRatio: 9,
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const Role = (props) => {
  const { styles: classes = {}, data } = props;

  const IfNoLabels = [
    "Its different, but Iam enjoying the learning",
    "Its different, but my team is supportive",
    "While its different, Iam still able to manage",
    "Iam still struggling",
  ];
  const IfNoValueLabels = ["enjoying", "supportive", "manage", "struggling"];

  const HonestlyLabels = [
    "Oh yes ",
    "Its been alright so far",
    "Its a job and I get paid to do it",
    "Its not what I expected",
  ];
  const HonestlyValueLabels = ["Oh_yes", "alright", "get_paid", "expected"];

  return (
    <Grid
      container
      style={{
        marginTop: "60px",
        border: "1px solid #000000",
        padding: "40px",
      }}
    >
      <h3 className={classes.sectionTitle}>Role</h3>
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className={classes.gridContainerRole}>
              <h5 className={classes.sectionSubTitle}>
                Is your work in line with what was communicated to you at the
                time of joining?
              </h5>
              <StackHorizontalBar
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...BooleanLabels.map((label, key) => ({
                      label: label,
                      title: label,
                      data: [
                        data?.[
                          "Is your work in line with what was communicated to you at the time of joining?"
                        ]?.[label.toLowerCase()],
                      ],
                      backgroundColor: BooleanColors[key],
                      hoverBackgroundColor: BooleanColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
                options={{
                  aspectRatio: 9,
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.gridContainerRole}>
              <h5 className={classes.sectionSubTitle}>If No</h5>
              <StackHorizontalBar
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...IfNoLabels.map((label, key) => ({
                      label: IfNoLabels[key],
                      data: [
                        data?.[
                          "Is your work in line with what was communicated to you at the time of joining?"
                        ]?.[IfNoValueLabels[key]],
                      ],
                      backgroundColor: chartColors[key],
                      hoverBackgroundColor: chartColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
                options={{
                  aspectRatio: 9,
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: "20px" }} spacing={2}>
          <Grid item xs={12} md={6}>
            <div className={classes.gridContainerRole}>
              <h5 className={classes.sectionSubTitle}>
                Honestly speaking do you enjoy your work
              </h5>
              <StackHorizontalBar
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...HonestlyLabels.map((label, key) => ({
                      label: HonestlyLabels[key],
                      data: [
                        data?.["Honestly speaking do you enjoy your work ?"]?.[
                          HonestlyValueLabels[key]
                        ],
                      ],
                      backgroundColor: chartColors[key],
                      hoverBackgroundColor: chartColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
                options={{
                  aspectRatio: 9,
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const Team = (props) => {
  const { styles: classes = {}, data } = props;

  const FriendsLabels = ["a few", "Some", "Many", "None"];
  const FriendsValueLabels = ["a_few", "some", "many", "none"];

  const TeamEnjoyLabels = ["Opportunitues", "Bonding", "Interact Much"];
  const TeamEnjoyValueLabels = ["opportunitues", "bonding", "interact_much"];

  return (
    <Grid
      container
      style={{
        marginTop: "60px",
        border: "1px solid #000000",
        padding: "40px",
      }}
    >
      <h3 className={classes.sectionTitle}>Team</h3>
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className={classes.gridContainerTeam}>
              <h5 className={classes.sectionSubTitle}>
                Have you made any friends at work
              </h5>
              <StackHorizontalBar
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...FriendsLabels.map((label, key) => ({
                      label: FriendsLabels[key],
                      data: [
                        data?.["Have you made any friends at work ?"]?.[
                          FriendsValueLabels[key]
                        ],
                      ],
                      backgroundColor: chartColors[key],
                      hoverBackgroundColor: chartColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
                options={{
                  aspectRatio: 9,
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.gridContainerTeam}>
              <h5 className={classes.sectionSubTitle}>
                Does the team you work with allow you to enjoy your work and
                inspire you to do well
              </h5>
              <StackHorizontalBar
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...TeamEnjoyLabels.map((label, key) => ({
                      label: TeamEnjoyLabels[key],
                      data: [
                        data?.[
                          "Does the team you work with allow you to enjoy your work and inspire you to do well"
                        ]?.[TeamEnjoyValueLabels[key]],
                      ],
                      backgroundColor: chartColors[key],
                      hoverBackgroundColor: chartColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
                options={{
                  aspectRatio: 9,
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const Communication = (props) => {
  const { styles: classes = {}, data } = props;

  const CommunicationLabels = ["Enough", "Have not seen", "Hardly Anything"];

  const CommunicationValueLabels = ["enough", "havent_seen", "hardly_anything"];

  return (
    <Grid
      container
      style={{
        marginTop: "60px",
        border: "1px solid #000000",
        padding: "40px",
      }}
    >
      <h3 className={classes.sectionTitle}>Communication</h3>
      <Grid item xs={12}>
        <div className={classes.gridContainer}>
          <h5 className={classes.sectionSubTitle}>
            Do you think there are adequate communication channels for employee
            to voice their opinion
          </h5>
          <StackHorizontalBar
            data={{
              labels: ToolTipTitle,
              datasets: [
                ...CommunicationLabels.map((label, key) => ({
                  label: CommunicationLabels[key],
                  data: [
                    data?.[
                      "Do you think there are adequate communication channels for employee to voice their opinion ?"
                    ]?.[CommunicationValueLabels[key]],
                  ],
                  backgroundColor: chartColors[key],
                  hoverBackgroundColor: chartColors[key],
                  ...stackedBarConstants,
                })),
              ],
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export const Appreciation = (props) => {
  const { styles: classes = {}, data } = props;

  const AppreciationLabels = ["Feel Special", "Recognised", "Culture"];

  const AppreciationValueLabels = ["feel_special", "recognised", "culture"];

  return (
    <Grid
      container
      style={{
        marginTop: "40px",
        border: "1px solid #000000",
        padding: "40px",
      }}
    >
      <h3 className={classes.sectionTitle}>Appreciation</h3>
      <Grid item xs={12}>
        <div className={classes.gridContainer}>
          <h5 className={classes.sectionSubTitle}>
            When you go beyond what you are expected to do, do you get
            adequately recognised for it
          </h5>
          <StackHorizontalBar
            data={{
              labels: ToolTipTitle,
              datasets: [
                ...AppreciationLabels.map((label, key) => ({
                  label: AppreciationLabels[key],
                  data: [
                    data?.[
                      "When you go beyond what you are expected to do, do you get adequately recognised for it"
                    ]?.[AppreciationValueLabels[key]],
                  ],
                  backgroundColor: chartColors[key],
                  hoverBackgroundColor: chartColors[key],
                  ...stackedBarConstants,
                })),
              ],
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export const LAndD = (props) => {
  const { styles: classes = {}, data } = props;

  const LearnTrainingLabels = ["Expected", "Yes", "Better", "No"];

  const LearnTrainingValueLabels = ["expected", "yes", "better", "no"];

  const LearnGrownLabels = [
    "Yes Definitely",
    "Enjoyed my work",
    "Its very monotonous",
  ];

  const LearnGrownValueLabels = [
    "yes_definitely",
    "enjoyed_my_work",
    "its_very_monotonous",
  ];

  return (
    <Grid
      container
      style={{
        marginTop: "60px",
        border: "1px solid #000000",
        padding: "40px",
      }}
    >
      <h3 className={classes.sectionTitle}>Learning and Development</h3>
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div className={classes.gridLandDContainer}>
              <h5 className={classes.sectionSubTitle}>
                Do you feel you have received sufficient training to do your job
                effectively?
              </h5>
              <StackHorizontalBar
                options={{
                  aspectRatio: 9,
                }}
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...LearnTrainingLabels.map((label, key) => ({
                      label: LearnTrainingLabels[key],
                      data: [
                        data?.[
                          "Do you feel you have received sufficient training to do your job effectively?"
                        ]?.[LearnTrainingValueLabels[key]],
                      ],
                      backgroundColor: chartColors[key],
                      hoverBackgroundColor: chartColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.gridLandDContainer}>
              <h5 className={classes.sectionSubTitle}>
                In the last few months, do you feel you have grown in terms of
                your knowledge and capabilities?
              </h5>
              <StackHorizontalBar
                options={{
                  aspectRatio: 9,
                }}
                data={{
                  labels: ToolTipTitle,
                  datasets: [
                    ...LearnGrownLabels.map((label, key) => ({
                      label: LearnGrownLabels[key],
                      data: [
                        data?.[
                          "In the last few months, do you feel you have grown in terms of your knowledge and capabilities?"
                        ]?.[LearnGrownValueLabels[key]],
                      ],
                      backgroundColor: chartColors[key],
                      hoverBackgroundColor: chartColors[key],
                      ...stackedBarConstants,
                    })),
                  ],
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const Diversity = (props) => {
  const { styles: classes = {}, data } = props;

  const DiversityLabels = ["Witnessed", "No say, No do", "Action"];

  const DiversityValueLabels = ["witnessed", "say", "action"];

  const DiversityLabels1 = ["I can see", "Bias", "Comment"];

  const DiversityValueLabels1 = ["i_can_see", "bias", "comment"];

  return (
    <Grid
      container
      style={{
        marginTop: "60px",
        border: "1px solid #000000",
        padding: "40px",
      }}
    >
      <h3 className={classes.sectionTitle}>Diversity</h3>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div className={classes.gridContainerDiversity}>
            <h5 className={classes.sectionSubTitle}>
              Do you feel that all employees are treated fairly regardless of
              grade, tenure, gender or language
            </h5>
            <StackHorizontalBar
              options={{
                aspectRatio: 9,
              }}
              data={{
                labels: ToolTipTitle,
                datasets: [
                  ...DiversityLabels.map((label, key) => ({
                    label: DiversityLabels[key],
                    data: [
                      data?.[
                        "Do you feel that all employees are treated fairly regardless of grade, tenure, gender or language ?"
                      ]?.[DiversityValueLabels[key]],
                    ],
                    backgroundColor: chartColors[key],
                    hoverBackgroundColor: chartColors[key],
                    ...stackedBarConstants,
                  })),
                ],
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.gridContainerDiversity}>
            <h5 className={classes.sectionSubTitle}>
              Do you think there are equal opportunities for women ?
            </h5>
            <StackHorizontalBar
              options={{
                aspectRatio: 9,
              }}
              data={{
                labels: ToolTipTitle,
                datasets: [
                  ...DiversityLabels1.map((label, key) => ({
                    label: DiversityLabels1[key],
                    data: [
                      data?.[
                        "Do you think there are equal opportunities for women ?"
                      ]?.[DiversityValueLabels1[key]],
                    ],
                    backgroundColor: chartColors[key],
                    hoverBackgroundColor: chartColors[key],
                    ...stackedBarConstants,
                  })),
                ],
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const WorkLifeBalance = (props) => {
  const { styles: classes = {}, data } = props;

  const WorkLifeLabels = [
    "Yes, we have reasonable work life balance",
    "No, but I am ok with it",
    "No, it just work work and work",
  ];

  const WorkLifeValueLabels = ["reasonable", "ok_with_it", "work_work"];

  const WorkLifeLabels1 = ["Things will get better", "Culture here"];
  const WorkLifeValueLabels1 = ["things_will_get_better", "culture_herer"];

  return (
    <Grid
      container
      style={{
        marginTop: "60px",
        border: "1px solid #000000",
        padding: "40px",
      }}
    >
      <h3 className={classes.sectionTitle}>Work Life Balance</h3>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div className={classes.gridContainerWorkLifeBalance}>
            <h5 className={classes.sectionSubTitle}>
              Are you able to balance your work and personal commitments?
            </h5>
            <StackHorizontalBar
              options={{
                aspectRatio: 9,
              }}
              data={{
                labels: ToolTipTitle,
                datasets: [
                  ...WorkLifeLabels.map((label, key) => ({
                    label: WorkLifeLabels[key],
                    data: [
                      data?.[
                        "Are you able to balance your work and personal commitments?"
                      ]?.[WorkLifeValueLabels[key]],
                    ],
                    backgroundColor: chartColors[key],
                    hoverBackgroundColor: chartColors[key],
                    ...stackedBarConstants,
                  })),
                ],
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.gridContainerWorkLifeBalance}>
            <h5 className={classes.sectionSubTitle}>
              Have you discussed this with anyone ?
            </h5>
            <StackHorizontalBar
              options={{
                aspectRatio: 9,
              }}
              data={{
                labels: ToolTipTitle,
                datasets: [
                  ...WorkLifeLabels1.map((label, key) => ({
                    label: WorkLifeLabels1[key],
                    data: [
                      data?.["Have you discussed this with anyone ?"]?.[
                        WorkLifeValueLabels1[key]
                      ],
                    ],
                    backgroundColor: chartColors[key],
                    hoverBackgroundColor: chartColors[key],
                    ...stackedBarConstants,
                  })),
                ],
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
