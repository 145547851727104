exports.VALIDATIONS = {
  MAXIMUM_CHARACTERS_TEXT_FIELD: 50,
  MAXIMUM_CHARACTERS_TEXT_ADDRESS: 100,
  MAXIMUM_CHARACTERS_TEXT_AREA: 255,
  MAXIMUM_CHARACTERS_SUPPORT_TICKET_AREA: 750,
  MAXIMUM_CHARACTERS_ZIP_CODE: 6,
  MINIMUM_CHARACTERS_ZIP_CODE: 6,
};

exports.FLOAT_REGEX = /^[+-]?[0-9]*[.]?([0-9])*$/;
exports.INT_REGEX = /^[+-]?[0-9]*$/;

exports.ONLY_BLANK_SPACES = "onlyBlankSpaces";
exports.VALIDATE_NAME = "validateName";
exports.VALIDATE_PERCENTAGE = "validatePercentage";
exports.VALIDATE_PASSWORD = "validatePassword";
exports.IS_PASSWORD_MATCH = "isPasswordMatch";
exports.IS_NEW_OLD_PASSWORD_MATCH = "isNewOldPasswordMatch";
exports.VALIDATE_WEBSITE = "validateWebsite";
exports.VALIDATE_ZIPCODE = "validateZipcode";
exports.PERMISSIONS_SELECTED = "validatePermissions";
exports.POSITIVE_NUMBER = "validatePositiveNumberOnly";

exports.FIELD_IS_REQUIRED = "This field is required.";
exports.PERMISSION_REQUIRED = "Please select at least one permission.";
exports.SUB_MENU_REQUIRED = "Please select at least one menu";
exports.FEATURE_REQUIRED = "Please select at least one feature";
exports.ENTER_VALID_NAME = "Enter valid name";
exports.ENTER_VALID_PHONE = "Enter valid phone number";
exports.BLANK_SPACES_NOT_ALLOWED = "Blank spaces not allowed";
exports.SPECIAL_CHARACTER_SPACES_NOT_ALLOWED =
  "Only alphabets, numbers, _ and - is allowed";
exports.ENTER_VALID_EMAIL = "Email is not valid";
exports.ONLY_DIGIT_ALLOW = "Only digits are allowed";
exports.PASSWORD_MISMATCH = "Those passwords didn't match. Please try again.";
exports.MAXIMUM_ALLOWED_CHARACTERS_FOR_TEXT_FIELD =
  "Maximum 50 characters are allowed";
exports.MAXIMUM_ALLOWED_CHARACTERS_FOR_TEXT_ADDRESS =
  "Maximum 100 characters are allowed";
exports.MAXIMUM_ALLOWED_CHARACTERS_FOR_TEXT_AREA =
  "Maximum 255 characters are allowed";
exports.MAXIMUM_CHARACTERS_SUPPORT_TICKET_AREA =
  "Maximum 750 characters are allowed";
exports.MAXIMUM_CHARACTERS_ZIP_CODE = "Maximum 6 characters are allowed";
exports.MINIMUM_CHARACTERS_ZIP_CODE = "Minimum 6 characters are allowed";
exports.ENTER_VALID_PASSWORD =
  "Password should have minimum 8 characters and maximum 15 characters consisting 1 uppercase, 1 lowercase, 1 numeric, 1 special characters";
exports.ENTER_DIFFERENT_PASSWORD =
  "New password can not be same as old password";
exports.ENTER_VALID_WEBSITE = "Please enter valid website";
exports.ENTER_VALID_ZIPCODE = "Please enter valid zip code";
exports.POSITIVE_NUMBER_MESSAGE = "Please enter number greater than 0";
exports.VALIDATE_PERCENTAGE_MESSAGE = "Please enter number less than 100";

exports.CONFIG_TYPES = {
  CUSTOMER: "Cust",
  APPLICATION: "App",
};

exports.ORGANISATION_STATUS = {
  inactive: "status-warning",
  active: "status-success",
  suspended: "status-danger",
};

exports.FILE_STATUS = {
  "data quality check": "status-success",
  processed: "status-success",
  rejected: "status-danger",
  failed: "status-danger",
  "in progress": "status-info",
  deleted: "status-danger",
};

exports.BUILD_STATUS = {
  draft: "status-info",
  processed: "status-success",
  rejected: "status-danger",
  failed: "status-danger",
  "in progress": "status-info",
  deleted: "status-danger",
  scheduled: "status-info",
  "to be scheduled": "status-info",
  successful: "status-success",
};

exports.SUPPORT_TICKET_STATUS = {
  rejected: "status-danger",
  open: "status-warning",
  closed: "status-success",
  "in progress": "status-info",
  assigned: "status-info",
  resolved: "status-success",
};

exports.PROMOTION_STATUS = {
  "in-active": "status-danger",
  active: "status-success",
};

exports.SUBSCRIPTION_STATUS = {
  "in-active": "status-warning",
  active: "status-success",
  suspended: "status-danger",
  cancelled: "status-warning",
  expired: "status-danger",
};

exports.INVOICE_STATUS = {
  initiated: "status-info",
  paid: "status-success",
  failed: "status-danger",
  unpaid: "status-warning",
  cancelled: "status-danger",
};

exports.TAX_STATUS = {
  "in-active": "status-warning",
  active: "status-success",
  suspended: "status-danger",
};

exports.SCHEDULE_FREQUENCY = {
  DAILY: "daily",
  MONTHLY: "monthly",
};

// exports.SIDE_MENU = [
//   {
//     label: "Workspace",
//     name: "Workspace",
//     children: [
//       {
//         label: "File Upload",
//         action: this.ACTIONS.FILE_UPLOAD,
//         name: "File Upload",
//         default: false,
//       },
//       {
//         label: "Build",
//         action: this.ACTIONS.BUILD,
//         name: "Build",
//         default: false,
//       },
//       { label: "Use", action: this.ACTIONS.USE, name: "Use", default: false },
//       {
//         label: "Optimise Decision",
//         action: this.ACTIONS.OPTIMISE_DECISION,
//         name: "Optimise Decision",
//         default: false,
//       },
//       {
//         label: "Predict",
//         action: this.ACTIONS.PREDICT,
//         name: "Predict",
//         default: false,
//       },
//       { label: "Classify", action: this.ACTIONS.CLASSIFY, name: "Classify" },
//       {
//         label: "Anomaly Detection",
//         action: this.ACTIONS.ANOMALY_DETECTION,
//         name: "Anomaly",
//         default: false,
//       },
//       {
//         label: "Profile",
//         action: this.ACTIONS.PROFILE,
//         name: "Profile",
//         default: true,
//       },
//     ],
//   },
//   {
//     label: "Administration",
//     name: "Administration",
//     children: [
//       {
//         label: "Customer Support",
//         action: this.ACTIONS.SUPPORT,
//         name: "Customer Support",
//         default: false,
//       },
//       {
//         label: "Configuration",
//         action: this.ACTIONS.CONFIGURATION,
//         name: "Configuration",
//         default: false,
//       },
//       {
//         label: "Roles and Permission",
//         action: this.ACTIONS.ROLE,
//         name: "Role",
//         default: false,
//       },
//       {
//         label: "Organisation Management",
//         action: this.ACTIONS.ORGANIZATIONS,
//         name: "Organisation",
//         default: false,
//       },
//       {
//         label: "User Management",
//         action: this.ACTIONS.USERS,
//         name: "User",
//         default: false,
//       },
//       {
//         label: "Menu",
//         action: this.ACTIONS.MENU,
//         name: "Menu",
//         default: false,
//       },
//       {
//         label: "Submenu",
//         action: this.ACTIONS.SUBMENU,
//         name: "Submenu",
//         default: false,
//       },
//       {
//         label: "Feature Selection",
//         action: this.ACTIONS.FEATURE_SELECTION,
//         name: "Feature Selection",
//         default: false,
//       },
//       {
//         label: "Subscription",
//         action: this.ACTIONS.SUBSCRIPTION,
//         name: "Subscription",
//         default: false,
//       },
//     ],
//   },
// ];

exports.ACTION_OPERATORS = [
  { value: " ", text: "Select" },
  { value: "equalTo", text: "Equal to" },
  { value: "between", text: "Between" },
  { value: "greaterThan", text: "Greater than" },
  { value: "lessThan", text: "Less than" },
];

exports.ACTION_DEFAULT_OPERATOR = " ";

exports.BUILD_TYPE = {
  NEW_BUILD: "New build",
  DRAFT: "Draft",
  CLONE: "Clone",
  MANUAL_RETRAIN: "Manual retrain",
};

exports.BUILD_STATE = {
  NEW_BUILD: "New build",
  DRAFT: "DRAFT",
  CLONE: "Clone",
  MANUAL_RETRAIN: "Manual retrain",
};

exports.PLAN_TYPE = {
  PAY_AS_YOU_GO: "pay per use",
  NUMBER_OF_USERS: "per user license",
};

exports.PAYMENT_STATUS = {
  bacs_debit: "Bank Debits",
  card: "Card",
  "bank debits": "Bank Debits",
};

exports.PLAN_NAMES = {
  FREE: "guest",
  ENTERPRISE: "enterprise",
  PROFESSIONAL: "professional",
};

exports.USER_STATUS = {
  active: "status-success",
  inactive: "status-danger",
};

exports.CONFIG_VALIDATION_REGEX = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  int: /^\d*$/,
  comma_separated_int: /[0-9]+(,[0-9]+)*/,
  mobile: /^\+[1-9]{1}[0-9]{3,14}$/,
  str: /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/,
};
