import React, { useState, useEffect, useContext } from "react";

import Header from './components/Header.js';

import Messages from "./components/Messages";
import messageStyles from "./components/Messages.module.scss";

import Input from "./components/Input";

import {closeSurvey} from "../thunk/Action";

import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import styles from './App.scss';

import { ChatContext } from "./context/chatContext";
import {setWelcomeMessage, resetChatData} from "./context/chatActions";
import getChat from "./context/chatApi";

const App = (props) => {
    const [messages, setMessages] = useState([]);
    const {chatDispatch, chatConversationState} = useContext(ChatContext);

    useEffect(() => {
        setWelcomeMessage(props.welcomeMessage, props.start_command, chatDispatch);
        if(props.welcomeMessage == '') {
            getChat(
                props.start_command,
                '',
                props.employee_id,
                props.survey_schedule_id,
                props.survey_id,
                props.surveySenderId,
                chatConversationState.answerAnonymously
            )(chatDispatch);
        }
    }, [props]);
    const send = async text => {
    };

    return (
        <div className="chatbot">
            <Header/>
            {!chatConversationState.chatLoading ? <>
            <Messages/>
            {chatConversationState?.answerType == 'text' || chatConversationState?.previousAnswerFeedbackType == 'text' ?
                <Input onSend={send}/> :
                chatConversationState?.start == false ? <div className={styles.contactInfo}>
                    <p>For any queries, reach us at ash@acenange.com</p>
                </div> : undefined}
            </> : <div className={messageStyles.messagesContainer}></div>}
        </div>
    );
}

const mapStateToProps = (state) => {
    return state.reducer.survey;
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            closeSurvey,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(App);
