import React, { useState, useEffect } from "react";
import FormElement from "../../../component/formElement/formElement";
import { useDispatch } from "react-redux";

import Card from "../../../component/UIElements/Card";
import { useStyles } from "./UpdateClient.style";
import { backArrow, rightArrowWhite } from "../../../component/Images";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { createCustomerList } from "../../../thunk/Action";
import InputTextField from "../../../component/UIElements/InputTextField";
import addPlusIcon from "../../../assets/images/svgs/plus_add_club.svg";
import deleteIcon from "../../../assets/images/svgs/close.png";
import { FormHelperText, TextField } from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { isValidPhoneNumber } from "react-phone-number-input";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import MobileNumber from "../../../component/UIElements/MobileNumber";
let numbers = [];
const UpdateClient = (props) => {
  useEffect(() => {
    // console.log("is_ useEffect");
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const indusrtyData = [
    { id: 1, title: "IT" },
    { id: 2, title: "ITES" },
    { id: 3, title: "BFSI" },
    { id: 4, title: "CONSULTING" },
    { id: 5, title: "MANUFACTURING" },
    { id: 6, title: "ECOMMERCE" },
    { id: 7, title: "RETAIL" },
    { id: 8, title: "TELECOMMUNICATION" },
    { id: 9, title: "FMCG" },
    { id: 10, title: "MEDIA & ENTERTAINMENT" },
    { id: 11, title: "FINTECH" },
    { id: 12, title: "EDUTECH" },
    { id: 13, title: "AVIATION" },
    { id: 14, title: "AUTOMOTIVE" },
    { id: 15, title: "INSURANCE" },
    { id: 16, title: "HEALTHCARE" },
    { id: 17, title: "PHARMA" },
    { id: 18, title: "REAL ESTATE" },
    { id: 19, title: "DEFENCE" },
    { id: 20, title: "TOURISM" },
    { id: 21, title: "SERVICES" },
    { id: 22, title: "OTHERS" },
  ];

  const dispatch = useDispatch();

  const [step, setStep] = useState(0);

  const [inputs, setInputs] = useState([]);
  const [subInputs, setSubInputs] = useState({});
  const [spocInputs, setSpocInputs] = useState({});

  const [formFields, setFormFields] = useState([{ name: "", description: "" }]);
  const addFields = () => {
    setFormFields([...formFields, { name: "", description: "" }]);
  };
  const removeFields = (index) => {
    let data = [...formFields];
    data.splice(index, 1);
    setFormFields(data);
  };

  const onChangeIndustryName = (text, idx) => {
    const localObj = formFields.map((item, index) =>
      index === idx ? { ...item, name: text } : item
    );
    setFormFields(localObj);
  };

  const onChangeIndustryDesc = (text, idx) => {
    const localObj = formFields.map((item, index) =>
      index === idx ? { ...item, description: text } : item
    );
    setFormFields(localObj);
  };

  const [subformFields, setSubFormFields] = useState([
    { name: "", description: "" },
  ]);
  const addsubFields = () => {
    setSubFormFields([...subformFields, { name: "", description: "" }]);
  };
  const removeSubFields = (index) => {
    let data = [...subformFields];
    data.splice(index, 1);
    setSubFormFields(data);
  };

  const onChangeSubIndustryName = (text, idx) => {
    const localObj = subformFields.map((item, index) =>
      index === idx ? { ...item, name: text } : item
    );
    setSubFormFields(localObj);
  };

  const onChangeSubIndustryDesc = (text, idx) => {
    const localObj = subformFields.map((item, index) =>
      index === idx ? { ...item, description: text } : item
    );
    setSubFormFields(localObj);
  };
  const Data = props.spocDetails;
  console.log("step___Data", Data);
  const [sopformFields, setSopFormFields] = useState(Data);
  const [isValidMobile, setIsValidMobile] = useState(true);
  const [dialCode, setDialCode] = useState("");
  /**
   * This function is trigger when user fill the mobile input field
   * @param phone
   * @param validPhoneNumber
   * @param code
   * update the state value of user phone, isValidMobile, dialCode
   */
  const updateMobileNumber = (phone, validPhoneNumber, code, index) => {
    // const formData = Object.assign({}, setSopFormFields);
    // formData['phoneNo'] = phone;
    // setSopFormFields(formData);
    setIsValidMobile(validPhoneNumber);
    setDialCode(code);
    console.log("index", index);
    console.log("PHON INPUT::", validPhoneNumber);
    console.log("code INPUT::", phone);

    const localObj = sopformFields.map((item, idx) =>
      idx === index
        ? {
            ...item,
            spoc_mobile_number: phone,
            smobilenumber: phone?.length === 0 ? false : true,
          }
        : item
    );
    console.log("localObj", localObj);

    if (!validPhoneNumber && !numbers.includes(index)) {
      numbers.push(index);
    } else if (validPhoneNumber) {
      const idx = numbers.indexOf(index);
      if (idx > -1) {
        // only splice array when item is found
        numbers.splice(idx, 1); // 2nd parameter means remove one item only
      }
    }
    console.log("numbers", numbers);
    setSopFormFields(localObj);
  };
  const updateChroMobileNumber = (phone, validPhoneNumber, code) => {
    // const formData = Object.assign({}, setSopFormFields);
    // formData['phoneNo'] = phone;
    // setSopFormFields(formData);
    setIsValidMobile(validPhoneNumber);
    setDialCode(code);
    props.onChange12(phone);

    console.log("PHON INPUT::", props);
    console.log("code INPUT::", phone);
  };
  const addSopFields = () => {
    setSopFormFields([
      ...sopformFields,
      {
        spoc_name: "",
        spoc_designation: "",
        spoc_email: "",
        spoc_mobile_number: "",
      },
    ]);
  };

  const removeSopFields = (index) => {
    let data = [...sopformFields];
    data.splice(index, 1);
    setSopFormFields(data);
  };

  const onChangeSpocName = (text, idx) => {
    const localObj = sopformFields.map((item, index) =>
      index === idx
        ? { ...item, spoc_name: text, sname: text?.length === 0 ? false : true }
        : item
    );
    setSopFormFields(localObj);
  };

  const onChangeSpocDesig = (text, idx) => {
    const localObj = sopformFields.map((item, index) =>
      index === idx
        ? {
            ...item,
            spoc_designation: text,
            sdesination: text?.length === 0 ? false : true,
          }
        : item
    );
    setSopFormFields(localObj);
  };

  const onChangeSpocEmail = (text, idx) => {
    const localObj = sopformFields.map((item, index) =>
      index === idx
        ? {
            ...item,
            spoc_email: text,
            semail: text?.length === 0 ? false : true,
          }
        : item
    );
    setSopFormFields(localObj);
  };

  const onChangeSpocMobileNo = (text, idx) => {
    const localObj = sopformFields.map((item, index) =>
      index === idx ? { ...item, spoc_mobile_number: text } : item
    );
    setSopFormFields(localObj);
  };

  const onCreateClick = () => {
    props.onClick(sopformFields);
  };

  const classes = useStyles();
  const [errorf, setErrorf] = useState(false);
  const [validNameerror, setValidNameError] = useState(false);
  const [validLastNameerror, setValidLastNameError] = useState(false);
  const [validEntityNameerror, setValidEntityNameError] = useState(false);
  const [validEntityTypeerror, setValidEntityTypeError] = useState(false);

  const [validEmailerror, setValidEmailError] = useState(false);
  const [validmobilenumbererror, setValidmobilenumbererror] = useState(false);
  const [validspocnameerror, setValidSpocNameerror] = useState(false);
  const [validpannoerror, setValidPannoerror] = useState(false);
  const [validGstnoerror, setValidGstnoerror] = useState(false);
  const handlenext1 = () => {
    if (
      !props.value1 ||
      !props.value3 ||
      !props.value4 ||
      !props.value5 ||
      !props.value6 ||
      !props.value7 ||
      !props.value8 ||
      !props.value9 ||
      !props.value11 ||
      !props.value12 ||
      !props.value13
    ) {
      setErrorf(true);
    } else if (isValidMobile === false) {
      setIsValidMobile(false);
    } else if (props.value11.match(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/) === null) {
      setValidEmailError(true);
    } else if (
      props.value1.match(/^[A-Za-z](([_ -])?[A-Za-z]*)+$/) === null ||
      // props.value1.match(/^[0-9]+$/) !== null ||
      props.value1.length > 50
    ) {
      setValidNameError(true);
    } else if (
      props.value3.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) === null ||
      props.value3.match(/^[0-9]+$/) !== null ||
      props.value3.length > 50
    ) {
      setValidEntityNameError(true);
    } else if (
      props.value4.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) === null ||
      props.value4.match(/^[0-9]+$/) !== null ||
      props.value4.length > 50
    ) {
      setValidEntityTypeError(true);
    }
    //else if (
    //   props.value12.match(/^[0-9]+$/) === null ||
    //   props.value12.length > 10 ||
    //   props.value12.length < 10
    // ) {
    //   setValidmobilenumbererror(true);
    // }
    else if (
      props.value9.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) === null ||
      props.value9.match(/^[0-9]+$/) !== null ||
      props.value9.length > 10 ||
      props.value9.length < 10
    ) {
      setValidPannoerror(true);
    } else if (
      props.value8.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) === null ||
      props.value8.match(/^[0-9]+$/) !== null ||
      props.value8.length > 15 ||
      props.value8.length < 15
    ) {
      setValidGstnoerror(true);
    } else {
      // console.log(alert("hi"))
      setStep(1);
    }
    // setStep(1)
    // console.log("props.label0000000", props.value11);
  };
  const [spocerr, setSpocErr] = useState(false);

  const handlenext2 = () => {
    if (
      !props.valueIndustry ||
      !props.valuesubIndustry ||
      numbers.length !== 0
    ) {
      console.log("call if");
      setErrorf(true);
    } else if (
      sopformFields.filter(
        (item, idx) =>
          item.spoc_name === "" ||
          item.spoc_name.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) ===
            null ||
          item.spoc_name.match(/^[0-9]+$/) !== null
      ).length > 0 ||
      sopformFields.filter(
        (item, idx) =>
          item.spoc_designation === "" ||
          item.spoc_designation.match(
            /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
          ) === null ||
          item.spoc_designation.match(/^[0-9]+$/) !== null
      ).length > 0 ||
      sopformFields.filter(
        (item, idx) =>
          item.spoc_email === "" ||
          item.spoc_email.match(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/) === null
      ).length > 0 ||
      sopformFields.filter(
        (item, idx) =>
          item.spoc_mobile_number === "" ||
          item.spoc_mobile_number.match(/^[0-9]+$/) !== null
        //  ||
        // item.spoc_mobile_number.length > 10 ||
        // item.spoc_mobile_number.length < 10
      ).length > 0
    ) {
      let finalData = sopformFields.map((item, idx) => {
        return {
          ...item,
          sname:
            item?.spoc_name.length === 0 ||
            item?.spoc_name.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) ===
              null ||
            item?.spoc_name.match(/^[0-9]+$/) !== null
              ? false
              : true,
          sdesination:
            item?.spoc_designation.length === 0 ||
            item?.spoc_designation.match(
              /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
            ) === null ||
            item?.spoc_designation.match(/^[0-9]+$/) !== null
              ? false
              : true,
          semail:
            item?.spoc_email.length === 0 ||
            item?.spoc_email.match(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/) === null
              ? false
              : true,
          smobilenumber:
            item?.spoc_mobile_number.length === 0 ||
            item?.spoc_mobile_number.match(/^[0-9]+$/) === null ||
            item?.spoc_mobile_number.length > 10 ||
            item?.spoc_mobile_number.length < 10
              ? false
              : true,
        };
      });
      setSopFormFields(finalData);
      setErrorf(true);
      console.log("call else if");
      // onCreateClick();
    } else {
      console.log("call else");
      onCreateClick();
    }
  };

  // console.log("step___", step);
  return (
    <Box>
      <Typography
        className={classes.backBtn}
        onClick={() => props.setOpenDialog(false)}
      >
        <img src={backArrow} /> Back
      </Typography>

      <header className={classes.steperHeader}>
        <h4 className={`title ${step === 0 && "activeStep"}`}>Step 1</h4>
        <img src={rightArrowWhite} />
        <h4 className={`title ${step === 1 && "activeStep"}`}>Step 2</h4>
      </header>

      {/* {step === 0 && (
        <Card className={classes.cardStyle}>
          <Grid
            container
            spacing={3}
            // flexDirection={"column"} display="flex" alignItems="center"
          >
            {props.value1 !== undefined ? (
              <Grid item md={props.md_label1 || 6} xs={12}>
                <TextField
                  id="outlined-basic"
                  label={props.label1}
                  variant="outlined"
                  className={classes.TextField_Style}
                  value={props.value1}
                  onChange={props.onChange1}
                />
              </Grid>
            ) : null}
            {props.value2 !== undefined ? (
              <Grid item md={props.md_label2 || 6} xs={12}>
                <TextField
                  id="outlined-basic"
                  label={props.label2}
                  variant="outlined"
                  className={classes.TextField_Style}
                  value={props.value2}
                  onChange={props.onChange2}
                />
              </Grid>
            ) : null}
            {props.value3 !== undefined ? (
              <Grid item md={props.md_label3 || 6} xs={12}>
                <TextField
                  id="outlined-basic"
                  label={props.label3}
                  variant="outlined"
                  className={classes.TextField_Style}
                  value={props.value3}
                  onChange={props.onChange3}
                />
              </Grid>
            ) : null}
            {props.value4 !== undefined ? (
              <Grid item md={props.md_label4 || 6} xs={12}>
                <TextField
                  id="outlined-basic"
                  label={props.label4}
                  variant="outlined"
                  className={classes.TextField_Style}
                  value={props.value4}
                  onChange={props.onChange4}
                />
              </Grid>
            ) : null}
            {props.value5 !== undefined ? (
              <Grid item md={props.md_label5 || 6} xs={12}>
                <TextField
                  id="outlined-basic"
                  label={props.label5}
                  variant="outlined"
                  className={classes.TextField_Style}
                  value={props.value5}
                  onChange={props.onChange5}
                />
              </Grid>
            ) : null}
            {props.value6 !== undefined ? (
              <Grid item md={props.md_label6 || 6} xs={12}>
                <TextField
                  id="outlined-basic"
                  label={props.label6}
                  variant="outlined"
                  className={classes.TextField_Style}
                  value={props.value6}
                  onChange={props.onChange6}
                />
              </Grid>
            ) : null}
            <Grid container spacing={3} style={{}}>
              <Grid
                item
                md={props.md_label19 || 12}
                xs={12}
                style={{
                  display: "flex",
                  gap: "15px",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  className={classes.btnStyle}
                  // color="primary"
                  onClick={props.onClick}
                >
                  Save
                </Button>
              </Grid>
            </Grid>{" "}
          </Grid>
        </Card>
      )} */}
      {/* {step === 1 && (
        <Card className={classes.cardStyle}>
          <Grid container spacing={3} style={{}}>
            <Grid
              item
              md={props.md_label19 || 12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "15px",
                alignItems: "center",
              }}
            >
              <Button variant="outlined" onClick={() => setStep(0)}>
                Previous
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={props.onClick}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Card>
      )}
    {/* </Box>
  );
}; */}

      {step === 0 && (
        <Card className={classes.cardStyle}>
          <Grid
            container
            spacing={3}
            // flexDirection={"column"} display="flex" alignItems="center"
          >
            {/* {props.value1 !== undefined ? ( */}
            <>
              <Grid item md={props.md_label1 || 6} xs={12}>
                <TextField
                  required
                  error={
                    errorf && !props.value1
                      ? true
                      : validNameerror &&
                        (props.value1.match(
                          /^[A-Za-z](([_ -])?[A-Za-z]*)+$/
                        ) === null ||
                          //  props.value1.match(/^[0-9]+$/) !== null ||
                          props.value1.length > 50)
                      ? true
                      : false
                  }
                  helperText={
                    errorf && !props.value1
                      ? "Required First Name."
                      : validNameerror &&
                        (props.value1.match(
                          /^[A-Za-z](([_ -])?[A-Za-z]*)+$/
                        ) === null ||
                          // props.value1.match(/^[0-9]+$/) !== null ||
                          props.value1.length > 50)
                      ? "Enter Valid First name."
                      : ""
                  }
                  id="outlined-basic"
                  label={props.label1}
                  variant="outlined"
                  className={classes.TextField_Style}
                  value={props.value1}
                  onChange={props.onChange1}
                />
              </Grid>
            </>
            {/* ) : null}  */}
            {/* {props.value2 !== undefined ? ( */}
            {/* <>
                <Grid item md={props.md_label2 || 6} xs={12}>
                  <TextField
                    id="outlined-basic"
                    label={props.label2}
                    variant="outlined"
                    className={classes.TextField_Style}
                    value={props.value2}
                    onChange={props.onChange2}
                  />
                </Grid>
              </> */}
            {/* ) : null} */}
            {props.value3 !== undefined ? (
              <>
                <Grid item md={props.md_label3 || 6} xs={12}>
                  <TextField
                    required
                    error={
                      errorf && !props.value3
                        ? true
                        : validEntityNameerror &&
                          (props.value3.match(
                            /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                          ) === null ||
                            props.value3.match(/^[0-9]+$/) !== null ||
                            props.value3.length > 50)
                        ? true
                        : false
                    }
                    helperText={
                      errorf && !props.value3
                        ? "Required Entity Name."
                        : validEntityNameerror &&
                          (props.value3.match(
                            /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                          ) === null ||
                            props.value3.match(/^[0-9]+$/) !== null ||
                            props.value3.length > 50)
                        ? "Enter valid Entity Name."
                        : ""
                    }
                    id="outlined-basic"
                    label={props.label3}
                    variant="outlined"
                    className={classes.TextField_Style}
                    value={props.value3}
                    onChange={props.onChange3}
                  />
                </Grid>
              </>
            ) : null}
            {props.value4 !== undefined ? (
              <>
                <Grid item md={props.md_label4 || 6} xs={12}>
                  <FormControl
                    fullWidth
                    error={errorf && !props.value4 ? true : false}
                  >
                    <InputLabel id="demo-simple-select-label">
                      {props.label4} *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={props.value4}
                      onChange={props.onChange4}
                      label="Entity Type *"
                    >
                      <MenuItem value={"Private"}>Private</MenuItem>
                      <MenuItem value={"Public"}>Public</MenuItem>
                    </Select>
                    <FormHelperText>
                      {errorf && !props.value4 ? "Required Entity Type." : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </>
            ) : null}

            {props.value5 !== undefined ? (
              <>
                <Grid item md={props.md_label5 || 6} xs={12}>
                  <TextField
                    required
                    error={errorf && !props.value5 ? true : false}
                    helperText={
                      errorf && !props.value5 ? "Required Employee Size." : ""
                    }
                    id="outlined-basic"
                    label={props.label5}
                    variant="outlined"
                    className={classes.TextField_Style}
                    value={props.value5}
                    onChange={props.onChange5}
                  />
                </Grid>
              </>
            ) : null}
            {props.value6 !== undefined ? (
              <>
                <Grid item md={props.md_label6 || 6} xs={12}>
                  <TextField
                    required
                    error={errorf && !props.value6 ? true : false}
                    helperText={
                      errorf && !props.value6 ? "Required Address." : ""
                    }
                    id="outlined-basic"
                    label={props.label6}
                    variant="outlined"
                    className={classes.TextField_Style}
                    value={props.value6}
                    onChange={props.onChange6}
                  />
                </Grid>
              </>
            ) : null}
            {props.value7 !== undefined ? (
              <>
                <Grid item md={props.md_label7 || 6} xs={12}>
                  <TextField
                    required
                    error={errorf && !props.value7 ? true : false}
                    helperText={
                      errorf && !props.value7 ? "Required Billing Address." : ""
                    }
                    id="outlined-basic"
                    label={props.label7}
                    variant="outlined"
                    className={classes.TextField_Style}
                    value={props.value7}
                    onChange={props.onChange7}
                  />
                </Grid>
              </>
            ) : null}
            {props.value8 !== undefined ? (
              <>
                <Grid item md={props.md_label8 || 6} xs={12}>
                  <TextField
                    error={
                      errorf && !props.value8
                        ? true
                        : validGstnoerror &&
                          (props.value8.match(
                            /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                          ) === null ||
                            props.value8.match(/^[0-9]+$/) !== null ||
                            props.value8.length < 15 ||
                            props.value8.length > 15)
                        ? true
                        : false
                    }
                    helperText={
                      errorf && !props.value8
                        ? "Required GST No."
                        : validGstnoerror &&
                          (props.value8.match(
                            /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                          ) === null ||
                            props.value8.match(/^[0-9]+$/) !== null ||
                            props.value8.length < 15 ||
                            props.value8.length > 15)
                        ? "Enter valid GST no."
                        : ""
                    }
                    id="outlined-basic"
                    label={props.label8}
                    variant="outlined"
                    className={classes.TextField_Style}
                    value={props.value8}
                    onChange={props.onChange8}
                  />
                </Grid>
              </>
            ) : null}
            {props.value9 !== undefined ? (
              <>
                <Grid item md={props.md_label9 || 6} xs={12}>
                  <TextField
                    required
                    error={
                      errorf && !props.value9
                        ? true
                        : validpannoerror &&
                          (props.value9.match(
                            /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                          ) === null ||
                            props.value9.match(/^[0-9]+$/) !== null ||
                            props.value9.length > 10 ||
                            props.value9.length < 10)
                        ? true
                        : false
                    }
                    helperText={
                      errorf && !props.value9
                        ? "Required PAN No."
                        : validpannoerror &&
                          (props.value9.match(
                            /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                          ) === null ||
                            props.value9.match(/^[0-9]+$/) !== null ||
                            props.value9.length > 10 ||
                            props.value9.length < 10)
                        ? "Enter valid PAN no."
                        : ""
                    }
                    id="outlined-basic"
                    label={props.label9}
                    variant="outlined"
                    className={classes.TextField_Style}
                    value={props.value9}
                    onChange={props.onChange9}
                  />
                </Grid>
              </>
            ) : null}

            {props.value11 !== undefined ? (
              <>
                <Grid item md={props.md_label11 || 6} xs={12}>
                  <TextField
                    required
                    error={
                      errorf && !props.value11
                        ? true
                        : validEmailerror &&
                          props.value11.match(
                            /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/
                          ) === null
                        ? true
                        : false
                    }
                    helperText={
                      errorf && !props.value11
                        ? "Required Email."
                        : validEmailerror &&
                          props.value11.match(
                            /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/
                          ) === null
                        ? "Enter Valid Email."
                        : ""
                    }
                    id="outlined-basic"
                    label={props.label11}
                    variant="outlined"
                    className={classes.TextField_Style}
                    value={props.value11}
                    onChange={props.onChange11}
                  />
                </Grid>
              </>
            ) : null}
            {props.value12 !== undefined ? (
              <>
                <Grid item md={props.md_label12 || 6} xs={12}>
                  {/* <TextField
                    id="outlined-basic"
                    label={props.label12}
                    variant="outlined"
                    className={classes.TextField_Style}
                    value={props.value12}
                    onChange={props.onChange12}
                  /> */}
                  <MobileNumber
                    required
                    phone={props.value12}
                    updateMobileNumber={updateChroMobileNumber}
                    isValidMobile={isValidMobile}
                    errorf={errorf}
                    mobile_numberC={props.value12}
                    employee
                  />
                  {!props.value12 && errorf ? (
                    <span
                      style={{
                        color: "#F44336",
                        fontSize: "12px",
                        position: "relative",
                        left: "15px",
                      }}
                    >
                      Required Mobile.
                    </span>
                  ) : (
                    !isValidMobile && (
                      <span
                        style={{
                          color: "#F44336",
                          fontSize: "12px",
                          position: "relative",
                          left: "15px",
                        }}
                      >
                        Enter valid Mobile
                      </span>
                    )
                  )}
                </Grid>
              </>
            ) : null}
            {props.value13 !== undefined ? (
              <>
                <Grid item md={props.md_label13 || 6} xs={12}>
                  <FormControl
                    fullWidth
                    error={errorf && !props.value13 ? true : false}
                  >
                    <InputLabel id="demo-simple-select-label">
                      {props.label13} *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={props.value13}
                      onChange={props.onChange13}
                      label="Engagement Type *"
                    >
                      <MenuItem value={"Onetime"}>Onetime</MenuItem>
                      <MenuItem value={"Ongoing"}>Ongoing</MenuItem>
                    </Select>
                    <FormHelperText>
                      {errorf && !props.value13
                        ? "Required Engagement Type."
                        : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </>
            ) : null}
            {props.onChange14 !== undefined ? (
              <>
                <Grid item md={props.md_onChange14 || 6} xs={12}>
                  <TextField
                    type="file"
                    inputProps={{
                      accept:
                        "image/*, .xlsx, .xls, .csv, .pdf, .pptx, .pptm, .ppt",
                    }}
                    id="outlined-basic"
                    label={props.label14}
                    variant="outlined"
                    className={classes.TextField_Style}
                    value={props.value14}
                    onChange={props.onChange14}
                  />
                </Grid>
              </>
            ) : null}

            <Grid container spacing={3} style={{}}>
              <Grid
                item
                md={props.md_label19 || 12}
                xs={12}
                style={{
                  display: "flex",
                  gap: "15px",
                  justifyContent: "center",
                }}
              >
                <Button variant="contained" onClick={() => handlenext1()}>
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      )}
      {step === 1 && (
        <Card className={classes.cardStyle}>
          <div style={{ paddingTop: "15px" }}>
            <Grid
              container
              spacing={3}
              // flexDirection={"column"} display="flex" alignItems="center"
            >
              {props.valueIndustry !== undefined ? (
                <>
                  <Grid item md={props.md_labelIndustry || 6} xs={12}>
                    <FormControl
                      fullWidth
                      error={errorf && !props.valueIndustry ? true : false}
                    >
                      <InputLabel id="demo-simple-select-label">
                        {props.labelIndustry} *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={props.valueIndustry}
                        onChange={props.onChangeIndustry}
                        label="Industy Name *"
                      >
                        {indusrtyData.map((item, index) => {
                          return (
                            <MenuItem value={item.title}>{item.title}</MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>
                        {errorf && !props.valueIndustry
                          ? "Required Industy Name."
                          : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </>
              ) : null}
              {props.valuesubIndustry !== undefined ? (
                <>
                  <Grid item md={props.md_labelsubIndustry || 6} xs={12}>
                    <TextField
                      required
                      error={errorf && !props.valuesubIndustry ? true : false}
                      helperText={
                        errorf && !props.valuesubIndustry
                          ? "Required sub Industy Name."
                          : ""
                      }
                      id="outlined-basic"
                      label={props.labelsubIndustry}
                      variant="outlined"
                      className={classes.TextField_Style}
                      value={props.valuesubIndustry}
                      onChange={props.onChangesubIndustry}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>

            {sopformFields.map((item, index) => {
              console.log("SPOCNAME::", item);
              console.log("index::", index);
              console.log("errorf", errorf);

              return (
                <>
                  <Grid key={index} container spacing={3}>
                    <Grid item md={props.md_label19 || 1} xs={12}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography>{index + 1}</Typography>
                      </div>
                    </Grid>

                    <Grid item md={props.md_label20 || 5} xs={12}>
                      <TextField
                        required
                        error={item.spoc_name.length === 0}
                        helperText={
                          item.spoc_name.length === 0
                            ? "Required SPOC Designation"
                            : ""
                        }
                        id="outlined-basic"
                        label={"SPOC Name"}
                        variant="outlined"
                        className={classes.TextField_Style}
                        onChange={(e) => {
                          onChangeSpocName(e.target.value, index);
                        }}
                        // onChange={
                        //  props.onChangesopformFields
                        // }

                        value={item.spoc_name}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item md={props.md_label21 || 5} xs={12}>
                      <TextField
                        required
                        error={item.spoc_designation.length === 0}
                        helperText={
                          item.spoc_designation.length === 0
                            ? "Required SPOC Designation"
                            : ""
                        }
                        id="outlined-basic"
                        label={"SPOC Designation"}
                        variant="outlined"
                        className={classes.TextField_Style}
                        onChange={(e) => {
                          onChangeSpocDesig(e.target.value, index);
                        }}
                        value={item.spoc_designation}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item md={props.md_label19 || 1} xs={12}></Grid>
                  </Grid>

                  <Grid
                    key={index}
                    container
                    spacing={3}
                    style={{ marginBottom: "20px", marginTop: "1%" }}
                  >
                    <Grid item md={props.md_label19 || 1} xs={12}></Grid>

                    <Grid item md={props.md_label22 || 5} xs={12}>
                      <TextField
                        required
                        error={
                          item.spoc_email.length === 0 ||
                          item.spoc_email.match(
                            /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/
                          ) === null
                        }
                        helperText={
                          item.spoc_email.length === 0
                            ? "Required SPOC Email."
                            : item.spoc_email.match(
                                /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/
                              ) === null
                            ? "Enter valid email."
                            : ""
                        }
                        id="outlined-basic"
                        label={"SPOC Email"}
                        variant="outlined"
                        className={classes.TextField_Style}
                        onChange={(e) => {
                          onChangeSpocEmail(e.target.value, index);
                        }}
                        value={item.spoc_email}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="email"
                      />
                    </Grid>

                    {/* <Grid item md={props.md_label23 || 5} xs={12}>
                      <TextField
                        id="outlined-basic"
                        label={"SPOC Mobile Number"}
                        variant="outlined"
                        className={classes.TextField_Style}
                        onChange={(e) => {
                          onChangeSpocMobileNo(e.target.value, index);
                        }}
                        value={item.spoc_mobile_number}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid> */}
                    <Grid item md={props.md_label23 || 5} xs={12}>
                      {/* <TextField
                        required
                        error={!item.smobilenumber}
                        helperText={
                          !item.smobilenumber
                            ? "Required SPOC Mobile Number."
                            : ""
                        }
                        id="outlined-basic"
                        label={"SPOC Mobile Number"}
                        variant="outlined"
                        className={classes.TextField_Style}
                        onChange={(e) => {
                          onChangeSpocMobileNo(e.target.value, index);
                        }}

                      /> */}
                      {/* 
                      <MobileNumber
                        key={index}
                        phone={item.spoc_mobile_number}
                        updateMobileNumber={updateMobileNumber}
                        index={index}
                      /> */}
                      <MobileNumber
                        required
                        updateMobileNumber={updateMobileNumber}
                        index={index}
                        isValidMobile={isValidMobile}
                        numbers={numbers}
                        errorf={errorf}
                        sopformFields={sopformFields}
                        phone={item.spoc_mobile_number}
                        // mobile_numberC={props.mobile_numberC}
                        employee
                      />
                      {sopformFields &&
                      errorf &&
                      (sopformFields[index].spoc_mobile_number === "" ||
                        item.spoc_mobile_number === "") ? (
                        <span
                          style={{
                            color: "#F44336",
                            fontSize: "12px",
                            position: "relative",
                            left: "15px",
                          }}
                        >
                          Required Mobile.
                        </span>
                      ) : (
                        numbers.includes(index) && (
                          //  !isValidMobile &&
                          <span
                            style={{
                              color: "#F44336",
                              fontSize: "12px",
                              position: "relative",
                              left: "15px",
                            }}
                          >
                            Enter valid Mobile.
                          </span>
                        )
                      )}
                      {/* <>
      <ReactPhoneInput
        // specialLabel={`Phone number ${required ? "*" : ""}`}
        defaultCountry='in'
        country='in'
        enableSearchField={true}
        disableAreaCodes={true}
        countryCodeEditable={false}
        // value={phone}
        value={item.spoc_mobile_number}
        onChange={(phone, data,e) => {
          if (phone) {
            let phoneNumber = '+' + phone.replace(/[^0-9]+/g, '');
            const dialCode = '+' + data.dialCode;
            updateMobileNumber(phoneNumber, isValidPhoneNumber(phoneNumber), dialCode);
            onChangeSpocMobileNo(e.target.value, index);          }
        }}
      />
    </> */}
                    </Grid>

                    <Grid item md={props.md_label19 || 1} xs={12}>
                      {index !== 0 && (
                        <Box
                          sx={{
                            marginTop: "10px",
                          }}
                          display="flex"
                          alignItems={"center"}
                          justifyContent={"space-between"}
                        >
                          <img
                            src={deleteIcon}
                            onClick={() => removeSopFields(index)}
                            alt="add"
                          />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </>
              );
            })}

            <Grid item md={props.md_label19 || 1} xs={12}></Grid>
            <Grid item md={props.md_label20 || 10} xs={12} className="111">
              <img src={addPlusIcon} onClick={addSopFields} alt="add" />
            </Grid>
            <Grid item md={props.md_label19 || 1} xs={12}></Grid>
            <Grid container spacing={3} style={{}}>
              <Grid
                item
                md={props.md_label19 || 12}
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "15px",
                  alignItems: "center",
                }}
              >
                <Button variant="outlined" onClick={() => setStep(0)}>
                  Previous
                </Button>
                <Button
                  variant="contained"
                  className={classes.btnStyle}
                  // color="primary"
                  // onClick={() => {
                  //   onCreateClick();
                  // }}
                  onClick={() => handlenext2()}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            {/* </Grid> */}
          </div>
        </Card>
      )}
    </Box>
  );
};

export default UpdateClient;
