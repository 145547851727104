import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import "./SearchBarRole.css";

const SearchBarRole = ({ search, setSearch }) => {
  const clearInput = () => {
    setSearch("");
  };

  return (
    // <div>
    <div className="ContainerRole">
      <div className="form-GroupRole">
        <div>
          <button className="Btn" type="submit">
            <SearchIcon className="search-Icon" size="large" />
          </button>
        </div>
        <div className="SearchRole">
          <input
            type="text"
            className="Search-inputRole"
            placeholder="search "
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {search != 0 && (
          <div className="ClearBtn" onClick={clearInput}>
            <ClearIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBarRole;
