import React from "react";
import {
  Grid,
  TextField,
  FormControl,
  MenuItem,
  FormHelperText,
  InputLabel,
  Select,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@mui/material";
import axios from "axios";
import API from "../../../../../E2E/Axios.Utils";

const UpdateTeamForm = ({
  currentUserDetails,
  // handleDialog,
  // update,
  // setUpdate,
  setAlert,
  setAlertType,
  setAlertContent,
  employeeIdData,
  handleform,
  submitRef,
  setOrgIdData,
  setToggleEditTeam,
}) => {
  const [teamId, setTeamId] = React.useState(currentUserDetails.team_id);
  const [oragnisationData, setoragnisationData] = React.useState([]);
  const [role, setRole] = React.useState("");

  // console.log("currentUserDetails.team_id", currentUserDetails.team_id);

  const initialValues = {
    team_name: currentUserDetails.team_name,
    org_id: currentUserDetails.org_id,
    // employee_ids: "",
    remember: false,
  };

  const token = localStorage.getItem("token").toString();

  const onSubmit = (values, props) => {
    console.log(values);

    const data = {
      team_name: values.team_name,
      org_id: values.org_id,
      employee_ids: employeeIdData,
      remember: false,
    };

    API.put(`/app/secure/team_mapping?team_id=${teamId}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        console.log("res.data.msg", res);
        setAlertContent(res.data.message);
        setAlertType("success");
        setAlert(true);
        setToggleEditTeam(false);
      })
      .catch((err) => {
        // console.log(err.response.data.message);
        if (err?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
        } else {
          setAlertContent(err.data.message);
          setAlertType("error");
          setAlert(true);
        }
      });
    props.resetForm();
  };

  console.log("handleform", handleform);

  React.useEffect(() => {
    if (handleform) onSubmit();
  }, [handleform]);

  const AlphaNumeric = /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/;

  const validationSchema = Yup.object().shape({
    team_name: Yup.string()
      .matches(AlphaNumeric, "Please enter valid tab name.")
      .max(40, "Tab name must be at most 40 characters.")
      .required("This field is required."),
    org_id: Yup.string().required("This field is required."),
  });
  const getOrganisationData = () => {
    API.get("/app/secure/list_customer?page=1&max_rows=1000", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setoragnisationData(res.data.customer_list);
      })
      .catch((err) => {});
  };
  React.useEffect(() => {
    getOrganisationData();
  }, []);

  React.useEffect(() => {
    var role = localStorage.getItem("role");
    setRole(role);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(props) => (
        <Form noValidate>
          <Grid container xs={12} sx={{ gap: "3.5em" }}>
            <Grid item xs={5.65}>
              <Field
                as={TextField}
                label="Team Name"
                name="team_name"
                fullWidth
                error={props.errors.team_name && props.touched.team_name}
                helperText={<ErrorMessage name="team_name" />}
                required
                sx={{ marginBottom: "2.5%" }}
              />
            </Grid>

            {role === "Super Admin" ? (
              <Grid item xs={5.65}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={Boolean(
                    props &&
                      props.touched &&
                      props.touched.org_id &&
                      props.errors &&
                      props.errors.org_id
                  )}
                >
                  <InputLabel id="select" required>
                    Select Organisation
                  </InputLabel>
                  <Field
                    as={Select}
                    name="org_id"
                    id="select"
                    label="select Organisation"
                    variant="outlined"
                    error={Boolean(
                      props &&
                        props.touched &&
                        props.touched.org_id &&
                        props.errors &&
                        props.errors.org_id
                    )}
                    required
                  >
                    {oragnisationData.map((name) => {
                      return (
                        <MenuItem
                          key={name.org_id}
                          value={name.org_id}
                          onClick={() => setOrgIdData(name.org_id)}
                        >
                          {name.client_entity_name}
                        </MenuItem>
                      );
                    })}
                  </Field>
                  <FormHelperText sx={{ color: "red" }}>
                    {props &&
                      props.touched &&
                      props.touched.org_id &&
                      props.errors &&
                      props.errors.org_id}
                  </FormHelperText>
                </FormControl>
              </Grid>
            ) : null}
          </Grid>

          <Button
            type="submit"
            ref={submitRef}
            variant="contained"
            sx={{ display: "none" }}
          >
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateTeamForm;
