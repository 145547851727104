import React, { useState } from "react";
import CustomerSurvey from "./CustomerSurvey";
import ScheduleSurvey from "../client/tabsContent/tabTeam/ScheduleSurvey";
import ScheduleSurveyStyle from "./ScheduleSurveyStyle";
import SurveyQuestion from "../survey/SurveyQuestion/SurveyQuestion";
import Permission from "../../component/Permission/Permission";

const CustomerSurvay = (props) => {
  const [toggleEditTeam, setToggleEditTeam] = React.useState(false);
  const [toggleTeam, setToggleTeam] = React.useState(true);
  const [csurveyId, setCSurveyId] = React.useState("");

  return (
    <Permission menu="Surveys">
      <>
        {toggleTeam ? (
          toggleEditTeam ? (
            <SurveyQuestion
              setToggleEditTeam={setToggleEditTeam}
              csurveyId={csurveyId}
            />
          ) : (
            //   <UpdateTeam setToggleEditTeam={setToggleEditTeam} />

            <CustomerSurvey
              setToggleTeam={setToggleTeam}
              setToggleEditTeam={setToggleEditTeam}
              setCSurveyId={setCSurveyId}
            />
          )
        ) : (
          <ScheduleSurveyStyle setToggleTeam={setToggleTeam} />
        )}
      </>
    </Permission>
  );
};

export default CustomerSurvay;
