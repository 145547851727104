import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    padding: "24px",
    // marginTop: '80px',
    backgroundColor: "#EEEEEE",
  },
  body: {
    width: "100%",
    "& .MuiAppBar-colorDefault": {
      backgroundColor: "transparent",
      boxShadow: "none",
      borderBottom: "2px solid #555555",
    },
    "& .PrivateTabIndicator-colorPrimary-6": {
      backgroundColor: "#2D404E",
    },
    "& .MuiTab-root": {
      minWidth: "auto",
    },
    "& .MuiBox-root": {
      padding: "24px 0",
    },
    "& .MuiButtonBase-root": {
      "& .MuiTab-wrapper": {
        fontFamily: "'Inter'",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#2D404E",
        textTransform: "capitalize",
      },
    },
  },

  // Tabs

  progressBar: {
    "&::after": {
      display: "block",
      // "width": "50%",
      content: "''",
      background: "#128CB0",
      height: "8px",
      position: "relative",
      top: "-8px",
    },
  },

  circelPer: {
    backgroundColor: "#BEDFFA",
    width: "83px",
    height: "83px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "'Montserrat'",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#000000",

    // '&::before': {
    //   display: 'block',
    //   "content": "''", "background": "red", "height": "8px",
    //   "position": "relative", "top": "-8px"
    // },
  },

  cardStyle: {
    borderRadius: "0 !important",
    boxShadow: "none !important",
    borderBottom: "8px solid #D9D9D9",
    cursor: "pointer",

    "& header": {
      display: "flex",
      justifyContent: "space-between",
      "& .cardTitle": {
        fontFamily: "'Montserrat'",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "17px",
        color: "#000000",
        marginBottom: "6px",
      },
      "& .detail": {
        fontFamily: "'Montserrat'",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "12px",
        lineHeight: "15px",
        color: "#000000",
        marginBottom: "6px",
      },
      "& .percentage": {
        fontFamily: "'Montserrat'",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "20px",
        color: "#000000",
      },
    },
    "& .chart": {
      display: "flex",
      gap: "12px",
      alignItems: "center",
      "& .items": {
        "& .item": {
          display: "flex",
          gap: "10px",
          marginBottom: 5,

          "& .number": {
            fontFamily: "'Montserrat'",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "12px",
            lineHeight: "15px",
            textAlign: "right",
            color: "#000000",
            marginBottom: 0,
          },
          "& .label": {
            marginBottom: 0,
            fontFamily: "'Montserrat'",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "15px",
            color: "#000000",
          },
        },
      },

      // Circular Progressbar
      "& .CircularProgressbar": {
        background: "#BEDFFA",
        // height: "72px",
        width: "83px",
        borderRadius: "50%",
        "& .CircularProgressbar-path": {
          stroke: "#2D404E",
        },
        "& .CircularProgressbar-trail": {
          stroke: "#BEDFFA",
        },
        "& .CircularProgressbar-text": {
          fill: "#000000",
          fontFamily: "'Montserrat'",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "15px",
        },
      },
    },
  },

  serachBar: {
    backgroundColor: "#FCFCFC",
    height: "52px",
    width: "100%",
    border: "none",
    padding: "0 15px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
    "& input": {
      backgroundColor: "#FCFCFC",
      height: "52px",
      width: "94%",
      border: "none",
      "& :focus": {
        border: "none",
      },
    },
  },
  profile_logoProfile: {
    "& .profileTitle": {
      fontSize: "24px",
      marginBottom: "15px",
    },
    "& .profileSubTitle": {
      fontFamily: "'Montserrat'",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "15px",
      color: "#1D1D1D",
    },
  },

  profiles: {
    display: "flex",
    justifyContent: "space-between",
    "& .contactPersion": {
      fontFamily: "'Montserrat'",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
    },
  },

  profile_userProfile: {
    "& .profileTitle": {
      fontSize: "12px",
      marginBottom: "3px",
    },
    "& .profileSubTitle": {
      fontFamily: "'Montserrat'",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
    },
  },

  userProfileDropdown: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
  },
});

export { useStyles };
