import { Paper, Typography } from "@mui/material";

// ----------------------------------------------------------------------

export default function NoResultsFound() {
  return (
    <Paper>
      <Typography gutterBottom align="center" variant="subtitle1">
        No Results found
      </Typography>
    </Paper>
  );
}
