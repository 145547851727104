import * as React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, ListItemIcon, ListItemText } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchNotFound from "../../../Utils/SearchNotFound/SearchNotFount";
// import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { Avatar, Tooltip, Typography } from "@mui/material";
import CustomModel from "../../../Utils/CustomModel/CustomModel";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateRoleCard from "./UpdateRoleCard";
import { Notification } from "../../../Utils/SnackbarNotification/Notification";
import { useNavigate } from "react-router";
import API from "../../../E2E/Axios.Utils";

const headCells = [
  {
    id: "Role Name",
    numeric: false,
    disablePadding: true,
    label: "ROLE NAME",
  },
  {
    id: "Created On",
    numeric: true,
    disablePadding: false,
    label: "CREATED DATE",
  },
  {
    id: "Users Assigned",
    numeric: true,
    disablePadding: false,
    label: "USERS ASSIGNED",
  },
  {
    id: "Permissions",
    numeric: true,
    disablePadding: false,
    label: "PERMISSIONS",
  },
  {
    id: "Actions",
    numeric: true,
    disablePadding: false,
    label: "ACTIONS",
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sx={{
              bgcolor: "#128CB0",
              color: "white",
              fontFamily: "Montserrat",
              fontWeight: 600,
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  search,
  update,
  setUpdate,
  setAlert,
  setAlertType,
  setAlertContent,
}) {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [usersData, setUsersData] = React.useState([]);
  const [usersSearch, setUsersSearch] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleAlert = () => {
    setAlert(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const token = localStorage.getItem("token").toString();
  const getUsersSearch = () => {
    API.get(`/app/secure/search_role?query=${search}&page=1`, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setUsersSearch(res.data.roles);
    });
  };

  const getUsersData = () => {
    setLoading(true);
    API.get("/app/secure/all_role_list", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        // console.log("res", res);
        setUsersData(res.data.roles);
        // setAlertContent(res.data.message);
        // setAlertType("success");
        // setAlert(true);
        setLoading(false);
      })
      .catch((err) => {
        // console.log("err", err);
        if (err?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          setLoading(false);
          navigate("/signin");
        } else {
          setAlertContent(err.data.message);
          setAlertType("error");
          setAlert(true);
          setLoading(false);
        }
      });
  };

  React.useEffect(() => {
    getUsersData();
  }, [update]);

  React.useEffect(() => {
    if (search) getUsersSearch();
  }, [search]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersData.length) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ mb: 5 }}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="Companies Table"
            sx={{ minWidth: 750 }}
          >
            <EnhancedTableHead />

            {(search &&
              (usersSearch?.length > 0 ? (
                <TableBody>
                  {usersSearch
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user) => {
                      const {
                        role_name,
                        id,
                        created_date,
                        assigned_to,
                        permissions,
                      } = user;

                      return (
                        <TableRow hover tabIndex={-1} key={id}>
                          <TableCell>{role_name}</TableCell>
                          <TableCell>
                            {moment(created_date).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell>
                            <AssociatedUsers users={assigned_to} />
                          </TableCell>
                          <TableCell>{permissions.join(", ")}</TableCell>
                          <TableCell>
                            <RoleMoreMenu currentDetails={id} details={user} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <SearchNotFound />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))) || (
              <TableBody>
                {usersData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => {
                    const {
                      role_name,
                      id,
                      created_date,
                      assigned_to,
                      permissions,
                    } = user;

                    return (
                      <TableRow hover tabIndex={-1} key={id}>
                        <TableCell>{role_name}</TableCell>
                        <TableCell>
                          {moment(created_date).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell>
                          <AssociatedUsers users={assigned_to} />
                        </TableCell>

                        <TableCell>{permissions.join(", ")}</TableCell>
                        <TableCell>
                          <RoleMoreMenu
                            currentDetails={id}
                            details={user}
                            setAlert={setAlert}
                            setAlertType={setAlertType}
                            setAlertContent={setAlertContent}
                            update={update}
                            setUpdate={setUpdate}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[4, 8, 12]}
          component="div"
          count={
            (search && (usersSearch ? usersSearch?.length : 0)) ||
            (usersData ? usersData.length : 0)
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </Box>
  );
}
// ***************************************************
function AssociatedUsers({ users }) {
  const renderAvatar = (user) => {
    const initials = user
      .split(" ")
      .map((item, index) => (index < 2 ? item.substr(0, 1) : ""));
    return (
      <Avatar
        // className="text-transform-capitalize small assign-user-avatar"
        sx={{ bgcolor: "#2D404E", width: 30, height: 30 }}
      >
        {initials.join("")}
      </Avatar>
    );
  };
  const renderTooltip = () => (
    <>
      {users.map((user) => (
        <Typography variant="body1">{user}</Typography>
      ))}
    </>
  );
  return (
    <>
      {users.length <= 3
        ? users.map((user) => renderAvatar(user))
        : users.length > 3 && (
            <div style={{ display: "flex" }}>
              {renderAvatar(users[0])}
              {renderAvatar(users[1])}
              <Tooltip title={renderTooltip()}>
                <Typography
                  variant="body1"
                  className="user-count"
                  sx={{ display: "flex", alignItems: "center", ml: 0.3 }}
                >{`+${users.length - 2}`}</Typography>
              </Tooltip>
            </div>
          )}
    </>
  );
}

// ***************************************************
function RoleMoreMenu({
  currentDetails,
  details,
  setAlert,
  setAlertType,
  setAlertContent,
  update,
  setUpdate,
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [openEdit, setEditOpen] = React.useState(false);
  const [roleId, setRoleId] = React.useState(details.id);

  const handleDialog = () => {
    setEditOpen(!openEdit);
  };

  const token = localStorage.getItem("token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const handleRoleDelete = () => {
    API.delete(
      `/app/secure/deactivate_role?id=${roleId}`,

      config
    )
      .then((res) => {
        // console.log("res-", res);
        setUpdate(update + 1);
        setAlertContent(`Record Deleted Successfully.`);
        setAlertType("success");
        setAlert(true);
      })

      .catch((err) => {
        // console.log(err);
        setAlertContent(`Record Delete Failed.`);
        setAlertType("error");
        setAlert(true);
      });
  };

  const ref = React.useRef(openEdit);

  return (
    <>
      <CustomModel handleDialog={handleDialog} open={openEdit}>
        <UpdateRoleCard
          roleName={details.role_name}
          adminRole={details.is_admin_role}
          roleId={currentDetails}
          handleDialog={handleDialog}
          setAlert={setAlert}
          setAlertType={setAlertType}
          setAlertContent={setAlertContent}
          update={update}
          setUpdate={setUpdate}
        />
      </CustomModel>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertRoundedIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          to="#"
          sx={{ color: "text.secondary" }}
          onClick={() => {
            handleDialog();
            setIsOpen(false);
          }}
        >
          <ListItemIcon>
            <EditIcon width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        {/* <MenuItem
          sx={{ color: "text.secondary" }}
          onClick={() => {
            handleRoleDelete(currentDetails);
            setIsOpen(false);
          }}
        >
          <ListItemIcon>
            <DeleteIcon width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem> */}
      </Menu>
    </>
  );
}
