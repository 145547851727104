import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  Container,
  Divider,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import moment from "moment";
import CustomModel from "../../Utils/CustomModel/CustomModel";
import EditPersonalProfile from "./Components/EditPersonalProfile ";
import { LoadingButton } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import EditCompanyProfile from "./Components/EditCompanyProfile";
import { Notification } from "../../Utils/SnackbarNotification/Notification";
import ChangePassword from "./Components/ChangePassword";
import { useNavigate } from "react-router";
import { useStyles } from "../question/Question.style";
import Header from "../../component/header/Header";
import LeftDrawer from "../../component/leftDrawer/LeftDrawer";
import API from "../../E2E/Axios.Utils";

const Profile = () => {
  const navigate = useNavigate();
  const [personalProfile, setPersonalProfile] = useState([]);
  const [companyProfile, setCompanyProfile] = useState([]);

  const [open, setOpen] = React.useState(false);
  const [isopen, setisOpen] = React.useState(false);
  const [openPswd, setOpenPswd] = React.useState(false);
  const [update, setUpdate] = React.useState(0);
  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [edit, setEdit] = useState(false);
  const [opendrawer, setOpenDrawer] = React.useState(true);

  const classes = useStyles();

  const ref = React.useRef(open);

  const handleDialog = () => {
    setOpen(!open);
  };
  const handleDialogCompany = () => {
    setisOpen(!isopen);
  };
  const handleDialogPassword = () => {
    setOpenPswd(!openPswd);
  };

  const handleAlert = () => {
    setAlert(false);
  };
  const token = localStorage.getItem("token").toString();

  const role = localStorage.getItem("role");

  const getProfileInfo = () => {
    API.get(`/app/secure/get_profile`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        // console.log("setProflePersonal", res.data.personal_info);
        setPersonalProfile(res.data.personal_info);
        setCompanyProfile(res.data.company_info);
        // setAlertContent(res.data.message);
        // setAlertType("success");
        // setAlert(true);
      })
      .catch((err) => {
        // console.log(err);
        if (err.response.status == 401) {
          // setAlertContent("Token is invalid or expired.");
          // setAlertType("error");
          // setAlert(true);
          navigate("/signin");
        } else {
          // setAlertContent(`Error : Something went wrong`);
          // setAlertType("error");
          // setAlert(true);
        }
      });
  };

  useEffect(() => {
    getProfileInfo();
  }, [update]);

  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <LeftDrawer open={opendrawer} />
        <Container
          style={{
            background: "#EEEEEE",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 4.6,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Montserrat",
                fontSize: "18px",
              }}
            >
              Profile
            </Typography>
          </Box>
          <Paper
            sx={{
              height: "150px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              xs={4}
            >
              <Grid item xs={3}>
                <AccountCircleIcon color="primary" sx={{ fontSize: 70 }} />
              </Grid>
              <Grid item xs={7}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: "bold", fontSize: "24px" }}>
                      <span>{personalProfile.first_name}&nbsp;</span>
                      <span>{personalProfile.last_name}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ fontWeight: 550 }}>
                      {personalProfile.role}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      {moment(personalProfile.join_date).format("DD MMM YYYY")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
              xs={5.5}
            >
              <Grid item>
                {/* <Typography>To be Discussed</Typography>
              <Typography>XX</Typography> */}
              </Grid>
              <Grid item>
                {/* <Typography>To be Discussed</Typography>
              <Typography>XX</Typography> */}
              </Grid>
              <Grid item>
                {/* <Typography>To be Discussed</Typography>
              <Typography>XX</Typography> */}
              </Grid>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Grid
              container
              sx={{ display: "flex", alignItems: "center" }}
              xs={2.5}
            >
              <Grid item>
                <VpnKeyIcon color="primary" sx={{ fontSize: 25, ml: 2.5 }} />
              </Grid>
              <Grid item>
                <Typography>
                  <>
                    <CustomModel
                      handleDialog={handleDialogPassword}
                      open={openPswd}
                    >
                      <ChangePassword
                        currentUserDetails={personalProfile}
                        handleDialog={handleDialogPassword}
                        setUpdate={setUpdate}
                        update={update}
                        setAlert={setAlert}
                        setAlertType={setAlertType}
                        setAlertContent={setAlertContent}
                      />
                    </CustomModel>
                    <LoadingButton ref={ref} onClick={() => setOpenPswd(true)}>
                      Change Password
                    </LoadingButton>
                  </>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 4.6,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Montserrat",
                fontSize: "18px",
              }}
            >
              Personal Information
            </Typography>
            <>
              <CustomModel
                handleDialog={handleDialog}
                open={open}
                // open={openEdit}
                // title="Update User"
              >
                <EditPersonalProfile
                  currentUserDetails={personalProfile}
                  handleDialog={handleDialog}
                  setUpdate={setUpdate}
                  update={update}
                  setAlert={setAlert}
                  setAlertType={setAlertType}
                  setAlertContent={setAlertContent}
                />
              </CustomModel>
              <LoadingButton ref={ref} onClick={() => setOpen(true)}>
                <EditIcon width={24} height={24} />
              </LoadingButton>
            </>
          </Box>
          <Paper sx={{ height: "150px" }}>
            <Grid container>
              <Grid item xs={3} sx={{ padding: 2 }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                  }}
                >
                  First Name
                </Typography>
                <Typography>{personalProfile.first_name}</Typography>
              </Grid>
              <Grid item xs={3} sx={{ padding: 2 }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                  }}
                >
                  Last Name
                </Typography>
                <Typography>{personalProfile.last_name}</Typography>
              </Grid>
              <Grid item xs={3} sx={{ padding: 2 }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                  }}
                >
                  Email
                </Typography>
                <Typography>{personalProfile.email}</Typography>
              </Grid>
              <Grid item xs={3} sx={{ padding: 2 }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                  }}
                >
                  Phone Number
                </Typography>
                <Typography>{personalProfile.phone}</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sx={{ pl: 2 }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: "bold",
                  }}
                >
                  Role
                </Typography>
                <Typography>{personalProfile.role}</Typography>
              </Grid>
            </Grid>
          </Paper>
          {/* Company Profile */}
          {role !== "Employee" &&
          role !== "Super Admin" &&
          role !== "Client" &&
          role !== "Admin" ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 4.6,
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "18px",
                  }}
                >
                  Company Information
                </Typography>
                <>
                  <CustomModel handleDialog={handleDialogCompany} open={isopen}>
                    <EditCompanyProfile
                      currentUserDetails={companyProfile}
                      handleDialog={handleDialogCompany}
                      setUpdate={setUpdate}
                      update={update}
                      setAlert={setAlert}
                      setAlertType={setAlertType}
                      setAlertContent={setAlertContent}
                    />
                  </CustomModel>
                  <LoadingButton ref={ref} onClick={() => setisOpen(true)}>
                    <EditIcon width={24} height={24} />
                  </LoadingButton>
                </>
              </Box>
              <Paper sx={{ height: "150px" }}>
                <Grid container>
                  <Grid item xs={3} sx={{ padding: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                      }}
                    >
                      Name
                    </Typography>
                    <Typography>{companyProfile.Business_Name}</Typography>
                  </Grid>
                  <Grid item xs={3} sx={{ padding: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                      }}
                    >
                      Email
                    </Typography>
                    <Typography>{companyProfile.Contact_Email_ID}</Typography>
                  </Grid>
                  <Grid item xs={3} sx={{ padding: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                      }}
                    >
                      Phone Number
                    </Typography>
                    <Typography>
                      {companyProfile.Business_Phone_Number}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} sx={{ padding: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                      }}
                    >
                      Registered Number
                    </Typography>
                    <Typography>
                      {companyProfile.Company_Registered_Number}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={3} sx={{ pl: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                      }}
                    >
                      Website
                    </Typography>
                    <Typography>{companyProfile.Company_Website}</Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ pl: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                      }}
                    >
                      Address
                    </Typography>
                    <Typography>
                      {companyProfile.Business_Address_First_Line}
                    </Typography>
                  </Grid>

                  <Grid item xs={3} sx={{ pl: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "bold",
                      }}
                    >
                      Postal/Zip code
                    </Typography>
                    <Typography>{companyProfile.Postal_code}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </>
          ) : null}

          <Notification
            message={
              alertContent
                ? alertContent
                : `Success : Data Fetched Successfully.`
            }
            open={alert}
            severity={alertType}
            onClose={handleAlert}
          />
        </Container>
      </div>
    </>
  );
};

export default Profile;
