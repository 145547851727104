import React, { useContext } from "react";
import { ChatContext } from "../context/chatContext";

import Logo from "../../assets/images/logo/ic_Logo.svg";
import Ash from '../assets/ash.png';

import CloseIcon from '@mui/icons-material/Close';

import styles from './Header.module.scss';

import { connect } from "react-redux";
import {closeSurvey} from "../../thunk/Action";
import { bindActionCreators } from 'redux';
import {resetChatData} from "../context/chatActions";

const Header = (props) => {
    const { chatConversationState, chatDispatch } = useContext(ChatContext);

    return (
        <>
            <div className={styles.header}>
                <div className={styles.headerContainer}>
                <div className={styles.chatbotContainer}>
                    <div className={styles.avatar}>
                        <img src={Ash} className={styles.avatarImage}/>
                        <div className={styles.onlineStatus}></div>
                    </div>
                </div>
                <div className={styles.botInfoContainer}>
                    <h4 className={styles.botName}><span className={styles.botNameA}>A</span>sh</h4>
                    <p className={styles.botInfo}>{chatConversationState.chat.loading ? 'Typing' : ''}</p>
                </div>
                </div>
                <div className={styles.closeContainer}>
                    <div className={styles.closeIcon} onClick={() => {
                        resetChatData(chatDispatch);
                        props.closeSurvey()
                    }}>
                        <CloseIcon />
                    </div>
                    <div className={styles.botInfo}>
                        <p className={styles.poweredByText}>Powered by</p><img className={styles.logoImage} src={Logo}/>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return state;
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            closeSurvey,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
