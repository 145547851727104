import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    height: "80px",
    width: '100%',
    "& .css-19z1ozs-MuiPaper-root-MuiAppBar-root": {
      height: "80px",
      display: "flex",
      justifyContent: "center",
      background: "linear-gradient(91.25deg, #394857 -28.42%, #00212A 117.39%)",
    },
    "& .avatar": {
      display: "flex",
      alignItems: "center",
      "& img": {
        cursor: "pointer",
      },
    },
  },
  logo: {},
  searchBox: {
    display: "flex",
    // alignItems: "center",
    marginTop:'7px',
    width: "625px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    "& input": {
      width: "625px",
      // height: "54px",
      background: "#F9F9F9",
      borderRadius: "4px",
    },
  },
  searchButton: {
    marginLeft: "auto",
    width: "50px",
    height: "50px",
    backgroundColor: "#128CB0",
    borderRadius: "4px",
  },
  notificationBox: {
    marginTop:'7px',
    display: "flex",
    alignItems: "center",
    // backgroundColor:'red',
    borderRadius: "4px",
  },
  dropdownBox: {
    borderRadius: "4px",
    backgroundColor: "#FAFEFF",
    padding: "5px 10px",
  },
  titleText: {
    color: "black",
    marginLeft: "1px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: "700",
  },
  contentText: {
    color: "black",
    marginLeft: "1px",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontWeight: "400",
  },

});

export { useStyles };
