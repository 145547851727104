// Constant or static string
  
  // API call method
  export const method = {
    get: 'GET',
    post: 'POST',
    put: 'PUT',
    delete:'DELETE',
  };
  

  