import * as React from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Pagination,
  Grid,
  FormControl,
  Select,
  Button,
} from "@mui/material";
import Popover from "@mui/material/Popover";
import { Arrow } from "../../../component/Images";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { Typography } from "@mui/material";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router";
import API from "../../../E2E/Axios.Utils";
import AvatarComp from "../../../component/UIElements/Avatar";

const headCells = [
  {
    id: "Name",
    numeric: false,
    disablePadding: true,
    label: "NAME",
  },
  {
    id: "STATUS",
    numeric: true,
    disablePadding: false,
    label: "STATUS",
  },
  {
    id: "REASON",
    numeric: true,
    disablePadding: false,
    label: "REASON",
  },

  {
    id: "ACTIONS",
    numeric: true,
    disablePadding: false,
    label: "ACTIONS",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy } = props;

  const handlesort = (val) => {
    props.setSortBy(val);
    props.setSortOrder(val);
    console.log(val);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: "bold", bgcolor: "#128CB0", color: "white" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              {headCell.label}
              {headCell.label !== "" ? (
                <>
                  <Box
                    sx={{ marginLeft: "5px", paddingTop: "3px" }}
                    onClick={() => {
                      handlesort(headCell.label);
                    }}
                  >
                    <AvatarComp image={Arrow} height={15} />
                  </Box>
                </>
              ) : null}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  search,
  update,
  setUpdate,
  setAlert,
  setAlertType,
  setAlertContent,
  setToggleEditTeam,
  setEditUserData,
  setOveralldata,
  setActive,
  setInactive,
}) {
  const navigate = useNavigate();
  const [pages, setPages] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [usersData, setUsersData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(null);
  const [maxrow, setMaxRow] = React.useState(10);
  const [selecteds, setSelecteds] = React.useState("");
  const [totalRecord, setTotalRecord] = React.useState("");
  const [sortby, setSortBy] = React.useState("");
  const [sortorder, setSortOrder] = React.useState(false);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [surveyResponse, setSurveyResponse] = React.useState([]);

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
  };

  const handleAlert = () => {
    setAlert(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPages(1);
  };

  const token = localStorage.getItem("token").toString();

  const getUsersData = () => {
    API.get(
      `/app/secure/reward?page=${pages}&max_rows=${maxrow}&sort_by=${sortby}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        setUsersData(res.data.points_details);
        setPageCount(res?.data?.total_page_count);
        setTotalRecord(res?.data?.total_record_count);
        setSortBy(sortby);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          setAlertContent("Token is invalid or expired.");
          setAlertType("error");
          setAlert(true);
          setLoading(false);
          navigate("/login");
        } else {
          setAlertContent(err.data.message);
          setAlertType("error");
          setAlert(true);
          setLoading(false);
        }
      });
  };

  React.useEffect(() => {
    getUsersData();
  }, [update, pages, maxrow, sortby]);

  const Reorder = (val) => {
    console.log("REORDERVAL::", val);
    setSortOrder(!sortorder);

    if (val === "NAME" && sortorder) {
      setSortBy("-survey_id__name");
    }
    if (val === "STATUS" && sortorder) {
      setSortBy("-status");
    }
    if (val === "REASON" && sortorder) {
      setSortBy("-reason");
    }
  };

  const selectionChangeHandler = (event) => {
    setSelecteds(event.target.value);
  };

  const sortbyFunction = (val) => {
    if (val === "NAME") {
      setSortBy("survey_id__name");
    }
    if (val === "STATUS") {
      setSortBy("status");
    }
    if (val === "REASON") {
      setSortBy("reason");
    }
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const open1 = Boolean(anchorEl1);
  const popId = open1 ? "simple-popover" : undefined;

  const viewResponse = (survey_id, survey_schedule_id) => {
    API.get(
      `/app/secure/view_response?page=1&max_rows=20&survey_id=${survey_id}&survey_schedule_id=${survey_schedule_id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        setSurveyResponse(res.data.response_details);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          setLoading(false);
          navigate("/login");
        } else {
          setAlertContent(err.data.message);
          setAlertType("error");
          setAlert(true);
          setLoading(false);
        }
      });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ mb: 2 }}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="Companies Table"
            sx={{ minWidth: 750 }}
          >
            <EnhancedTableHead
              setSortBy={sortbyFunction}
              setSortOrder={Reorder}
            />

            {
              <TableBody>
                {usersData.map((user, index) => {
                  const {
                    survey_name,
                    reason,
                    survey_id,
                    status,
                    survey_schedule_id,
                  } = user;

                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell align="center">{survey_name}</TableCell>
                      <TableCell align="center">{status}</TableCell>
                      <TableCell align="center">{reason}</TableCell>

                      <TableCell align="center">
                        <div>
                          <Button
                            aria-describedby={popId}
                            variant="contained"
                            onClick={(e) => {
                              viewResponse(survey_id, survey_schedule_id);
                              handleClick1(e);
                            }}
                            sx={{
                              backgroundColor: "transparent",
                              border: "1px solid #2D404E",
                              borderRadius: "20px",
                              width: "200px",
                              height: "36px",
                              color: "black",
                              fontFamily: "Montserrat",
                              fontWeight: 600,
                              "&:hover": {
                                color: "white",
                                border: "none",
                              },
                            }}
                          >
                            View Response
                          </Button>
                          <Popover
                            id={popId}
                            open={open1}
                            anchorEl={anchorEl1}
                            onClose={handleClose1}
                            transformOrigin={{
                              horizontal: "center",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "center",
                              vertical: "bottom",
                            }}
                          >
                            {surveyResponse && surveyResponse?.length > 0 ? (
                              surveyResponse.map((val, index) => {
                                const { title, answer } = val;
                                return (
                                  <MenuItem
                                    key={index}
                                    style={{
                                      display: "flex",
                                      justifyContent: "left",
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                      fontFamily: "Montserrat",
                                    }}
                                  >
                                    <Grid container>
                                      <Grid item xs={12}>
                                        Question {index + 1}: {title}
                                      </Grid>
                                      <Grid item xs={12}>
                                        Answer {index + 1}: {answer}
                                      </Grid>
                                    </Grid>
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <div>
                                <MenuItem
                                  key={index}
                                  style={{
                                    display: "flex",
                                    justifyContent: "left",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                    fontFamily: "Montserrat",
                                    fontWeight: 600,
                                  }}
                                >
                                  No data to display.
                                </MenuItem>
                              </div>
                            )}
                          </Popover>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {}
              </TableBody>
            }
          </Table>
        </TableContainer>

        <Grid container spacing={2} sx={{ padding: "10px" }}>
          <Grid item xs={7}>
            <Box display="flex" alignItems={"center"} flexDirection="row">
              <Typography
                sx={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                Row Per Page
              </Typography>
              <FormControl variant="standard" sx={{ m: 0, minWidth: 50 }}>
                <Select
                  size="small"
                  value={selecteds}
                  onChange={selectionChangeHandler}
                  disableUnderline
                  style={{
                    textAlign: "center",
                    fontSize: "14px",
                    paddingLeft: 10,
                    paddingTop: 8,
                    ":before": {
                      borderColor: "#fafafc",
                    },
                    ":after": {
                      borderColor: "#fafafc",
                    },
                    height: 30,
                    justifyContent: "center",
                  }}
                >
                  <MenuItem
                    value={1}
                    onClick={() => {
                      setMaxRow(10);
                    }}
                  >
                    10
                  </MenuItem>
                  <MenuItem
                    value={2}
                    onClick={() => {
                      setMaxRow(20);
                    }}
                  >
                    20
                  </MenuItem>
                  <MenuItem
                    value={3}
                    onClick={() => {
                      setMaxRow(50);
                    }}
                  >
                    50
                  </MenuItem>
                  <MenuItem
                    value={4}
                    onClick={() => {
                      setMaxRow(100);
                    }}
                  >
                    100
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={4} sx={{ marginLeft: "40px" }}>
            <Pagination
              style={{ marginTop: "2%", marginLeft: "5%" }}
              onChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={pages}
              rowsPerPage={rowsPerPage}
              count={pageCount || 0}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
