import React from 'react';


const AvatarComp = props => {
  return (
    <div className={`avatar ${props.className}`} style={props.style}>
      <img
      onClick={props.onClick}
        src={props.image}
        alt={props.alt}
        style={{ width: props.width, height: props.height ,tintColor:props.tintColor }}
      />
    </div>
  );
};

export default AvatarComp;
