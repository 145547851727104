import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import API from "../../../E2E/Axios.Utils";
import FileSaver from "file-saver";

const DownloadReport = (props) => {
  const [csvData, setCsvData] = useState([]);
  const data = props;

  const downloadCsv = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    await API.post(`/app/secure/export_dashboard`, data, config)
      .then((res) => {
        setCsvData(res.data.link);
        FileSaver.saveAs(res.data.link, "csvFile");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        sx={{
          bgcolor: "#2D404E",
          padding: "10px",
          borderRadius: "20px",
          width: "200px",
          height: "36px",
          fontFamily: "Montserrat",
          fontWeight: 600,
        }}
        onClick={downloadCsv}
      >
        Download Report
      </Button>
    </div>
  );
};
export default DownloadReport;
