import React from "react";
import { Box, Button, Typography, Link, Card, Grid } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Rectangle } from "../../component/Images";
import TextFieldcomp from "../../component/UIElements/TextFieldComp";
import { forgetPassword } from "../../thunk/Action";
import { useStyles } from "../login/Login.style";
import { Notification } from "../../Utils/SnackbarNotification/Notification";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("");

  const handleAlert = () => {
    setAlert(false);
  };

  const handleClick = (newState) => () => {
    if (!email) {
      setAlertContent("Enter email.");
      setAlertType("error");
      setAlert(true);
    } else if (
      email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) === null
    ) {
      setAlertContent("Enter valid username or email.");
      setAlertType("error");
      setAlert(true);
    } else {
      const request = {
        email: email,
        // password: password,
        onSuccess: (res) => {
          if (res?.status === 200) {
            // alert((res?.data?.message));
            setAlertContent(res?.data?.message);
            setAlertType("success");
            setAlert(true);
            localStorage.setItem("token", res?.data?.token);
            setTimeout(() => {
              navigate("/login");
            }, 500);
          }
        },
        onFail: (error) => {
          // setIsLoading(false);
          setAlertContent(error?.response?.data?.message);
          setAlertType("error");
          setAlert(true);
          if (error?.response?.status === 400) {
          }
        },
      };
      dispatch(forgetPassword(request));
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{
        backgroundImage: `url(${Rectangle})`,
        backgroundRepeat: "no-repeat",
        height: "100vh",
        backgroundSize: "cover",
      }}
    >
      <Card className={classes.cardStyle}>
        <div style={{ padding: "25px" }}>
          <Typography
            sx={{
              fontWeight: "800",
              fontSize: "20px",
              margin: "10px",
              textAlign: "center",
            }}
          >
            Forgot Password
          </Typography>
          <TextFieldcomp
            label="Username or email address *"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button
            variant="contained"
            color="secondary"
            sx={{
              padding: "8px",
              borderRadius: "10px",
              width: "100%",
              marginTop: "10px",
              textTransform: "none",
              background:
                "linear-gradient(93.39deg, #582FF9 1.3%, #AC2FF9 97.46%), #1B1C1D",
            }}
            onClick={handleClick({
              vertical: "bottom",
              horizontal: "center",
            })}
          >
            Submit
          </Button>
          <Grid container justifyContent="center" sx={{ mt: 2 }}>
            <Link href="/login" variant="body2">
              Sign In
            </Link>
          </Grid>

          <Notification
            message={
              alertContent
                ? alertContent
                : `Success : Data Fetched Successfully.`
            }
            open={alert}
            severity={alertType}
            onClose={handleAlert}
          />
        </div>
      </Card>
    </Box>
  );
};
export default ForgetPassword;
