import React from "react";
import { Button, Box, Grid } from "@mui/material";
import { useNavigate } from "react-router";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Grid container>
      <Grid item xs={12} sx={{ mt: "20%", ml: "43%" }}>
        <h1>Page Not Found</h1>
      </Grid>
      <Grid item xs={12} sx={{ ml: "50%" }}>
        <Button onClick={() => navigate("/login")} variant="contained">
          Login
        </Button>
      </Grid>
    </Grid>
  );
};

export default PageNotFound;
