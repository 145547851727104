import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import React, { useState } from "react";
import BatchTableOverview from "./OverviewComponents/BatchTableOverview";
import { Notification } from "../../../../../Utils/SnackbarNotification/Notification";

const BatchCardOverview = ({ data }) => {
  const [alert, setAlert] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  console.log("databatchcard", data);

  const handleAlert = () => {
    setAlert(false);
  };

  return (
    <>
      <Grid
        container
        sx={{
          mt: 4,
          gap: "3rem",
        }}
        // spacing={3}
      >
        <Grid Item>
          <Card sx={{ minWidth: 200 }}>
            <CardContent>
              <Typography sx={{ fontWeight: "bold" }}>
                Total User: {data.user_counts}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid Item>
          <Card sx={{ minWidth: 200 }}>
            <CardContent>
              <Typography sx={{ fontWeight: "bold" }}>
                Count of click: {data.Total_start_count}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid Item>
          <Card sx={{ minWidth: 200 }}>
            <CardContent>
              <Typography sx={{ fontWeight: "bold" }}>
                Number of email sent: {data.Total_email_count}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid Item>
          <Card sx={{ minWidth: 200 }}>
            <CardContent>
              <Typography sx={{ fontWeight: "bold" }}>
                Survey Submitted: {data.Total_finish_count}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6">Users</Typography>
        <Box sx={{ mt: 3 }}>
          <BatchTableOverview
            surveyScheduleID={data.survey_schedule_id}
            setAlert={setAlert}
            setAlertType={setAlertType}
            setAlertContent={setAlertContent}
          />
        </Box>
        <Notification
          message={
            alertContent ? alertContent : `Success : Data Fetched Successfully.`
          }
          open={alert}
          severity={alertType}
          onClose={handleAlert}
        />
      </Box>
    </>
  );
};

export default BatchCardOverview;

{
  /* <Grid Item>
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Grid container>
                    <Grid item>
                      <Typography sx={{ fontWeight: "bold" }}>
                        Survey name: {data.survey_id.name}
                      </Typography>
                    </Grid>
                    <Grid item sx={{ width: 60, height: 60, ml: 3 }}>
                      <CircularProgressbar value={66} text={`${66}%`} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid> */
}
