import React, { useState, useContext } from "react";
import MuiAppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import AvatarComp from "../UIElements/Avatar";
import Avatar from "@mui/material/Avatar";

import { logo, Search, Ellipse, downArrow } from "../Images";
import { useStyles } from "./Header.style";
import { Button, Container, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InputBox from "../UIElements/InputBox";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AuthContext } from "../../context/auth-context";
import { loginUser } from "../../thunk/Action";
import { useDispatch } from "react-redux";
// const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = () => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = React.useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handalLogout = () => {
    auth.logout();
    navigate("/login");
    localStorage.removeItem("token");
  };
  const [name, setName] = React.useState("");
  const [role, setRole] = React.useState("");

  // const getApicall = () => {
  //   const request = {
  //     email: email,
  //     password: password,
  //     onSuccess: (res) => {
  //       if (res?.status === 200) {
  //         // alert((res?.data?.message));

  //         setMessage(res?.data?.message);
  //         console.log("email::::",res?.data?.first_name)
  //         localStorage.setItem("token", res?.data?.token);
  //         setEmail(res?.data?.email)

  //       }
  //     },
  //     onFail: (error) => {
  //       // setIsLoading(false);

  //       setMessage(error?.response?.data?.message);
  //       if (error?.response?.status === 400) {
  //         console.log("bad request");
  //         // Alert.alert(strings.error, error?.response?.data?.message);
  //       }
  //     },
  //   };
  //   dispatch(loginUser(request));
  // };
  // React.useEffect(() => {
  //   getApicall();
  // }, []);

  React.useEffect(() => {
    var firstname = localStorage.getItem("firstname");
    setName(firstname);
    // console.log("firstname++++++++++++++++++++", JSON.stringify(firstname));

    // var token1 = localStorage.getItem("role");
    // setName(token);
    // console.log("DATA++++++++++++++++++++", JSON.stringify(token));

    var role = localStorage.getItem("role");
    setRole(role);
    // console.log("role++++++++++++++++++++", JSON.stringify(role));
  }, []);

  const handleProfile = () => {
    navigate("/profile");
    handleClose();
  };
  return (
    <Box className={classes.root}>
      <AppBar
        position="fixed"
        sx={{
          height: "80px",
          display: "flex",
          justifyContent: "center",
          background:
            "linear-gradient(91.25deg, #394857 -28.42%, #00212A 117.39%)",
        }}
      // open={open}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container rowSpacing={2}>
              <Grid item xs={6} style={{ paddingLeft: "50px" }}>
                <Box className={classes.logo}>
                  <AvatarComp image={logo} width="" height="67px" />
                </Box>
              </Grid>
              <Grid item xs={6} sx={{ marginLeft: "auto" }}>
                <Box
                  // display="flex"
                  // alignItems="center"
                  justifyContent="flex-end"
                  // flexDirection="row"
                  className={classes.notificationBox}
                >
                  <NotificationsNoneIcon sx={{ marginRight: 2 }} />
                  <Box
                    className={classes.dropdownBox}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    flexDirection="row"
                  >
                    {/* <Avatar image={Ellipse} /> */}
                    <Avatar>{name[0]}</Avatar>
                    <Box flexDirection="column">
                      <Typography
                        sx={{
                          color: "black",
                          marginLeft: 1,
                          fontSize: "14px",
                          fontFamily: "Montserrat",
                          fontWeight: "700",
                        }}
                      >
                        {name}
                      </Typography>
                      <Typography
                        sx={{
                          color: "black",
                          marginLeft: 1,
                          fontSize: "14px",
                          fontFamily: "Montserrat",
                          fontWeight: "400",
                        }}
                      >
                        {role}
                      </Typography>
                    </Box>
                    <IconButton
                      sx={{ marginLeft: "30px" }}
                      onClick={handleClick}
                    >
                      <AvatarComp image={downArrow} />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem onClick={handleProfile}>Profile</MenuItem>
                      <MenuItem onClick={handalLogout}>Logout</MenuItem>
                    </Menu>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
