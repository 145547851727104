import React, { useState, useEffect } from "react";
import FormElement from "../../../component/formElement/formElement";
import { useDispatch } from "react-redux";
import {
  getEmployeeList,
  createEmployeeList,
  getSortbyEmployeeList,
} from "../../../thunk/Action";
import Card from "../../../component/UIElements/Card";
import { useStyles } from "./updateEmployee.style";
import { backArrow, rightArrowWhite } from "../../../component/Images";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import InputTextField from "../../../component/UIElements/InputTextField";
import DatePicker from "react-datepicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText, Snackbar, TextField } from "@mui/material";
import { getCustomerList } from "../../../thunk/Action";
import { useNavigate } from "react-router-dom";
import MobileNumber from "../../../component/UIElements/MobileNumber";
import API from "../../../E2E/Axios.Utils";
import moment from "moment";
import { Notification } from "../../../Utils/SnackbarNotification/Notification";

const UpdateEmployee = (props) => {
  console.log("props", props);
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const dispatch = useDispatch();
  const [data, setData] = React.useState([]);

  const token = localStorage.getItem("token").toString();

  const getOrganisationData = () => {
    API.get("/app/secure/list_customer?page=1&max_rows=1000", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setData(res.data.customer_list);
      })
      .catch((err) => {});
  };
  React.useEffect(() => {
    getOrganisationData();
  }, []);

  const [isValidMobile, setIsValidMobile] = useState(true);
  const [dialCode, setDialCode] = useState("");
  const [mobile_numberC, setMobile_numberC] = React.useState("");

  /**
   * This function is trigger when user fill the mobile input field
   * @param phone
   * @param validPhoneNumber
   * @param code
   * update the state value of user phone, isValidMobile, dialCode
   */
  const updateMobileNumber = (phone, validPhoneNumber, code, e) => {
    setIsValidMobile(validPhoneNumber);
    setDialCode(code);
    props.onChangemobile_number(phone);
  };

  const [step, setStep] = useState(0);
  const [errorf, setErrorf] = useState(false);
  const [Validfnameerror, setValidfnameerror] = useState(false);
  const [Validlnameerror, setValidlnameerror] = useState(false);
  const [ValidTypeofDepterror, setValidTypeofDepterror] = useState(false);
  const [validEmailerror, setValidEmailError] = useState(false);
  const [validmobilenumbererror, setValidmobilenumbererror] = useState(false);
  const [Validfunctionerror, setValidfunctionerror] = useState(false);
  const [Validregionerror, setValidregionerror] = useState(false);
  const [Validcentererror, setValidcentererror] = useState(false);
  const [Validlocationerror, setValidlocationerror] = useState(false);
  const [Validtypeofroleerror, setValidtypeofroleerror] = useState(false);
  const [Validmanagernameerror, setValidmanagernameerror] = useState(false);
  const [Validmanagertenureerror, setValidmanagertenureerror] = useState(false);
  const [Validsizeofteamerror, setValidsizeofteamerror] = useState(false);
  const [Validrecruitementchannelerror, setValidrecruitementchannelerror] =
    useState(false);
  const [Valideducationerror, setValideducationerror] = useState(false);
  const [Validworkexperianceerror, setValidworkexperianceerror] =
    useState(false);

  const [Validlandlinenumbererror, setValidlandlinenumbererror] =
    useState(false);
  const [
    ValidemergencycontactnumberCerror,
    setValidemergencycontactnumberCerror,
  ] = useState(false);
  const [validDesignationerror, setValidDesignationError] = useState(false);

  const handlenext1 = () => {
    if (
      !props.fname ||
      !props.lname ||
      !props.gender ||
      !props.selected ||
      !props.email_id ||
      !props.grade ||
      !props.designation ||
      !props.department ||
      !props.functionvar ||
      !props.mobile_number
    ) {
      setErrorf(true);
    } else if (isValidMobile === false) {
      setIsValidMobile(false);
    } else if (
      props.fname.match(/^[A-Za-z](([_ -])?[A-Za-z]*)+$/) === null ||
      props.fname.length > 50
    ) {
      setValidfnameerror(true);
    } else if (
      props.lname.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) === null ||
      props.lname.match(/^[0-9]+$/) !== null ||
      props.lname.length > 50
    ) {
      setValidlnameerror(true);
    } else if (
      props.functionvar.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) ===
        null ||
      props.functionvar.match(/^[0-9]+$/) !== null ||
      props.functionvar.length > 50
    ) {
      setValidfunctionerror(true);
    } else if (props.email_id.match(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/) === null) {
      setValidEmailError(true);
    } else if (props.designation.match(/^[a-zA-Z]+(?: [a-zA-Z]+)?$/) === null) {
      setValidDesignationError(true);
    } else if (props?.landline_number?.length !== 0) {
      if (
        props?.landline_number?.match(/^[0-9]+$/) === null ||
        props?.landline_number?.length > 10 ||
        props?.landline_number?.length < 10
      ) {
        setValidlandlinenumbererror(true);
      } else if (props?.emergency_contact_number?.length !== 0) {
        if (
          props?.emergency_contact_number?.match(/^[0-9]+$/) === null ||
          props?.emergency_contact_number?.length > 10 ||
          props?.emergency_contact_number?.length < 10
        ) {
          setValidemergencycontactnumberCerror(true);
        } else {
          setStep(1);
          setValidemergencycontactnumberCerror(false);
        }
      } else {
        setStep(1);
        setErrorf(false);
      }
    } else {
      setStep(1);
      setErrorf(false);
    }
  };
  const handlenext2 = () => {
    if (
      !props.location ||
      !props.date_of_joining_the_company ||
      !props.maneger_name
    ) {
      setErrorf(true);
    } else if (
      props.location.match(/^[A-Za-z](([_ -])?[A-Za-z]*)+$/) === null ||
      props.location.length > 50
    ) {
      setValidlocationerror(true);
    } else if (props?.region?.length !== 0) {
      if (
        props?.region?.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) === null ||
        props?.region?.match(/^[0-9]+$/) !== null ||
        props?.region?.length > 50
      ) {
        setValidregionerror(true);
      } else if (props?.type_of_role?.length !== 0) {
        if (
          props?.type_of_role?.match(/^[A-Za-z](([_ -])?[A-Za-z]*)+$/) ===
            null ||
          props?.type_of_role?.length > 50
        ) {
          setValidtypeofroleerror(true);
        } else {
          setStep(2);
          setValidtypeofroleerror(false);
        }
      } else if (props?.center?.length !== 0) {
        if (
          props?.center?.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) ===
            null ||
          props?.center?.match(/^[0-9]+$/) !== null ||
          props?.center?.length > 50
        ) {
          setValidcentererror(true);
        } else {
          setStep(2);
          setValidcentererror(false);
        }
      } else {
        setValidregionerror(false);
        setStep(2);
      }
    } else {
      setErrorf(false);
      setStep(2);
    }
  };
  const handlenext3 = () => {
    if (
      !props.manager_gender ||
      !props.manager_tenure_in_organisation ||
      !props.is_first_time_manager ||
      !props.recruitment_channel ||
      !props.highest_education ||
      !props.type_of_shift ||
      !props.org_id
    ) {
      setErrorf(true);
    } else if (
      props.manager_tenure_in_organisation.match(/^[0-9]+$/) === null ||
      props.manager_tenure_in_organisation.length > 50
    ) {
      setValidmanagertenureerror(true);
    } else if (
      props.recruitment_channel.match(
        /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
      ) === null ||
      props.recruitment_channel.match(/^[0-9]+$/) !== null ||
      props.recruitment_channel.length > 50
    ) {
      setValidrecruitementchannelerror(true);
    } else if (
      props.highest_education.match(/^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/) ===
        null ||
      props.highest_education.match(/^[0-9]+$/) !== null ||
      props.highest_education.length > 50
    ) {
      setValideducationerror(true);
    } else if (props?.total_work_experience?.length !== 0) {
      if (
        props?.total_work_experience?.match(/^[0-9]+$/) === null ||
        props?.total_work_experience?.length > 50
      ) {
        setValidworkexperianceerror(true);
      } else {
        console.log("hiii im here else");
        props.onClick();
      }
    } else {
      console.log("hiii im here else");
      props.onClick();
    }
  };

  const classes = useStyles();

  return (
    <Box>
      <Typography
        className={classes.backBtn}
        onClick={() => props.setOpenDialog(false)}
      >
        <img src={backArrow} /> Back
      </Typography>

      <header className={classes.steperHeader}>
        <h4 className={`title ${step === 0 && "activeStep"}`}>Step 1</h4>
        <img src={rightArrowWhite} />
        <h4 className={`title ${step === 1 && "activeStep"}`}>Step 2</h4>
        <img src={rightArrowWhite} />
        <h4 className={`title ${step === 2 && "activeStep"}`}>Step 3</h4>
      </header>

      {step === 0 && (
        <Card className={classes.cardStyle}>
          <Grid container spacing={3}>
            <InputTextField
              error={
                errorf && !props.fname
                  ? true
                  : Validfnameerror &&
                    (props.fname.match(/^[A-Za-z](([_ -])?[A-Za-z]*)+$/) ===
                      null ||
                      props.fname.length > 50)
                  ? true
                  : false
              }
              helperText={
                errorf && !props.fname
                  ? "Required First Name."
                  : Validfnameerror &&
                    (props.fname.match(/^[A-Za-z](([_ -])?[A-Za-z]*)+$/) ===
                      null ||
                      props.fname.length > 50)
                  ? "First name must be at most 50 characters and enter alphabatic value."
                  : ""
              }
              element="TextField"
              label="First Name Of The Employee"
              value={props.fname}
              onChange={props.onChangefname}
            />
            <InputTextField
              required
              error={
                errorf && !props.lname
                  ? true
                  : Validlnameerror &&
                    (props.lname.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.lname.match(/^[0-9]+$/) !== null ||
                      props.lname.length > 50)
                  ? true
                  : false
              }
              helperText={
                errorf && !props.lname
                  ? "Required Last Name."
                  : Validlnameerror &&
                    (props.lname.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.lname.match(/^[0-9]+$/) !== null ||
                      props.lname.length > 50)
                  ? "Last name must be at most 50 characters and enter alphanumeric value."
                  : ""
              }
              element="TextField"
              label="Last Name Of The Employee"
              value={props.lname}
              onChange={props.onChangelname}
            />
            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <FormControl
                fullWidth
                error={errorf && !props.gender ? true : false}
              >
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.gender}
                  label="Gender"
                  onChange={props.onChangegender}
                >
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"transgender"}>Transgender</MenuItem>
                  <MenuItem value={"prefer not to respond"}>
                    Prefer not to respond
                  </MenuItem>
                </Select>
                <FormHelperText>
                  {errorf && !props.gender ? "Required Gender." : ""}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <div
                className={[
                  errorf && !props.selected
                    ? classes.datepickerError
                    : classes.datepicker,
                ]}
              >
                {props.selected ? (
                  <p
                    class="MuiFormHelperText-sizeMedium css-1wc848c-MuiFormHelperText-root"
                    style={{ marginBottom: "-24px" }}
                  >
                    Date Of Birth
                  </p>
                ) : null}
                <TextField
                  type="date"
                  style={{ width: "100%" }}
                  format={"YYYY/MM/DD"}
                  name="Date of Birth"
                  onChange={props.onChange}
                  InputProps={{
                    inputComponent: InputComponent,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={moment(props.selected).format("YYYY-MM-DD")}
                />

                {errorf && !props.selected ? (
                  <p class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
                    Required Date Of Birth.
                  </p>
                ) : null}
              </div>
            </Grid>

            <Grid item md={props.md_label12 || 6} xs={12}>
              <MobileNumber
                required
                phone={props.mobile_number}
                updateMobileNumber={updateMobileNumber}
                isValidMobile={isValidMobile}
                errorf={errorf}
                mobile_numberC={props.mobile_number}
                employee
              />
              {!props.mobile_number && errorf ? (
                <span
                  style={{
                    color: "#F44336",
                    fontSize: "12px",
                    position: "relative",
                    left: "15px",
                  }}
                >
                  Required Mobile.
                </span>
              ) : (
                !isValidMobile && (
                  <span
                    style={{
                      color: "#F44336",
                      fontSize: "12px",
                      position: "relative",
                      left: "15px",
                    }}
                  >
                    Enter valid Mobile
                  </span>
                )
              )}
            </Grid>

            <InputTextField
              error={
                props?.landline_number?.length !== 0
                  ? Validlandlinenumbererror &&
                    (props.landline_number.match(/^[0-9]+$/) === null ||
                      props.landline_number.length > 10 ||
                      props.landline_number.length < 10)
                    ? true
                    : false
                  : false
              }
              helperText={
                props?.landline_number?.length !== 0
                  ? Validlandlinenumbererror &&
                    (props.landline_number.match(/^[0-9]+$/) === null ||
                      props.landline_number.length > 10 ||
                      props.landline_number.length < 10)
                    ? "Enter valid landline number."
                    : ""
                  : ""
              }
              element="TextField"
              label="Landline Number"
              value={props.landline_number}
              onChange={props.onChangelandline_number}
            />
            <InputTextField
              error={
                props?.emergency_contact_number?.length !== 0
                  ? ValidemergencycontactnumberCerror &&
                    (props.emergency_contact_number.match(/^[0-9]+$/) ===
                      null ||
                      props.emergency_contact_number.length > 10 ||
                      props.emergency_contact_number.length < 10)
                    ? true
                    : false
                  : false
              }
              helperText={
                props?.emergency_contact_number?.length !== 0
                  ? ValidemergencycontactnumberCerror &&
                    (props.emergency_contact_number.match(/^[0-9]+$/) ===
                      null ||
                      props.emergency_contact_number.length > 10 ||
                      props.emergency_contact_number.length < 10)
                    ? "Enter valid emergency contact number."
                    : ""
                  : ""
              }
              element="TextField"
              label="Emergency Contact Number"
              value={props.emergency_contact_number}
              onChange={props.onChangeemergency_contact_number}
            />
            <InputTextField
              required
              error={
                errorf && !props.email_id
                  ? true
                  : validEmailerror &&
                    props.email_id.match(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/) ===
                      null
                  ? true
                  : false
              }
              helperText={
                errorf && !props.email_id
                  ? "Required Email id."
                  : validEmailerror &&
                    props.email_id.match(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/) ===
                      null
                  ? "Enter Valid Email"
                  : ""
              }
              element="TextField"
              label="Email id"
              value={props.email_id}
              onChange={props.onChangeemail_id}
            />
            <InputTextField
              required
              error={errorf && !props.grade ? true : false}
              helperText={errorf && !props.grade ? "Required Grade." : ""}
              element="TextField"
              label="Grade"
              value={props.grade}
              onChange={props.onChangegrade}
            />
            <InputTextField
              required
              error={
                errorf && !props.designation
                  ? true
                  : validDesignationerror &&
                    props.designation.match(/^[a-zA-Z]+(?: [a-zA-Z]+)?$/) ===
                      null
                  ? true
                  : false
              }
              helperText={
                errorf && !props.designation
                  ? "Required Designation."
                  : validDesignationerror &&
                    props.designation.match(/^[a-zA-Z]+(?: [a-zA-Z]+)?$/) ===
                      null
                  ? "Enter valid designation."
                  : ""
              }
              element="TextField"
              label="Designation"
              value={props.designation}
              onChange={props.onChangedesignation}
            />
            <InputTextField
              error={errorf && !props.department ? true : false}
              helperText={
                errorf && !props.department ? "Required Department." : ""
              }
              element="TextField"
              label="Department"
              value={props.department}
              onChange={props.onChangedepartment}
            />
            <InputTextField
              required
              error={
                errorf && !props.functionvar
                  ? true
                  : Validfunctionerror &&
                    (props.functionvar.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.functionvar.match(/^[0-9]+$/) !== null ||
                      props.functionvar.length > 50)
                  ? true
                  : false
              }
              helperText={
                errorf && !props.functionvar
                  ? "Required Function."
                  : Validfunctionerror &&
                    (props.functionvar.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.functionvar.match(/^[0-9]+$/) !== null ||
                      props.functionvar.length > 50)
                  ? "Enter valid function."
                  : ""
              }
              element="TextField"
              label="Function"
              value={props.functionvar}
              onChange={props.onChangefunctionvar}
            />
          </Grid>

          <Grid container spacing={3} style={{}}>
            <Grid
              item
              md={props.md_label19 || 12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "15px",
              }}
            >
              <Button onClick={handlenext1} variant="contained">
                Next
              </Button>
            </Grid>
          </Grid>
        </Card>
      )}
      {step === 1 && (
        <Card className={classes.cardStyle}>
          <Grid container spacing={3}>
            <InputTextField
              error={
                props?.type_of_role?.length !== 0
                  ? Validtypeofroleerror &&
                    (props?.type_of_role?.match(
                      /^[A-Za-z](([_ -])?[A-Za-z]*)+$/
                    ) === null ||
                      props?.type_of_role?.length > 50)
                    ? true
                    : false
                  : false
              }
              helperText={
                props?.type_of_role?.length !== 0
                  ? Validtypeofroleerror &&
                    (props?.type_of_role?.match(
                      /^[A-Za-z](([_ -])?[A-Za-z]*)+$/
                    ) === null ||
                      props?.type_of_role?.length > 50)
                    ? "Enter valid type of role."
                    : ""
                  : ""
              }
              element="TextField"
              label="Type Of Role"
              value={props.type_of_role}
              onChange={props.onChangetype_of_role}
            />
            <InputTextField
              error={
                props?.region?.length !== 0
                  ? Validregionerror &&
                    (props?.region?.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props?.region?.match(/^[0-9]+$/) !== null ||
                      props?.region?.length > 50)
                    ? true
                    : false
                  : false
              }
              helperText={
                props?.region?.length !== 0
                  ? Validregionerror &&
                    (props?.region?.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props?.region?.match(/^[0-9]+$/) !== null ||
                      props?.region?.length > 50)
                    ? "Enter valid region."
                    : ""
                  : ""
              }
              element="TextField"
              label="Region"
              value={props.region}
              onChange={props.onChangeregion}
            />
            <InputTextField
              required
              error={
                errorf && !props.location
                  ? true
                  : Validlocationerror &&
                    (props.location.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.location.match(/^[0-9]+$/) !== null ||
                      props.location.length > 50)
                  ? true
                  : false
              }
              helperText={
                errorf && !props.location
                  ? "Required Location."
                  : Validlocationerror &&
                    (props.location.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props.location.match(/^[0-9]+$/) !== null ||
                      props.location.length > 50)
                  ? "Enter valid location."
                  : ""
              }
              element="TextField"
              label="Location"
              value={props.location}
              onChange={props.onChangelocation}
            />
            <InputTextField
              error={
                props?.center?.length !== 0
                  ? Validcentererror &&
                    (props?.center?.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props?.center?.match(/^[0-9]+$/) !== null ||
                      props?.center?.length > 50)
                    ? true
                    : false
                  : false
              }
              helperText={
                props?.center?.length !== 0
                  ? Validcentererror &&
                    (props?.center?.match(
                      /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                    ) === null ||
                      props?.center?.match(/^[0-9]+$/) !== null ||
                      props?.center?.length > 50)
                    ? "Enter valid center."
                    : ""
                  : ""
              }
              element="TextField"
              label="Center"
              value={props.center}
              onChange={props.onChangecenter}
            />

            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <div
                className={[
                  errorf && !props.date_of_joining_the_company
                    ? classes.datepickerError
                    : classes.datepicker,
                ]}
              >
                {props.date_of_joining_the_company ? (
                  <p
                    class="MuiFormHelperText-sizeMedium css-1wc848c-MuiFormHelperText-root"
                    style={{ marginBottom: "-24px" }}
                  >
                    Date Of Joining The Company
                  </p>
                ) : null}
                <TextField
                  type="date"
                  style={{ width: "100%" }}
                  format={"YYYY/MM/DD"}
                  name="Date Of Joining The Company"
                  inputFormat="yyyy/MM/dd"
                  onChange={props.onChangedate_of_joining_the_company}
                  InputProps={{
                    inputComponent: InputComponent,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={moment(
                    props.date_of_joining_the_company
                  ).format("YYYY-MM-DD")}
                />

                {errorf && !props.date_of_joining_the_company ? (
                  <p class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wc848c-MuiFormHelperText-root">
                    Required Date Of Joining The Company.
                  </p>
                ) : null}
              </div>
            </Grid>
            <InputTextField
              element="TextField"
              label="Employee's Last Performance Rating"
              value={props.employees_last_performance_rating}
              onChange={props.onChangeemployees_last_performance_rating}
            />
            <InputTextField
              element="TextField"
              label="Employee's Current Performance Rating"
              value={props.employees_current_performance_rating}
              onChange={props.onChangeemployees_current_performance_rating}
            />

            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <div className={[classes.datepicker]}>
                {props.last_promotion_date ? (
                  <p
                    class="MuiFormHelperText-sizeMedium css-1wc848c-MuiFormHelperText-root"
                    style={{ marginBottom: "-24px" }}
                  >
                    Date of Last Promotion
                  </p>
                ) : null}
                <TextField
                  type="date"
                  style={{ width: "100%" }}
                  format={"YYYY/MM/DD"}
                  name="Date of Last Promotion"
                  onChange={props.onChangelast_promotion_date}
                  InputProps={{
                    inputComponent: InputComponent,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={
                    props.last_promotion_date == "Invalid date"
                      ? moment("YYYY-MM-DD").format("YYYY-MM-DD")
                      : moment(props.last_promotion_date).format("YYYY-MM-DD")
                  }
                />
              </div>
            </Grid>
            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Promoted Internally
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.promoted_internally}
                  label="Promoted Internally"
                  onChange={props.onChangepromoted_internally}
                >
                  <MenuItem value={"yes"}>Yes</MenuItem>
                  <MenuItem value={"no"}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {props.promoted_internally === "yes" ? (
              <Grid item md={props.md || 6} xs={props.xs || 12}>
                <div className={classes.datepicker}>
                  {props.if_yes_last_promotion_date ? (
                    <p
                      class="MuiFormHelperText-sizeMedium css-1wc848c-MuiFormHelperText-root"
                      style={{ marginBottom: "-24px" }}
                    >
                      Date of Last Promotion
                    </p>
                  ) : null}
                  <TextField
                    type="date"
                    style={{ width: "100%" }}
                    format={"YYYY/MM/DD"}
                    name="Date of Last Promotion"
                    onChange={props.onChangeif_yes_last_promotion_date}
                    InputProps={{
                      inputComponent: InputComponent,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    defaultValue={moment(
                      props.if_yes_last_promotion_date
                    ).format("YYYY-MM-DD")}
                  />
                </div>
              </Grid>
            ) : null}
          </Grid>

          <Grid container spacing={3} style={{}}>
            <Grid
              item
              md={props.md_label19 || 12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <Button variant="outlined" onClick={() => setStep(0)}>
                Previous
              </Button>
              <Button variant="contained" onClick={handlenext2}>
                Next
              </Button>
            </Grid>
          </Grid>
        </Card>
      )}
      {step === 2 && (
        <Card className={classes.cardStyle}>
          <Grid container spacing={3}>
            <InputTextField
              required
              error={
                errorf && !props.maneger_name
                  ? true
                  : Validmanagernameerror &&
                    (props.maneger_name.match(
                      /^[A-Za-z](([_ -])?[A-Za-z]*)+$/
                    ) === null ||
                      props.maneger_name.length > 50)
                  ? true
                  : false
              }
              helperText={
                errorf && !props.maneger_name
                  ? "Required Manager / Supervisor Name."
                  : Validmanagernameerror &&
                    (props.maneger_name.match(
                      /^[A-Za-z](([_ -])?[A-Za-z]*)+$/
                    ) === null ||
                      props.maneger_name.length > 50)
                  ? "Enter valid Manager / Supervisor Name."
                  : ""
              }
              element="TextField"
              label="Manager Name"
              value={props.maneger_name}
              onChange={props.onChangemaneger_name}
            />

            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <FormControl
                fullWidth
                error={errorf && !props.manager_gender ? true : false}
              >
                <InputLabel id="demo-simple-select-label">
                  Manager / Supervisor's gender *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.manager_gender}
                  label="Manager / Supervisor's gender *"
                  onChange={props.onChangemanager_gender}
                >
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"transgender"}>Transgender</MenuItem>
                  <MenuItem value={"prefer not to respond"}>
                    Prefer not to respond
                  </MenuItem>
                </Select>
                <FormHelperText>
                  {errorf && !props.manager_gender
                    ? "Required Manager / Supervisor's Gender."
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <InputTextField
              required
              error={
                errorf && !props.manager_tenure_in_organisation
                  ? true
                  : Validmanagertenureerror &&
                    (props.manager_tenure_in_organisation.match(/^[0-9]+$/) ===
                      null ||
                      props.manager_tenure_in_organisation.length > 50)
                  ? true
                  : false
              }
              helperText={
                errorf && !props.manager_tenure_in_organisation
                  ? "Required Manager Tenure In Organisation."
                  : Validmanagertenureerror &&
                    (props.manager_tenure_in_organisation.match(/^[0-9]+$/) ===
                      null ||
                      props.manager_tenure_in_organisation.length > 50)
                  ? "Enter valid Manager Tenure In Organisation."
                  : ""
              }
              element="NumberField"
              label="Manager Tenure In Organisation"
              value={props.manager_tenure_in_organisation}
              onChange={props.onChangemanager_tenure_in_organisation}
            />
            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <FormControl
                fullWidth
                error={errorf && !props.is_first_time_manager ? true : false}
              >
                <InputLabel id="demo-simple-select-label">
                  Is the Manager / Supervisor a first time manager? *
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Is the Manager / Supervisor a first time manager? *"
                  value={props.is_first_time_manager}
                  onChange={props.onChangeis_first_time_managerC}
                >
                  <MenuItem value={"yes"}>Yes</MenuItem>
                  <MenuItem value={"no"}>No</MenuItem>
                </Select>
                <FormHelperText>
                  {errorf && !props.is_first_time_manager
                    ? "Required First Time Manager."
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>

            <InputTextField
              element="NumberField"
              label="Size Of Team"
              value={props.size_of_team}
              onChange={props.onChangesize_of_team}
            />

            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <FormControl
                fullWidth
                error={
                  errorf && !props.recruitment_channel
                    ? true
                    : Validrecruitementchannelerror &&
                      (props.recruitment_channel.match(
                        /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                      ) === null ||
                        props.recruitment_channel.match(/^[0-9]+$/) !== null ||
                        props.recruitment_channel.length > 50)
                    ? true
                    : false
                }
              >
                <InputLabel id="demo-simple-select-label">
                  Recruitment Channel *
                </InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.recruitment_channel}
                  label="Recruitment Channel *"
                  onChange={props.onChangerecruitment_channel}
                >
                  <MenuItem value={"employee referrals"}>
                    Employee Referrals
                  </MenuItem>
                  <MenuItem value={"facebook"}>Facebook</MenuItem>
                  <MenuItem value={"linkedIn"}>LinkedIn</MenuItem>
                  <MenuItem value={"twitter"}>Twitter</MenuItem>
                  <MenuItem value={"career portal"}>Career Portal</MenuItem>
                  <MenuItem value={"placement agencies"}>
                    Placement Agencies
                  </MenuItem>
                </Select>
                <FormHelperText>
                  {errorf && !props.recruitment_channel
                    ? "Required Recruitment Channel."
                    : Validrecruitementchannelerror &&
                      (props.recruitment_channel.match(
                        /^[a-zA-Z0-9](([_ -])?[a-zA-Z0-9]*)+$/
                      ) === null ||
                        props.recruitment_channel.match(/^[0-9]+$/) !== null ||
                        props.recruitment_channel.length > 50)
                    ? "Enter valid Recruitment Channel."
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>

            <InputTextField
              element="TextField"
              label="Previous Employer"
              value={props.previous_employer}
              onChange={props.onChangeprevious_employer}
            />

            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <FormControl
                fullWidth
                error={errorf && !props.highest_education ? true : false}
              >
                <InputLabel id="demo-simple-select-label">
                  Highest Education *
                </InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={props.highest_education}
                  label="Highest Education *"
                  onChange={props.onChangehighest_education}
                >
                  <MenuItem value={"high school"}>High School</MenuItem>
                  <MenuItem value={"college"}>College</MenuItem>
                  <MenuItem value={"graduate"}>Graduate</MenuItem>
                  <MenuItem value={"post graduate"}>Post Graduate</MenuItem>
                  <MenuItem value={"phd"}>PhD</MenuItem>
                </Select>
                <FormHelperText>
                  {errorf && !props.highest_education
                    ? "Required highest education."
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>

            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <FormControl
                fullWidth
                error={errorf && !props.type_of_shift ? true : false}
              >
                <InputLabel id="demo-simple-select-label">
                  Type of Shift The Team Works In *
                </InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.type_of_shift}
                  label="Type of Shift The Team Works In *"
                  onChange={props.onChangetype_of_shift}
                >
                  <MenuItem value={"day"}>Day</MenuItem>
                  <MenuItem value={"night"}>Night</MenuItem>
                  <MenuItem value={"evening"}>Evening</MenuItem>
                  <MenuItem value={"morning"}>Morning</MenuItem>
                </Select>
                <FormHelperText>
                  {errorf && !props.type_of_shift
                    ? "Required Type Of Shift."
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <InputTextField
              error={
                props?.total_work_experience?.length !== 0
                  ? Validworkexperianceerror &&
                    (props?.total_work_experience?.match(/^[0-9]+$/) === null ||
                      props?.total_work_experience?.length > 50)
                    ? true
                    : false
                  : false
              }
              helperText={
                props?.total_work_experience?.length !== 0
                  ? Validworkexperianceerror &&
                    (props?.total_work_experience?.match(/^[0-9]+$/) === null ||
                      props?.total_work_experience?.length > 50)
                    ? "Enter valid Total Work Experience"
                    : ""
                  : ""
              }
              element="NumberField"
              label="Total Work Experience"
              value={props.total_work_experience}
              onChange={props.onChangetotal_work_experience}
            />

            <Grid item md={props.md || 6} xs={props.xs || 12}>
              <FormControl
                fullWidth
                error={errorf && !props.org_id ? true : false}
              >
                <InputLabel id="demo-simple-select-label">
                  ORGANISATION
                </InputLabel>

                <Select
                  error={errorf && !props.org_id ? true : false}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={props.org_id}
                  label="ORGANISATION"
                  onChange={props.onChangeorg_id}
                >
                  {data.map((item) => {
                    console.log(props);
                    return (
                      <MenuItem value={item.org_id}>
                        {item.client_entity_name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>
                  {errorf && !props.org_id ? "Required organisation." : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <div>
            <Notification
              message={props.alertContent}
              open={props.alert}
              severity={props.alertType}
              onClose={props.handleAlert}
            />
          </div>

          <Grid container spacing={3} style={{}}>
            <Grid
              item
              md={props.md_label19 || 12}
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <Button variant="outlined" onClick={() => setStep(1)}>
                Previous
              </Button>
              <Button
                variant="contained"
                className={classes.btnStyle}
                onClick={handlenext3}
              >
                Update Employee
              </Button>
            </Grid>
          </Grid>
        </Card>
      )}
    </Box>
  );
};

export default UpdateEmployee;

function InputComponent({ defaultValue, inputRef, ...props }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(() =>
    props.value || defaultValue == "Invalid date" ? "YYYY-MM-DD" : defaultValue
  );

  const handleChange = (event) => {
    setValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <div style={{ position: "relative", width: "90%" }}>
      <div
        style={{
          position: "absolute",
          top: 2,
          left: 0,
          bottom: 2,
          background: "white",
          pointerEvents: "none",
          right: 45,
          display: "flex",

          justifyContent: "center",
          paddingLeft: 15,
          flexDirection: "column",
        }}
      >
        <span
          style={{
            color: "rgba(0, 0, 0, 0.54)",
            padding: 0,
            fontSize: "0.8rem",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontWeight: 400,
            lineHeight: 1,
            letterSpacing: " 0.00938em",
          }}
        >
          {props.name}
        </span>
        <span>{value}</span>
      </div>
      <input
        className={classes.input}
        ref={inputRef}
        {...props}
        onChange={handleChange}
        value={value}
      />
    </div>
  );
}
