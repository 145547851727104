import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  titleText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#0B0B0C",
  },
  root: {
    "& .css-1191obr-MuiPaper-root-MuiAppBar-root": {
      // width: 'calc(100% - 240px)',
      width: "100%",
    },
    "& .MuiDrawer-paperAnchorLeft": {
      marginTop: "80px",
    },
    background: "linear-gradient(91.25deg, #394857 -28.42%, #00212A 117.39%)",
  },
  drawerOnOff: {
    position: "absolute",
    width: "46px",
    height: "46px",
    borderRadius: "50%",
    "& .MuiButtonBase-root": {
      backgroundColor: "#FFC20E",
    },
  },
  box_Style: {
    padding: "15px 10px !important",
    backgroundColor: "#fff",
    borderRadius: "5px",

    display: "flex",
    justifyContent: "space-between",
    // alignItems: "center",

    "& .titles": {
      display: "flex",
      gap: "30px",
      alignItems: "center",
    },
    "& .title": {
      fontFamily: "'Montserrat'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
      marginBottom: "20px",
    },
    "& .value": {
      fontFamily: "'Montserrat'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "10px",
      lineHeight: "15px",
      color: "#000000",
      marginBottom: "20px",
    },
    "& .subTitle": {
      fontFamily: "'Montserrat'",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "15px",
      color: "#000000",
      marginBottom: "0",
    },
    "& .CircularProgressbar ": {
      width: "60px",
      height: "60px",
      backgroundColor: "#BEDFFA",
      borderRadius: "50%",
    },
    "& .CircularProgressbar-path ": {
      stroke: "#128CB0",
    },
    "& .CircularProgressbar-trail ": {
      stroke: "#BEDFFA",
    },
    "& .CircularProgressbar-text ": {
      fill: "#000000",
      fontFamily: "'Montserrat'",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "15px",
    },
  },
  contentContainer: {
    display: "flex",
  },
  tileLabel: {
    margin: 0,
    fontWeight: "700",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#1D2D3A",
    textTransform: "uppercase",
  },
  sectionTitle: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "29px",
    color: "black",
  },
  sectionSubTitle: {
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    marginBottom: "10px",
  },
  dashboardContainer: {
    background: "#EEEEEE",
    paddingTop: "40px",
    paddingLeft: "48px",
    paddingRight: "48px",
    paddingBottom: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  gridContainer: {
    background: "#FFFFFF",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
  },
  gridLandDContainer: {
    background: "#FFFFFF",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    height: "220px",
  },
  gridContainerEmailCount: {
    background: "#FFFFFF",
    padding: "20px",
    height: "150px",
    display: "flex",
    flexDirection: "column",
  },
  gridContainerEmployee: {
    background: "#FFFFFF",
    padding: "20px",
    height: "130px",
    display: "flex",
    flexDirection: "column",
  },
  gridContainerTeam: {
    background: "#FFFFFF",
    padding: "20px",
    height: "200px",
    display: "flex",
    flexDirection: "column",
  },
  gridContainerDiversity: {
    background: "#FFFFFF",
    padding: "20px",
    height: "200px",
    display: "flex",
    flexDirection: "column",
  },
  gridContainerWorkLifeBalance: {
    background: "#FFFFFF",
    padding: "20px",
    height: "200px",
    display: "flex",
    flexDirection: "column",
  },
  gridContainerRole: {
    background: "#FFFFFF",
    padding: "20px",
    height: "200px",
    display: "flex",
    flexDirection: "column",
  },
  gridContainerManager: {
    background: "#FFFFFF",
    padding: "20px",
    height: "220px",
    display: "flex",
    flexDirection: "column",
  },
  gridContainerDrivers: {
    background: "#FFFFFF",
    padding: "20px",
    height: "100px",
  },
  dashboardTitle: {
    fontSize: "36px",
  },
  filterTitle: {
    fontWeight: "700",
    fontSize: "16px",
  },
  selectDropdown: {
    transition: "none !important",
  },
  filterOptionsContainer: {
    padding: "26px 10px",
  },
  filterName: {
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "15px",
    color: "#FFFFFF",
    marginBottom: "7px",
  },
  employeeCount: {
    fontWeight: "400",
    fontSize: "36px",
    margin: 0,
    marginTop: "12px",
  },
  reminderEmailTitle: {
    fontWeight: "700",
    fontSize: "12px",
    color: "#5368F0",
  },
  reminderEmailValue: {
    fontWeight: "600",
    fontSize: "15px",
    color: "#1D2D3A",
  },
  npsScoreEqual: {
    fontWeight: "600",
    fontSize: "40px",
  },
  npsScore: {
    fontWeight: "700",
    fontSize: "64px",
    color: "#1D2D3A",
  },
  npsInfo: {
    fontWeight: "500",
    fontSize: "12px",
  },
  calenderInput: {
    padding: "10px",
    backgroundColor: "white",
    width: "190px",

    "& input": {
      padding: "10px",
      height: "1.6878em",
      fontSize: "14px",
    },
  },
  alignCenterClass: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
});
export { useStyles };
